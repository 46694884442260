/* eslint-disable no-unused-expressions,no-plusplus,no-underscore-dangle */
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Tabs,
  Tab,
  Sorter,
  ItemMenu,
  Button,
  TextInput,
  Container,
  Row,
  Col,
  ContractCard,
  Pagination,
  toaster,
  InvoiceCard
} from "cf-neo-ui";
import { Query, Mutation } from "react-apollo";
import { withI18n } from "@lingui/react";
import { Trans, t, plural } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import classes from "./styles.module.scss";
import { GET_CONTRACTS_QUERY } from "./query";
import { EXPORT_CONTRACT_PDF, CONTACTER } from "./mutations";
import LoadingMissions from "../../components/LoadingMissions/LoadingMissions";
import { isOnePage } from "../../utils/helpers";
import theme from "../../configs/theme";
@inject("appStore", "sessionStore")
@observer
class FilteredDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      limit: 6,
      skip: 0,
      activeTab: "Contrats",
      search: "",
      isVisible: false,
      statusFilter: "",
      contractFilter: "",
      currentPageForContracts: 1,
      skipAccessibleContracts: 0,
      pageSize: this.setPageSize(props.appStore.width),
      isLoadingData: false,
      envelopeToDownload: ""
    };
    this.total = 0;
  }

  timeConverter = timestamp => {
    const a = new Date(timestamp);
    const months = [
      "January",
      "February",
      "Mar",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const humanDate = ` ${month} ${year} `;
    return humanDate;
  };
  renderPagination = dataLength => {
    const { pageSize, currentPageForContracts } = this.state;
    const paginationProps = {
      pageSize,
      total: dataLength,
      current: currentPageForContracts,
      onChange: current => {
        this.setState({
          currentPageForContracts: current,
          skipAccessibleContracts: (current - 1) * pageSize
        });
      }
    };

    return <Pagination {...paginationProps} />;
  };
  setPageSize = width => {
    if (width >= 1440) return 6;
    if (width >= 1024) return 6;
    if (width >= 768) return 6;
    return 2;
  };

  getPdfToDownload = (mutation, envelopId) => {
    if (envelopId) {
      mutation({ variables: { envelopeId: envelopId } });
    }
  };

  downloadPdf = async data => {
    const { result, title } = data;
    const a = document.createElement("a");
    a.href = `data:application/octet-stream;base64,${encodeURI(result)}`;
    a.download = title;
    a.click();
  };

  getQueryVariables = () => {
    const {
      limit,
      skip,
      contractFilter,
      statusFilter,
      pageSize,
      skipAccessibleContracts
    } = this.state;
    let data = {
      limit,
      skip,
      filter: {}
    };
    if (contractFilter !== "") {
      data.filter.search = contractFilter;
    }
    if (statusFilter !== "") {
      data.filter.status = statusFilter;
    }
    data.limit = pageSize;
    data.skip = skipAccessibleContracts;
    return data;
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({
      contractFilter: e.target.value,
      skipAccessibleContracts: 0
    });
    this.total = 0;
  };

  handleEnterPressed = e => {
    e.preventDefault();
    this.setState({
      contractFilter: e.target.value,
      skipAccessibleContracts: 0
    });
    this.total = 0;
  };

  render() {
    const { sessionStore, i18n } = this.props;
    const { activeTab } = this.state;
    let Contrats = i18n._(t`Contrats`);
    var url = "";
    const { cardsCount, pageSize } = this.state;
    let QUERY = GET_CONTRACTS_QUERY;

    if (typeof window !== "undefined") {
      url = window.location.href;
    }
    return (
      <div className={classes.content}>
        <div className={classes.pageHeader}>
          <div className={classes.title}>
            <h1
              text={i18n._(t`Documents`)}
              menu
              positionMenu="left"
              chevronSize={14}
              chevronColor="#000000"
              classNameText="font-style: bold;
                             font-weight: bold;
                             font-size: 1000px;
                             line-height: 35px;
                             color: #000000;"
              underline
            >
              <Trans>Documents</Trans>
            </h1>
          </div>
          <div className={classes.searchBar}>
            <TextInput
              id="mi_sb"
              placeholder={i18n._(t`Chercher un document...`)}
              onEnterPressed={e => this.handleEnterPressed(e)}
              size="small"
              onChange={e => this.handleChange(e)}
              icon="search"
              clickableIcon={false}
              className={classes.search}
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
              radius={10}
            />
          </div>
        </div>

        <Query
          query={QUERY}
          variables={this.getQueryVariables()}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading)
              return (
                <LoadingMissions
                  cardsCount={cardsCount}
                  xl={3}
                  lg={3}
                  md={4}
                  sm={6}
                  xs={6}
                  colClassName={classes.favedMissionsCard}
                  containerClassName={classes.marTop}
                />
              );

            if (error) {
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );
            }
            if (data) {
              return (
                <div>
                  <Col xl={9} lg={9} md={12} id="cccc">
                    <div className={classes.pageBody}>
                      <div className={classes.tab}>
                        <Tabs
                          className={classes.dashboardContracts}
                          defaultActiveTab="Contrats"
                          activeTab={activeTab}
                          type="transparentV2"
                          onChange={this.tabChangeHandler}
                        >
                          <Tab name="Contrats" tab={Contrats}>
                            <div>
                              <Container className={classes.marTop}>
                                <Row>
                                  {data.contracts.contracts.length < 1
                                    ? [
                                        i18n._(
                                          t`Vous n’avez pas encore de document`
                                        )
                                      ]
                                    : data.contracts.contracts.map(contract => {
                                        const contractObject = {
                                          id: contract.placementId,
                                          mission: contract.title,
                                          title: contract.companyName,
                                          date: this.timeConverter(
                                            contract.dateBegin
                                          ),

                                          status:
                                            contract.status === "completed"
                                              ? i18n._(t`Signé`)
                                              : i18n._(t`En attente`),

                                          colorStatus:
                                            contract.status === "sent"
                                              ? "orange"
                                              : "green"
                                        };

                                        return (
                                          <Col
                                            key={
                                              data.contracts.id ||
                                              data.contracts._id
                                            }
                                            lg={3}
                                            md={4}
                                            sm={6}
                                            xs={6}
                                            className={
                                              classes.favedMissionsCard
                                            }
                                          >
                                            <Mutation
                                              mutation={EXPORT_CONTRACT_PDF}
                                              onCompleted={data => {
                                                this.downloadPdf(
                                                  data.exportContractPDF
                                                )
                                                  .then(() => {
                                                    this.setState({
                                                      isLoadingData: false
                                                    });
                                                  })
                                                  .catch(error => {
                                                    console.log("error", error);
                                                  });
                                              }}
                                              onError={() =>
                                                toaster.error({
                                                  title: `${i18n._(
                                                    t`Erreur`
                                                  )} !`,
                                                  description: i18n._(
                                                    t`Un probleme de téléchargement de contrat`
                                                  )
                                                })
                                              }
                                            >
                                              {exportContractPDF => (
                                                <ContractCard
                                                  contract={contractObject}
                                                  isLoadingData={
                                                    this.state.isLoadingData &&
                                                    contract.envelopId ===
                                                      this.state
                                                        .envelopeToDownload
                                                      ? true
                                                      : false
                                                  }
                                                  clickPDF={() => {
                                                    this.setState({
                                                      isLoadingData: true,
                                                      envelopeToDownload:
                                                        contract.envelopId
                                                    });
                                                    return this.getPdfToDownload(
                                                      exportContractPDF,
                                                      contract.envelopId
                                                    );
                                                  }}
                                                />
                                              )}
                                            </Mutation>
                                          </Col>
                                        );
                                      })}
                                </Row>
                              </Container>

                              {isOnePage(
                                pageSize,
                                data.contracts.contracts
                              ) ? null : (
                                <div className={classes.pagination}>
                                  {this.renderPagination(data.contracts.total)}
                                </div>
                              )}
                            </div>
                          </Tab>
                        </Tabs>
                      </div>

                      <div className={classes.filter}>
                        {activeTab === "Contrats" ? (
                          <Sorter
                            text={
                              this.state.statusFilter == ""
                                ? i18n._(t`Filtrer par statut`)
                                : i18n._(this.state.statusFilter)
                            }
                            menu
                            positionMenu="right"
                            onChange={() => {}}
                          >
                            <ItemMenu
                              className={classes.item}
                              label={i18n._(t`Signé`)}
                              value="Signé"
                              onClick={() =>
                                this.setState({
                                  statusFilter: "completed",
                                  skipAccessibleContracts: 0
                                })
                              }
                            />
                            <ItemMenu
                              className={classes.item}
                              label={i18n._(t`En attente`)}
                              value="En attente"
                              onClick={() =>
                                this.setState({
                                  statusFilter: "sent",
                                  skipAccessibleContracts: 0
                                })
                              }
                            />
                            <ItemMenu
                              className={classes.item}
                              label={i18n._(t`tous`)}
                              value=""
                              onClick={() =>
                                this.setState({
                                  statusFilter: "",
                                  skipAccessibleContracts: 0
                                })
                              }
                            />
                          </Sorter>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={3} md={4}>
                    <InvoiceCard
                      invoice={{
                        title: {
                          ...(sessionStore.account.role === "CLIENT"
                            ? [i18n._(t`Une question d'ordre juridique ?`)]
                            : [i18n._(t`Pas de modèle de facture?`)])
                        },
                        titledownloadfacture: {
                          ...(sessionStore.account.role === "CLIENT"
                            ? [i18n._(t`Contactez notre équipe "Legal"`)]
                            : [
                                i18n._(
                                  t`Télécharger le template de facture de Mindquest`
                                )
                              ])
                        },
                        downloadBtn: {
                          ...(sessionStore.account.role === "CLIENT"
                            ? [i18n._(t`Contacter`)]
                            : [i18n._(t`Télécharger`)])
                        }
                      }}
                      typeIcon={
                        sessionStore.account.role === "CLIENT"
                          ? "chevron-right"
                          : "download"
                      }
                      className={classes.InvoiceCard}
                      downloadOrContact={() => {
                        {
                          sessionStore.account.role === "CLIENT"
                            ? window.open("mailto:legal@minquest.io", "_blank")
                            : window.open(
                                "https://visite.mindquest.io/kit-demarrage-freelance",
                                "_blank"
                              );
                        }
                      }}
                    />
                  </Col>
                </div>
              );
            }
          }}
        </Query>
      </div>
    );
  }
}

FilteredDocuments.wrappedComponent.propTypes = {
  appStore: PropTypes.shape({
    width: PropTypes.number,
    changeUrl: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  limit: PropTypes.number.isRequired,
  skip: PropTypes.number.isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  })
};

export default withI18n()(withRouter(FilteredDocuments));
