import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Divider, Modal, SourcerContactCard, toaster } from "cf-neo-ui";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react/index";
import { withRouter } from "react-router-dom";
import classes from "./SingleCandidateBodyLeftSlide.module.scss";
import { t } from "@lingui/macro";
import SingleCandidateModal from "./SingleCandidateModal/SingleCandidateModal";
import Routes from "../../../layout/routes";
import routes from "../../../layout/routes/hbLayoutRoutes";
import ModalContactRequest from "../../../dashboard-single-candidate/ModalContactRequest";
import { ApolloConsumer, Mutation } from "react-apollo";
import CREATE_CLIENT_INTEREST from "../../../dashboard-single-candidate/mutation";
import { LOGOUT_MUTATION } from "../../../layout/hbfLayout/mutation";
import { UPDATE_Talent_POOL_MUTATION } from "../../../search-candidates-page/SearchCandidatesBody/candidates-list/mutation";

@inject("sessionStore")
@observer
class SingleCandidateBodyLeftSlide extends Component {
  constructor(props) {
    super(props);
    this.listKey = 0;
  }

  closeModal = () => {
    this.props.closeModal();
  };

  closeModal2 = () => {
    this.props.closeModal2();
  };

  contact = () => {
    const { sessionStore, candidate, history } = this.props;
    sessionStore.setContactPageEmail({
      email: candidate.email,
      name: candidate.name
    });
    history.push(routes.contact.path);
  };

  toLogin = () => {
    const { id, sessionStore, history } = this.props;
    sessionStore.setBackRef(id);
    history.push(Routes.HbLayoutRoutes.Login.path);
  };

  linkedInIconHandler = url => {
    window.open(url, "_blank");
  };

  displayCandidateCard = i18n => {
    const { sessionStore, candidate, loading: loadingGeneral } = this.props;
    let iconClickHandler = null;
    let link = "";
    if (typeof window !== "undefined") {
      link = window.location.pathname;
      link = link.split("/")[1];
    }

    if (candidate.linkedIn) {
      iconClickHandler = () => this.linkedInIconHandler(candidate.linkedIn);
    }

    if (
      sessionStore.authToken &&
      sessionStore.account.role === "CLIENT" &&
      link === "talent"
    ) {
      const {
        client,
        logout,
        email,
        companyName,
        lastName,
        firstName,
        companyID
      } = sessionStore;
      const clientID = client._id;
      let mutationName;
      let mutationVariables = {};
      if (candidate.existInSearchTalentPool) {
        mutationName = CREATE_CLIENT_INTEREST;
        mutationVariables.clientId = clientID;
        mutationVariables.candidateBhId = Number(candidate.id);
        //mutationVariables.message = "voir la disponibilté du candidat";
      } else {
        mutationName = UPDATE_Talent_POOL_MUTATION;
        mutationVariables.poolName = "Mes recherches de talent IT";
        mutationVariables.clientID = clientID;
        mutationVariables.clientEmail = email;
        mutationVariables.clientCompanyName = companyName;
        mutationVariables.clientName = firstName + " " + lastName;
        mutationVariables.sourceTalentPool = "search";
        mutationVariables.creatorID = clientID;
        mutationVariables.shared = true;
        mutationVariables.clientCompanyId = companyID;
        mutationVariables.talentID = Number(candidate.id);
      }

      return loadingGeneral ? null : (
        <ApolloConsumer>
          {apolloClient => (
            <Mutation
              mutation={mutationName}
              variables={mutationVariables}
              onError={errors => {
                errors.graphQLErrors.forEach(({ message, data }) => {
                  if (message === "UNAUTHENTICATED") {
                    apolloClient.mutate({
                      mutation: LOGOUT_MUTATION,
                      variables: { token: sessionStore.authToken }
                    });
                    logout();
                  }
                  if (data && data.isCustomError) {
                    this.onErrorHandler(message);
                  }
                });
              }}
            >
              {mutation => (
                <SourcerContactCard
                  avatar={candidate.avatar}
                  name={candidate.firstName}
                  position={candidate.occupation}
                  description={candidate.description}
                  onApply={
                    !candidate.existInSearchTalentPool
                      ? () => {
                          this.props.clickHandler();
                          mutation();
                        }
                      : () => {
                          this.props.clickHandler2();
                          mutation();
                        }
                  }
                  remote={candidate.contractType}
                  msg={
                    i18n._(t`Vous souhaitez connaitre la disponibilité de `) +
                    " " +
                    candidate.firstName +
                    " " +
                    i18n._(t`et qualifier son profil ?`)
                  }
                  employementPreference={candidate.employmentPreference}
                  address={candidate.address?.city}
                  applyButtonLabel={
                    !candidate.existInSearchTalentPool
                      ? i18n._(t`Ajouter à mes viviers`)
                      : i18n._(t`Contacter notre équipe Mindquest`)
                  }
                  textButton={candidate.existInSearchTalentPool}
                  showButton={!candidate.existInSearchTalentPool}
                />
              )}
            </Mutation>
          )}
        </ApolloConsumer>
      );
    } else if (
      sessionStore.authToken &&
      sessionStore.account.role === "CANDIDATE"
    ) {
      return loadingGeneral ? null : (
        <SourcerContactCard
          avatar={candidate.avatar}
          name={candidate.name}
          email={candidate.email}
          emailClick={this.contact}
          position={candidate.occupation}
          phoneNumber={candidate.phoneNumber}
          description={candidate.description}
          onIconClick={iconClickHandler}
          showButton={false}
          textButton={false}
        />
      );
    } else {
      return loadingGeneral ? null : (
        <SourcerContactCard
          avatar={candidate.avatar}
          name={candidate.id}
          position={candidate.occupation}
          description={candidate.description}
          onApply={this.toLogin}
          applyButtonLabel={i18n._(t`Contacter`)}
          showButton={false}
          textButton={false}
        />
      );
    }
  };

  render() {
    const { i18n, className, id, isVisible, isVisible2 } = this.props;
    return (
      <div className={[classes.slideBar, className].join(" ")}>
        <Modal
          centred
          isVisible={isVisible}
          withCloseButton={false}
          onClose={() => this.closeModal()}
          position="fixed"
        >
          <SingleCandidateModal close={this.closeModal} candidateBhId={id} />
        </Modal>

        <Modal isVisible={isVisible2} withCloseButton={false} position="fixed">
          <div
            style={{
              width: "35%",
              margin: "auto",
              position: "fixed",
              top: "22%",
              left: "33%"
            }}
          >
            <ModalContactRequest close={this.closeModal2} candidateBhId={id} />
          </div>
        </Modal>

        <section>{this.displayCandidateCard(i18n)}</section>
      </div>
    );
  }
}

SingleCandidateBodyLeftSlide.wrappedComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  candidate: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    avatar: PropTypes.string,
    occupation: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    description: PropTypes.string,
    linkedIn: PropTypes.string
  }).isRequired,
  id: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  className: PropTypes.string
};

SingleCandidateBodyLeftSlide.defaultProps = {
  className: ""
};

export default withI18n()(withRouter(SingleCandidateBodyLeftSlide));
