const theme = {
  fontFamily: `'Lato', sans-serif`,
  defaultAvatar: "/defaultAvatar.webp",
  colors: {
    mainColor: "#BF142A",
    mainColorGradient: "#FA324A",
    lightMainColor: "#FA1937",
    lightMainColorGradient: "#FA3249"
  }
};

export default theme;
