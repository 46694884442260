import React, { Component } from "react";
import { IconCircle } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import classes from "./footer.module.scss";
import configs from "../../../configs/club-freelance-globals";
import runtimeVars from "../../../configs/runTimeVars";
import { inject } from "mobx-react";
import * as PropTypes from "prop-types";

@inject("appStore")
class AboutWidget extends Component {
  openPage = provider => {
    window.open(configs.socialMedia[provider], "_blank");
  };

  render() {
    const { appStore } = this.props;
    const { currentLanguage } = appStore;
    return (
      <div className={classes.widget}>
        <div>
          <div className={classes.logo}>
            <picture>
              <source
                type="images/webp"
                // srcSet="/assets/images/EXE_LOGO_MINDQUEST.webp"
              />
              <img
                src="/assets/images/EXE_LOGO_MINDQUEST.svg"
                alt="Mindquest"
                width={145}
                height={"100%"}
              />
            </picture>
          </div>
          <div className={classes.description}>
            <span>
              <Trans>Mission pour Freelances</Trans>
            </span>
            {/* <span> */}
            {/*  <Trans>informatique et consultants IT</Trans> */}
            {/* </span> */}
          </div>
          <div className={classes.contactDetails}>
            <span>{configs.contactEmail}</span>
            <span>{configs.phoneNumber[runtimeVars.APP_LANG]}</span>
          </div>
          <div className={classes.social}>
            <IconCircle
              type="twitter"
              dimension={40}
              innerColor="#fff"
              iconWidth={20}
              iconHeight={16}
              onClick={() =>
                currentLanguage === "fr"
                  ? this.openPage("twitter")
                  : this.openPage("twitter_en")
              }
            />
            <IconCircle
              type="linked-in"
              dimension={40}
              innerColor="#fff"
              iconWidth={17}
              iconHeight={16}
              onClick={() => this.openPage("linkedIn")}
            />
          </div>
        </div>
      </div>
    );
  }
}
AboutWidget.propTypes = {
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  })
};
export default AboutWidget;
