import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { I18nProvider } from "@lingui/react";
import NewsBanner from "./news-banner/NewsBanner";
import Join from "./join/Join";
import Companies from "./companies/Companies";
import Footer from "../footer/Footer";
import BlogHeader from "./blog-header/BlogHeader";
import BlogHeaderEn from "./blog-header-en/BlogHeader";
import catalogEn from "../../../locales/en/messages";
import catalogFr from "../../../locales/fr/messages";

const catalogs = {
  en: catalogEn,
  fr: catalogFr
};

@inject("appStore")
@observer
class BlogLayout extends Component {
  render() {
    const { component, appStore } = this.props;
    const { currentLanguage } = appStore;
    const RenderComponent = component;
    return (
      <div>
        <I18nProvider language={currentLanguage} catalogs={catalogs}>
          {currentLanguage === "fr" ? <BlogHeader /> : <BlogHeaderEn />}
          <RenderComponent />
          <NewsBanner />
          <Join />
          <Companies />
          <Footer />
        </I18nProvider>
      </div>
    );
  }
}

BlogLayout.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

export default BlogLayout;
