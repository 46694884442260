import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { Button, Tabs, Tab, toaster, Spinner, Sorter } from "cf-neo-ui";
import { Trans, t } from "@lingui/macro";
import { withApollo } from "react-apollo";
import { inject, observer } from "mobx-react";
import classes from "./Styles.module.scss";
import Activities from "./activities/Activities";
import AccessibleActivities from "./accessible-activities/Activities";
import staticMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import {
  CLIENT_STATS_CSV_QUERY,
  ACCESSIBLE_CLIENT_STATS_CSV_QUERY
} from "../queries";

@inject("statsStore", "sessionStore")
@observer
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activated: "activities"
    };
  }

  download = e => {
    e.preventDefault();

    const { client, statsStore, sessionStore, i18n } = this.props;
    if (statsStore.emptyData) {
      if (statsStore.dateStart && statsStore.dateEnd) {
        toaster.warning({
          title: i18n._(t`Aucune donnée à exporter`),
          description: i18n._(t`Pas d’activité pendant la periode sélectionnée`)
        });
      } else {
        toaster.warning({
          title: i18n._(t`Aucune donnée à exporter`),
          description: i18n._(t`Pas d’activité jusqu’à maintenant`)
        });
      }
      return;
    }

    this.setState({ loading: true });

    const res = client.query({
      query: statsStore.requestAll
        ? ACCESSIBLE_CLIENT_STATS_CSV_QUERY
        : CLIENT_STATS_CSV_QUERY,
      variables: {
        id: Number.parseInt(sessionStore.id, 10),
        dateStart: statsStore.dateStart,
        dateEnd: statsStore.dateEnd
      },
      fetchPolicy: "no-cache"
    });
    res
      .then(response => {
        const { data } = response;
        if (data) {
          this.setState({ loading: false });
          toaster.info({
            title: i18n._(t`Fichier généré avec succès`),
            description: i18n._(t`le fichier vous a été envoyé par email`)
          });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        toaster.error({
          title: i18n._(t`Oops!`),
          description: i18n._(t`Nous n’avons pas pu générer le fichier demandé`)
        });
      });
  };

  tabChangeHandler = activated => {
    const { statsStore } = this.props;
    statsStore.clear();
    statsStore.setRequestAll(activated === "accessible-activities");
    this.setState({ activated });
  };

  render() {
    const { i18n } = this.props;
    const { loading, activated } = this.state;
    const meta = staticMetaTags(i18n).dashboardCompany;

    return (
      <div className={classes.bodyCard}>
        {metaTags(
          urls.dashboardCompany(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          null,
          null,
          false
        )}
        <div className={classes.titleSection}>
          <div className={classes.sorterWithoutMenu}>
            <Sorter
              text={i18n._(t`Statistiques`)}
              menu={false}
              onChange={() => {}}
              chevronSize={0.00001}
              chevronColor="#000000"
              classNameText="font-style: normal;
                            font-weight: bold;
                            font-size: 36px;
                            color: #000000;
                            line-height: 1;"
              underline
            />
          </div>

          {loading ? (
            <Button
              key="1"
              variant="tertiary"
              role="button"
              type="button"
              className={classes.exportButton}
            >
              <Spinner
                type="pointed-circular"
                color="blue"
                size={8}
                bg="#8d0417"
              />
            </Button>
          ) : (
            <Button
              key="2"
              variant="tertiary"
              role="button"
              type="button"
              className={classes.exportButton}
              onClick={this.download}
            >
              <Trans>Exporter en CSV</Trans>
            </Button>
          )}
        </div>

        <Tabs
          defaultActiveTab="activities"
          activeTab={activated}
          onChange={activeTab => this.tabChangeHandler(activeTab)}
        >
          <Tab name="activities" tab={i18n._(t`Activité sur vos offres`)}>
            <Activities />
          </Tab>
          <Tab
            name="accessible-activities"
            tab={i18n._(t`Activité sur toutes les offres`)}
          >
            <AccessibleActivities />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

Index.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  statsStore: PropTypes.shape({
    dateStart: PropTypes.number,
    dateEnd: PropTypes.number,
    emptyData: PropTypes.bool,
    requestAll: PropTypes.bool,
    setRequestAll: PropTypes.func,
    clear: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  client: PropTypes.shape({
    query: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(withApollo(Index)));
