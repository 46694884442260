import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { I18nProvider, withI18n } from "@lingui/react";
import { H1, H2, TextInput, Button, Spinner } from "cf-neo-ui";
import { Trans, t } from "@lingui/macro";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import MostViewed from "../blog-cards-display/BlogCardsDisplay";
import LoadingMostViewed from "../blog-cards-display/LoadingBlogCardDisplay";
import classes from "./Search.module.scss";
import { categoriesRefEn, categoriesRefFr, latestPostsQuery } from "../query";
import staticPagesMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import ldJson from "./ldJson";
import catalogEn from "../../../locales/en/messages";
import PlusButton from "../plus-button/PlusButton";
import catalogFr from "../../../locales/fr/messages";

const catalogs = {
  en: catalogEn,
  fr: catalogFr
};
@inject("appStore")
@observer
class Search extends Component {
  constructor(props) {
    super(props);
    this.latestPostsPage = 1;
    this.inputValue = "";
    this.state = {
      landing: true,
      input: ""
    };
  }

  search = () => {
    if (!this.inputValue) this.setState({ landing: true });
    else this.setState({ landing: false, input: this.inputValue });
  };

  changeHandler = e => {
    this.inputValue = e.target.value.replace(/\s+/g, " ");
  };

  showMore = fetchMore => {
    const { appStore } = this.props;
    const { currentLanguage } = appStore;
    let categoriesRef = categoriesRefFr;
    if (currentLanguage === "en") categoriesRef = categoriesRefEn;
    this.latestPostsPage += 1;
    return fetchMore({
      fetchPolicy: "cache-and-network",
      query: latestPostsQuery,
      variables: {
        search: this.inputValue,
        notifyOnNetworkStatusChange: true,
        page: this.latestPostsPage,
        perPage: 9,
        language: currentLanguage,
        orderBy: "relevance"
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...prev, posts: [...prev.posts, ...fetchMoreResult.posts] };
      }
    });
  };

  render() {
    const { i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    const { landing, input } = this.state;
    const meta =
      currentLanguage === "fr"
        ? staticPagesMetaTags(i18n).blog.search
        : staticPagesMetaTags(i18n).blogEn.search;
    return (
      <I18nProvider language={currentLanguage} catalogs={catalogs}>
        <div className={classes.limitedBody}>
          {metaTags(
            urls.search(),
            meta.title,
            meta.description,
            meta.openGraphImage.facebookOpenGraphImageUrl,
            meta.openGraphImage.linkedInOpenGraphImageUrl,
            meta.keywords,
            ldJson(i18n)
          )}
          <section>
            <H1 className={classes.heading}>
              <Trans>Recherche</Trans>
            </H1>
            <h2>
              <Trans>
                Recherchez un article sur le blog parmi notre sélection de
                contenus, actualités, conseil pratiques et ressources pour
                Talents et Décideurs IT.
              </Trans>
            </h2>
            <div className={classes.searchBar}>
              <TextInput
                placeholder={i18n._(
                  t`Search for all our articles and resources`
                )}
                onChange={this.changeHandler}
                onEnterPressed={this.search}
                // value={input}
              />
              <Button onClick={this.search}>
                <Trans>Rechercher</Trans>
              </Button>
            </div>
          </section>
          <Query
            query={latestPostsQuery}
            variables={{
              search: input,
              perPage: 9,
              page: 1,
              language: currentLanguage,
              orderBy: "relevance"
            }}
            skip={!input}
          >
            {({ loading, error, data, fetchMore }) => {
              if (error)
                return (
                  <div className="error_container">
                    <span>
                      {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                    </span>
                    <img
                      style={{
                        margin: 20
                      }}
                      src={"/assets/svg/download.svg"}
                      alt="error"
                    />
                  </div>
                );
              if (loading)
                return (
                  <Spinner
                    className={classes.spinner}
                    type="pointed-circular"
                    color="#c40b24"
                    size={120}
                  />
                );
              if (landing) {
                return (
                  <Query
                    query={latestPostsQuery}
                    variables={{ perPage: 6, language: currentLanguage }}
                  >
                    {({
                      loading: loadingMost,
                      error: errorMost,
                      data: dataMost
                    }) => {
                      if (errorMost) return <div>error most viewed</div>;
                      return (
                        <section>
                          <h5 className={classes.intro}>
                            <Trans>
                              Les
                              {` ${(dataMost &&
                                dataMost.posts &&
                                dataMost.posts.length) ||
                                6} `}
                              articles les plus lus cette semaine
                            </Trans>
                          </h5>
                          {loadingMost ? (
                            <LoadingMostViewed cardsNumber={6} />
                          ) : (
                            <MostViewed articles={dataMost.posts} />
                          )}
                        </section>
                      );
                    }}
                  </Query>
                );
              }

              const nResults =
                data && data.posts && data.posts.length
                  ? data.posts[0].total
                  : 0;

              if (!nResults) {
                return (
                  <section>
                    <h5 className={classes.intro}>
                      <Trans>
                        Aucun résultat pour votre recherche. Essayez avec
                        d’autres mots-clés.
                      </Trans>
                    </h5>
                  </section>
                );
              }
              if (nResults) {
                return (
                  <section>
                    <h5 className={classes.intro}>
                      {nResults === 1 ? (
                        <Trans>Un seul article trouvé</Trans>
                      ) : (
                        <Trans>
                          {`${nResults} `}
                          résultats sont trouvés
                        </Trans>
                      )}
                    </h5>
                    <MostViewed articles={data.posts} />
                    <section style={{ marginBottom: "20px" }}>
                      {!loading &&
                        data.posts.length &&
                        data.posts[0] &&
                        this.latestPostsPage < data.posts[0].totalPages && (
                          <PlusButton onClick={() => this.showMore(fetchMore)}>
                            <Trans>More articles</Trans>
                          </PlusButton>
                        )}
                    </section>
                  </section>
                );
              }

              return <div>something wrong happened</div>;
            }}
          </Query>
        </div>
      </I18nProvider>
    );
  }
}

Search.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(Search);
