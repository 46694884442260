import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Button, H3, Icon, Modal, toaster } from "cf-neo-ui";
import Routes from "../../../layout/routes";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import classes from "./SingleCandidateStickyHeader.module.scss";
import theme from "../../../../configs/theme";
import { t, Trans } from "@lingui/macro";
import SingleCandidateModal from "../../SingleCandidateBody/SingleCandidateBodyLeftSlide/SingleCandidateModal/SingleCandidateModal";
import SocialMedia from "../../../single-mission/SocialMedia/SocialMedia";
import { ApolloConsumer, Mutation } from "react-apollo";
import CREATE_CLIENT_INTEREST from "../../../dashboard-single-candidate/mutation";
import { LOGOUT_MUTATION } from "../../../layout/hbfLayout/mutation";
import { UPDATE_Talent_POOL_MUTATION } from "../../../search-candidates-page/SearchCandidatesBody/candidates-list/mutation";

@inject("sessionStore")
@observer
class SingleCandidateStickyHeader extends Component {
  constructor(props) {
    super(props);
    this.listKey = 0;
    /*this.state = {
      isVisible: false
    };*/
  }

  timeConverter = timestamp => {
    const a = new Date(timestamp);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const humanDate = `${date} ${month} ${year} `;
    return humanDate;
  };

  toLogin = () => {
    const { id, sessionStore, history } = this.props;
    sessionStore.setBackRef(id);
    history.push(Routes.HbLayoutRoutes.Login.path);
  };

  clickHandler = () => {
    /*this.setState({ isVisible: true });*/
    this.props.clickHandler();
  };

  clickHandler2 = () => {
    this.props.clickHandler2();
  };

  closeModal = () => {
    /*this.setState({ isVisible: false });*/
    this.props.closeModal();
  };
  closeModal2 = () => {
    this.props.closeModal2();
  };
  displayContactBtn = i18n => {
    const { sessionStore, existInSearchTalentPool, id } = this.props;
    let link = "";
    if (typeof window !== "undefined") {
      link = window.location.pathname;
      link = link.split("/")[1];
    }

    if (
      sessionStore.authToken &&
      sessionStore.account.role === "CLIENT" &&
      link === "talent"
    ) {
      const {
        client,
        logout,
        email,
        companyName,
        lastName,
        firstName,
        companyID
      } = sessionStore;

      let mutationName;
      let mutationVariables = {};
      const clientID = client._id;
      if (existInSearchTalentPool) {
        mutationName = CREATE_CLIENT_INTEREST;
        mutationVariables.clientId = clientID;
        mutationVariables.candidateBhId = id;
        // mutationVariables.message = "voir la disponibilté du candidat";
      } else {
        mutationName = UPDATE_Talent_POOL_MUTATION;
        mutationVariables.poolName = "Mes recherches de talent IT";
        mutationVariables.clientID = clientID;
        mutationVariables.clientEmail = email;
        mutationVariables.clientCompanyName = companyName;
        mutationVariables.clientName = firstName + " " + lastName;
        mutationVariables.sourceTalentPool = "search";
        mutationVariables.creatorID = clientID;
        mutationVariables.shared = true;
        mutationVariables.clientCompanyId = companyID;
        mutationVariables.talentID = id;
      }
      return (
        <ApolloConsumer>
          {apolloClient => (
            <Mutation
              mutation={mutationName}
              variables={mutationVariables}
              onError={errors => {
                errors.graphQLErrors.forEach(({ message, data }) => {
                  if (message === "UNAUTHENTICATED") {
                    apolloClient.mutate({
                      mutation: LOGOUT_MUTATION,
                      variables: { token: sessionStore.authToken }
                    });
                    logout();
                  }
                  if (data && data.isCustomError) {
                    this.onErrorHandler(message);
                  }
                });
              }}
            >
              {mutation => (
                <Button
                  size="small"
                  onClick={
                    !existInSearchTalentPool
                      ? () => {
                          this.props.clickHandler();
                          mutation();
                        }
                      : () => {
                          this.props.clickHandler2();
                          mutation();
                        }
                  }
                  icon="chevron-right"
                  name={i18n._(t`Contacter`)}
                >
                  {!existInSearchTalentPool ? (
                    <Trans>Ajouter à mes viviers</Trans>
                  ) : (
                    <Trans>Contacter</Trans>
                  )}
                </Button>
              )}
            </Mutation>
          )}
        </ApolloConsumer>
      );
    } else if (
      sessionStore.authToken &&
      sessionStore.account.role === "CANDIDATE"
    ) {
      return null;
    } else {
      return (
        <Button
          name={i18n._(t`Contacter`)}
          size="small"
          onClick={this.toLogin}
          icon="chevron-right"
        >
          <Trans>Contacter</Trans>
        </Button>
      );
    }
  };

  render() {
    const {
      id,
      occupation,
      city,
      employmentPreference,
      dateAvailable,
      availability,
      i18n
    } = this.props;
    let link = "";
    if (typeof window !== "undefined") {
      link = window.location.pathname;
      link = link.split("/")[1];
    }
    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          {/*<Modal
            centred
            isVisible={isVisible}
            withCloseButton={false}
            onClose={() => this.closeModal()}
            position="fixed"
          >
            <SingleCandidateModal close={this.closeModal} />
          </Modal>*/}
          <H3 className={classes.title}>{occupation}</H3>
          <div className={classes.info}>
            <div
              title={i18n._(t`Ville de résidence`)}
              className={classes.infoItem}
            >
              <Icon
                type="location"
                width={14}
                height={16}
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
              {city}
            </div>
            {link !== "talent" ? (
              <div
                title={i18n._(t`Remote/Présentiel`)}
                className={classes.infoItem}
              >
                <Icon
                  type="briefcase"
                  width={19}
                  height={16}
                  color={theme.colors.mainColor}
                  color2={theme.colors.lightMainColor}
                />
                {availability}
              </div>
            ) : null}
            {link !== "talent" ? (
              <div
                title={i18n._(t`Disponibilité`)}
                className={classes.infoItem}
              >
                <Icon
                  type="clock"
                  width={14}
                  height={16}
                  color={theme.colors.mainColor}
                  color2={theme.colors.lightMainColor}
                />
                <Trans>
                  À partir de
                  {this.timeConverter(dateAvailable)}
                </Trans>
              </div>
            ) : null}
            <div
              title={i18n._(t`Type de contrat`)}
              className={classes.infoItem}
            >
              <Icon
                type="adjust"
                width={16}
                height={16}
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
              {employmentPreference}
            </div>
            {link !== "talent" ? (
              <div className={classes.social}>
                <SocialMedia id={id} />
              </div>
            ) : null}
          </div>
          <div className={classes.applyButton}>
            {this.displayContactBtn(i18n)}
          </div>
        </div>
      </div>
    );
  }
}

SingleCandidateStickyHeader.wrappedComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  occupation: PropTypes.string,
  city: PropTypes.string,
  dateAvailable: PropTypes.string,
  employmentPreference: PropTypes.string,
  availability: PropTypes.string,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    setBackRef: PropTypes.func,
    authToken: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

SingleCandidateStickyHeader.defaultProps = {
  occupation: "",
  city: "",
  employmentPreference: "",
  availability: "",
  dateAvailable: ""
};

export default withI18n()(withRouter(SingleCandidateStickyHeader));
