import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react/index";
import CompanySection1 from "./CompanySection/CompanySection1";
import CardSection from "./CardSection/CardSection";
import classes from "./StyledMarketingLandingPage.module.scss";
import staticPagesMetaTags from "../../configs/staticPagesMetaTags";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import ldJson from "./ldJson";

class MarketingLandingPage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { i18n } = this.props;
    const meta = staticPagesMetaTags(i18n).home;

    return (
      <div style={{ width: "100%" }}>
        {metaTags(
          urls.home(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          ldJson(i18n)
        )}
        <div className={classes.image} />
        <CardSection />
        <CompanySection1 />
      </div>
    );
  }
}

MarketingLandingPage.propTypes = {
  i18n: PropTypes.shape({}).isRequired
};

export default withI18n()(MarketingLandingPage);
