import moment from "moment";
import cfConfig from "../../configs/club-freelance-globals";
import urls from "../../utils/urls";

const employemntTypes = (customText1, employmentType) => {
  const res = [];
  if (customText1)
    res.push(
      customText1.toLowerCase() === "full time" ? "FULL_TIME" : "PART_TIME"
    );

  if (employmentType)
    res.push(
      employmentType.toLowerCase() === "contract" ? "CONTRACTOR" : "TEMPORARY"
    );
  return res;
};

const ldJson = (i18n, jobOrder) => [
  {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: jobOrder.title,
    description: `<p>${jobOrder.customTextBlock1 || "Mindquest mission"}</p>`,
    identifier: {
      "@type": "PropertyValue",
      name: cfConfig.name,
      value: jobOrder.id
    },
    datePosted: moment(jobOrder.dateAdded).format("YYYY-MM-DD"),
    validThrough: moment(jobOrder.startDate).format("YYYY-MM-DD"),
    employmentType: employemntTypes(
      jobOrder.customText1,
      jobOrder.employmentType
    ),
    hiringOrganization: {
      "@type": "Organization",
      name: cfConfig.name,
      logo: "https://mindquest.io/mq.webp",
      sameAs: [
        urls.home(),
        cfConfig.socialMedia.linkedIn,
        cfConfig.socialMedia.twitter,
        cfConfig.socialMedia.facebook
      ]
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: jobOrder.address.address1 + jobOrder.address.address2,
        addressRegion: jobOrder.address.state,
        addressLocality: jobOrder.address.city,
        postalCode: jobOrder.address.zip,
        addressCountry: jobOrder.address.countryCode
      }
    }
  }
];

export default ldJson;
