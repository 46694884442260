import gql from "graphql-tag";

const SUB_TO_NEWSLETTER = gql`
  mutation SubToNewsletter(
    $email: String
    $language: String
    $list: [String!]
  ) {
    subToNewsletter(
      input: { email: $email, language: $language, list: $list }
    ) {
      status
    }
  }
`;

export { SUB_TO_NEWSLETTER };
