import AppStore from "./AppStore";
import SessionStore from "./SessionStore";
import MissionSearchStore from "./MissionsSearchStore";
import ReferenceTask from "./ReferenceTaskStore";
import StatsStore from "./StatsStore";
import OffersSearchStore from "./OffersSearchStore";
import TimesheetStore from "./TimesheetStore";
import PostANeedStore from "./PostANeedStore";
import ModalStore from "./ModalStore";
import TalentStore from "./TalentStore";
import JobSubmissionStore from "./JobSubmissionStore";
import SignataireStore from "./SignataireStore";
import SmatchStore from "./SmatchStore";
import CandidatesSearchStore from "./CandidatesSearchStore";
import NewTimesheetStore from "./NewTimesheetStore";

const createStores = (initialState = {}) => ({
  appStore: new AppStore(initialState.appStore),
  sessionStore: new SessionStore(initialState.sessionStore),
  missionsSearchStore: new MissionSearchStore(initialState.missionsSearchStore),
  offersSearchStore: new OffersSearchStore(initialState.offersSearchStore),
  referenceTask: new ReferenceTask(initialState.referenceTask),
  statsStore: new StatsStore(initialState.statsStore),
  postANeedStore: new PostANeedStore(initialState.postANeedStore),
  modalStore: new ModalStore(initialState.modalStore),
  timesheetStore: new TimesheetStore(initialState.timesheetStore),
  talentStore: new TalentStore(initialState.talentStore),
  jobSubmissionStore: new JobSubmissionStore(initialState.jobSubmissionStore),
  signataireStore: new SignataireStore(initialState.signataireStore),
  smatchStore: new SmatchStore(initialState.smatchStore),
  candidatesSearchStore: new CandidatesSearchStore(
    initialState.candidatesSearchStore
  ),
  newTimesheetStore: new NewTimesheetStore(initialState.newTimesheetStore)
});

export default createStores;
