import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Container, Row, Col, Pagination, TalentPool } from "cf-neo-ui";
import { Query } from "react-apollo";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react/index";
import { TALENT_POOLS_QUERY } from "../dashboard-company/queries";
import classes from "./styles.module.scss";
import LoadingMissions from "../../components/LoadingMissions/LoadingMissions";
import { isOnePage } from "../../utils/helpers";
@inject("appStore", "sessionStore", "modalStore", "talentStore")
@observer
class MyTalentPool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: this.setPageSize(props.appStore.width),
      currentPage: 1,
      skip: 0,
      limit: this.setPageSize(props.appStore.width)
    };
  }

  setPageSize = width => {
    if (width >= 1440) return 8;
    if (width >= 1024) return 6;
    if (width >= 768) return 6;
    if (width >= 500) return 3;
    return 2;
  };

  renderPagination = data => {
    const { pageSize, currentPage } = this.state;
    const paginationProps = {
      pageSize,
      total: data,
      current: currentPage,
      onChange: current => {
        this.setState({ currentPage: current, skip: (current - 1) * pageSize });
      }
    };

    return <Pagination {...paginationProps} />;
  };
  toDetails = id => {
    const { history } = this.props;
    history.push(`/dashboard/company/talents-pool/${id}`);
  };
  AccessToPool = talentpool => {
    const { sessionStore } = this.props;
    const {
      changepoolName,
      changetalentPool,
      changesourceTalentPool,
      changeShared,
      addcreatorID
    } = sessionStore;
    changepoolName(talentpool.poolName);
    changeShared(talentpool.shared);
    changetalentPool(talentpool);
    changesourceTalentPool(talentpool.sourceTalentPool);
    addcreatorID(talentpool.creatorID);
  };

  render() {
    const { sessionStore, appStore, i18n, search, order } = this.props;
    const { client, companyID } = sessionStore;

    let i = 0;
    const { skip, pageSize, currentPage, limit } = this.state;
    const { currentLanguage } = appStore;
    return (
      <Query
        query={TALENT_POOLS_QUERY}
        variables={{
          clientID: client._id,
          clientCompanyId: companyID,
          limit: limit,
          skip: skip,
          search: search ? search : "",
          order: order
        }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <LoadingMissions
                cardsCount={this.setPageSize(appStore.width)}
                lg={3}
                md={3}
                sm={4}
                xs={6}
                colClassName={classes.favedMissionsCard}
                containerClassName={classes.marTop}
              />
            );

          if (error) {
            return (
              <div className="error_container">
                <span>
                  {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                </span>
                <img
                  style={{
                    margin: 20
                  }}
                  src={"/assets/svg/download.svg"}
                  alt="error"
                />
              </div>
            );
          }

          let { talentPools } = data;
          const talentPoolsReduced = talentPools.talentPools.reduce((a, b) => {
            if (a.indexOf(b) < 0) a.push(b);
            return a;
          }, []);

          if (talentPoolsReduced.length === 0)
            return (
              <div className={classes.talentCards}>
                <p className={classes.text1}>
                  <Trans>Pas de vivier</Trans>
                </p>
                <p className={classes.text2}>
                  <Trans>Pour trouvez des viviers, soumettez un vivier.</Trans>
                </p>
              </div>
            );
          let TalentsPoolToDisplay = talentPoolsReduced;

          return (
            <div>
              <Container className={classes.marTop}>
                <Row>
                  {TalentsPoolToDisplay.map(tp => {
                    const namePool =
                      currentLanguage === "en" &&
                      tp.poolName.toLowerCase() ===
                        "mes recherches de talent it"
                        ? i18n._(t`Mes recherches de talent IT`)
                        : tp.poolName.toLowerCase();
                    const highlighted =
                      tp.sourceTalentPool === "Cockpit" ? true : false;
                    const msg =
                      tp.sourceTalentPool === "search"
                        ? `${tp.nbProfile}` +
                          " " +
                          (tp.nbProfile > 1
                            ? i18n._(t`profils trouvés`)
                            : i18n._(t`profil trouvé`))
                        : tp.sourceTalentPool === "client"
                        ? `${tp.nbProfile}` +
                          " " +
                          (tp.nbProfile > 1
                            ? i18n._(t` profils ajoutés`)
                            : i18n._(t` profil ajouté`))
                        : `${tp.nbProfile}` +
                          " " +
                          (tp.nbProfile > 1
                            ? i18n._(t` profils ajoutés`)
                            : i18n._(t` profil ajouté`));
                    const defaultPool =
                      tp.sourceTalentPool === "search" ? true : false;
                    const altText = defaultPool
                      ? tp.creatorID === client._id
                        ? i18n._(t`(Vivier par défaut)`)
                        : i18n._(t`vivier de `) + " " + `${tp.clientName}`
                      : tp.creatorID === client._id
                      ? ""
                      : i18n._(t`vivier de `) + " " + `${tp.clientName}`;

                    i += 1;
                    return (
                      <Col
                        key={i}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={6}
                        id="11111nour"
                        className={classes.favedMissionsCard}
                      >
                        {tp && (
                          <TalentPool
                            key={i}
                            talentPool={{
                              shared: tp.shared,
                              nbCollaborator:
                                tp.nbCollaborator +
                                " " +
                                i18n._(t`Collaborateur(s)`)
                            }}
                            altText={altText}
                            highlighted={highlighted}
                            defaultPool={defaultPool}
                            shared={tp.shared}
                            poolName={namePool.toLowerCase()}
                            nbProfile={msg}
                            onShowDetails={() => this.toDetails(tp._id)}
                            showDetailsLabel={i18n._(t`Voir ce vivier`)}
                            updateAt={moment
                              .unix(tp.updatedAt / 1000)
                              .format("DD/MM/YYYY")}
                            onClick={() =>
                              this.AccessToPool({
                                shared: tp.shared,
                                sourceTalentPool: tp.sourceTalentPool,
                                creatorID: tp.creatorID,
                                clientID: client._id,
                                poolName: tp.poolName,
                                nbProfile: tp.nbProfile
                              })
                            }
                            withFav={false}
                            draggable={false}
                          />
                        )}
                      </Col>
                    );
                  })}
                </Row>
              </Container>
              {isOnePage(pageSize, talentPools.count) ? null : (
                <div className={classes.pagination}>
                  {this.renderPagination(talentPools.count)}
                </div>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

MyTalentPool.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  search: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    client: PropTypes.object
  }).isRequired,
  appStore: PropTypes.shape({
    changeWidth: PropTypes.func,
    width: PropTypes.number
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisible: PropTypes.func,
    changeIsVisible2: PropTypes.func
  }).isRequired,
  talentStore: PropTypes.shape({
    changeTalent: PropTypes.func,
    talent: PropTypes.shape
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(MyTalentPool));
