import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import { Mutation } from "react-apollo";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { Modal, toaster } from "cf-neo-ui";
import metaTags from "../../utils/editMetaData";
import MissionsPageHeader from "./MissionsPageHeader/MissionsPageHeader";
import MissionsPageBody from "./MissionsPageBody/MissionsPageBody";
import AlertModal from "../../components/alert-modal/AlertModal";
import { USER_ALERT_QUERY } from "../dashboard-missions/queries";
import { CREATE_MISSION_ALERT } from "./mutations";
import urls from "../../utils/urls";
import staticPagesMetaTags from "../../configs/staticPagesMetaTags";
import ldJson from "./ldJson";
import runtimeVars from "../../configs/runTimeVars";

const conditionalPush = (arr, title, tags) =>
  tags.length && arr.push({ title, tags });

const stringToArr = str => (str ? [str] : []);

@inject("missionsSearchStore")
@observer
class MissionsPage extends Component {
  componentDidMount() {
    if (typeof window !== "undefined") window.scroll(0, 0);
  }

  getModalData = i18n => {
    const { missionsSearchStore } = this.props;
    const {
      categories,
      skills,
      businessSectors,
      specialties,
      workType,
      cityLabels,
      employmentType,
      onSite
    } = missionsSearchStore;

    const data = [];
    conditionalPush(data, i18n._(t`Domaine d’expertise`), categories);
    conditionalPush(data, i18n._(t`Compétence`), skills);
    conditionalPush(data, i18n._(t`Secteur`), businessSectors);
    conditionalPush(data, i18n._(t`Spécialité`), specialties);
    conditionalPush(data, i18n._(t`Rythme de travail`), stringToArr(workType));
    conditionalPush(
      data,
      i18n._(t`Type de contrat`),
      stringToArr(employmentType)
    );
    conditionalPush(data, i18n._(t`Remote/Régie`), stringToArr(onSite));
    conditionalPush(data, i18n._(t`Localisation`), cityLabels);

    return data;
  };

  getQueryVariables = () => {
    const { missionsSearchStore } = this.props;
    const {
      categories,
      skills,
      businessSectors,
      specialties,
      workType,
      cityLabels,
      employmentType,
      onSite
    } = missionsSearchStore;

    const input = {
      categories,
      skills,
      businessSectors,
      specialties,
      workType,
      employmentType,
      onSite,
      countryId: runtimeVars.APP_COUNTRY_ID,
      locations: cityLabels
    };

    return { input };
  };

  render() {
    const { missionsSearchStore, i18n } = this.props;
    const meta = staticPagesMetaTags(i18n).missions;
    return (
      <div>
        {metaTags(
          urls.missions(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          ldJson(i18n)
        )}
        <Modal
          centred
          isVisible={missionsSearchStore.isAlertModalOpen}
          onClose={missionsSearchStore.setAlertModal}
        >
          <Mutation
            mutation={CREATE_MISSION_ALERT}
            variables={this.getQueryVariables()}
            onCompleted={() =>
              toaster.success({
                title: i18n._(t`Alerte créée avec succès`),
                description: i18n._(
                  t`Vous pouvez voir votre alerte dans votre profil`
                )
              })
            }
            onError={() =>
              toaster.error({
                title: i18n._(t`Erreur de création d’alerte`),
                description: i18n._(
                  t`Un problème est survenu lors de la création d’alerte`
                )
              })
            }
            ignoreResults
            refetchQueries={[{ query: USER_ALERT_QUERY }]}
          >
            {createMissionAlert => (
              <AlertModal
                data={this.getModalData(i18n)}
                primaryButtonLabel={i18n._(t`Créer`)}
                secondaryButtonLabel={i18n._(t`Annuler`)}
                onCancel={missionsSearchStore.setAlertModal}
                onSubmit={() => {
                  createMissionAlert();
                  missionsSearchStore.setAlertModal();
                }}
              />
            )}
          </Mutation>
        </Modal>
        <MissionsPageHeader />
        <MissionsPageBody />
      </div>
    );
  }
}

MissionsPage.wrappedComponent.propTypes = {
  missionsSearchStore: PropTypes.shape({
    isAlertModalOpen: PropTypes.bool,
    setAlertModal: PropTypes.func,
    categories: PropTypes.shape({}),
    skills: PropTypes.shape({}),
    specialties: PropTypes.shape({}),
    businessSectors: PropTypes.shape({}),
    employmentType: PropTypes.string,
    onSite: PropTypes.string,
    workType: PropTypes.string,
    cityLabels: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(MissionsPage);
