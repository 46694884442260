import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import "./styles.scss";
import SiderCompany from "./sider-company";
import Sider from "./sider";
import MainContent from "./MainContent";
import DashBoardHeader from "./header/DashBoardHeader";
import { ApolloConsumer } from "react-apollo";

@inject("referenceTask", "sessionStore")
@observer
class LpbLayout extends Component {
  render() {
    const { referenceTask, component, sessionStore } = this.props;
    const { account } = sessionStore;
    const blockScrollClass = referenceTask.isSideMenuOpen ? "block-scroll" : "";
    const user = account.role;
    return (
      <section
        className={`lpb-layout ${blockScrollClass}`}
        style={{
          position: "relative",
          minHeight: "100vh"
        }}
      >
        <DashBoardHeader />
        <ApolloConsumer>
          {client => user === "CANDIDATE" && <Sider apolloClient={client} />}
        </ApolloConsumer>
        <ApolloConsumer>
          {client =>
            user === "CLIENT" && <SiderCompany apolloClient={client} />
          }
        </ApolloConsumer>
        <MainContent component={component} />
      </section>
    );
  }
}

LpbLayout.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    account: PropTypes.object
  }).isRequired,
  referenceTask: PropTypes.shape({
    isSideMenuOpen: PropTypes.bool
  }).isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
};

export default LpbLayout;
