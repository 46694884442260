import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Query } from "react-apollo";
import Fade from "react-reveal/Fade";
import gql from "graphql-tag";
import { Button, Col, Modal, Row } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import { withI18n } from "@lingui/react/index";
import classes from "./HomePageCompany.module.scss";
import HeroSection1 from "./HeroSection/HeroSection1";
import ServiceSection1 from "./ServiceSection/ServiceSection1";
import TwoFoldExpertiseSection from "./TwoFoldExpertiseSection/TwoFoldExpertiseSection";
import HowItWorks from "./HowItWorks/HowItWorks";
import MoreDetails from "./MoreDetails/MoreDetails";
import Banner from "./banner/Banner";
import ExpertisesSection1 from "./ExpertisesSection22/ExpertisesSection1";
import FinderSection1 from "./FinderSection/FinderSection1";
import TestimonySectionNewVersion from "../home-page-consultant/TestimonySectionNewVersion/TestimonySection1";
import CompanySection1 from "./CompanySection/CompanySection1";
import Routes from "../layout/routes";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import ldJson from "./ldJson";
import PostNeedSelection from "../post-a-need/postNeedSelection";

const GET_CATEGORIES = gql`
  {
    categories {
      name
    }
  }
`;

@inject("sessionStore", "modalStore")
@observer
class HomePageCompany extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  /* toForm = () => {
    const { i18n, sessionStore, history } = this.props;
    const { signUpUserRole, authToken } = sessionStore;
    if (authToken) {
      if (signUpUserRole === "CLIENT") {
        if (i18n.language === "fr") window.open(configs.postNeed.link_fr);
        else window.open(configs.postNeed.link_uk);
      } else history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
    } else history.push(Routes.HbLayoutRoutes.Login.path);
  };

  toForm = () => {
    const { sessionStore, history } = this.props;
    const { signUpUserRole, authToken } = sessionStore;
    if (authToken) {
      if (signUpUserRole === "CLIENT") {
        const { Offer } = Routes.HbLayoutRoutes;
        history.push(Offer.path);
      } else history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
    } else history.push(Routes.HbLayoutRoutes.Login.path);
  };

  */

  clickHandler = () => {
    const { history } = this.props;
    const { HbfLayoutRoutes } = Routes;
    const { SearchCandidatesPage } = HbfLayoutRoutes;
    history.push(SearchCandidatesPage.path);
    // const { modalStore, sessionStore, history } = this.props;
    // const { signUpUserRole, authToken } = sessionStore;
    // if (authToken) {
    //   if (signUpUserRole === "CANDIDATE") {
    //     history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
    //   } else if (signUpUserRole === "CLIENT") {
    //     modalStore.changeIsVisible(true);
    //   }
    // } else {
    //   history.push(Routes.HbLayoutRoutes.Login.path);
    // }
  };

  render() {
    const { history, i18n, modalStore } = this.props;
    const { changeIsVisible, isVisible } = modalStore;
    const { HbLayoutRoutes } = Routes;
    const { SignUp } = HbLayoutRoutes;
    const meta = staticMetaTags(i18n).client;
    return (
      <div className={classes.section0biggest}>
        <Modal
          centred
          isVisible={isVisible}
          withCloseButton={false}
          onClose={() => changeIsVisible(false)}
          position="fixed"
        >
          <PostNeedSelection />
        </Modal>
        {metaTags(
          urls.client(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          ldJson(i18n)
        )}
        <section className={classes.section0}>
          <div className={classes.section0Bg} />
          <Row>
            <div className={classes.block1}>
              <HeroSection1 />
            </div>

            <div className={classes.block2}>
              <Fade big>
                <div className={classes.image1} />
              </Fade>
              <Fade bottom>
                <div className={classes.imageDashboard} />
              </Fade>
            </div>
          </Row>
        </section>

        <section className={classes.section1}>
          <ServiceSection1 />
        </section>

        <section className={classes.section2}>
          <div className={classes.section2Bg} />
          <TwoFoldExpertiseSection />
          <HowItWorks />
          <MoreDetails />
          <Banner />
          <ExpertisesSection1 />
          <Query query={GET_CATEGORIES}>
            {({ loading, error, data }) => {
              if (loading) return "Loading...";
              if (error) return `Error! ${error.message}`;

              const { categories } = data;
              return <FinderSection1 options={categories} />;
            }}
          </Query>
        </section>
        <section className={classes.section3}>
          <div className={classes.section3Bg}>
            <div className={classes.testimony}>
              <TestimonySectionNewVersion />
            </div>
          </div>
        </section>
        <div className={classes.joinClub}>
          <h3>
            <Trans>Vous souhaitez rejoindre Club Freelance ?</Trans>
          </h3>
          <div className={classes.button}>
            <Row className={classes.classRow}>
              <Col
                xs={6}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.classCol}
              >
                <Button
                  icon="chevron-right"
                  // onClick={this.toForm}
                  onClick={this.clickHandler}
                >
                  <Trans>Je cherche un consultant IT</Trans>
                </Button>
              </Col>
              <Col
                xs={6}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.classCol}
              >
                <Button
                  icon="chevron-right"
                  variant="secondary"
                  onClick={() => history.push(`/missions`)}
                >
                  <Trans>Je cherche une mission</Trans>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <CompanySection1 />
      </div>
    );
  }
}

HomePageCompany.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    signUpUserRole: PropTypes.string
  }).isRequired,
  modalStore: PropTypes.shape({
    isVisible: PropTypes.bool,
    changeIsVisible: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(HomePageCompany));
