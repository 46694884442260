import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Row, Icon, Button, PopUp } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";

@inject("newTimesheetStore")
@observer
class DeleteRangePopUp extends Component {
  deleteRange = () => {
    const { newTimesheetStore } = this.props;
    const { openClosePopUps, deletePeriod, rangeDetails } = newTimesheetStore;
    deletePeriod(rangeDetails, rangeDetails.themeName);
    openClosePopUps("deleteRange");
  };

  returnToMoreInformationPopUp = () => {
    const { newTimesheetStore } = this.props;
    const { openClosePopUps } = newTimesheetStore;
    openClosePopUps("deleteRange");
    openClosePopUps("moreInformation");
  };

  render() {
    const { device } = this.props;
    return (
      <PopUp mobile={device === "mobile"} withCloseButton={false}>
        <div
          style={{
            position: "relative",
            height: "140px",
            width: device === "mobile" ? "100%" : "380px"
          }}
        >
          <div style={{ borderBottom: "1px solid" }}>
            <Row
              style={{
                marginBottom: "10px"
              }}
            >
              <span style={{ margin: "0 15px" }}>
                <Icon
                  type="bell"
                  color="#8D0417"
                  color2="#D3354A"
                  width={14}
                  height={14}
                />
              </span>
              <span>
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#242424"
                  }}
                >
                  <Trans>Supprimer la plage de jours/heures</Trans>
                </div>
              </span>
            </Row>
          </div>

          <div
            style={{
              padding: "20px 50px 20px 50px",
              width: "250px",
              margin: "0 auto",
              textAlign: "center",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              color: "#242424"
            }}
          >
           <Trans>Êtes-vous sûr de vouloir supprimer cette plage de jours/heures ?</Trans>
          </div>
          <div style={{ padding: "0 40px" }}>
            <div
              style={{
                float: "right",
                margin: "0 10px"
              }}
            >
              <Button
                variant="primary"
                size="small"
                onClick={() => this.deleteRange()}
              >
                <Trans>Confirmer</Trans>
              </Button>
            </div>
            <div
              style={{
                float: "left",
                margin: "0 10px"
              }}
            >
              <Button
                variant="secondary"
                size="small"
                onClick={() => this.returnToMoreInformationPopUp()}
              >
                <Trans>Retour</Trans>
              </Button>
            </div>
          </div>
        </div>
      </PopUp>
    );
  }
}

DeleteRangePopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func
  }).isRequired,
  device: PropTypes.string
};
export default withApollo(withI18n()(withRouter(DeleteRangePopUp)));
