import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react/index";
import FilteredDocuments from "./FilteredDocuments";

@inject("appStore", "offersSearchStore", "sessionStore")
@observer
class GetDocs extends Component {
  render() {
    const { sessionStore } = this.props;
    return (
      <div>
        <FilteredDocuments />
      </div>
    );
  }
}

GetDocs.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  offersSearchStore: PropTypes.shape({
    limit: PropTypes.number,
    skip: PropTypes.number
  }).isRequired
};

export default withI18n()(withRouter(GetDocs));
