import gql from "graphql-tag";

const CREATE_CLIENT_INTEREST = gql`
  mutation createClientInterestMutation($clientId: Int!, $candidateBhId: Int!) {
    createClientInterest(clientId: $clientId, candidateBhId: $candidateBhId) {
      note
    }
  }
`;

export default CREATE_CLIENT_INTEREST;
