import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import "./PrivacyAndPolicyUpdateModal.scss";
import {
  Button,
  Col,
  FormCard,
  FormGroup,
  Icon,
  Row,
  Spinner,
  TagList,
  Textarea,
  Validation
} from "cf-neo-ui";
import theme from "../../configs/theme";
import { t, Trans } from "@lingui/macro";
import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Routes from "../../containers/layout/routes";
import { Mutation } from "react-apollo";
import classes from "../alert-modal/alertModal.module.scss";

@inject("sessionStore", "appStore", "modalStore")
@observer
class PrivacyAndPolicyUpdateModal extends Component {
  render() {
    const { PrivacyPolicy, TermsAndConditionsOfUse } = Routes.HbLayoutRoutes;
    const { onSubmit } = this.props;

    return (
      <div className="single-candidate-page-modal-session single-candidate-page-modal-container single-candidate-page-modal-signup-selection">
        <FormCard>
          <div className={classes.header}>
            <div className={classes.headerIcon}>
              <Icon
                size="tiny"
                type="bell"
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
            </div>
            <Trans>Nouvelle politique de confidentialité et GCU</Trans>
          </div>
          <Row>
            <Col
              className="single-candidate-page-modal-form-card-content single-candidate-page-modal-center"
              style={{ textAlign: "center" }}
            >
              <Trans>
                Nous avons apporté des modifications à nos conditions
                d'utilisation, ainsi qu'à notre
                <br />
                politique de confidentialité le 03 février 2022. vous pouvez les
                consulter sur ces pages: <br />
                <br />
                <Link
                  to={TermsAndConditionsOfUse.path}
                  target="_blank"
                  style={{ color: "#d3354a" }}
                >
                  {" "}
                  Nouvelles conditions générales d’utilisation{" "}
                </Link>{" "}
                <Link
                  to={PrivacyPolicy.path}
                  target="_blank"
                  style={{ color: "#d3354a" }}
                >
                  {" "}
                  Nouvelle politique de confidentialité{" "}
                </Link>
              </Trans>
            </Col>
          </Row>
          <Row>
            <Col className="single-candidate-page-modal-form-card-content single-candidate-page-modal-center">
              <div className="single-candidate-page-modal-center">
                <Button size="small" onClick={onSubmit}>
                  <Trans>J'accepte</Trans>
                </Button>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

PrivacyAndPolicyUpdateModal.propTypes = {
  onSubmit: PropTypes.func
};

PrivacyAndPolicyUpdateModal.defaultProps = {
  onSubmit: () => {}
};

export default PrivacyAndPolicyUpdateModal;
