import runtimeVars from "./runTimeVars";
import cookie from "react-cookies";

const { DOMAIN } = runtimeVars;

// expiration date for the cookie: 1 year
const cookieSaveOpt = {
  path: "/",
  sameSite: false,
  domain: DOMAIN,
  expires: cookie.load("cookies-deny")
    ? new Date(Date.now() - 31104000000)
    : new Date(Date.now() + 31104000000)
};
const cookieSaveToken = {
  path: "/",
  sameSite: false,
  domain: DOMAIN,
  expires: new Date(Date.now() + 31104000000)
};
const cookieRemoveOpt = {
  path: "/",
  domain: DOMAIN
};
export { cookieSaveOpt, cookieRemoveOpt, cookieSaveToken };
