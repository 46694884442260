import React, { Component } from "react";
import { withI18n } from "@lingui/react";
import * as PropTypes from "prop-types";
import { PopUp, Icon } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";

@inject("newTimesheetStore", "appStore")
@observer
class TimesheetRejectedSuccessfullyPopUp extends Component {
  render() {
    const { newTimesheetStore, device } = this.props;
    const { openClosePopUps } = newTimesheetStore;
    return (
      <PopUp
        mobile={device === "mobile"}
        onClose={() => openClosePopUps("timesheetRejectedSuccessfully")}
      >
        <div
          style={{
            position: "relative",
            height: "210px",
            width: device === "mobile" ? "100%" : "342px"
          }}
        >
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              color: "#242424",
              textAlign: "center",
              marginTop: "27px"
            }}
          >
            <Trans>Timesheet rejetée</Trans>
          </div>
          <div
            style={{
              marginTop: "35px",
              textAlign: "center"
            }}
          >
            <Icon
              type="rejected"
              width={37}
              color="#8d0417"
              color2="#d3354a"
              filled
            />
          </div>
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "13px",
              lineHeight: "20px",
              color: "#242424",
              textAlign: "center",
              marginTop: "25px"
            }}
          >
            <Trans>Le consultant IT sera informé.</Trans>
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "47px",
              fontFamily: "Montserrat",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
              color: "#000000"
            }}
          >
            <Trans>Merci de d'indiquer le raison du rejet</Trans>
          </div>
        </div>
      </PopUp>
    );
  }
}

TimesheetRejectedSuccessfullyPopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func
  }).isRequired,
  device: PropTypes.string
};
export default withI18n()(TimesheetRejectedSuccessfullyPopUp);
