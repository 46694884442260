import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  TestimonialCardNewVersionForMobile,
  TestimonialCardNewVersionForDesktop,
  ExpertiseCard
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import $ from "jquery";
import { inject, observer } from "mobx-react";
import Fade from "react-reveal/Fade";
import classes from "./TestimonySection1.module.scss";
import getTestimoniesData from "../../../configs/getTestimoniesData";

import carouselConfig from "./CarouselConfig";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./style.scss";

@inject("appStore")
@observer
class TestimonySection1 extends Component {
  key = 0;

  state = {
    render: false
  };

  componentDidMount() {
    this.setState({ render: true });
  }

  toDetails = () => {
    const { history } = this.props;
    history.push(`/missions`);
  };

  render() {
    const { i18n, appStore, page } = this.props;
    const { render } = this.state;
    if (!render) return null;
    if (typeof window !== "undefined") {
      window.jQuery = $;
      window.$ = $;
    }
    /* eslint-disable global-require */
    const OwlCarousel = require("react-owl-carousel");
    /* eslint-enable global-require */
    // eslint-disable-next-line no-return-assign
    return (
      <div className="carousel_home_page_testimony">
        <div className={classes.temoignages}>
          <Fade>
            <h3 style={{ fontSize: "50px" }}>
              <Trans>Les acteurs IT parlent de nous</Trans>
            </h3>
          </Fade>
          <Fade>
            <p className={classes.paragraph}>
              <Trans>Notre communauté au coeur de notre service.</Trans>
            </p>
          </Fade>
          <div className={classes.carousel}>
            <OwlCarousel {...carouselConfig}>
              {getTestimoniesData(i18n, page).map(testimony => {
                this.key += 1;
                return (
                  <div className="item" key={this.key}>
                    {appStore.width > 820 ? (
                      <TestimonialCardNewVersionForDesktop
                        image={testimony.image}
                        name={testimony.name}
                        position={testimony.position}
                        domain={testimony.domain}
                        paragraph={testimony.paragraph}
                        version={testimony.version}
                      />
                    ) : (
                      <TestimonialCardNewVersionForMobile
                        image={testimony.image}
                        name={testimony.name}
                        position={testimony.position}
                        domain={testimony.domain}
                        paragraph={testimony.paragraph}
                        version={testimony.version}
                      />
                    )}
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
    );
  }
}

// https://github.com/mobxjs/mobx-react/issues/70#issuecomment-231834410
TestimonySection1.wrappedComponent.propTypes = {
  appStore: PropTypes.shape({
    width: PropTypes.number,
    clicked: PropTypes.bool,
    changeClicked: PropTypes.func,
    changeWidth: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  page: PropTypes.string
};

export default withI18n()(withRouter(TestimonySection1));
