import React, { Component } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { inject, observer } from "mobx-react";
import * as PropTypes from "prop-types";
import classes from "./TwitterCard.module.scss";
import globals from "../../../../configs/club-freelance-globals";

@inject("appStore")
@observer
class TwitterCard extends Component {
  render() {
    const { appStore } = this.props;
    const { currentLanguage } = appStore;
    return (
      <div className={classes.container}>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName={globals.socialMedia.twitterScreenName}
          noScrollbar
          autoHeight
          lang={currentLanguage}
        />
      </div>
    );
  }
}
TwitterCard.propTypes = {
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

export default TwitterCard;
