import React, { Component } from "react";
import * as PropTypes from "prop-types";
import * as queryString from "query-string";
import { withRouter } from "react-router-dom";
import {
  Table,
  Modal,
  Col,
  Validation,
  toaster,
  Spinner,
  Pagination,
  Button,
  TextInput,
  Icon
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import classes from "./styles.module.scss";
import theme from "../../configs/theme";
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import validate from "../../utils/validators";
import { LIST_COLLABORATOR_QUERY } from "../dashboard-company/queries";
import { isOnePage } from "../../utils/helpers";
import PoolExistModal from "../talentPool-Modal/poolExistModal";
import DeleteCollaborator from "../talentPool-Modal/deleteCollaborator";

const RESEND_EMAIL_COLLABORATOR_MUTATION = gql`
  mutation resendEmailCollaborator($clientEmail: String!, $clientID: String) {
    resendEmailCollaborator(
      input: { clientEmail: $clientEmail, clientID: $clientID }
    ) {
      collaborator {
        clientID
        clientEmail
        clientCompanyId
        clientCompanyName
        clientName
        nbPool
        permitPool
        dateAdded
      }
    }
  }
`;

const UPDATE_Talent_POOL_COLLABORATOR_MUTATION = gql`
  mutation AddToListCollaboratorMutation(
    $creatorID: String
    $clientCompanyId: Int
    $clientEmail: String!
  ) {
    AddToListCollaborator(
      input: {
        creatorID: $creatorID
        clientCompanyId: $clientCompanyId
        clientEmail: $clientEmail
      }
    ) {
      collaborator {
        clientID
        clientEmail
        clientCompanyId
        clientCompanyName
        clientName
        nbPool
        permitPool
        dateAdded
      }
    }
  }
`;

@inject("appStore", "modalStore", "sessionStore")
@observer
class ManagementTalentPool extends Component {
  formatTable = listCollaborator => {
    const { i18n, appStore } = this.props;
    const { width } = appStore;

    const isMobile = width <= 1170 ? true : false;
    if (
      !listCollaborator ||
      !Array.isArray(listCollaborator) ||
      !listCollaborator.length
    )
      return null;
    return listCollaborator.map(
      ({
        clientName,
        clientCompanyName,
        clientEmail,
        emailClient,
        nbPool,
        clientID
      }) => [
        isMobile ? clientName?.substring(0, 25) : clientName,
        clientCompanyName,
        emailClient ? emailClient : clientEmail,
        nbPool,
        <div>
          <span style={{ "margin-right": "15px" }}>
            {" "}
            <Mutation
              mutation={RESEND_EMAIL_COLLABORATOR_MUTATION}
              variables={{
                clientEmail: clientEmail && clientEmail.toLowerCase(),
                clientID: clientID ? clientID : ""
              }}
              onCompleted={data => {
                toaster.success({
                  title: i18n._(t`Demande par email renvoyée`),
                  description: i18n._(
                    t`Votre demande de collaboration a bien été renvoyée`
                  )
                });
              }}
            >
              {resendEmail => (
                <p
                  className={classes.buttonDelete}
                  title={`${i18n._(t`Renvoyer`)}`}
                >
                  <Icon
                    type="resend"
                    color={theme.colors.mainColor}
                    color2={theme.colors.mainColor}
                    onClick={() => {
                      resendEmail(clientEmail);
                    }}
                    width={20}
                    filled={true}
                    height={20}
                  ></Icon>
                </p>
              )}
            </Mutation>
          </span>
          <span>
            <p
              className={classes.buttonDelete}
              title={`${i18n._(t`Supprimer`)}`}
            >
              <Icon
                type="close"
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
                width={20}
                filled={true}
                onClick={() => this.deleteCollaborator(clientEmail, clientID)}
                height={20}
              ></Icon>
            </p>
          </span>
        </div>
      ]
    );
  };
  constructor(props) {
    super(props);
    const queryObj = queryString.parse(props.location.search);
    this.state = {
      isEmailValid: true,
      emailValidationMessage: "",
      search: "",
      sortBy: "All",
      sortPool: "All",
      activeTab: queryObj.active,
      employmentType: "",
      order: 1,
      currentPage: 1,
      pageSize: 5,
      skip: 0,
      collaboratorEmail: "",
      clientID: ""
    };
    this.total = 0;
  }

  tabChangeHandler = tabName => {
    const { history } = this.props;
    history.push(`/dashboard/company/talents?active=${tabName}`);
  };
  deleteCollaborator(clientEmail, clientID) {
    const { modalStore } = this.props;
    const { changetoDeleteCollaborator } = modalStore;
    changetoDeleteCollaborator(true);
    this.setState({ collaboratorEmail: clientEmail, clientID: clientID });
  }
  validateEmail(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required", "email"], i18n);
    this.setState({
      isEmailValid: res.isValid,
      emailValidationMessage: res.message
    });
    return res.isValid;
  }
  emailChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeEmailCollaborator(String(e.target.value).toLowerCase());
    this.validateEmail(e.target.value);
  };
  isFormValid() {
    const { sessionStore } = this.props;
    const { emailCollaborator } = sessionStore;
    let valid = true;
    if (!this.validateEmail(emailCollaborator)) valid = false;
    return valid;
  }
  loading = () => {
    return (
      <Spinner
        type="pointed-circular"
        color={theme.colors.mainColor}
        size={60}
      />
    );
  };
  setTotalCollaborator = collaborator => {
    if (collaborator) this.total = collaborator.count;
    return this.total;
  };
  renderPagination = length => {
    const { pageSize, currentPage } = this.state;
    const paginationProps = {
      pageSize,
      total: length,
      current: currentPage,
      onChange: current => {
        this.setState({ currentPage: current, skip: (current - 1) * pageSize });
      }
    };
    return <Pagination {...paginationProps} />;
  };
  getQueryVariables = () => {
    const { pageSize, skip } = this.state;

    const filter = {};

    if (skip) filter.skip = skip;
    if (pageSize) filter.limit = pageSize;

    return filter;
  };
  updateCollaboratorCompletedHandler = () => {
    const { i18n, appStore, sessionStore } = this.props;
    toaster.success({
      title: i18n._(t`Mes Collaborateurs`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
  };
  onErrorHandlerAdd = msg => {
    const { i18n, modalStore } = this.props;
    const { changePoolExist } = modalStore;

    if (
      msg ===
      'Unexpected error value: "Cet email est associé à un compte consultant dans mindquest et ne peut pas ajouté en tant que collaborateur"'
    ) {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Cet email est associé à un compte consultant dans mindquest et ne peut pas ajouté en tant que collaborateur`
        )
      });
    } else if (msg === 'Unexpected error value: "Ce client existe déjà"') {
      changePoolExist(true);
    } else if (
      msg ===
      'Unexpected error value: "Vous ne puvez pas ajouter votre email à la liste des collaborateurs"'
    ) {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Vous ne puvez pas ajouter votre email à la liste des collaborateurs`
        )
      });
    } else {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Une erreur est survenue, veuillez réessayer ultérieurement`
        )
      });
    }
  };
  render() {
    const { i18n, sessionStore, modalStore } = this.props;
    const {
      pageSize,
      skip,
      isEmailValid,
      emailValidationMessage,
      collaboratorEmail,
      clientID
    } = this.state;
    const { poolExist, toDeleteCollaborator } = modalStore;
    let longueur = null;
    const { client, emailCollaborator, companyID } = sessionStore;
    const isMobile = window.screen.width <= 480;
    return (
      <div className={classes.allBody}>
        {/* Modal */}

        <Modal isVisible={poolExist} withCloseButton={true} position="fixed">
          <PoolExistModal
            clientEmail={emailCollaborator.toLowerCase()}
            clientID={client._id}
          ></PoolExistModal>
        </Modal>
        <Modal
          isVisible={toDeleteCollaborator}
          withCloseButton={true}
          position="fixed"
        >
          <DeleteCollaborator
            clientEmail={collaboratorEmail.toLowerCase()}
            clientID={clientID}
          ></DeleteCollaborator>
        </Modal>

        {/* Sending email part top */}
        <div className={classes.topBody}>
          <Col lg={6} md={6} sm={6} xs={6}>
            <span className={classes.titleCollaborator}>
              <Trans>Ajouter un collaborateur</Trans>
            </span>
            <br />
            <span className={classes.textCollaborator}>
              <Trans>
                Votre futur collaborateur recevra un email permettant de se
                connecter ou de s'inscrire afin d'avoir accès à vos différents
                viviers. N'oubliez pas d'activer en collaboratif, les viviers
                que vous souhaitez partager.
              </Trans>
            </span>
          </Col>
          <Col className={classes.mutationPart} lg={6} md={6} sm={6} xs={6}>
            <Col lg={8} md={8} sm={8} xs={8}>
              <Validation
                errorMessage={emailValidationMessage}
                valid={isEmailValid}
              >
                <TextInput
                  className={classes.textColaborator}
                  placeholder={i18n._(t`Entrer une adresse email`)}
                  size="small"
                  clickableIcon={false}
                  color={theme.colors.mainColor}
                  color2={theme.colors.lightMainColor}
                  radius={10}
                  value={emailCollaborator}
                  onChange={this.emailChangeHandler}
                />
              </Validation>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
              <Mutation
                mutation={UPDATE_Talent_POOL_COLLABORATOR_MUTATION}
                variables={{
                  creatorID: client._id,
                  clientCompanyId: companyID,
                  clientEmail: emailCollaborator.toLowerCase()
                }}
                refetchQueries={[
                  {
                    query: LIST_COLLABORATOR_QUERY,
                    variables: {
                      creatorID: client._id,
                      skip: skip,
                      limit: pageSize
                    }
                  }
                ]}
                onCompleted={data => {
                  this.updateCollaboratorCompletedHandler(data);
                  sessionStore.changeEmailCollaborator("");
                }}
                onError={errors => {
                  errors.graphQLErrors.forEach(({ message }) => {
                    if (message) {
                      this.onErrorHandlerAdd(message);
                      close();
                    }
                  });
                }}
              >
                {(updateCollaborator, { loading }) => (
                  <span className={classes.buttonSend}>
                    {loading ? (
                      <Button disabled>
                        <Spinner
                          type="pointed-circular"
                          color="#FFFFFF"
                          size={12}
                        />
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        size="small"
                        onClick={() => {
                          this.isFormValid() && updateCollaborator();
                        }}
                      >
                        <Trans>Envoyer</Trans>
                      </Button>
                    )}
                  </span>
                )}
              </Mutation>
            </Col>
          </Col>

          <br />
        </div>
        {/* only in mobile image + text  */}
        {isMobile && (
          <div className={classes.scrollMobile}>
            <div className={classes.MobileIcon}>
              <Icon size="small" type="mobile" color="red" />
            </div>

            <h5 className={classes.TextscrollMobile}>
              {" "}
              <Trans>
                Naviguer horizentalement ou basculez votre écran en position
                paysage pour consulter toutes les statistiques
              </Trans>
            </h5>
          </div>
        )}
        {/* table collaborator */}
        <Col>
          <span className={classes.titleCollaborator}>
            <Trans>Tous vos collaborateurs</Trans>
          </span>
        </Col>
        <Query
          query={LIST_COLLABORATOR_QUERY}
          variables={{
            creatorID: client._id,
            skip: skip,
            limit: pageSize
          }}
        >
          {({ loading, error, data }) => {
            if (error) return null;
            if (loading) return this.loading();

            var tableBody = [];
            const { listCollaborator } = data;
            const { collaborator } = listCollaborator;

            if (collaborator)
              longueur = this.setTotalCollaborator(listCollaborator);
            if (collaborator.length > 0) {
              tableBody = this.formatTable(collaborator);
            } else {
              return (
                <div>
                  <Trans>Pas de collaborateur</Trans>
                </div>
              );
            }
            return (
              <div>
                <Table
                  className={classes.tableColaborator}
                  head={[
                    i18n._(t`Prénom Nom`),
                    i18n._(t`Entreprise`),
                    i18n._(t`Adresse email`),
                    i18n._(t`Nb de viviers`),
                    i18n._(t`Actions`)
                  ]}
                  body={tableBody}
                  fixedColumns={1}
                />
                {!collaborator ||
                collaborator.length === 0 ||
                isOnePage(pageSize, longueur) ? null : (
                  <div
                    className={classes.pagination}
                    style={{ marginTop: "25px" }}
                  >
                    {this.renderPagination(longueur)}
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

ManagementTalentPool.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    isVisible: PropTypes.bool,
    isVisible2: PropTypes.bool,
    changeIsVisible: PropTypes.func,
    changeIsVisible2: PropTypes.func,
    changeModalType: PropTypes.func,
    modalType: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(ManagementTalentPool));
