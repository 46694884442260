import gql from "graphql-tag";

const UPDATE_TIMESHEET = gql`
  mutation UpdateTimesheet(
    $id: Int!
    $action: String!
    $ip: String
    $workPlan: [WorkPlanInput]
    $messages: [DetailsInput]
    $purchaseOrder: String
    $themes: [ThemeInput]
  ) {
    updateTimesheet(
      id: $id
      action: $action
      ip: $ip
      workPlan: $workPlan
      messages: $messages
      purchaseOrder: $purchaseOrder
      themes: $themes
    ) {
      id
      title
      client {
        id
        firstName
        lastName
        name
        email
        companyName
        isSigned
      }
      candidate {
        id
        firstName
        lastName
        name
        email
        city
      }
      placementID
      createdAt
      month
      year
      updatedAt
      status
      workPlan {
        day
        minutes
        hours
        theme {
          name
          color
        }
        type
        place
        description
      }
      totals {
        normalTime
        specialTime
        extraTime
        remoteTime
      }
      approvingClient {
        id
        firstName
        lastName
        name
        email
        companyName
        isSigned
      }
      approvingClient2 {
        id
        firstName
        lastName
        name
        email
        companyName
        isSigned
      }
      holidays {
        month
        year
        country
        holidays {
          day
          name
        }
      }
      themes {
        name
        color
      }
      clientCorporation {
        id
        name
      }
      #      to verify if should be deleted or not
      #      dateAdded
      #      dateLastUpdate
      #      statusMaker
      #      signature
      #      country {
      #        code
      #        name
      #      }
    }
  }
`;

const SEND_MESSAGE_TIMESHEET = gql`
  mutation SendMessageTimesheet($timesheetID: Int!, $messages: [DetailsInput]) {
    sendMessageTimesheet(timesheetID: $timesheetID, messages: $messages) {
      timesheetID
      messages {
        details {
          type
          text
        }
        date
        speaker
      }
    }
  }
`;
export { UPDATE_TIMESHEET, SEND_MESSAGE_TIMESHEET };
