import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import { BlogPoster, BlogPosterSkeleton } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import {
  categoriesRefEn,
  categoriesRefFr,
  latestPostsQuery
} from "../../../../../query";
import classes from "./SugDisplayVideos.module.scss";
import VideoCarousel from "./SugCarousel";
import decode from "../../../../../../../utils/Utf8Text";
import { t } from "@lingui/macro";

@inject("appStore")
@observer
class SugDisplayVideos extends Component {
  loading = () => {
    return (
      <div className={classes.container}>
        <BlogPosterSkeleton className={classes.videoCard} action="play" />
        <BlogPosterSkeleton className={classes.videoCard} action="play" />
      </div>
    );
  };

  navigate = id => {
    const { history } = this.props;
    history.push(`/blog/news/${id}`);
  };

  render() {
    const { currentBlog, appStore, relatedPosts, i18n } = this.props;
    const { currentLanguage, width } = appStore;
    return relatedPosts.length ? (
      <Query
        query={latestPostsQuery}
        variables={{
          include: relatedPosts.slice(0, 3).join(","),
          language: currentLanguage
        }}
      >
        {({ loading, error, data }) => {
          if (error)
            return (
              <div className="error_container">
                <span>
                  {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                </span>
                <img
                  style={{
                    margin: 20
                  }}
                  src={"/assets/svg/download.svg"}
                  alt="error"
                />
              </div>
            );
          if (loading) {
            return width > 780 ? (
              this.loading()
            ) : (
              <BlogPosterSkeleton
                action="play"
                className={classes.loadingSkeleton}
              />
            );
          }
          return width > 780 ? (
            <div className={classes.container}>
              {data.posts.map(video => (
                <BlogPoster
                  key={video.id}
                  className={classes.videoCard}
                  poster={
                    video.featured_media && video.featured_media.source_url
                      ? video.featured_media.source_url
                      : "/mq.webp"
                  }
                  action="play"
                  title={decode(video.title)}
                  href={`/blog/news/${video.id}`}
                  cardClick={() => this.navigate(video.id)}
                />
              ))}
            </div>
          ) : (
            <VideoCarousel items={data.posts} />
          );
        }}
      </Query>
    ) : (
      <Query
        query={latestPostsQuery}
        variables={{
          page: 1,
          perPage: 2,
          categoryIds:
            currentLanguage === "fr"
              ? categoriesRefFr.videos
              : categoriesRefEn.videos,
          language: currentLanguage,
          excludeBlog: currentBlog.id
        }}
      >
        {({ loading, error, data }) => {
          if (error)
            return (
              <div className="error_container">
                <span>
                  {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                </span>
                <img
                  style={{
                    margin: 20
                  }}
                  src={"/assets/svg/download.svg"}
                  alt="error"
                />
              </div>
            );
          if (loading) {
            return appStore.width > 780 ? (
              this.loading()
            ) : (
              <BlogPosterSkeleton
                action="play"
                className={classes.loadingSkeleton}
              />
            );
          }
          return appStore.width > 780 ? (
            <div className={classes.container}>
              {data.posts.map(video => (
                <BlogPoster
                  key={video.id}
                  className={classes.videoCard}
                  poster={
                    video.featured_media && video.featured_media.source_url
                      ? video.featured_media.source_url
                      : "/mq.webp"
                  }
                  action="play"
                  title={video.title}
                  href={`/blog/news/${video.id}`}
                  cardClick={() => this.navigate(video.id)}
                />
              ))}
            </div>
          ) : (
            <VideoCarousel items={data.posts} />
          );
        }}
      </Query>
    );
  }
}

SugDisplayVideos.wrappedComponent.propTypes = {
  currentBlog: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf(["video", "book", "article"])
  }).isRequired,
  relatedPosts: PropTypes.arrayOf(PropTypes.string),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    currentLanguage: PropTypes.string
  }).isRequired
};

SugDisplayVideos.defaultProps = {
  relatedPosts: []
};

export default withRouter(SugDisplayVideos);
