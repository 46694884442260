import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Row, Col, TagList, Icon } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import classes from "./alertModal.module.scss";
import theme from "../../configs/theme";
import { withI18n } from "@lingui/react";
import { inject } from "mobx-react";

@inject("appStore")
class AlertModal extends Component {
  render() {
    const {
      onCancel,
      onSubmit,
      data,
      primaryButtonLabel,
      secondaryButtonLabel
    } = this.props;
    const { appStore } = this.props;
    const { currentLanguage } = appStore;
    return (
      <div className={classes.alertModal}>
        <div className={classes.header}>
          <div className={classes.headerIcon}>
            <Icon
              size="tiny"
              type="bell"
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
          </div>
          <Trans>Alerte Mission</Trans>
        </div>
        <div className={classes.body}>
          <Row>
            {data.length > 1
              ? data.map(col => (
                  <Col
                    sm={6}
                    xs={6}
                    key={new Date().valueOf() * Math.random()}
                    className={classes.alertColumn}
                  >
                    <div className={classes.subtitle}>{col.title}</div>
                    <div className={classes.tagList}>
                      {col.tags.includes("Permanent") &&
                      currentLanguage === "fr" ? (
                        <TagList
                          tags={["CDI"]}
                          variant="secondary"
                          onClose={this.closeTagHandler}
                        />
                      ) : col.tags.includes("Contract") &&
                        currentLanguage === "fr" ? (
                        <TagList
                          tags={["Freelance"]}
                          variant="secondary"
                          onClose={this.closeTagHandler}
                        />
                      ) : (
                        <TagList
                          tags={col.tags}
                          variant="secondary"
                          onClose={this.closeTagHandler}
                        />
                      )}
                    </div>
                  </Col>
                ))
              : data.map(col => (
                  <Col
                    key={new Date().valueOf() * Math.random()}
                    className={classes.alertColumn}
                  >
                    <div className={classes.subtitle}>{col.title}</div>
                    <div className={classes.tagList}>
                      {col.tags.includes("Permanent") &&
                      currentLanguage === "fr" ? (
                        <TagList
                          tags={["CDI"]}
                          variant="secondary"
                          onClose={this.closeTagHandler}
                        />
                      ) : col.tags.includes("Contract") &&
                        currentLanguage === "fr" ? (
                        <TagList
                          tags={["Freelance"]}
                          variant="secondary"
                          onClose={this.closeTagHandler}
                        />
                      ) : (
                        <TagList
                          tags={col.tags}
                          variant="secondary"
                          onClose={this.closeTagHandler}
                        />
                      )}
                    </div>
                  </Col>
                ))}
          </Row>
        </div>
        <div className={classes.footer}>
          <Button size="small" variant="secondary" onClick={onCancel}>
            {secondaryButtonLabel}
          </Button>
          <Button
            size="small"
            style={{ marginLeft: "10px" }}
            onClick={onSubmit}
          >
            {primaryButtonLabel}
          </Button>
        </div>
      </div>
    );
  }
}

AlertModal.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  primaryButtonLabel: PropTypes.string.isRequired,
  secondaryButtonLabel: PropTypes.string.isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

AlertModal.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {}
};

export default withI18n()(AlertModal);
