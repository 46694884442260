import React, { Component } from "react";
import * as PropTypes from "prop-types";
import moment from "moment";
import { Mutation } from "react-apollo";
import { ReportCard } from "cf-neo-ui";
import classes from "./DisplayReportCards.module.scss";
import runtimeVars from "../../../../configs/runTimeVars";
import SET_N_TS_SEEN from "../../timesheets-tab/notifications/mutation";

class DisplayReportCards extends Component {
  constructor(props) {
    super(props);
    moment.locale(runtimeVars.APP_LANG);
  }

  download = url => {
    window.open(url, "_blank");
  };

  getDateString = (month, year) => {
    return moment()
      .month(month - 1)
      .year(year)
      .format("MMMM YYYY");
  };

  render() {
    const { cards } = this.props;
    if (!cards) return null;
    return (
      <div className={classes.list}>
        {cards.map(card => (
          <Mutation
            key={card.id}
            mutation={SET_N_TS_SEEN}
            variables={{
              timesheetID: card.id,
              type: "REPORT_GENERATED"
            }}
            onCompleted={() => {}}
            onError={() => {}}
          >
            {setTSSeen => (
              <span onClick={() => setTSSeen()}>
                <ReportCard
                  style={{ width: "200px" }}
                  className={classes.item}
                  timesheet={{
                    id: card.placementID,
                    title: card.title,
                    date: this.getDateString(card.month, card.year),
                    project: ""
                  }}
                  footer={card.sum}
                  clickPDF={() => {
                    this.download(card.downloadLink);
                  }}
                />
              </span>
            )}
          </Mutation>
        ))}
      </div>
    );
  }
}

DisplayReportCards.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      project: PropTypes.string,
      date: PropTypes.string,
      footer: PropTypes.string,
      timesheet: PropTypes.shape({
        id: PropTypes.number
      })
    })
  )
};

DisplayReportCards.defaultProps = {
  cards: null
};

export default DisplayReportCards;
