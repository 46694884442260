import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import {
  Row,
  Col,
  AutoComplete,
  TagList,
  Validation,
  Button,
  FormGroup,
  TextInput,
  Select,
  InputSkeleton,
  Spinner,
  toaster
} from "cf-neo-ui";
import Routes from "../../layout/routes/index";
import validate from "../../../utils/validators";
import { UPDATE_OPPORTUNITY_FROM_FORM } from "../mutations";
import { SINGLE_OPPORTUNITY_QUERY } from "../query";
import languageList from "../../../configs/spokenLanguages";
import "./styles.scss";
import domaineExpertise from "../../../configs/domaineExpertise";
const GET_CATEGORIES = gql`
  {
    categories {
      name
    }
  }
`;
const GET_SKILLS = gql`
  {
    skills {
      name
    }
  }
`;

const GET_SPECIALTIES = gql`
  {
    specialties {
      name
    }
  }
`;

@inject("sessionStore", "postANeedStore")
@observer
class DesiredProfile extends Component {
  constructor(props) {
    super(props);

    const c = `${props.postANeedStore.certifications.map(
      certif => certif.text
    )}`;

    this.state = {
      isCategoriesValid: true,
      isSkillsValid: true,
      isSpecialtiesValid: true,
      isSpokenLanguagesValid: true,
      isAnnualSalaryValid: true,
      isDailyRateValid: true,
      categoriesValidationMessage: "",
      skillsValidationMessage: "",
      spokenLanguagesValidationMessage: "",
      specialtiesValidationMessage: "",
      annualSalaryValidationMessage: "",
      dailyRateValidationMessage: "",
      certifications: c,
      saveCase: false
    };
  }

  dailyRateChangeHandler = e => {
    const { postANeedStore } = this.props;
    postANeedStore.changeDailyRate(e.target.value);
    this.validateDailyRate(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  annualSalaryChangeHandler = e => {
    const { postANeedStore } = this.props;
    postANeedStore.changeAnnualSalary(e.target.value);
    this.validateAnnualSalary(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  experienceChangeHandler = item => {
    const { postANeedStore } = this.props;
    postANeedStore.changeExperience(item.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  spokenLanguagesAddHandler = item => {
    const { postANeedStore } = this.props;
    postANeedStore.addSpokenLanguage(item);
    this.validateSpokenLanguages(
      postANeedStore.spokenLanguages.map(language => language.value)
    );
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  spokenLanguagesRemoveHandler = item => {
    const { postANeedStore } = this.props;
    postANeedStore.removeSpokenLanguage(item);
    this.validateSpokenLanguages(
      postANeedStore.spokenLanguages.map(language => language.value)
    );
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  certificationsAddHandler = item => {
    const { postANeedStore } = this.props;
    postANeedStore.addCertification(item);
    this.validateCertifications(
      postANeedStore.certifications.map(certif => certif)
    );
    this.changeCertificationsFormat(postANeedStore.certifications);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  certificationsRemoveHandler = item => {
    const { postANeedStore } = this.props;
    postANeedStore.removeCertification(item);
    this.validateCertifications(
      postANeedStore.certifications.map(certif => certif)
    );
    this.changeCertificationsFormat(postANeedStore.certifications);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  categoryAddHandler = value => {
    const { i18n, postANeedStore } = this.props;
    postANeedStore.addCategory(domaineExpertise(i18n, value, "key"));
    this.validateCategories(postANeedStore.categories);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  categoryRemoveHandler = value => {
    const { i18n, postANeedStore } = this.props;
    postANeedStore.removeCategory(domaineExpertise(i18n, value, "key"));
    this.validateCategories(postANeedStore.categories);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  skillAddHandler = value => {
    const { postANeedStore } = this.props;
    postANeedStore.addSkill(value);
    this.validateSkills(postANeedStore.skills);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  skillRemoveHandler = value => {
    const { postANeedStore } = this.props;
    postANeedStore.removeSkill(value);
    this.validateSkills(postANeedStore.skills);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  specialtyAddHandler = value => {
    const { postANeedStore } = this.props;
    postANeedStore.addSpecialties(value);
    this.validateSpecialties(postANeedStore.specialties);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  specialtyRemoveHandler = value => {
    const { postANeedStore } = this.props;
    postANeedStore.removeSpecialties(value);
    this.validateSkills(postANeedStore.specialties);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  getMutationVariables = () => {
    const { postANeedStore } = this.props;
    const { certifications } = this.state;
    const {
      _id,
      categories,
      specialties,
      skills,
      dailyRate,
      annualSalary,
      experience,
      spokenLanguages,
      contractType
    } = postANeedStore;
    let expectedBillRate = parseFloat(annualSalary);
    if (contractType === "Freelance") expectedBillRate = parseFloat(dailyRate);

    const input = {
      _id,
      certifications,
      categories,
      specialties,
      skills,
      expectedBillRate,
      spokenLanguages: spokenLanguages.map(({ value }) => value),
      yearsRequired: experience
    };

    return { input };
  };

  skillsValidation = () => {
    const { postANeedStore } = this.props;
    this.validateSkills(postANeedStore.skills);
  };

  specialtiesValidation = () => {
    const { postANeedStore } = this.props;
    this.validateSpecialties(postANeedStore.specialties);
  };

  categoriesValidation = () => {
    const { postANeedStore } = this.props;
    this.validateCategories(postANeedStore.categories);
  };

  changeCertificationsFormat = certifications => {
    const c = `${certifications.map(certif => certif)}`;
    this.setState({ certifications: c });
  };

  updateOpportunityCompletedHandler = () => {
    const { i18n } = this.props;
    toaster.success({
      title: i18n._(t`Détails de l'offre`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
    this.setState({ saveCase: false });
  };

  isFormValid = () => {
    const { postANeedStore } = this.props;
    const {
      contractType,
      categories,
      skills,
      specialties,
      spokenLanguages,
      certifications,
      dailyRate,
      annualSalary
    } = postANeedStore;
    let valid = true;
    if (!this.validateSkills(skills)) valid = false;
    if (!this.validateCategories(categories)) valid = false;
    if (!this.validateSpokenLanguages(spokenLanguages)) valid = false;
    if (!this.validateSpecialties(specialties)) valid = false;
    if (contractType === "Freelance" || !contractType) {
      if (!this.validateDailyRate(dailyRate)) valid = false;
    } else if (!this.validateAnnualSalary(annualSalary)) valid = false;
    if (!this.validateCertifications(certifications)) valid = false;

    this.changeCertificationsFormat(certifications);

    return valid;
  };

  validateCertifications = value => {
    const { i18n } = this.props;
    const res = validate(value, [{ minlength: 0 }], i18n);
    return res.isValid;
  };

  validateDailyRate = value => {
    const { i18n } = this.props;
    const res = validate(value, ["number", { maxlength: 20 }], i18n);
    this.setState({
      isDailyRateValid: res.isValid,
      dailyRateValidationMessage: res.message
    });
    return res.isValid;
  };

  validateAnnualSalary = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "number", { maxlength: 20 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isAnnualSalaryValid: res.isValid,
      annualSalaryValidationMessage: res.message
    });
    return res.isValid;
  };

  validateCategories = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        "required",
        {
          rule: { minlength: 1 },
          msg: i18n._(t`Choisissez au moins un domaine`)
        }
      ],
      i18n
    );
    this.setState({
      isCategoriesValid: res.isValid,
      categoriesValidationMessage: res.message
    });
    return res.isValid;
  };

  validateSpokenLanguages = value => {
    const { i18n } = this.props;
    const res = validate(value, ["required", { minlength: 1 }], i18n);
    this.setState({
      isSpokenLanguagesValid: res.isValid,
      spokenLanguagesValidationMessage: res.message
    });
    return res.isValid;
  };

  validateSkills = value => {
    // be aware: any modification done here must be copied to skills validation in ./myInformation.jsx
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        {
          rule: { minlength: 2, maxlength: 10 },
          msg: i18n._(t`Choisissez entre deux et dix compétences`)
        }
      ],
      i18n
    );
    this.setState({
      isSkillsValid: res.isValid,
      skillsValidationMessage: res.message
    });
    return res.isValid;
  };

  validateSpecialties = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        {
          rule: { minlength: 1 },
          msg: ""
        }
      ],
      i18n
    );
    this.setState({
      isSpecialtiesValid: true,
      specialtiesValidationMessage: res.message
    });
    return true;
  };

  submit = () => {
    const valid = this.isFormValid();
    if (!valid) return;
    const { history } = this.props;
    history.push(Routes.HbLayoutRoutes.ConfirmPostANeed.path);
  };

  getValue = experience => {
    const { i18n } = this.props;
    if (experience === 1) return i18n._(t`Junior (0-3)`);
    if (experience === 2) return i18n._(t`Confirmed (3-7)`);
    if (experience === 3) return i18n._(t`Senior (7-10)`);
    if (experience === 4) return i18n._(t`Expert (10+)`);
    return i18n._(t`Sélectionner l'expérience demandée`);
  };

  render() {
    const { postANeedStore, i18n, history } = this.props;
    const { LpbLayoutRoutes } = Routes;
    const {
      isCategoriesValid,
      isSkillsValid,
      isDailyRateValid,
      isAnnualSalaryValid,
      isSpokenLanguagesValid,
      categoriesValidationMessage,
      skillsValidationMessage,
      spokenLanguagesValidationMessage,
      isSpecialtiesValid,
      specialtiesValidationMessage,
      annualSalaryValidationMessage,
      dailyRateValidationMessage,
      saveCase
    } = this.state;
    const {
      _id,
      contractType,
      categories,
      skills,
      specialties,
      spokenLanguages,
      certifications,
      dailyRate,
      experience,
      annualSalary
    } = postANeedStore;

    const categoriesToShow = [];
    categories.map(cat => categoriesToShow.push(domaineExpertise(i18n, cat)));

    return (
      <div className="profile my-information">
        <div className="formCard no-gutter with-padding">
          <Row>
            <Col lg={6} xl={6} md={6} sd={6} xs={6}>
              <FormGroup>
                <label className="form-label" htmlFor="expertise">
                  <Trans>Domaines d&apos;expertise</Trans>
                  <span className="asterisc">*</span>
                </label>
                <div>
                  <Query query={GET_CATEGORIES}>
                    {({ loading, error, data }) => {
                      if (loading) return <InputSkeleton />;
                      if (error) return i18n._(t`Erreur!`) + error.message;
                      const allCategories = [];
                      data.categories.map(cat =>
                        allCategories.push(domaineExpertise(i18n, cat.name))
                      );
                      return (
                        <div className="withSrcollBar">
                          <AutoComplete
                            autoComplete="off"
                            id="expertise"
                            clearOnSelect
                            options={allCategories}
                            iconColor="#FA324A"
                            iconColor2="#BF142A"
                            icon="chevron-down"
                            placeholder={i18n._(t`Sélectionner des domaines`)}
                            onSelect={val => this.categoryAddHandler(val)}
                          />
                        </div>
                      );
                    }}
                  </Query>
                </div>
                <Validation
                  errorMessage={categoriesValidationMessage}
                  valid={isCategoriesValid}
                >
                  <TagList
                    tags={categoriesToShow}
                    variant="secondary"
                    closable
                    className="tag-list"
                    onClose={val => this.categoryRemoveHandler(val)}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col lg={6} xl={6} md={6} sd={6} xs={6}>
              <FormGroup>
                <label className="form-label" htmlFor="specialities">
                  <Trans>Spécialités</Trans>
                </label>
                <div>
                  <Query query={GET_SPECIALTIES}>
                    {({ loading, error, data }) => {
                      if (loading) return <InputSkeleton />;
                      if (error) return i18n._(t`Erreur!`) + error.message;

                      const { specialties: specialtiesData } = data;
                      return (
                        <div className="withSrcollBar">
                          <AutoComplete
                            autoComplete="off"
                            id="specialities"
                            clearOnSelect
                            // clearOnClickOutside
                            options={specialtiesData.map(item => item.name)}
                            iconColor="#FA324A"
                            iconColor2="#BF142A"
                            icon="chevron-down"
                            placeholder={i18n._(
                              t`Sélectionner des spécialités`
                            )}
                            onSelect={val => this.specialtyAddHandler(val)}
                            // onClickOutside={this.specialtiesValidation}
                          />
                        </div>
                      );
                    }}
                  </Query>
                </div>
                <Validation
                  errorMessage={specialtiesValidationMessage}
                  valid={isSpecialtiesValid}
                >
                  <TagList
                    tags={specialties}
                    variant="secondary"
                    closable
                    className="tag-list"
                    onClose={val => this.specialtyRemoveHandler(val)}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6} md={6} sd={6} xs={6}>
              <FormGroup>
                <label className="form-label" htmlFor="skills">
                  <Trans>Comp&eacute;tences</Trans>
                  <span className="asterisc">*</span>
                </label>
                <div>
                  <Query query={GET_SKILLS}>
                    {({ loading, error, data }) => {
                      if (loading) return <InputSkeleton />;
                      if (error) return i18n._(t`Erreur!`) + error.message;
                      const allSkills = [];
                      data.skills.map(skill => allSkills.push(skill.name));
                      return (
                        <div className="withSrcollBar">
                          <AutoComplete
                            autoComplete="off"
                            id="skills"
                            clearOnSelect
                            // clearOnClickOutside
                            options={allSkills}
                            iconColor="#FA324A"
                            iconColor2="#BF142A"
                            icon="chevron-down"
                            placeholder={i18n._(
                              t`Sélectionner des compétences`
                            )}
                            onSelect={val => this.skillAddHandler(val)}
                            // onClickOutside={this.skillsValidation}
                          />
                        </div>
                      );
                    }}
                  </Query>
                </div>
                <Validation
                  errorMessage={skillsValidationMessage}
                  valid={isSkillsValid}
                >
                  <TagList
                    tags={skills}
                    variant="secondary"
                    closable
                    className="tag-list"
                    onClose={val => this.skillRemoveHandler(val)}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col lg={6} xl={6} md={6} sd={6} xs={6}>
              <FormGroup>
                <label className="form-label" htmlFor="spokenLanguages">
                  <Trans>Langues parlées</Trans>
                  <span className="asterisc">*</span>
                </label>
                <div>
                  <Select
                    id="spokenLanguages"
                    options={{
                      groupA: languageList(i18n)
                    }}
                    onSelect={this.spokenLanguagesAddHandler}
                    placeholder={i18n._(t`Sélectionner des langues`)}
                  />
                </div>
                <Validation
                  errorMessage={spokenLanguagesValidationMessage}
                  valid={isSpokenLanguagesValid}
                >
                  <TagList
                    tags={spokenLanguages.map(language => language.text)}
                    variant="secondary"
                    closable
                    className="tag-list"
                    onClose={val => this.spokenLanguagesRemoveHandler(val)}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6} md={6} sd={6} xs={6}>
              <FormGroup>
                <label className="form-label" htmlFor="experienceYears">
                  <Trans>Expérience</Trans>
                  <span className="asterisc">*</span>
                </label>
                <Select
                  id="experienceYears"
                  options={{
                    groupA: [
                      {
                        text: i18n._(t`Junior (0-3)`),
                        value: 1
                      },
                      {
                        text: i18n._(t`Confirmed (3-7)`),
                        value: 2
                      },
                      {
                        text: i18n._(t`Senior (7-10)`),
                        value: 3
                      },
                      { text: i18n._(t`Expert (10+)`), value: 4 }
                    ]
                  }}
                  onSelect={this.experienceChangeHandler}
                  placeholder={this.getValue(experience)}
                />
              </FormGroup>
            </Col>
            <Col lg={6} xl={6} md={6} sd={6} xs={6}>
              <FormGroup>
                <label className="form-label" htmlFor="certifications">
                  <Trans>Certifications</Trans>
                </label>
                <div>
                  <AutoComplete
                    id="certifications"
                    autoComplete="off"
                    clearOnSelect
                    options={[
                      "ITIL",
                      "Prince2",
                      "PMI",
                      "ISTQB",
                      "SAFE",
                      "CISCO",
                      "CCNP",
                      "CCIA",
                      "HANA",
                      "ISO27001",
                      "CCNA",
                      "EBIOS",
                      "AWS",
                      "AZURE",
                      "Salesforce Admin",
                      "Salesforce developer",
                      "Scrum",
                      "Agile"
                    ]}
                    iconColor="#FA324A"
                    iconColor2="#BF142A"
                    icon="chevron-down"
                    placeholder={i18n._(t`Sélectionner des certifications`)}
                    onSelect={val => this.certificationsAddHandler(val)}
                    valid={true}
                  />
                </div>
                <TagList
                  tags={certifications.map(certif => certif)}
                  variant="secondary"
                  closable
                  className="tag-list"
                  onClose={val => this.certificationsRemoveHandler(val)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6} md={6} sd={6} xs={6}>
              {contractType === "Freelance" || !contractType ? (
                <FormGroup>
                  <label className="form-label" htmlFor="dailyRate">
                    <Trans>Taux journalier moyen</Trans>
                  </label>
                  <Validation
                    errorMessage={dailyRateValidationMessage}
                    valid={isDailyRateValid}
                  >
                    <TextInput
                      id="dailyRate"
                      type="number"
                      autoComplete="off"
                      className="form-input"
                      placeholder={i18n._(t`Par jour`)}
                      iconColor="#D3354A"
                      value={dailyRate}
                      onChange={this.dailyRateChangeHandler}
                    />
                  </Validation>
                </FormGroup>
              ) : (
                <FormGroup>
                  <label className="form-label" htmlFor="annualSalary">
                    <Trans>Salaire Annuel</Trans>
                    <span className="asterisc">*</span>
                  </label>
                  <Validation
                    errorMessage={annualSalaryValidationMessage}
                    valid={isAnnualSalaryValid}
                  >
                    <TextInput
                      id="annualSalary"
                      type="text"
                      className="form-input"
                      placeholder={i18n._(t`Par an`)}
                      iconColor="#D3354A"
                      value={annualSalary}
                      onChange={this.annualSalaryChangeHandler}
                    />
                  </Validation>
                </FormGroup>
              )}
            </Col>
          </Row>
          <br />
          <div className="next-buttons">
            <Button
              variant="secondary"
              onClick={() => history.push(LpbLayoutRoutes.Offers.path)}
            >
              <Trans>Annuler</Trans>
            </Button>
            <Mutation
              mutation={UPDATE_OPPORTUNITY_FROM_FORM}
              variables={this.getMutationVariables()}
              refetchQueries={[
                {
                  query: SINGLE_OPPORTUNITY_QUERY,
                  variables: { id: _id }
                }
              ]}
              onCompleted={data => this.updateOpportunityCompletedHandler(data)}
              onError={errors => {
                errors.graphQLErrors.forEach(({ message, data }) => {
                  if (data && data.isCustomError) {
                    this.onErrorHandler(message);
                  }
                });
              }}
            >
              {(mutation, { loading }) => (
                <Button
                  disabled={loading || !saveCase}
                  onClick={() => {
                    if (this.isFormValid()) return mutation();
                    return null;
                  }}
                >
                  {loading ? (
                    <Spinner
                      type="pointed-circular"
                      color="#FFFFFF"
                      size={12}
                    />
                  ) : (
                    <Trans>Enregistrer</Trans>
                  )}
                </Button>
              )}
            </Mutation>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

DesiredProfile.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  postANeedStore: PropTypes.shape({
    changeContractType: PropTypes.func,
    changeJobTitle: PropTypes.func,
    changeProjectName: PropTypes.func,
    changeRemote: PropTypes.func,
    changeAddress: PropTypes.func,
    changeExperience: PropTypes.func,
    addSpokenLanguage: PropTypes.func,
    removeSpokenLanguage: PropTypes.func,
    addCertification: PropTypes.func,
    removeCertification: PropTypes.func,
    addCategory: PropTypes.func,
    removeCategory: PropTypes.func,
    addSkill: PropTypes.func,
    removeSkill: PropTypes.func,
    addSpecialties: PropTypes.func,
    removeSpecialties: PropTypes.func,
    changeDailyRate: PropTypes.func,
    changeAnnualSalary: PropTypes.func,
    changeStartDate: PropTypes.func,
    changeEndDate: PropTypes.func,
    changeRecruitmentContext: PropTypes.func,
    changeJobDescription: PropTypes.func,
    resetFields: PropTypes.func,
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    contractType: PropTypes.string,
    experience: PropTypes.number,
    categories: PropTypes.shape({}),
    skills: PropTypes.shape({}),
    specialties: PropTypes.shape({}),
    spokenLanguages: PropTypes.shape({}),
    certifications: PropTypes.shape({}),
    dailyRate: PropTypes.number,
    annualSalary: PropTypes.number
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(DesiredProfile));
