import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { Button, FormCard, Icon, Row, Col, Radio, RadioGroup } from "cf-neo-ui";
import { Mutation } from "react-apollo";
import moment from "moment";
import { CREATE_ACCOUNT_DELETION_REQUEST_MUTATION } from "./mutations";
import Routes from "../../layout/routes";
import runtimeVars from "../../../configs/runTimeVars";
import styles from "./styles.module.scss";

@inject("modalStore", "sessionStore")
@observer
class DeleteAccount extends Component {
  constructor(props) {
    super(props);
    moment.locale(runtimeVars.APP_LANG);
    const initProps = { ...props };
    this.state = {
      ...initProps,
      check: false,
      buttonClicked: false,
      option: "ACCOUNT_DELETION", //take 2 value "ACCOUNT_DELETION" or "ACCOUNT_AND_DATA_DELETION"
      clicked: false
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  isFormValid() {
    const { option } = this.state;
    let valid = true;
    if (!option) valid = false;
    if (!valid) return false;
    return valid;
  }

  clickCancelHandler = () => {
    const { modalStore } = this.props;
    const { changeParamsDeleteAccountPopUp } = modalStore;
    changeParamsDeleteAccountPopUp(false);
    this.setState({ clicked: false });
  };

  changeOption = v => {
    this.setState({ option: v });
  };

  render() {
    const { PrivacyPolicy, TermsAndConditionsOfUse } = Routes.HbLayoutRoutes;
    const { i18n, sessionStore } = this.props;
    const { account } = sessionStore;
    const { option, clicked } = this.state;
    const { role } = account;
    return (
      <div className={styles.popUpContainer}>
        <FormCard className="formCard no-gutter">
          <Row>
            <Col className={styles.formCardContent}>
              <div className={styles.header}>
                <Icon
                  className={styles.bellIcon}
                  type="Bell"
                  color="#8D0417"
                  color2="#D3354A"
                  width={14}
                  height={14}
                />
                <p>
                  {clicked ? (
                    <Trans>Suppression en cours</Trans>
                  ) : (
                    <Trans>Suppression du compte ou de vos données</Trans>
                  )}
                </p>
                <Icon
                  className={styles.closeIcon}
                  type="close"
                  color="#8D0417"
                  color2="#D3354A"
                  width={14}
                  height={14}
                  onClick={() => this.clickCancelHandler()}
                />
              </div>
              <div className={styles.container}>
                {clicked ? (
                  <div>
                    <p>
                      <Trans>
                        Nous avons bien pris en compte votre demande. Les
                        ajustements seront effectués par nos équipes dans un
                        délai de 30 jours. Merci d’avoir utilisé les services de
                        Mindquest.
                      </Trans>
                    </p>
                    <p
                      style={{
                        color: "#8D0417",
                        fontStyle: "italic",
                        fontSize: "14px"
                      }}
                    >
                      <Trans>
                        Consulter notre{" "}
                        <Link
                          to={PrivacyPolicy.path}
                          target="_blank"
                          style={{ color: "#8D0417" }}
                        >
                          {" "}
                          politique de confidentialité{" "}
                        </Link>{" "}
                        et nos{" "}
                        <Link
                          to={TermsAndConditionsOfUse.path}
                          target="_blank"
                          style={{ color: "#8D0417" }}
                        >
                          {" "}
                          conditions générales d’utilisations{" "}
                        </Link>{" "}
                      </Trans>
                    </p>
                  </div>
                ) : (
                  <p>
                    {role === "CANDIDATE" ? (
                      <Trans>
                        Il est possible de supprimer uniquement votre compte ou
                        supprimer toutes vos informations de notre base de
                        données. Cette dernière action nous empêchera de vous
                        contacter pour de nouvelles opportunités.
                      </Trans>
                    ) : (
                      <Trans>
                        Il est possible de supprimer uniquement votre compte ou
                        supprimer toutes vos informations de notre base de
                        données. Cependant, cela nous empêchera de vous proposer
                        des talents IT à l’avenir.
                      </Trans>
                    )}
                  </p>
                )}

                {!clicked && (
                  <RadioGroup
                    id="work-type"
                    name="RadioGrp"
                    onChange={this.changeOption}
                    orientation="vertical"
                    className={styles.radioBlock}
                    defaultSelected={"ACCOUNT_DELETION"}
                  >
                    <Radio
                      style={{ whiteSpace: "pre-line", textAlign: "left" }}
                      label={i18n._(
                        t`Supprimer uniquement votre profil Mindquest`
                      )}
                      value="ACCOUNT_DELETION"
                    />
                    <Radio
                      style={{ whiteSpace: "pre-line", textAlign: "left" }}
                      label={i18n._(
                        t`Supprimer le profil et vos données personnelles`
                      )}
                      value="ACCOUNT_AND_DATA_DELETION"
                    />
                  </RadioGroup>
                )}
                {!clicked && (
                  <div className={styles.buttonsBlock}>
                    <Button
                      className={styles.cancelButton}
                      variant="secondary"
                      size="small"
                      onClick={() => this.clickCancelHandler()}
                    >
                      <Trans>Annuler</Trans>
                    </Button>
                    <Mutation
                      mutation={CREATE_ACCOUNT_DELETION_REQUEST_MUTATION}
                      variables={{
                        option
                      }}
                      onCompleted={() => {
                        this.setState({ clicked: true });
                        this.setState({ option: null });
                      }}
                      onError={errors => {
                        errors.graphQLErrors.forEach(({ message, data }) => {
                          if (data && data.isCustomError) {
                            this.onErrorHandler(message);
                          }
                        });
                      }}
                    >
                      {(mutation, { loading }) => (
                        <Button
                          disabled={loading}
                          style={{ marginLeft: "20px" }}
                          variant="primary"
                          size="small"
                          onClick={() => {
                            if (this.isFormValid()) {
                              return mutation();
                            }
                            return null;
                          }}
                        >
                          <Trans>Confirmer la suppression</Trans>
                        </Button>
                      )}
                    </Mutation>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

DeleteAccount.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    changeParamsDeleteAccountPopUp: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(DeleteAccount));
