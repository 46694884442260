import React, { PureComponent } from "react";
import { Trans } from "@lingui/macro";
import classes from "./Companies.module.scss";
import Zoom from "react-reveal/Zoom";

class Companies extends PureComponent {
  render() {
    return (
      <div className={classes.container}>
        <h5 className={classes.title}>
          <Trans>These companies trust us with their IT talent needs</Trans>
        </h5>
        <div className={classes.comapniesLogos}>
          <div className={classes.logo}>
            <Zoom>
              <img
                src="/assets/images/companies/air-liquide.svg"
                alt="AIR Liquide"
              />
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/biogaran.svg"
                />
                <img
                  src="/assets/images/companies/biogaran.svg"
                  alt="BIOGARAN"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/coyote.svg"
                />
                <img src="/assets/images/companies/coyote.svg" alt="Coyote" />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/imerys.svg"
                />
                <img src="/assets/images/companies/imerys.svg" alt="Imerys" />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/laposte.svg"
                />
                <img
                  src="/assets/images/companies/laposte.svg"
                  alt="La poste"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/microsoft.svg"
                />
                <img
                  src="/assets/images/companies/microsoft.svg"
                  alt="Microsoft"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/svg"
                  srcSet="/assets/images/companies/saint-gobain.svg"
                />
                <img
                  src="/assets/images/companies/saint-gobain.svg"
                  alt="Saint Gobain"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/savencia.svg"
                />
                <img
                  src="/assets/images/companies/savencia.svg"
                  alt="Savencia"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <img src="/assets/images/companies/suez.svg" alt="Suez" />
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <img src="/assets/images/companies/vivescia.svg" alt="Vivescia" />
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/vinci.svg"
                />
                <img src="/assets/images/companies/vinci.svg" alt="Vinci" />
              </picture>
            </Zoom>
          </div>
        </div>
      </div>
    );
  }
}

export default Companies;
