import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react/index";
import { withRouter } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import {
  MissionDisplay,
  SourcerContactCard,
  MissionCardSkeleton
} from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { Query } from "react-apollo";
import FaveUnfave from "../../components/fave-unfave";
import formatMissionDuration from "../../utils/formatMissionDuration";
import classes from "./styles.module.scss";
import Routes from "../layout/routes";
import CorporateUserQuery from "./query";
import defaultSourcer from "../single-mission/defaultValues";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import runtimeVars from "../../configs/runTimeVars";

@inject("sessionStore")
@observer
class SourcerOffers extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  showDetails = id => {
    const { history } = this.props;
    history.push(`${Routes.HbfLayoutRoutes.MissionsPage.path}/${id}`);
  };

  toLinkedIn = (url, defaultUrl) => {
    if (url && url !== "0") window.open(url, "_blank");
    else window.open(defaultUrl, "_blank");
  };

  toContact = sourcer => {
    const { sessionStore, history } = this.props;
    sessionStore.setContactPageEmail({
      email: sourcer.email,
      name: sourcer.firstName
    });
    history.push(Routes.HbLayoutRoutes.contact.path);
  };

  displayMissions = missions => {
    const { i18n } = this.props;
    if (!missions || !missions.length) {
      return (
        <div>
          <Trans>
            Il n’y a pas des missions disponible a ce moment pour ce sourceur
          </Trans>
        </div>
      );
    }
    return missions.map(mission => (
      <div key={mission.id} className={classes.mission}>
        <FaveUnfave missionId={mission.id}>
          <MissionDisplay
            title={mission.title}
            location={mission.address.city}
            duration={formatMissionDuration(
              mission.duration,
              i18n,
              mission.employmentType
            )}
            field={mission.customText1}
            urgent={mission.customText10 ? i18n._(t`URGENT`) : ""}
            onShowDetails={() => this.showDetails(mission.id)}
            showDetailsLabel={i18n._(t`Voir l’offre`)}
            href={`${Routes.HbfLayoutRoutes.MissionsPage.path}/${mission.id}`}
          />
        </FaveUnfave>
      </div>
    ));
  };

  corporateUserDescription = sourcer => {
    if (runtimeVars.APP_LANG.toLowerCase() === "fr") {
      if (sourcer.customText3 && sourcer.customText3 !== "0")
        return sourcer.customText3;
    } else if (sourcer.customText2 && sourcer.customText2 !== "0")
      return sourcer.customText2;
    return null;
  };

  render() {
    const { i18n, match } = this.props;
    let sourcer = {};
    const defaults = defaultSourcer(i18n);
    const meta = staticMetaTags(i18n).sourcerOffers;
    return (
      <Query query={CorporateUserQuery} variables={{ id: match.params.id }}>
        {({ loading, error, data }) => {
          if (error) return <div className={classes.errors}>Error</div>;
          if (!loading) sourcer = data.corporateUser;
          if (!loading && !data.corporateUser)
            return (
              <div className={classes.errors}>
                <Trans>Il n’y a pas de sourcer avec cet Id</Trans>
              </div>
            );
          return (
            <div className={classes.container}>
              {!loading &&
                metaTags(
                  urls.sourcerOffers(sourcer.id),
                  meta.title,
                  `${meta.description}, ${sourcer.firstName}`,
                  meta.openGraphImage.facebookOpenGraphImageUrl,
                  meta.openGraphImage.linkedInOpenGraphImageUrl,
                  `${meta.keywords}, ${sourcer.firstName}`
                )}
              <div className={classes.sourcerCard}>
                {loading ? null : (
                  <SourcerContactCard
                    avatar={sourcer.headShot}
                    showButton={true}
                    textButton={false}
                    name={sourcer.firstName || defaults.name}
                    position={sourcer.occupation || defaults.position}
                    email={sourcer.email || defaults.email}
                    emailClick={() => this.toContact(sourcer)}
                    phoneNumber={sourcer.phone || defaults.phoneNumber}
                    description={
                      this.corporateUserDescription(sourcer) ||
                      defaults.description
                    }
                    onApply={() => this.toContact(sourcer)}
                    onIconClick={() =>
                      this.toLinkedIn(sourcer.customText1, defaults.linkedIn)
                    }
                    applyButtonLabel={i18n._(t`Contact`)}
                  />
                )}
              </div>
              <div className={classes.missions}>
                {loading
                  ? [1, 2, 3].map(mission => (
                      <div key={mission} className={classes.mission}>
                        <MissionCardSkeleton />
                      </div>
                    ))
                  : this.displayMissions(sourcer.jobOrders)}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

SourcerOffers.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    setContactPageEmail: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(SourcerOffers));
