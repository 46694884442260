import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import {
  Button,
  Col,
  Divider,
  FormCard,
  FormGroup,
  Row,
  Icon,
  Spinner,
  TextInput,
  toaster
} from "cf-neo-ui";
import { withRouter } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import gql from "graphql-tag";
import theme from "../../configs/theme";

import classes from "../../components/alert-modal/alertModal.module.scss";

@inject("appStore", "sessionStore", "modalStore", "talentStore")
@observer
class ModalContactRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDescriptionValid: true,
      descriptionValidationMessage: ""
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }
  closeModal = () => {
    const { modalStore } = this.props;
    const { changetoDelete } = modalStore;
    changetoDelete(false);
  };
  onErrorHandlerupdate = msg => {
    const { i18n } = this.props;
    if (
      msg ===
      'Unexpected error value: "Vous avez dépasser le nombre limité de vivier"'
    ) {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(t`Vous avez dépasser le nombre limité de vivier`)
      });
    } else {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Une erreur est survenue, veuillez réessayer ultérieurement`
        )
      });
    }
  };
  render() {
    const { i18n, history, close } = this.props;

    return (
      <div className={classes.bodyPage}>
        <div className={classes.alertModal}>
          <div className={classes.header}>
            <div className={classes.headerIcon}>
              <Icon
                size="tiny"
                type="ok"
                width={30}
                height={30}
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
            </div>
            <Trans>Votre demande est bien enregistrée</Trans>
          </div>
          <div className={classes.body}>
            <Row style={{ marginLeft: "30px", marginRight: "30px" }}>
              {i18n._(t`Notre équipe Mindquest échangera avec le consultant afin de  
              vérifier sa disponibilté. Nous reviendrons vers vous
              dans le plus breff délais.`)}
            </Row>
          </div>
          <div className={classes.footerContinue}>
            <a
              style={{
                margin: "13px",
                "text-decoration": "underline",
                color: "#d02e2e"
              }}
              onClick={() => close()}
            >
              <Trans>Continuer</Trans>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

ModalContactRequest.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape
  }).isRequired,
  modalStore: PropTypes.shape({
    changetoDelete: PropTypes.func
  }).isRequired,
  vivierID: PropTypes.string
};

export default withI18n()(withRouter(ModalContactRequest));
