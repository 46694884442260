import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Button } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";

// it is used for the animation
import Fade from "react-reveal/Fade";
import Routes from "../../layout/routes/index";
import classes from "./HeroSection1.module.scss";

@inject("sessionStore")
@observer
class HeroSection1 extends Component {
  render() {
    const { history } = this.props;
    const { HbLayoutRoutes } = Routes;
    const { SignUp } = HbLayoutRoutes;
    return (
      <div className={classes.hero}>
        <Fade top>
          <h6>
            <br />
            <br />
            <Trans>Vous êtes freelance ?</Trans>
          </h6>
        </Fade>
        <Fade bottom>
          <h1>
            <Trans>Rejoignez le Club</Trans>
          </h1>
        </Fade>
        <Fade bottom>
          <h2 className={classes.paragraph}>
            <Trans>Trouvez le job IT qui vous correspond vraiment</Trans>
          </h2>
        </Fade>

        <div style={{ paddingTop: "60px" }}>
          <Fade bottom>
            <Button
              className={classes.styleButton}
              icon="award"
              iconPosition="left"
              variant="secondary"
              onClick={() => history.push(`${SignUp.path}?type=consultant`)}
              id="cta_hf_sec1_join"
            >
              <Trans>Rejoindre le club</Trans>
            </Button>
          </Fade>
        </div>
      </div>
    );
  }
}

// https://github.com/mobxjs/mobx-react/issues/70#issuecomment-231834410
HeroSection1.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(HeroSection1));
