import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import { Row, IconCircle } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import Fade from "react-reveal/Fade";

import CATEGORIES_QUERY from "./query";
import classes from "./ExpertisesSection1.module.scss";
import "react-multi-carousel/lib/styles.css";
import Result from "./Result";
import domaineExpertise from "../../../configs/domaineExpertise";

@inject("missionsSearchStore", "appStore")
@observer
class ExpertisesSection1 extends Component {
  toDetails = val => {
    const { history, missionsSearchStore } = this.props;
    missionsSearchStore.addCategory(val);
    missionsSearchStore.setCategoryOptions();
    history.push(`/missions`);
  };

  moreExpertises = () => {
    const { appStore } = this.props;
    appStore.changeClicked();
  };

  getImage = id => {
    return `/assets/images/categories/${id}.webp`;
  };

  render() {
    const { appStore, i18n } = this.props;
    const allCategories = [];
    return (
      <div className={classes.expertise}>
        <Fade>
          <h2>
            <Trans>Expertise</Trans>
          </h2>
        </Fade>
        <Fade>
          <p className={classes.paragraph}>
            <Trans>Quel est votre domaine ?</Trans>
          </p>
        </Fade>
        <Query query={CATEGORIES_QUERY}>
          {({ data }) => {
            if (data && data.categories) {
              data.categories.map(cat => {
                allCategories.push({
                  name: domaineExpertise(i18n, cat.name),
                  skills: cat.skills,
                  id: cat.id
                });
              });
              return <Result categories={allCategories} />;
            } else return null;
          }}
        </Query>

        {appStore.clicked === false && (
          <Row className={classes.row}>
            {appStore.width < 768 ? (
              <div />
            ) : (
              <div className={classes.center}>
                <IconCircle
                  className={classes.IconCircle}
                  type="plus"
                  dimension={50}
                  innerColor="#fff"
                  innerColor2="#fde2f8"
                  color="#910E20"
                  color2="#910E20"
                  iconWidth={24}
                  iconHeight={24}
                  onClick={() => this.moreExpertises()}
                />
                <p>
                  <Trans>plus d’expertises</Trans>
                </p>
              </div>
            )}
          </Row>
        )}
      </div>
    );
  }
}

ExpertisesSection1.wrappedComponent.propTypes = {
  appStore: PropTypes.shape({
    width: PropTypes.number,
    clicked: PropTypes.bool,
    changeClicked: PropTypes.func,
    changeWidth: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  missionsSearchStore: PropTypes.shape({
    addCategory: PropTypes.func,
    setCategoryOptions: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(ExpertisesSection1));
