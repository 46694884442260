import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { Container } from "cf-neo-ui";
import { Query } from "react-apollo";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { inject, observer } from "mobx-react/index";
import classes from "./stylesTalentsInPool.module.scss";

import { LIST_POOL_LOGS_QUERY } from "../../dashboard-company/queries";

@inject("appStore", "sessionStore", "modalStore", "talentStore")
@observer
class History extends Component {
  constructor(props) {
    super(props);
  }

  getOperationText = log => {
    const { i18n } = this.props;
    const { sessionStore, searchTest } = this.props;
    const { poolName } = sessionStore;
    const talentName =
      log.talent && log.talent.firstName ? log.talent.firstName : "";
    const clientName =
      log.client && log.client.firstName && log.client.lastName
        ? log.client.firstName + " " + log.client.lastName
        : log.client && log.talent.firstName
        ? log.talent.firstName
        : log.client && log.client.lastName
        ? log.client.lastName
        : `${i18n._(t`Le client`)}`;
    if (log.operation === "Create Talent") {
      return (
        <div>
          <br />
          <b>{clientName} </b>
          <span>
            {`${i18n._(t`a ajouté le talent`)}` +
              " '' " +
              talentName +
              " #" +
              log.talentID +
              `${i18n._(t`'' au vivier le `)}` +
              moment.unix(log.createdAt / 1000).format("DD/MM/YYYY")}
          </span>
        </div>
      );
    } else if (log.operation === "Delete Talent") {
      return (
        <div>
          <br />
          <b>{clientName} </b>
          <span>
            {`${i18n._(t`a supprimé le talent `)}` +
              " ''" +
              talentName +
              " #" +
              log.talentID +
              `${i18n._(t`'' du vivier le `)}` +
              moment.unix(log.createdAt / 1000).format("DD/MM/YYYY")}
          </span>
        </div>
      );
    } else if (log.operation === "Create TalentPool" && !searchTest) {
      return (
        <div>
          <br />
          <b>{clientName}</b>
          <span>
            {" " +
              `${i18n._(t`a crée le vivier `)}` +
              " ''" +
              poolName +
              `${i18n._(t`'' le `)}` +
              moment.unix(log.createdAt / 1000).format("DD/MM/YYYY")}
          </span>
          .
        </div>
      );
    }
  };
  render() {
    const { location, sessionStore, i18n } = this.props;
    const { client } = sessionStore;
    const vivierID = location.pathname.split("talents-pool/")[1];
    let i = 0;
    return (
      <div>
        <Query
          query={LIST_POOL_LOGS_QUERY}
          variables={{
            vivierID: vivierID,
            clientID: client._id
          }}
          fetchPolicy="network-only"
          onCompleted={data => {}}
        >
          {({ loading, error, data }) => {
            if (loading)
              return <span>{i18n._(t` Veuillez patienter ... `)}</span>;
            if (error) {
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );
            }
            let { listPoolLogs } = data;
            return (
              <div className={classes.historyContainer}>
                <Container>
                  {listPoolLogs.map(log => {
                    return (
                      <div>
                        <span> {this.getOperationText(log)}</span>
                      </div>
                    );
                  })}
                </Container>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

History.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  shared: PropTypes.string.isRequired,
  searchTest: PropTypes.bool.isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  appStore: PropTypes.shape({
    changeWidth: PropTypes.func,
    width: PropTypes.number
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisible: PropTypes.func,
    changeIsVisible2: PropTypes.func,
    changeIsVisibleListPools: PropTypes.func
  }).isRequired,
  talentStore: PropTypes.shape({
    changeTalent: PropTypes.func,
    talent: PropTypes.shape
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(withApollo(History)));
