import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Button, H2, H3, P } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import classes from "./Banner.module.scss";
import HbLayoutRoutes from "../../containers/layout/routes/hbLayoutRoutes";
import withLogin from "../hoc/withLogin";

class Banner extends Component {
  clickHandler = () => {
    const { history } = this.props;
    history.push(`${HbLayoutRoutes.SignUp.path}?type=consultant`);
  };

  render() {
    return (
      <div className={classes.container}>
        <div className={classes.textBlock} id="BLOCK1">
          <H2 className={classes.header}>
            <Trans>Ne cherchez plus !</Trans>
          </H2>
          <br />
          <P className={classes.text}>
            <Trans>
              Rejoignez le club pour recevoir
              <br />
              des offres adaptées à vos envies.
            </Trans>
          </P>
        </div>
        <div className={classes.buttonBlock} id="BLOCK2">
          <Button
            className={classes.buttonLabel}
            icon="chevron-right"
            variant="secondary"
            size="small"
            onClick={this.clickHandler}
            id="cta_mi_ban_join"
          >
            <Trans>Rejoindre le club en 3 minutes</Trans>
          </Button>
        </div>
      </div>
    );
  }
}

Banner.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withLogin(withRouter(Banner));
