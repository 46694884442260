import React, { PureComponent } from "react";
import { TextSkeleton } from "cf-neo-ui";
import classes from "./Loading.module.scss";

class Loading extends PureComponent {
  render() {
    return (
      <div className={classes.limitedBody}>
        <div className={classes.categories}>
          <span>
            <TextSkeleton />
          </span>
          <span>
            <TextSkeleton />
          </span>
        </div>
        <div className={classes.title}>
          <TextSkeleton heading />
        </div>
      </div>
    );
  }
}

export default Loading;
