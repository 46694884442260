import React, { Component } from "react";
import * as PropTypes from "prop-types";
import * as queryString from "query-string";
import { withRouter } from "react-router-dom";
import { Tabs, Tab } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import classes from "./styles.module.scss";
import RecommendedMissions from "./RecommendedMissions";
import FavoriteMissions from "./FavoriteMissions";
import MyMissions from "./MyMissions";
import MyAlerts from "./MyAlerts";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";

class DashboardMissions extends Component {
  constructor(props) {
    super(props);
    const queryObj = queryString.parse(props.location.search);
    this.state = {
      activeTab: queryObj.active,
      fromWithinMissions: !!queryObj.active
    };
  }

  tabChangeHandler = tabName => {
    const { history } = this.props;
    history.replace({ search: `?active=${tabName}` });
    this.setState({ activeTab: tabName, fromWithinMissions: true });
  };

  render() {
    const { i18n } = this.props;
    const { activeTab, fromWithinMissions } = this.state;
    const meta = staticMetaTags(i18n).missions;
    return (
      <>
        {metaTags(
          urls.missionsDashboard(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          null,
          null,
          false
        )}
        <Tabs
          className={classes.dashboardMissions}
          defaultActiveTab="application"
          activeTab={activeTab}
          onChange={this.tabChangeHandler}
        >
          <Tab name="recommendation" tab={i18n._(t`Offres recommandées `)}>
            <RecommendedMissions />
          </Tab>
          <Tab name="application" tab={i18n._(t`Mes candidatures`)}>
            <MyMissions fromWithinMissions={fromWithinMissions} />
          </Tab>
          <Tab name="fav" tab={i18n._(t`Favoris`)}>
            <FavoriteMissions />
          </Tab>
          <Tab name="alert" tab={i18n._(t`Mes alertes`)}>
            <MyAlerts />
          </Tab>
        </Tabs>
      </>
    );
  }
}

DashboardMissions.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(DashboardMissions));
