import React, { Component } from "react";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import classes from "./CompanySection1.module.scss";

class CompanySection1 extends Component {
  render() {
    return (
      <div className={classes.company}>
        <Fade>
          <h2>
            <Trans>Nos références clients</Trans>
          </h2>
        </Fade>
        <Fade>
          <p className={classes.paragraph}>
            <Trans>Ils ont réalisés des projets avec nos freelances.</Trans>
          </p>
        </Fade>

        <div className={classes.comapniesLogos}>
          <div className={classes.logo}>
            <Zoom>
              <img
                src="/assets/images/companies/air-liquide.svg"
                alt="AIR Liquide"
                width="100%"
                height="100%"
              />
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/biogaran.svg"
                />
                <img
                  src="/assets/images/companies/biogaran.svg"
                  alt="BIOGARAN"
                  width="100%"
                  height="100%"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/coyote.svg"
                />
                <img
                  src="/assets/images/companies/coyote.svg"
                  alt="Coyote"
                  width="100%"
                  height="100%"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/imerys.svg"
                />
                <img
                  src="/assets/images/companies/imerys.svg"
                  alt="Imerys"
                  width="100%"
                  height="100%"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/laposte.svg"
                />
                <img
                  src="/assets/images/companies/laposte.svg"
                  alt="La poste"
                  width="100%"
                  height="100%"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/microsoft.svg"
                />
                <img
                  src="/assets/images/companies/microsoft.svg"
                  alt="Microsoft"
                  width="100%"
                  height="100%"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/svg"
                  srcSet="/assets/images/companies/saint-gobain.svg"
                />
                <img
                  src="/assets/images/companies/saint-gobain.svg"
                  alt="Saint Gobain"
                  width="100%"
                  height="100%"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/savencia.svg"
                />
                <img
                  src="/assets/images/companies/savencia.svg"
                  alt="Savencia"
                  width="100%"
                  height="100%"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/suez.svg"
                />
                <img
                  src="/assets/images/companies/suez.svg"
                  alt="Suez"
                  width="100%"
                  height="100%"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <img
                src="/assets/images/companies/vivescia.svg"
                alt="Vivescia"
                width="100%"
                height="100%"
              />
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/vinci.svg"
                />
                <img
                  src="/assets/images/companies/vinci.svg"
                  alt="Vinci"
                  width="100%"
                  height="100%"
                />
              </picture>
            </Zoom>
          </div>
        </div>
      </div>
    );
  }
}

export default withI18n()(CompanySection1);
