import { observable, action } from "mobx";
import runtimeVars from "../configs/runTimeVars";

const baseUrl = runtimeVars.FRONTEND_BASE_URL;
const frenchUrl = runtimeVars.FRONTEND_BASE_FR_URL;

const defaultWidth = typeof window === "undefined" ? 1000 : window.screen.width;

const extractLanguage = () => {
  if (typeof window === "undefined") return null;
  return localStorage.getItem("language");
};

const checkHost = () => {
  if (typeof window !== "undefined") {
    const full = window.location.host;
    // TODO : CHECK IP COUNTRY FR
    const parts = full.split(".");
    const subdomain = parts[0];

    if (subdomain === "fr") {
      localStorage.setItem("language", "fr");
    } else {
      localStorage.setItem("language", "en");
    }
    let currentLanguage = localStorage.getItem("language");
    let hrefVar;
    if (currentLanguage === "en") hrefVar = baseUrl;
    if (currentLanguage === "fr") hrefVar = frenchUrl;

    document
      ?.getElementsByTagName("html")[0]
      ?.setAttribute("lang", currentLanguage);
  }
};

export default class AppStore {
  @observable width;

  @observable isMapsScriptReady;

  @observable clicked;

  @observable layoutRefreshKey;

  @observable url;

  //@observable currentLanguage;

  constructor(initialState = {}) {
    this.width = initialState.width || defaultWidth;
    this.isMapsScriptReady = initialState.width || false;
    this.clicked = initialState.width || false;
    this.layoutRefreshKey = initialState.layoutRefreshKey || 0;
    this.url = initialState.url || "";
    this.currentLanguage =
      initialState.currentLanguage ||
      checkHost() ||
      extractLanguage() ||
      runtimeVars.APP_LANG;
  }

  @action
  setMapsScript = () => {
    this.isMapsScriptReady = true;
  };

  @action
  refreshLayout = () => {
    this.layoutRefreshKey += 1;
  };

  @action
  changeClicked = () => {
    this.clicked = !this.clicked;
  };

  @action
  changeUrl = url => {
    this.url = url;
  };

  @action
  changeWidth = () => {
    this.width = window.innerWidth;
  };

  @action
  changeLanguage = currentLanguage => {
    if (!currentLanguage) return;
    this.currentLanguage = currentLanguage.toLowerCase();

    if (typeof window !== "undefined") {
      const full = window.location.host;
      const parts = full.split(".");
      const subdomain = parts[0];
      localStorage.setItem("language", currentLanguage.toLowerCase());

      let path = window.location.pathname;
      if (currentLanguage === "fr" && subdomain !== "fr") {
        window.location.href = frenchUrl + path;
      } else if (currentLanguage !== "fr" && subdomain === "fr") {
        window.location.href = baseUrl + path;
      }
    }
  };
}
