import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Col, Talent } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import routes from "../../layout/routes/hbfLayoutRoutes";
import classes from "../../dashboard-missions/styles.module.scss";

@inject("modalStore", "talentStore")
@observer
class List extends Component {
  constructor(props) {
    super(props);
    const { recommended } = this.props;
    this.state = {
      recommended
    };
  }

  skipHandler = (id, skipMutation) => {
    const { recommended } = this.state;
    const index = recommended.findIndex(i => i.id === id);
    recommended.splice(index, 1);
    this.setState({ recommended });
    if (!recommended.length) {
      const { fetchMore } = this.props;
      fetchMore();
    }
    if (skipMutation) skipMutation();
  };

  toCV = talent => {
    const { modalStore, talentStore } = this.props;
    const { changeIsVisible, changeIsVisible2 } = modalStore;
    if (talent.accessible) {
      talentStore.changeTalent(talent);
      changeIsVisible2(true);
    } else changeIsVisible(true);
  };

  interested = id => {
    const { history } = this.props;
    history.push(`${routes.MissionsPage.path}/${id}`);
  };

  /* getPointColor = date => {
    const today = new Date();
    const dateAvailable = new Date(date);
    if (dateAvailable - today >= 0) return "red";
    return "blue";
  }; */

  getPointColor = (date, offerStatus) => {
    if (offerStatus) {
      const today = new Date();
      const dateAvailable = new Date(date);
      if (dateAvailable - today >= 0) return "red";
      return "green";
    }
    return "blue";
  };

  getAvailability = (date, offerStatus) => {
    const { i18n } = this.props;
    if (offerStatus) {
      const today = new Date();
      const dateAvailable = new Date(date);
      const diffYears = this.yearsDiff(today, dateAvailable);
      if (diffYears >= 1) return i18n._(t`disponible dans ${diffYears} ans`);
      if (diffYears === 0) {
        const diffMonths = this.monthsDiff(today, dateAvailable);
        if (diffMonths >= 1)
          return i18n._(t`disponible dans ${diffMonths} mois`);
        if (diffMonths === 0) {
          const diffDays = this.daysDiff(today, dateAvailable);
          if (diffDays >= 2)
            return i18n._(t`disponible dans ${diffDays} jours`);
          if (diffDays === 1) return i18n._(t`disponible dans un jour`);
        }
      }

      return i18n._(t`disponible immédiatement`);
    }

    return i18n._(t`Disponibilité à confirmer`);
  };

  yearsDiff = (d1, d2) => {
    return d2.getFullYear() - d1.getFullYear();
  };

  monthsDiff = (d1, d2) => {
    return d2.getMonth() - d1.getMonth();
  };

  daysDiff = (d1, d2) => {
    const secondsDiff = Math.floor((d2 - d1) / 1000);
    const minutesDiff = Math.floor(secondsDiff / 60);
    const hoursDiff = Math.floor(minutesDiff / 60);
    return Math.floor(hoursDiff / 24);
  };

  getEmploymentPreference = employmentPreference => {
    const list = [];
    if (employmentPreference && employmentPreference !== "") {
      const employmentPreferenceArray = employmentPreference.split(",");
      for (let j = 0; j < employmentPreferenceArray.length; j++) {
        if (employmentPreferenceArray[j] === "Permanent") {
          list.push("CDI");
        }
        if (employmentPreferenceArray[j] === "Contract") {
          list.push("Freelance");
        }
      }
      return list.toString();
    }
    return "";
  };

  render() {
    const { i18n, n, xl, lg, md, sm, xs } = this.props;
    let { recommended } = this.state;
    recommended = Array.isArray(recommended) ? recommended.slice(0, n) : [];
    return recommended[0] != null ? (
      recommended.map(
        talent =>
          talent != null && (
            <Col
              key={talent.id}
              xl={xl}
              lg={lg}
              md={md}
              sm={sm}
              xs={xs}
              className={classes.favedMissionsCard}
            >
              <Talent
                key={talent.id}
                talent={{
                  id: talent.id,
                  image: "/defaultAvatar.webp",
                  name: `${i18n._(t`candidat`)}  #${talent.id}`,
                  position: i18n._(t`${talent.occupation}`),
                  disponibility: this.getAvailability(
                    talent.dateAvailable,
                    talent.accessible
                  ),
                  pointColor: this.getPointColor(
                    talent.dateAvailable,
                    talent.accessible
                  ),
                  employmentPreference: this.getEmploymentPreference(
                    talent.employmentPreference
                  ),
                  onFave: () => {}
                }}
                onClick={() => this.toCV(talent)}
                withFav={false}
                draggable={false}
              />
            </Col>
          )
      )
    ) : (
      <div className={classes.talentCards}>
        <p className={classes.text1}>
          <Trans>
            Il n’y a pas de talents qualifiés par nos équipes pour le moment.
          </Trans>
        </p>
        <p className={classes.text2}>
          <Trans>Pour recevoir des profils, soumettez une offre.</Trans>
        </p>
      </div>
    );
  }
}

List.propTypes = {
  recommended: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.shape({}),
      customText1: PropTypes.string,
      customText10: PropTypes.string,
      duration: PropTypes.shape({}),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      total: PropTypes.string
    })
  ).isRequired,
  modalStore: PropTypes.shape({
    isVisible: PropTypes.bool,
    isVisible2: PropTypes.bool,
    changeIsVisible: PropTypes.func,
    changeIsVisible2: PropTypes.func,
    changeModalType: PropTypes.func,
    modalType: PropTypes.string
  }).isRequired,
  talentStore: PropTypes.shape({
    changeTalent: PropTypes.func,
    talent: PropTypes.shape
  }).isRequired,
  fetchMore: PropTypes.func,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  n: PropTypes.number,
  xl: PropTypes.number,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number
};

List.defaultProps = {
  fetchMore: () => {},
  n: 12,
  xl: 3,
  lg: 3,
  md: 4,
  sm: 6,
  xs: 6
};

export default withI18n()(withRouter(List));
