import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import { t, Trans } from "@lingui/macro";
import { Row, Col, FormCard, Table } from "cf-neo-ui";
import Routes from "../layout/routes/index";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";

import "./styles.scss";
import { Link } from "react-router-dom";

@inject("sessionStore")
@observer
class PrivacyPolicy extends Component {
  handleBackRef = () => {
    const { history, sessionStore } = this.props;
    const { MissionsPage } = Routes.HbfLayoutRoutes;
    history.push(`${MissionsPage.path}/${sessionStore.backRef}`);
    sessionStore.setBackRef(null);
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  componentDidMount() {
    this.scrollToTop();
  }

  render() {
    const { i18n } = this.props;
    const meta = staticMetaTags(i18n).privacyPolicy;
    return (
      <div className="session container privacy-policy">
        {metaTags(
          urls.privacyPolicy(),
          meta.title,
          meta.description,
          null,
          null,
          meta.keywords
        )}
        <br />
        <Row>
          <Col className="content" lg={6} xl={6}>
            <div className="center-txt bold">
              <h1 className="privacy-policy-title">
                <Trans>NOTRE POLITIQUE DE CONFIDENTIALITÉ</Trans>
              </h1>
              <span className="text-gray-bold">
                <Trans>Updated on 1 February 2022</Trans>
              </span>
            </div>
            <br />
          </Col>
        </Row>

        <br />

        <FormCard className="formCard no-gutter">
          <Row className="with-padding">
            <Col className="center">
              <br />
              <span>
                <Trans>
                  <strong> Club FREELANCE LIMITED </strong>, a company
                  registered in England and Wales under number 9050953, with
                  registered office at Biscuit Factory, 100 Drummond Rd, London
                  SE16 4DG, United Kingdom (‘the <strong> Company</strong>’)
                  attaches great importance to the protection of personal data
                  which it has to collect and process in the course of its
                  activity from both the users of its services and the platform
                  it operates.
                </Trans>
                <br />
                <br />
                <Trans>
                  Thus, the collection and processing of personal data carried
                  out by the Company in the course of the operation of its
                  business and use of the goods and services, including the
                  website operated by the Company at
                  <Link to="/">
                    <Trans> https://mindquest.io</Trans>
                  </Link>{" "}
                  (‘the <strong>Site</strong> ’), are governed by this
                  confidentiality policy (hereinafter referred to as ‘the{" "}
                  <strong>Policy</strong>’).
                </Trans>
                <br />
                <br />
                <span>
                  <Trans>
                    The purpose of this policy is to present to data subjects:
                  </Trans>
                </span>
                <ul>
                  <li>
                    <Trans>
                      The manner in which the Company processes personal data,
                      as defined below, which it collects and which the data
                      subjects, as defined below, provide with their consent to
                      enable the supply of, inter alia, the Company’s products
                      and services;
                    </Trans>
                  </li>
                  <br />
                  <li>
                    <Trans>
                      The rights of data subjects, as defined below;
                    </Trans>
                  </li>
                  <br />
                  <li>
                    <Trans>The name of the processor, as defined below;</Trans>
                  </li>
                  <br />
                  <li>
                    <Trans>Any beneficiaries of a data transfer.</Trans>
                  </li>
                </ul>
                <br />
                <Trans>
                  Data subjects are therefore invited to read this document
                  carefully to find out and understand the company’s practices
                  regarding the processing of users’ personal data.
                </Trans>
                <br />
                <br />
              </span>
              <h2 className="title">
                <Trans>1. Definitions</Trans>
              </h2>
              <br />
              <span>
                <Trans>
                  Capitalised terms have the following definition. Terms have
                  the same definition regardless of whether they are used in
                  singular or plural.
                </Trans>
              </span>
              <ul>
                <li>
                  <Trans>
                    <strong>Client (s)</strong> means any legal or natural
                    person that has created an account and can offer a job offer
                    or assignment for which users registered on the Platform can
                    apply.
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    <strong>‘Personal data’</strong> means any information
                    relating to an identified or identifiable natural person
                    (‘data <strong>subject</strong>’).
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    <strong>‘Controller’</strong> means the natural or legal
                    person, public authority, agency or other body which alone
                    or jointly with others determines the purposes and means of
                    the processing; where the purposes and means of such
                    processing are determined by Union or Member State law, the
                    controller may be designated or the specific criteria for
                    its designation may be provided for by Union or Member State
                    law. In the present case, the Company is the controller.
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    <strong>“Talent”</strong> means any person applying for a
                    permanent job, or duly registered in any trade and company
                    register or employed by an umbrella company and wishes to
                    offer his services to Clients.
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    <strong>‘Processing’</strong> means any operation or set of
                    operations which is performed upon personal data or sets of
                    personal data, whether or not by automatic means, such as
                    collection, recording, organisation, structuring, storage,
                    adaptation or alteration, retrieval, consultation, use,
                    disclosure by transmission, dissemination or otherwise
                    making available, alignment or combination, restriction,
                    erasure or destruction.
                  </Trans>
                </li>
              </ul>

              <h2 className="title">
                <Trans>
                  2. General rules on the collection and processing of personal
                  data
                </Trans>
              </h2>
              <br />
              <span>
                <Trans>
                  In accordance with Article 5 of the General Data Protection
                  Regulation No 2016/679 (GDPR), personal data must be:
                </Trans>
              </span>
              <ul>
                <li>
                  <Trans>
                    Processed lawfully, fairly and transparently in relation to
                    the person concerned;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    Collected for specified, explicit and legitimate purposes;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    Adequate, relevant and limited to what is necessary;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    Kept for no longer than is necessary for the purposes for
                    which the data are processed;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    Protected in their integrity and kept confidential.
                  </Trans>
                </li>
              </ul>

              <span>
                <Trans>
                  In accordance with Article 6 of the GDPR, personal data shall
                  be lawfully processed if such processing meets at least one of
                  the following characteristics:
                </Trans>
              </span>
              <ul>
                <li>
                  <Trans>
                    The person concerned has given his or her consent;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    Processing is necessary for the performance of a contract;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    Processing is necessary for compliance with a legal
                    obligation;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    Processing is necessary to protect the vital interests of
                    the data subject;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    Processing is necessary for the performance of a task
                    carried out in the public interest;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    The processing is in pursuit of the legitimate interests of
                    the controller.
                  </Trans>
                </li>
              </ul>

              <h2 className="title">
                <Trans>3. Nature of the collection of personal data</Trans>
              </h2>
              <br />
              <span>
                <Trans>
                  Data subjects may be required to provide the Company, in its
                  capacity as Controller within the meaning of the GDPR, with
                  information and personal data relating to them.
                </Trans>
              </span>
              <br />
              <span>
                <Trans>
                  These elements can be grouped into two categories according to
                  the data subjects, namely Talents and Clients.
                </Trans>
              </span>
              <br />
              <span className="black-title2">
                <Trans> For Talents, the following data are collected:</Trans>
              </span>
              <ul>
                <li>
                  <Trans>
                    Identification data: surnames, forenames, date and place of
                    birth, copy of your identity document, work permit;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    Details of your contact details: postal address, e-mail
                    address, mobile phone number;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    Data on your economic and socio-occupational situation:
                    professional experience, academic training, bank details,
                    Curriculum Vitae, references to former clients or employers,
                    current and envisaged remuneration;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    Data on the legal structure of Talent: name of the company,
                    identification number, legal representative of the company
                    and legal documents.
                  </Trans>
                </li>
              </ul>

              <span className="black-title2">
                <Trans> For Clients, the following data are collected:</Trans>
              </span>
              <ul>
                <li>
                  <Trans>
                    Surnames, forenames, gender, professional e-mail address;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>Data on recruitment needs.</Trans>
                </li>
              </ul>

              <span>
                <Trans>
                  It should be noted that the controller is, in accordance with
                  the applicable legislation, the entity which defines and
                  limits the data to be collected and the purposes of
                  processing.
                </Trans>
                <br />
                <br />
                <Trans>
                  Some personal data are required, while others are optional, to
                  access or benefit from certain products and services of the
                  Company.
                </Trans>
                <br />
                <br />
                <Trans>
                  In any event, such data shall be collected and processed on
                  the basis of a legal obligation, a legitimate interest of the
                  Company and/or the consent of the individual concerned.
                </Trans>
                <br />
                <br />
                <Trans>
                  If personal data of a mandatory nature are not communicated,
                  Company will not be able to reply to your requests, if any.
                </Trans>
              </span>
              <br />
              <h2 className="title">
                <Trans>4. Purpose of the collection of personal data</Trans>
              </h2>
              <br />
              <span>
                <Trans>
                  The Company, as the Controller, requests and collects personal
                  data concerning the individual concerned when the latter
                  wishes to acquire the products and/or services offered by the
                  Company.
                </Trans>
              </span>
              <br />
              <span>
                <Trans>
                  This information is necessary for the Company to perform the
                  services properly and to enable it to comply with its legal
                  obligations.
                </Trans>
              </span>
              <br />
              <span>
                <Trans>
                  Without them, the Company might not be able to provide all the
                  goods and/or services requested and ordered by the individual
                  concerned.
                </Trans>
              </span>
              <br />
              <span>
                <Trans>
                  The main purpose of collecting, storing and processing this
                  information and personal data is to:
                </Trans>
              </span>
              <ul>
                <li>
                  <Trans>
                    Offer clients recruitment services (permanent jobs or
                    contracts);
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    Propose IT assignments or CDI permanent job offers to
                    Talents Consultants;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>Management of the customer relationship;</Trans>
                </li>
                <br />
                <li>
                  <Trans>To fulfil contractual obligations;</Trans>
                </li>
                <br />
                <li>
                  <Trans>Managing the Company’s platform user account;</Trans>
                </li>
                <br />
                <li>
                  <Trans>Managing applications submitted by the Talents;</Trans>
                </li>
                <br />
                <li>
                  <Trans>The Company’s communication campaigns;</Trans>
                </li>
                <br />
                <li>
                  <Trans>Satisfaction surveys;</Trans>
                </li>
                <br />
                <li>
                  <Trans>Market research.</Trans>
                </li>
              </ul>

              <h2 className="title">
                <Trans>
                  5. Recipient (s) of the collection of personal data
                </Trans>
              </h2>
              <br />
              <span>
                <Trans>
                  The Company may share personal data collected for the purposes
                  indicated below with the following recipients:
                </Trans>
              </span>
              <ul>
                <li>
                  <Trans>
                    Subsidiaries: for more details on their location, click on
                    this{" "}
                    <Link to="/contact">
                      <Trans> link</Trans>
                    </Link>{" "}
                    .
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    Service providers: provision of services on behalf of the
                    Company (for example: maintenance of the website, host,
                    system management and security);
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    any administrative or judicial authority shall: upon request
                    under the applicable law.
                  </Trans>
                </li>
              </ul>

              <h2 className="title">
                <Trans>6. Storage period for personal data</Trans>
              </h2>
              <br />
              <span>
                <Trans>
                  Personal data and/or information will be kept only:
                </Trans>
              </span>
              <ul>
                <li>
                  <Trans>
                    To enable the Company to pursue the processing purposes
                    specified herein;
                  </Trans>
                </li>
                <br />
                <li>
                  <Trans>
                    In order to comply with the legal and regulatory obligations
                    relating to conservation and archiving.
                  </Trans>
                </li>
              </ul>
              <h2 className="title">
                <Trans>7. Exercise of the rights of data subjects</Trans>
              </h2>
              <br />
              <span>
                <Trans>
                  In accordance with the rules on the protection of personal
                  data, and in particular the General Data Protection Regulation
                  No 2016/679 (GDPR), everyone has the right of access,
                  rectification, restriction, deletion, where applicable
                  portability of data concerning him or her, and the right to
                  object, possibly subject to legitimate and compelling reasons,
                  to the processing of information and personal data relating to
                  him or her, to be exercised at any time by means of the data
                  amendment request form:{" "}
                  <Link to="/contact">
                    <Trans> https://mindquest.io/contact </Trans>
                  </Link>
                </Trans>
                <br />
                <br />
                <Trans>
                  Each individual concerned shall have the right to lodge a
                  complaint with a competent supervisory authority.
                </Trans>
                <br />
                <br />
              </span>

              <h2 className="title">
                <Trans>
                  8. Transfer of personal data outside the European Union
                </Trans>
              </h2>
              <br />
              <span>
                <Trans>
                  In general, the Company keeps the personal data of the persons
                  concerned in the territory of the European Union.
                </Trans>
                <br />
                <br />
                <Trans>
                  However, since some of the Company’s partners and service
                  providers may be located outside the European Union, personal
                  data collected in this way may be transferred to countries
                  which are not members of the European Union, whose legislation
                  on the protection of personal data differs from the European
                  Union. If the recipient country or countries do not ensure a
                  level of data protection equivalent to the European Union, the
                  company undertakes to take all appropriate safeguards, either
                  on the basis of an adequacy decision or, in the absence of
                  such a decision, on the basis of appropriate safeguards such
                  as the standard contractual clauses adopted.
                </Trans>
                <br />
                <br />
              </span>

              <h2 className="title">
                <Trans>9. Links to other websites and social media</Trans>
              </h2>
              <br />
              <span>
                <Trans>
                  The site may occasionally contain links to the websites of our
                  partners or third companies.
                </Trans>
                <br />
                <br />
                <Trans>
                  Please note that these websites have their own confidentiality
                  policy and that the Company accepts no responsibility for the
                  use by these websites and the information collected when you
                  click on these links. The Company invites you to read the
                  confidentiality policies of these sites before sending them
                  your personal data.
                </Trans>
                <br />
                <br />
              </span>

              <h2 className="title">
                <Trans>
                  10. Amendments to the Personal Data Protection Policy
                </Trans>
              </h2>
              <br />
              <span>
                <Trans>
                  The Company reserves the right to make any amendment to this
                  Personal Data Policy at any time in accordance with this
                  clause.
                </Trans>
                <br />
                <br />
                <Trans>
                  If the Company amends this Personal Data Protection Policy, it
                  will publish the new version on its website and update the
                  “Latest update” date at the top of this Personal Data
                  Protection Policy.
                </Trans>
                <br />
                <br />
                <Trans>
                  The Company therefore invites you to consult this page
                  regularly.
                </Trans>
              </span>
              <br />
              <br />
              <h2 className="title">
                <Trans>INFORMATION ON COOKIES</Trans>
              </h2>
              <br />
              <br />
              <h2 className="title">
                <Trans>1) Definition of cookies</Trans>
              </h2>
              <br />
              <span>
                <Trans>
                  A cookie is a small file that is uploaded to a device
                  (telephone, computer, etc.) when the user accesses an Internet
                  site. Cookies are used by many websites, while others make it
                  possible to remember user preferences, the language in which
                  the website is displayed, to record the user’s basket on a
                  shopping website, etc.
                </Trans>
                <br />
                <br />
                <Trans>
                  Cookies vary according to their origin and lifespan.
                </Trans>
              </span>
              <div className="with-padding">
                <ol>
                  <li>
                    <span>
                      <Trans>Lifetime of cookies</Trans>
                    </span>
                    <br />
                    <br />
                    <span>
                      <ul>
                        <li>
                          <Trans>
                            Session cookies: are stored on your computer and are
                            deleted when you leave your browser;
                          </Trans>
                        </li>
                        <li>
                          <Trans>
                            Persistent cookies: remain registered on your
                            computer even when you leave your browser (are
                            deleted after a deadline that is determined
                            according to the purpose of cookies, the user also
                            has the possibility to delete them manually).
                          </Trans>
                        </li>
                      </ul>
                    </span>
                  </li>
                  <br />
                  <li>
                    <span>
                      <Trans>Origin of cookies</Trans>
                    </span>
                    <br />
                    <br />
                    <span>
                      <ul>
                        <li>
                          <Trans>
                            Internal cookies: are cookies created and used by
                            the website;
                          </Trans>
                        </li>
                        <li>
                          <Trans>
                            Third party cookies: cookies created and used on the
                            website by external providers.
                          </Trans>
                        </li>
                        <br />
                      </ul>
                    </span>
                  </li>
                </ol>
              </div>
              <h2 className="title">
                <Trans>2) Managing cookies</Trans>
              </h2>
              <br />
              <span>
                <Trans>
                  The Company and its providers use cookies to improve your
                  digital experience and ensure better navigation security. You
                  can accept or refuse these different operations at any time by
                  clicking on "accept" or "refuse" in the cookie configuration
                  panel.
                </Trans>
                <br />
                <br />
                <Trans>
                  In addition, you have the possibility at any time to delete or
                  disable most cookies in your browser.
                </Trans>
              </span>
              <br />
              <span>
                <Trans>
                  To set cookies on your browser, you will find below the
                  browsers:
                </Trans>
              </span>
              <ul>
                <li>
                  <a href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en">
                    Google Chrome
                  </a>
                </li>
                <li>
                  <a href="https://support.microsoft.com/fr-fr/windows/microsoft-edge-donn%C3%A9es-de-navigation-et-confidentialit%C3%A9-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">
                    Microsoft Edge
                  </a>
                </li>
                <li>
                  <a href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac">
                    Apple Safari
                  </a>
                </li>
                <li>
                  <a href="https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies">
                    Mozilla Firefox
                  </a>
                </li>
              </ul>
              <h2 className="title">
                <Trans>3) Cookies used by the company</Trans>
              </h2>
              <br />
              <span className="black-title2">
                <Trans>Cookies strictly necessary</Trans>
              </span>
              <span>
                <Trans>
                  These cookies are essential for the functioning of the site.
                  In accordance with the applicable law, cookies strictly
                  necessary for the operation of the website are exempted from
                  collecting users’ consent.
                </Trans>
                <br />
                <br />
                <Trans>
                  However, you have the possibility to refuse these cookies by
                  setting your browser. In such a case, the site might suffer
                  partial malfunctions.
                </Trans>
              </span>
              <br />
              <Table
                head={[
                  i18n._(t`Name`),
                  i18n._(t`Purpose`),
                  i18n._(t`Type`),
                  i18n._(t`Duration`)
                ]}
                body={[
                  [
                    i18n._(t`actionCookie`),
                    i18n._(t`Optimisation of navigation`),
                    i18n._(t`1st Party`)
                  ],
                  [
                    i18n._(t`AUTH_TOKEN`),
                    i18n._(t`Optimisation of navigation`),
                    i18n._(t`1st Party`)
                  ],
                  [
                    i18n._(t`Google tag manager`),
                    i18n._(t`Configuration`),
                    i18n._(t`3 RD Party`)
                  ],
                  [
                    i18n._(t`EngageBay`),
                    i18n._(t`Sending of e-mails`),
                    i18n._(t`3 RD Party`)
                  ],
                  [
                    i18n._(t`Herefish`),
                    i18n._(t`Sends emails`),
                    i18n._(t`3 RD Party`)
                  ]
                ]}
                fixedColumns={1}
              />
              <br />
              <span className="black-title2">
                <Trans>Performance cookies</Trans>
              </span>
              <span>
                <Trans>
                  These cookies allow us to assess the performance of the site
                  and measure the audience. They also make it possible to find
                  out which pages the users visit on our website. This
                  information is collected by these cookies in anonymised form.
                </Trans>
              </span>
              <br />
              <Table
                head={[
                  i18n._(t`Name`),
                  i18n._(t`Purpose`),
                  i18n._(t`Type`),
                  i18n._(t`Duration`)
                ]}
                body={[
                  [
                    i18n._(t`firstVisitOrigin`),
                    i18n._(t`Site analysis cookie`),
                    i18n._(t`1st Party`)
                  ],
                  [
                    i18n._(t`Google Analytics`),
                    i18n._(t`Site analysis cookie`),
                    i18n._(t`3 RD Party`)
                  ],
                  [
                    i18n._(t`Hotjar`),
                    i18n._(t`Site analysis cookie`),
                    i18n._(t`3 RD Party`)
                  ],
                  [
                    i18n._(t`LinkedIn Insights`),
                    i18n._(t`Site analysis cookie`),
                    i18n._(t`3 RD Party`)
                  ],
                  [
                    i18n._(t`Printed`),
                    i18n._(t`Site analysis cookie`),
                    i18n._(t`3 RD Party`)
                  ],
                  [
                    i18n._(t`Neuvoo`),
                    i18n._(t`Site analysis cookie`),
                    i18n._(t`3 RD Party`)
                  ]
                ]}
                fixedColumns={1}
              />
              <br />
              <span className="black-title2">
                <Trans>Advertising cookies</Trans>
              </span>
              <span>
                <Trans>
                  The Company can offer you advertising content that can be
                  based on your interests.
                </Trans>
              </span>
              <br />
              <Table
                head={[
                  i18n._(t`Name`),
                  i18n._(t`Purpose`),
                  i18n._(t`Type`),
                  i18n._(t`Duration`)
                ]}
                body={[
                  [
                    i18n._(t`Google Adwords`),
                    i18n._(t`Cookie of advertising`),
                    i18n._(t`3 RD Party`)
                  ]
                ]}
                fixedColumns={1}
              />
              <br />
              <br />
              <br />
              <br />
            </Col>
          </Row>
        </FormCard>
      </div>
      // <div className="session container privacy-policy">
      //   {metaTags(
      //     urls.privacyPolicy(),
      //     meta.title,
      //     meta.description,
      //     meta.keywords
      //   )}
      //   <br />
      //   <Row>
      //     <Col className="content" lg={6} xl={6}>
      //       <div className="center-txt bold">
      //         <h1 className="privacy-policy-title">
      //           <Trans>CONFIDENTIALITY POLICY</Trans>
      //         </h1>
      //         <span className="text-gray-bold">
      //           <Trans>Updated on 1 February 2022</Trans>
      //         </span>
      //       </div>
      //       <br />
      //     </Col>
      //   </Row>
      //
      //   <br />
      //
      //   <FormCard className="formCard no-gutter">
      //     <Row className="with-padding">
      //       <Col className="center">
      //         <br />
      //         <span className="title">
      //           <Trans>Introduction</Trans>:
      //         </span>
      //         <span>
      //           <Trans>
      //             Club Freelance Limited is a staffing and sourcing company
      //             specialized in IT.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             Club Freelance Limited (company number 9050953) and its
      //             affiliates respect your privacy and are committed to
      //             protecting the privacy and security of your personal
      //             information under applicable data privacy laws.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             We are committed to ensuring that when we collect and use
      //             information about Website Users, our Candidates and Clients,
      //             we do so in accordance with all data protection legislation
      //             including the GDPR.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             This Privacy Notice sets out the basis on which we collect,
      //             store, use and disclose personal data we receive in writing,
      //             through our Websites, or through the recruitment services that
      //             we provide. It therefore applies to personal data that you
      //             provide to Club Freelance telephonically, electronically
      //             (including email) and in person.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             For the purpose of the GDPR, the data controller is, Club
      //             Freelance Limited, 100 Drummond Road SE 16 4DG London UK.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             This Privacy Policy was last updated in January 2019. The
      //             Privacy policy may be updated from time to time and should be
      //             read in the context of any additional specific information
      //             such as that provided in privacy policies applicable to
      //             specific businesses or local areas as displayed on the
      //             relevant Website or distributed to you from time to time.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             Please read the following carefully to understand our views
      //             and practices regarding your personal data, how we will treat
      //             it and your rights.
      //           </Trans>
      //         </span>
      //         <span className="title">
      //           <Trans>What kind of Personal Data do we collect?</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             We may collect, store, and use the following categories of
      //             personal information about you:
      //           </Trans>
      //         </span>
      //         <span className="black-title">
      //           <Trans>Candidate Data</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             Depending on the relevant circumstances and applicable local
      //             laws and requirements, we may collect information to enable us
      //             to offer you potential employment opportunities which are
      //             tailored to your circumstances and your interests. This
      //             information may include:
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             Personal contact details such as name, title, addresses,
      //             telephone numbers, personal and work email addresses, Skype or
      //             other web ID;
      //           </Trans>
      //           <br />
      //           <Trans>Sex/Gender;</Trans>
      //           <br />
      //           <Trans>
      //             A copy of your driving license and/or passport/identity card;
      //             Photograph;
      //           </Trans>
      //           <br />
      //           <Trans>Date of birth;</Trans>
      //           <br />
      //           <Trans>Languages;</Trans>
      //           <br />
      //           <Trans>Education & Qualifications;</Trans>
      //           <br />
      //           <Trans>Career History;</Trans>
      //           <br />
      //           <Trans>Daily rate Range;</Trans>
      //           <br />
      //           <Trans>Right to work status / citizenship;</Trans>
      //           <br />
      //           <Trans>
      //             Other information relevant to help us provide recruitment
      //             services;
      //           </Trans>
      //           <br />
      //           <Trans>References from past employers;</Trans>
      //           <br />
      //           <Trans>
      //             IP address National Insurance number or country equivalent;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             Limited company information, their representative’s
      //             information and payment and financial information and
      //             certificates included but not limited to certificate of
      //             incorporation, certificate of professional insurance;
      //           </Trans>
      //           <br />
      //           <Trans>Bank account details your company;</Trans>
      //           <br />
      //           <Trans>Location of employment or workplace;</Trans>
      //           <br />
      //           <Trans>
      //             Extra information that our clients may tell us about you /
      //             Performance information Extra information that you choose to
      //             tell us;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             Extra information that your referees choose to tell us about
      //             you. Please note that the above list of categories of personal
      //             data we may collect is not exhaustive.
      //           </Trans>
      //         </span>
      //         <span className="black-title">
      //           <Trans>If you fail to provide personal data</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             Where we need to collect personal data by law, or under the
      //             terms of a contract we have with you and you fail to provide
      //             that data when requested, we may not be able to perform the
      //             contract we have or are trying to enter into with you for the
      //             purposes of recruitment services. In this case, we may be
      //             unable to provide the recruitment services you have requested
      //             but we will notify you if this is the case at the time.
      //           </Trans>
      //         </span>
      //         <span className="black-title">
      //           <Trans>Client data</Trans>:
      //         </span>
      //         <span>
      //           <Trans>
      //             We do not much collect information about Clients. We generally
      //             only need to have your contact details or the details of
      //             individual contacts at your organisation (such as their names,
      //             telephone numbers and email addresses) to enable us to provide
      //             our services to you and to ensure that our relationship runs
      //             smoothly.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             We may obtain this information from you, from our Client or
      //             from public sources and this includes:
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             Company information available on local company registrars;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             Personal contact details of company employees we are in
      //             contact with such as name, title, work addresses, telephone
      //             numbers, work email addresses, languages and skills;
      //           </Trans>
      //         </span>
      //         <span className="black-title">
      //           <Trans>Website user</Trans>:
      //         </span>
      //         <span>
      //           <Trans>
      //             Technical information, including the Internet protocol (IP)
      //             address used to connect your computer to the Internet, your
      //             login information if applicable, geographical location, domain
      //             name from which you access the internet, browser type, and
      //             version;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             information about your visit, including the full Uniform
      //             Resource Locators (URL), clickstream to, through and from our
      //             site (including date and time), pages you viewed or searched
      //             for, page response times, download errors, length of visits to
      //             certain pages, page interaction information (such as
      //             scrolling, clicks, and mouse-overs).
      //           </Trans>
      //         </span>
      //         <span className="title">
      //           <Trans>
      //             What are our legitimate business interests in collecting and
      //             retaining your personal data?
      //           </Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             As a recruitment business and recruitment agency we introduce
      //             candidates to clients for independent professional contracts.
      //             The exchange of personal data of our candidates and our client
      //             contacts is a fundamental, essential part of this process.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             In order to support our candidates’ career aspirations and our
      //             clients’ resourcing needs we require a database of candidate
      //             and client personal data containing historical information as
      //             well as current resourcing requirements.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             To maintain, expand and develop our business we need to record
      //             the personal data of prospective candidates and client
      //             contacts.
      //           </Trans>
      //         </span>
      //         <span className="title">
      //           <Trans>How do we use your personal data?</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             We will only use your personal data when the law allows us to.
      //             Most commonly, we will use your personal data in the following
      //             circumstances:
      //           </Trans>
      //         </span>
      //         <span className="black-title">
      //           <Trans>Actual and potential candidates:</Trans>
      //         </span>
      //         <span>
      //           <Trans>To help find you a job;</Trans>
      //           <br />
      //           <Trans>
      //             To contact you or your company about jobs that Club Freelance
      //             is filling or may fill for Club Freelance clients;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             To provide you or your company with information about the job
      //             market;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             To communicate with you or your company after you have started
      //             a job to make sure all is going well or to remedy, or attempt
      //             to remedy, any problems;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             To answer any questions you have about a job or the job
      //             market;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             To fulfill any aspect your company’s contract with Club
      //             Freelance;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             To collect any money due, or allegedly due, to Club Freelance
      //             or any Club Freelance client (or Club Freelance client’s
      //             client);
      //           </Trans>
      //           <br />
      //           <Trans>
      //             To obtain or inquire about any property (including computers
      //             and confidential business information) owned, or allegedly
      //             owned, by Club Freelance or any Club Freelance client (or Club
      //             Freelance’s client’s client);
      //           </Trans>
      //           <br />
      //           <Trans>
      //             To establish, exercise or defend any legal claims; and To
      //             assist you or your company if you are dissatisfied or dislike
      //             the job, or any aspect of it.
      //           </Trans>
      //         </span>
      //         <span className="black-title">
      //           <Trans>Clients</Trans>
      //         </span>
      //         <span>
      //           <Trans>To fill an open vacancy at your company;</Trans>
      //           <br />
      //           <Trans>
      //             To contact you about candidates for jobs with whom Club
      //             Freelance has a relationship;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             To provide you with information about the job market;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             To communicate with you after your company has retained a Club
      //             Freelance candidate to make sure all is going well and to
      //             remedy, or attempt to remedy, any problems;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             To negotiate and fulfill any aspect of your company’s contract
      //             with Club Freelance;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             To answer any questions you have about a vacancy or a
      //             candidate or your company’s contract with Club Freelance;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             To resolve any issue with the issuance, payment, collection or
      //             enforcement of a Club Freelance invoice;
      //           </Trans>
      //           <br />
      //           <Trans>
      //             To establish, exercise or defend any legal claims.
      //           </Trans>
      //         </span>
      //         <span className="black-title">
      //           <Trans>Website users</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             We use your data to help us to improve your experience of
      //             using our website, we may therefore for example launch a
      //             search function and may then analyse your recent job search
      //             criteria to help us to present jobs that we think you may be
      //             interested in.
      //           </Trans>
      //         </span>
      //         <span className="black-title">
      //           <Trans>Marketing activities</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             We may send you updates and information about products and
      //             services which may be of interest to you. If you have
      //             consented to receive marketing, you may opt out at a later
      //             date. You have a right at any time to stop us from contacting
      //             you for marketing purposes. If you no longer wish to be
      //             contacted for marketing purposes, please unsubscribe from our
      //             next email.
      //           </Trans>
      //         </span>
      //         <span className="title">
      //           <Trans>How do we collect your personal data?</Trans>
      //         </span>
      //         <span className="black-title">
      //           <Trans>Candidate and Client data</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             We collect your personal data in two primary ways:
      //           </Trans>
      //         </span>
      //         <ol>
      //           <li>
      //             <Trans>
      //               Personal data that you, as the Candidate or Client, give to
      //               us; and
      //             </Trans>
      //           </li>
      //           <li>
      //             <Trans>
      //               Personal data that we receive from other sources.
      //             </Trans>
      //           </li>
      //         </ol>
      //         <div className="with-padding">
      //           <ol>
      //             <li>
      //               <span className="black-title">
      //                 <Trans>Personal data you give to us</Trans>
      //               </span>
      //               <br />
      //               <span>
      //                 <Trans>
      //                   There are numerous ways you can share your information
      //                   with us - it all depends on what suits you. These may
      //                   include:
      //                 </Trans>
      //                 <ul>
      //                   <li>
      //                     <Trans>
      //                       Entering your details on the Club Freelance website
      //                       or registering an account with us;
      //                     </Trans>
      //                   </li>
      //                   <li>
      //                     <Trans>
      //                       Leaving a hard copy CV at a Club Freelance
      //                       recruitment event, job fair or office;
      //                     </Trans>
      //                   </li>
      //                   <li>
      //                     <Trans>
      //                       Providing us with a copy of your CV or by
      //                       corresponding with us by post, phone, email or
      //                       otherwise or being interviewed by us;
      //                     </Trans>
      //                   </li>
      //                   <li>
      //                     <Trans>
      //                       Applying for jobs through a job aggregator, which
      //                       may then redirect you to the Club Freelance website;
      //                     </Trans>
      //                   </li>
      //                   <li>
      //                     <Trans>
      //                       Entering your personal details into a Club Freelance
      //                       microsite; or
      //                     </Trans>
      //                   </li>
      //                   <li>
      //                     <Trans>
      //                       Entering a competition through a social media
      //                       channel such as Facebook or Twitter.
      //                     </Trans>
      //                   </li>
      //                 </ul>
      //               </span>
      //             </li>
      //             <li>
      //               <span className="black-title">
      //                 <Trans>Personal data we receive from other sources</Trans>
      //               </span>
      //               <br />
      //               <span>
      //                 <Trans>
      //                   We also receive personal data about Candidates from
      //                   other sources. Depending on the relevant circumstances
      //                   and applicable local laws and requirements, these may
      //                   include personal data received in the following
      //                   situations:
      //                 </Trans>
      //                 <ul>
      //                   <li>
      //                     <Trans>
      //                       Your referees may disclose personal information
      //                       about you;
      //                     </Trans>
      //                   </li>
      //                   <li>
      //                     <Trans>
      //                       Our Clients may share personal information about you
      //                       with us;
      //                     </Trans>
      //                   </li>
      //                   <li>
      //                     <Trans>
      //                       We may obtain information about you from searching
      //                       for potential Candidates from third party sources,
      //                       such as LinkedIn and other job sites;
      //                     </Trans>
      //                   </li>
      //                   <li>
      //                     <Trans>
      //                       If you ’like’ our page on Facebook or ’follow’ us on
      //                       Twitter or LinkedIn, we will receive your personal
      //                       information from those sites; and
      //                     </Trans>
      //                   </li>
      //                   <li>
      //                     <Trans>
      //                       If you were referred to us through an (Recruitment
      //                       process Outsourcing) RPO or an Managed Services
      //                       providers MSP supplier, they may share personal
      //                       information about you with us.
      //                     </Trans>
      //                   </li>
      //                 </ul>
      //               </span>
      //             </li>
      //           </ol>
      //         </div>
      //         <span className="black-title">
      //           <Trans>Website users</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             When you visit our website, there is certain information that
      //             we may automatically collect, whether or not you decide to use
      //             our services. This includes your IP address, the date and the
      //             times and frequency with which you access the website and the
      //             way you browse its content. We will also collect data from you
      //             when you contact us via the website.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             We collect your data automatically via cookies, in line with
      //             cookie settings in your browser. If you are also a Candidate
      //             of Club Freelance, we may use data from your use of our
      //             websites to enhance other aspects of our communications with
      //             or service to you.
      //           </Trans>
      //         </span>
      //         <span className="title">
      //           <Trans>Who do we share your personal data with?</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             We will share your personal information with all entities in
      //             our group as part of our global recruitment activities,
      //             regular reporting activities on company performance, for
      //             system maintenance support and hosting of data.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>Selected third parties including:</Trans>
      //         </span>
      //         <ul>
      //           <li>
      //             <Trans>
      //               (where you are a candidate) clients for the purpose of
      //               introducing candidates to them and providing information
      //               during the recruitment process;
      //             </Trans>
      //           </li>
      //           <li>
      //             <Trans>
      //               (where you are a client) candidates for the purpose of
      //               arranging interviews and engagements;
      //             </Trans>
      //           </li>
      //           <li>
      //             <Trans>
      //               clients, business partners, suppliers and sub-contractors
      //               for the performance and compliance with obligations of any
      //               contract we enter into with them or you;
      //             </Trans>
      //           </li>
      //           <li>
      //             <Trans>
      //               subcontractors and other service providers including email
      //               marketing specialists, event organisers, payment and other
      //               financial service providers;
      //             </Trans>
      //           </li>
      //           <li>
      //             <Trans>
      //               analytics and search engine providers that assist us in the
      //               improvement and optimisation of our site;
      //             </Trans>
      //           </li>
      //           <li>
      //             <Trans>
      //               credit reference agencies, our insurance broker, compliance
      //               partners and other sub-contractors for the purpose of
      //               assessing your suitability for a role where this is a
      //               condition of us entering into a contract with you.
      //             </Trans>
      //           </li>
      //         </ul>
      //         <span>
      //           <Trans>
      //             We may also disclose your personal information to third
      //             parties:
      //           </Trans>
      //         </span>
      //         <ul>
      //           <li>
      //             <Trans>
      //               In the event that we sell or buy any business or assets, in
      //               which case we will disclose your personal data to the
      //               prospective seller or buyer of such business or assets;
      //             </Trans>
      //           </li>
      //           <li>
      //             <Trans>
      //               If a Club Freelance entity or substantially all of its
      //               assets are acquired by a third party, in which case personal
      //               data held by it about its customers will be one of the
      //               transferred assets;
      //             </Trans>
      //           </li>
      //           <li>
      //             <Trans>
      //               If we are under a duty to disclose or share your personal
      //               data in order to comply with any legal obligation, or in
      //               order to enforce or apply our terms of use and other
      //               agreements; or to protect the rights, property, or safety of
      //               Club Freelance, our customers, or others. This includes
      //               exchanging information with other companies and
      //               organisations for the purposes of fraud protection and
      //               credit risk reduction.
      //             </Trans>
      //           </li>
      //         </ul>
      //         <span className="title">
      //           <Trans>
      //             What measures we put in place to safeguard your personal data?
      //           </Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             We have put in place several technical and organisational
      //             measures aimed at protecting the confidentiality, the
      //             integrity and the availability of your personal data.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             All the endpoints of the company are protected with antivirus
      //             and firewalls.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             Encryption technology is in use to protect the integrity of
      //             the personal data we process.
      //           </Trans>
      //           <br />
      //           <Trans>
      //             Encryption prevents data from being easily accessible and
      //             recoverable in the event of theft or computer misuse. Our
      //             websites are protected by an SSL certificate.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             A password policy is currently in place to ensure that the
      //             data stored on our systems is only accessible to authorised
      //             personnel. The passwords are changed on a regular basis. We
      //             regularly make backups of our data to ensure that it is
      //             readily available in the event of technical problems or
      //             disaster recovery.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             However, while these measures provide security against
      //             unauthorised access, loss, theft or manipulation from third
      //             parties, the internet is not a fully secure domain. For this
      //             reason, Club Freelance cannot guarantee the security or
      //             integrity of any personal information which is transferred
      //             from you or to you via email/ the Internet.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             If you believe, for any reason, that your Personal Data has
      //             been breached while in Club Freelance’s care, custody or
      //             control please email Club Freelance immediately at
      //             contact@club-freelance.com.
      //           </Trans>
      //         </span>
      //         <span className="title">
      //           <Trans>How long do we keep your personal data for?</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             We understand our legal duty to retain accurate data and only
      //             retain personal data for as long as we need it for our
      //             legitimate business interests (or other appropriate legal
      //             basis) and that you are happy for us to do so. Accordingly, we
      //             run data routines to remove data that we no longer have a need
      //             to retain.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             To determine the appropriate retention period for personal
      //             data, we consider the amount, nature, and sensitivity of the
      //             personal data, the potential risk of harm from unauthorised
      //             use or disclosure of your personal data, the purposes for
      //             which we process your personal data and whether we can achieve
      //             those purposes through other means, and the applicable legal
      //             requirements.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             We will usually keep data for a maximum of 5 years and for
      //             full details of our retention strategy you can request it by
      //             contacting us at contact@club-freelance.com.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             In some circumstances you can ask us to delete your data: see
      //             Request erasure below for further information.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             In some circumstances we may anonymise your personal data (so
      //             that it can no longer be associated with you) for research or
      //             statistical purposes in which case we may use this information
      //             indefinitely without further notice to you.
      //           </Trans>
      //         </span>
      //         <span className="title">
      //           <Trans>
      //             How can you access, amend, or take back the personal
      //             information that you have given us and what are your rights?{" "}
      //           </Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             One of the GDPR’s main objectives is to protect and clarify
      //             the rights of EU citizens and individuals in the EU with
      //             regards to data privacy. This means that you retain various
      //             rights in respect of your data, even once you have given it to
      //             us. These are described in more detail below.
      //           </Trans>
      //         </span>
      //         <span className="black-title">
      //           <Trans>
      //             The right of access to the personal data Club Freelance
      //             processes on you:
      //           </Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             The GDPR gives you the right to access information held about
      //             you. We also encourage you to contact us to ensure your data
      //             is accurate and complete. A subject access request should be
      //             submitted to Club Freelance at contact@club-freelance.com.
      //           </Trans>
      //         </span>
      //
      //         <span className="black-title">
      //           <Trans>The right to rectification of your personal data:</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             You have the right to request correction of the personal
      //             information that we hold about you. This enables you to have
      //             any incomplete or inaccurate information we hold about you
      //             corrected.
      //           </Trans>
      //         </span>
      //
      //         <span className="black-title">
      //           <Trans>
      //             The right to erasure of your personal data in certain
      //             circumstances:
      //           </Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             This enables you to ask us to delete or remove personal
      //             information where there is no good reason for us continuing to
      //             process it. You also have the right to ask us to delete or
      //             remove your personal information where you have exercised your
      //             right to object to processing (see below).
      //           </Trans>
      //         </span>
      //
      //         <span className="black-title">
      //           <Trans>
      //             The right to object to the processing of your personal data
      //             that was based on our legitimate interest or for direct
      //             marketing purposes:
      //           </Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             This right enables you to object to us processing your
      //             personal data including where we do so for our legitimate
      //             interests, or for direct marketing purposes.
      //           </Trans>
      //         </span>
      //
      //         <span className="black-title">
      //           <Trans>
      //             This right enables you to object to us processing your
      //             personal data including where we do so for our legitimate
      //             interests, or for direct marketing purposes.
      //           </Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             This enables you to ask us to suspend the processing of
      //             personal information about you, in certain circumstances for
      //             example if you want us to establish its accuracy or the reason
      //             for processing it.
      //           </Trans>
      //         </span>
      //
      //         <span className="black-title">
      //           <Trans>
      //             The right to data portability in certain circumstances:
      //           </Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             If you wish, you have the right in certain circumstances to
      //             receive your personal data in a structured, commonly used and
      //             machine-readable format and have the right to transmit the
      //             data to another controller without hindrance from us.
      //           </Trans>
      //         </span>
      //
      //         <span className="black-title">
      //           <Trans>The right to withdraw consent:</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             Where we have obtained your consent to process your personal
      //             data for certain activities (for example, for our marketing
      //             arrangements or automatic profiling), you may withdraw this
      //             consent at any time by contacting Club Freelance and we will
      //             cease to carry out the particular activity that you previously
      //             consented. There may be circumstances where Club Freelance
      //             will still need to process your data for legal or official
      //             reasons. Where this is the case, we will tell you and we will
      //             restrict the data to only what is necessary for those specific
      //             reasons.
      //           </Trans>
      //         </span>
      //
      //         <span className="black-title">
      //           <Trans>The right to complain</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             You have the right to make a complaint at any time to a
      //             supervisory body which in the UK is the Information
      //             Commissioner’s Office (ICO), the UK supervisory authority for
      //             data protection issues (www.ico.org.uk). We would, however,
      //             appreciate the chance to deal with your concerns before you
      //             approach the ICO, so please contact us in the first instance.
      //           </Trans>
      //         </span>
      //
      //         <span className="black-title">
      //           <Trans>No fee usually required</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             You will not have to pay a fee to access your personal data
      //             (or to exercise any of the other rights). However, we may
      //             charge a reasonable fee if your request is clearly unfounded,
      //             repetitive or excessive. Alternatively, we may refuse to
      //             comply with your request in these circumstances.
      //           </Trans>
      //         </span>
      //
      //         <span className="black-title">
      //           <Trans>What we may need from you</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             We may need to request specific information from you to help
      //             us confirm your identity and ensure your right to access your
      //             personal data (or to exercise any of your other rights). This
      //             is a security measure to ensure that personal data is not
      //             disclosed to any person who has no right to receive it. We may
      //             also contact you to ask you for further information in
      //             relation to your request to speed up our response.
      //           </Trans>
      //         </span>
      //
      //         <span className="black-title">
      //           <Trans>Time limit to respond</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             We try to respond to all legitimate requests within one month.
      //             Occasionally it may take us longer than a month if your
      //             request is particularly complex or you have made a number of
      //             requests. In this case, we will notify you and keep you
      //             updated.
      //           </Trans>
      //         </span>
      //         <span className="title">
      //           <Trans>
      //             How do we store and transfer your data internationally?
      //           </Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             Currently all the data that we collect from you may/will be
      //             transferred to, and stored at, a destination inside the
      //             European Economic Area (”EEA”). It may be transferred to third
      //             parties outside of the EEA for the purpose of our recruitment
      //             services. It may also be processed by staff operating outside
      //             the EEA who work for one of our suppliers. This includes staff
      //             engaged in, among other things, recruitment services and the
      //             provision of support services. By submitting your personal
      //             data, you agree to this transfer, storing or processing.
      //           </Trans>
      //           <br />
      //           <br />
      //           <Trans>
      //             We will always ensure that there are adequate levels of
      //             protection and a similar legislation with regards to data
      //             protection. Each country might have different laws and
      //             regulations with regards to data protection. Overseas
      //             transfers outside the EEA will only be possible when we have
      //             the appropriate safeguards are in place.
      //           </Trans>
      //         </span>
      //         <span className="title">
      //           <Trans>Cookies policy</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             We may obtain data about you from cookies. These are small
      //             text files that are placed on your computer by websites that
      //             you visit. They are widely used in order to make websites
      //             work, or work more efficiently, as well as to provide
      //             information to the owners of the site. Cookies also enable us
      //             to deliver more personalised content.
      //           </Trans>
      //         </span>
      //         <span className="title">
      //           <Trans>Third-party links</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             Our website may include links to third-party websites,
      //             plug-ins and applications. Clicking on those links or enabling
      //             those connections may allow third parties to collect or share
      //             data about you. We do not control these third-party websites
      //             and are not responsible for their privacy statements. When you
      //             leave our website, we encourage you to read the privacy notice
      //             of every website you visit.
      //           </Trans>
      //         </span>
      //         <span className="title">
      //           <Trans>How to contact us about your personal information</Trans>
      //         </span>
      //         <span>
      //           <Trans>
      //             If you have any questions about this privacy notice, including
      //             any requests to exercise your legal rights please contact
      //             please contact Club Freelance at contact@club-freelance.com or
      //             0203 693 7476 or you can write to us at 100 Drummond Road SE
      //             16 4DG London UK.
      //           </Trans>
      //         </span>
      //         <br />
      //         <br />
      //         <br />
      //         <br />
      //       </Col>
      //     </Row>
      //   </FormCard>
      // </div>
    );
  }
}

PrivacyPolicy.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    backRef: PropTypes.string,
    setBackRef: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(PrivacyPolicy);
