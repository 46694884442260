import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react/index";
import Fade from "react-reveal/Fade";
import {
  Button,
  Sorter,
  Row,
  Col,
  toaster,
  Container,
  Icon,
  Spinner,
  Divider
} from "cf-neo-ui";
import Loading from "./loading";
import CloseIcon from "./icons/CloseIcon";
import classes from "./styles.module.scss";
import gql from "graphql-tag";
import { Mutation, Query } from "react-apollo";
import { Trans, t } from "@lingui/macro";
import {
  LIST_TALENT_POOL,
  TALENT_POOL_QUERY
} from "../dashboard-company/queries";

const UPDATE_TALENT_MUTATION = gql`
  mutation updateTalent(
    $vivierID: String
    $talentID: Int
    $recommended: Boolean
    $clientID: String
  ) {
    updateTalent(
      input: {
        vivierID: $vivierID
        talentID: $talentID
        recommended: $recommended
        clientID: $clientID
      }
    ) {
      result
    }
  }
`;

@inject(
  "jobSubmissionStore",
  "appStore",
  "sessionStore",
  "modalStore",
  "talentStore"
)
@observer
class TalentPoolsListModal extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    delete initProps.sessionStore;
    this.state = {
      vivierId: "",
      talentId: 0,
      pool: "",
      shared: true,
      pageSize: this.setPageSize(props.appStore.width),
      currentPage: 1,
      skip: 0,
      limit: 100
    };
  }

  setPageSize = width => {
    if (width >= 1440) return 8;
    if (width >= 1024) return 8;
    if (width >= 768) return 6;
    return 2;
  };

  sharedChangeHandler = value => {
    this.setState({ shared: value });
  };
  poolNameChangeHandler = e => {
    const { sessionStore } = this.props;
    const { changepoolName } = sessionStore;
    changepoolName(e.target.value);
  };
  closeModal = () => {
    const { modalStore, talentStore } = this.props;
    const { changeIsVisibleListPools, changeIsVisible2 } = modalStore;
    var { changeTalent, changeTalentID } = talentStore;
    changeTalent(null);
    changeTalentID(null);
    changeIsVisibleListPools(false);
    changeIsVisible2(false);
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    const { modalStore } = this.props;
    const { changeIsVisibleListPools } = modalStore;
    changeIsVisibleListPools(false);
    if (
      msg ===
      'Unexpected error value: "Vous avez dépasser le nombre limité de vivier"'
    ) {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(t`Vous avez dépasser le nombre limité de vivier`)
      });
    } else if (
      msg ===
      'Unexpected error value: "Vous avez dépasser le nombre limité de talent dans un vivier"'
    ) {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Vous ne pouvez intégrer que 15 talents à votre vivier par défaut "Mes recherches de talent IT"`
        )
      });
    } else {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Une erreur est survenue, veuillez réessayer ultérieurement`
        )
      });
    }
  };
  addTalentPool = () => {
    const { modalStore } = this.props;
    const { changeIsVisibleCreatePool } = modalStore;
    changeIsVisibleCreatePool(true);
  };
  addtalent = talent => {
    this.setState({ vivierId: talent.vivierID });
  };

  render() {
    const {
      location,
      i18n,
      sessionStore,
      jobSubmissionStore,
      appStore,
      modalStore,
      talentStore,
      orderList,
      skipList,
      limitList
    } = this.props;
    let { client, talentPoolFilter, companyID } = sessionStore;
    var { talent, talentID } = talentStore;
    const { jobSubmission, reset } = jobSubmissionStore;
    talent = talent ? talent : this.props.talent;
    const { skip, limit, vivierId } = this.state;
    const { isVisibleListPools } = modalStore;
    const clientID = client._id;
    const isMobile = appStore.width <= 550;
    const talentid =
      talent && talent.talentID
        ? talent.talentID
        : jobSubmission
        ? jobSubmission.candidateID
        : talentID
        ? talentID
        : null;

    return (
      <div className={classes.content} key={isVisibleListPools}>
        <div>
          <a
            onClick={this.closeModal}
            style={{
              width: "20px",
              float: "right",
              overflow: 9999,
              marginBottom: "10px",
              marginTop: "13px",
              marginLeft: "5px",
              cursor: "pointer"
            }}
          >
            <CloseIcon />
          </a>

          <Sorter
            text={i18n._(t`Ajouter à un vivier`)}
            menu={false}
            chevronSize={0.00001}
            chevronColor="#000000"
            className={classes.sorter}
            classNameText={
              !isMobile
                ? "font-style: normal;font-weight: bold;font-size: 25px;color: #000000;line-height: 1;"
                : "font-style: normal;font-weight: bold;font-size: 19px;color: #000000;line-height: 1;"
            }
          />

          <Button
            variant="tertiary"
            className={classes.buttonStyle}
            size="small"
            onClick={this.addTalentPool}
          >
            <Trans>Créer un vivier</Trans>
          </Button>
        </div>
        <div style={{ marginTop: "4%", marginRight: "4%", marginBottom: "2%" }}>
          <Query
            query={LIST_TALENT_POOL}
            variables={{
              clientID: clientID,
              talentID: talentid,
              clientCompanyId: companyID,
              limit: limit,
              skip: skip,
              sortBy: talentPoolFilter
            }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }) => {
              if (error) {
                return (
                  <div className="error_container">
                    <span>
                      {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                    </span>
                    <img
                      style={{
                        margin: 20
                      }}
                      src={"/assets/svg/download.svg"}
                      alt="error"
                    />
                  </div>
                );
              }
              if (loading) return <Loading />;

              let { listTalentPool } = data;
              const TalentsPool = listTalentPool.reduce((a, b) => {
                if (a.indexOf(b) < 0) a.push(b);
                return a;
              }, []);
              if (TalentsPool.length === 0)
                return (
                  <div className={classes.talentCards}>
                    <p className={classes.text1}>
                      <Trans>Pas de vivier</Trans>
                    </p>
                  </div>
                );
              return (
                <div>
                  <Container
                    style={{
                      height: "340px",
                      "overflow-y": "scroll",
                      "padding-top": "20px"
                    }}
                    className={classes.marTop}
                  >
                    <Col>
                      {TalentsPool.map((talentpool, index) => {
                        return (
                          <Row
                            style={{ display: "inline-block" }}
                            className={classes.favedMissionsCard}
                          >
                            <span className={classes.poolName}>
                              {talentpool.poolName.toLowerCase()}
                            </span>
                            <span style={{ marginLeft: "10px" }}>
                              {talentpool.shared && (
                                <Icon
                                  size="tiny"
                                  type="group"
                                  color="#8d0417"
                                  color2="#d3354a"
                                />
                              )}
                            </span>

                            <span style={{ float: "right", marginTop: "-6px" }}>
                              <Mutation
                                mutation={UPDATE_TALENT_MUTATION}
                                variables={{
                                  vivierID: vivierId,
                                  talentID: talentid,
                                  recommended:
                                    talent && talent.recommended
                                      ? talent.recommended
                                      : false,
                                  clientID: clientID
                                }}
                                onCompleted={data => {}}
                                refetchQueries={[
                                  {
                                    query: TALENT_POOL_QUERY,
                                    variables: {
                                      _id: vivierId,
                                      clientID: clientID,
                                      limit: limitList,
                                      skip: skipList,
                                      search: "",
                                      order: orderList
                                    }
                                  },
                                  {
                                    query: LIST_TALENT_POOL,
                                    variables: {
                                      clientID: clientID,
                                      talentID: talentid,
                                      clientCompanyId: companyID,
                                      limit: limit,
                                      skip: skip,
                                      sortBy: talentPoolFilter
                                    }
                                  }
                                ]}
                                onError={errors => {
                                  errors.graphQLErrors.forEach(
                                    ({ message, data }) => {
                                      if (message) {
                                        this.onErrorHandler(message);
                                      }
                                    }
                                  );
                                }}
                              >
                                {(deleteORadd, { loading, error, data }) => (
                                  <a
                                    onClick={() => {
                                      this.setState(
                                        {
                                          vivierId: TalentsPool[index].vivierID
                                        },
                                        function() {
                                          this.addtalent(TalentsPool[index]);
                                          deleteORadd();
                                        }
                                      );
                                    }}
                                    style={{
                                      cursor: "pointer"
                                    }}
                                  >
                                    {loading ? (
                                      <div>
                                        <Spinner
                                          type="pointed-circular"
                                          color="#8d0417"
                                          size={20}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <Icon
                                          size="small"
                                          type={
                                            (talentpool.exist && !data) ||
                                            data?.updateTalent?.result == "add"
                                              ? "heart"
                                              : "addheart"
                                          }
                                          filled={true}
                                          color="#8d0417"
                                          color2="#d3354a"
                                          width={25}
                                          height={25}
                                        />
                                      </div>
                                    )}
                                  </a>
                                )}
                              </Mutation>
                            </span>
                            {talentpool.poolName.toLowerCase() ===
                              "mes recherches de talent it" &&
                              clientID == talentpool.creatorID && (
                                <div
                                  style={{
                                    "margin-left": "-25px",
                                    "margin-right": "-20px"
                                  }}
                                >
                                  <Divider
                                    orientation="horizontal"
                                    color="#aaa"
                                    thickness="2px"
                                    type="solid"
                                  />
                                </div>
                              )}
                          </Row>
                        );
                      })}
                    </Col>
                  </Container>
                </div>
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
}

TalentPoolsListModal.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisibleCreatePool: PropTypes.func,
    changeIsVisible2: PropTypes.func,
    changeIsVisibleListPools: PropTypes.func,
    isVisibleCreatePool: PropTypes.bool,
    isVisibleListPools: PropTypes.bool
  }).isRequired,
  talentStore: PropTypes.shape({
    talent: PropTypes.object,
    talentID: PropTypes.number
  }),
  jobSubmissionStore: PropTypes.shape({
    changeJobSubmission: PropTypes.func,
    reset: PropTypes.func,
    jobSubmission: PropTypes.shape
  }).isRequired
};

export default withI18n()(withRouter(TalentPoolsListModal));
