import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { H1, Select } from "cf-neo-ui";
import { NavLink, withRouter } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import classes from "./SubMenu.module.scss";
import { categoriesRefEn, categoriesRefFr, latestPostsQuery } from "../query";

@inject("appStore")
@observer
class SubMenu extends Component {
  constructor(props) {
    super(props);
    this.latestPostsPage = 1;
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  showMore = fetchMore => {
    const { appStore } = this.props;
    const { currentLanguage } = appStore;
    this.latestPostsPage += 1;
    return fetchMore({
      fetchPolicy: "cache-and-network",
      query: latestPostsQuery,
      variables: {
        notifyOnNetworkStatusChange: true,
        page: this.latestPostsPage,
        perPage: 9,
        categoryIds:
          currentLanguage === "fr"
            ? categoriesRefFr.decryption
            : categoriesRefEn.decryption,
        language: currentLanguage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...prev, posts: [...prev.posts, ...fetchMoreResult.posts] };
      }
    });
  };
  listSubTitle = listSubCategories => {
    const { i18n } = this.props;
    var url = "";
    if (typeof window !== "undefined") {
      url = window.location.pathname;
      url.split("/")[3] === "press-review";
    }
    const exist = url.includes("press-review");
    if (!exist) {
      var arrayToReturn = [
        {
          text: i18n._(t`Découvrir les rubriques`),
          value: listSubCategories.title,
          selected: listSubCategories.title
        }
      ];
      for (let i = 0; i < listSubCategories.subCategories.length; i++) {
        const objToRe = {
          text: listSubCategories.subCategories[i].title,
          value: listSubCategories.subCategories[i].url,
          selected:
            listSubCategories.title == listSubCategories.subCategories[i].title
        };
        arrayToReturn.push(objToRe);
      }
    } else {
      var arrayToReturn = [
        {
          text: i18n._(t`Découvrir les rubriques`),
          value: "/blog/resources/",
          selected: listSubCategories.title
        }
      ];
      for (
        let i = 0;
        i < listSubCategories.subCategories[3].subCategories.length;
        i++
      ) {
        const objToRe = {
          text: listSubCategories.subCategories[3].subCategories[i].title,
          value: listSubCategories.subCategories[3].subCategories[i].url,
          selected:
            listSubCategories.title ==
            listSubCategories.subCategories[3].subCategories[i].title
        };
        arrayToReturn.push(objToRe);
      }
    }
    return arrayToReturn;
  };
  subtitleHandler = item => {
    const value = item.value;
    const { i18n, history } = this.props;
    var category = "";
    var subCategory = "";
    if (typeof window !== "undefined") {
      category = window.location.pathname.split("/")[2];
      subCategory = window.location.pathname.split("/")[4];
      if (
        item.text == i18n._(t`Découvrir les rubriques`) &&
        subCategory == undefined
      ) {
        history.push(`/blog/${category}`);
      }
      if (
        item.text == i18n._(t`Découvrir les rubriques`) &&
        value != undefined &&
        subCategory != undefined
      ) {
        history.push(item.value);
      }
    }
    if (value != undefined && item.text != i18n._(t`Découvrir les rubriques`)) {
      history.push(item.value);
    }
  };

  render() {
    const { appStore, dataToSend } = this.props;
    var url = "";
    if (typeof window !== "undefined") {
      url = window.location.pathname;
      url.split("/")[3] === "PressReview" ? classes.activeHeader : "";
    }
    const exist = url.includes("press-review");

    const isMobile = appStore.width <= 550;

    return (
      <div>
        <div className={classes.limitedBody}>
          <section>
            <H1 className={classes.heading}>
              <Trans>{dataToSend.title}</Trans>
            </H1>
            <div className={classes.container}>
              <div className={classes.header}>
                {isMobile ? (
                  <div>
                    {
                      <Select
                        id="sub_category"
                        options={{
                          groupA: this.listSubTitle(dataToSend)
                        }}
                        onSelect={this.subtitleHandler}
                      />
                    }
                  </div>
                ) : (
                  dataToSend.subCategories.map(subCategory => (
                    <div className={classes.headerItem}>
                      {subCategory.subCategories &&
                      subCategory.subCategories.length > 0 ? (
                        <div
                          activeClassName={classes.activeHeader}
                          className={[
                            classes.headerItems,
                            classes.headerChildrenWrapper
                          ].join(" ")}
                        >
                          <NavLink
                            className={[
                              classes.text,
                              exist && classes.activeHeader
                            ].join(" ")}
                            activeClassName={classes.activeHeader}
                            exact
                            to={`${subCategory.url}/`}
                          >
                            <Trans> {subCategory.title} </Trans>
                          </NavLink>
                          <div className={classes.children}>
                            {subCategory.subCategories.map(sub => (
                              <div className={classes.childCorrection}>
                                <NavLink
                                  className={classes.text}
                                  activeClassName={classes.activeHeader}
                                  exact
                                  to={`${sub.url}/`}
                                >
                                  <Trans>{sub.title}</Trans>
                                </NavLink>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <NavLink
                          className={classes.text}
                          activeClassName={classes.activeHeader}
                          exact
                          to={`${subCategory.url}/`}
                        >
                          <Trans>{subCategory.title}</Trans>
                        </NavLink>
                      )}
                    </div>
                  ))
                )}
              </div>
            </div>
            <h2 style={{ fontSize: "20px" }}>
              <Trans>{dataToSend.msg}</Trans>
            </h2>
          </section>

          <section />
        </div>
      </div>
    );
  }
}

SubMenu.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(SubMenu));
