import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { H2, H5, Spinner } from "cf-neo-ui";
import { Trans, plural } from "@lingui/macro";
import { Query } from "react-apollo";
import Articles from "../blog-cards-display/BlogCardsDisplay";
import classes from "./SuccessStory.module.scss";
import { categoriesRefEn, categoriesRefFr, latestPostsQuery } from "../query";
import staticPagesMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import PlusButton from "../plus-button/PlusButton";
import ldJson from "./ldJson";
import { inject, observer } from "mobx-react";

@inject("appStore")
@observer
class SuccessStory extends Component {
  constructor(props) {
    super(props);
    this.latestPostsPage = 1;
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  loadingDisplay = () => {
    return (
      <div className={classes.limitedBody}>
        <section>
          <H2 className={classes.heading}>
            <Trans>Success Stories</Trans>
          </H2>
          <p className={classes.text}>
            <Trans>
              L’expérience Club Freelance racontée par les Freelances
              informatiques et décideurs IT
            </Trans>
          </p>
        </section>
        <section>
          <Spinner
            className={classes.spinner}
            type="pointed-circular"
            color="#c40b24"
            size={120}
          />
        </section>
        <section />
      </div>
    );
  };

  showMore = fetchMore => {
    const { appStore } = this.props;
    const { currentLanguage } = appStore;

    this.latestPostsPage += 1;
    return fetchMore({
      fetchPolicy: "cache-and-network",
      query: latestPostsQuery,
      variables: {
        notifyOnNetworkStatusChange: true,
        page: this.latestPostsPage,
        perPage: 9,
        categoryIds:
          currentLanguage === "fr"
            ? categoriesRefFr.successStories
            : categoriesRefEn.successStories,
        language: currentLanguage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...prev, posts: [...prev.posts, ...fetchMoreResult.posts] };
      }
    });
  };

  render() {
    const { i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    const meta = staticPagesMetaTags(i18n).blog.successStories;
    return (
      <div>
        {metaTags(
          urls.successStories(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          ldJson
        )}
        <Query
          query={latestPostsQuery}
          variables={{
            page: 1,
            perPage: 9,
            categoryIds:
              currentLanguage === "fr"
                ? categoriesRefFr.successStories
                : categoriesRefEn.successStories,
            language: currentLanguage
          }}
          fetchPolicy="cache-and-network"
        >
          {({ loading, error, data, fetchMore, networkStatus }) => {
            if (error)
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );
            if (networkStatus === 1) return this.loadingDisplay();
            return (
              <div className={classes.limitedBody}>
                <section>
                  <H2 className={classes.heading}>
                    <Trans>Success Stories</Trans>
                  </H2>
                  <div className={classes.text}>
                    <Trans>
                      L’expérience Club Freelance racontée par les Freelances
                      informatiques et décideurs IT
                    </Trans>
                    <H5 className={classes.articleHeading}>
                      <span>
                        <Trans>
                          {i18n._(
                            plural({
                              value: data.posts ? data.posts[0].total : 0,
                              one: "# Article à lire",
                              other: "# Articles à lire"
                            })
                          )}
                        </Trans>
                      </span>
                    </H5>
                  </div>
                </section>
                <section>
                  {data && <Articles articles={data.posts} />}
                  {networkStatus === 3 && (
                    <Spinner
                      className={classes.spinner}
                      type="pointed-circular"
                      color="#c40b24"
                      size={120}
                    />
                  )}
                </section>
                <section style={{ marginBottom: "20px" }}>
                  {!loading &&
                    data.posts.length &&
                    data.posts[0] &&
                    this.latestPostsPage < data.posts[0].totalPages && (
                      <PlusButton onClick={() => this.showMore(fetchMore)}>
                        <Trans>More articles</Trans>
                      </PlusButton>
                    )}
                </section>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

SuccessStory.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(SuccessStory);
