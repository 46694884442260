import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Fade from "react-reveal/Fade";
import { Button, Row, Col, Numbering } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import classes from "./HowItWorks.module.scss";
import Routes from "../../layout/routes";

@inject("sessionStore", "modalStore")
@observer
class HowItWorks extends Component {
  /*
  toForm = () => {
    const { sessionStore, history } = this.props;
    const { signUpUserRole, authToken } = sessionStore;
    if (authToken) {
      if (signUpUserRole === "CLIENT") {
        const { Offer } = Routes.HbLayoutRoutes;
        history.push(Offer.path);
      } else history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
    } else history.push(Routes.HbLayoutRoutes.Login.path);
  };
  */

  clickHandler = () => {
    const { modalStore, sessionStore, history } = this.props;
    const { signUpUserRole, authToken } = sessionStore;
    if (authToken) {
      if (signUpUserRole === "CANDIDATE") {
        history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
      } else if (signUpUserRole === "CLIENT") {
        modalStore.changeIsVisible(true);
      }
    } else {
      history.push(Routes.HbLayoutRoutes.Login.path);
    }
  };

  render() {
    return (
      <div className={classes.HowItWorks}>
        <div className={classes.image2} />
        <Fade>
          <h2>
            <Trans>Comment ça marche ?</Trans>
          </h2>
        </Fade>
        <Fade>
          <p className={classes.paragraph}>
            <Trans>We only bill if you find your freelancer</Trans>
          </p>
        </Fade>
        <Fade bottom>
          <Button
            variant="primary"
            onClick={this.clickHandler}
            // onClick={this.toForm}
          >
            <Trans>Je poste un besoin</Trans>
          </Button>
        </Fade>
        <Row className={classes.r}>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <table>
              <Fade cascade>
                <tr>
                  <td>
                    <Numbering dimension={38}>1.</Numbering>
                  </td>
                  <td>
                    <p>
                      <Trans>
                        Vous créez votre profil et accédez à votre espace
                        personnel pour poster votre premier besoin, en seulement
                        quelques minutes
                      </Trans>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Numbering dimension={38}>2.</Numbering>
                  </td>
                  <td>
                    <p>
                      <Trans>
                        Un Account Manager spécialisé sur votre secteur vous
                        rappelle pour préciser votre besoin
                      </Trans>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Numbering dimension={38}>3.</Numbering>
                  </td>
                  <td>
                    <p>
                      <Trans>
                        Vous recevez une shortlist de candidats qualifiés (en
                        moyenne sous 48h)
                      </Trans>
                    </p>
                  </td>
                </tr>
              </Fade>
            </table>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <table>
              <Fade cascade>
                <tr>
                  <td>
                    <Numbering dimension={38}>4.</Numbering>
                  </td>
                  <td>
                    <p>
                      <Trans>
                        Vous pouvez gérer l’ensemble du process de sélection et
                        les entretiens via votre espace client, et en mode
                        collaboratif avec votre équipe
                      </Trans>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Numbering dimension={38}>5.</Numbering>
                  </td>
                  <td>
                    <p>
                      <Trans>
                        Une fois le candidat choisi, nous vous accompagnons dans
                        le process d’onboarding et tout au long de la mission
                      </Trans>
                    </p>
                  </td>
                </tr>
              </Fade>
            </table>
          </Col>
        </Row>
      </div>
    );
  }
}

HowItWorks.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    signUpUserRole: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    isVisible: PropTypes.bool,
    changeIsVisible: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(HowItWorks));
