import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Modal, Container, Row, Col, Pagination, TalentV2 } from "cf-neo-ui";
import { withApollo } from "react-apollo";
import SingleCandidate from "../../dashboard-single-candidate";
import TalentPoolsListModal from "../../talentPool-Modal/talentPoolsListModal";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react/index";
import classes from "./stylesTalentsInPool.module.scss";
import { isOnePage } from "../../../utils/helpers";
import AddTalentPool from "../../talentPool-Modal/NewTalentPool";
@inject("appStore", "sessionStore", "modalStore", "talentStore")
@observer
class ListTalents extends Component {
  constructor(props) {
    super(props);
    const { sessionStore } = this.props;
    this.total = 0;
    this.state = {
      pageSize: this.setPageSize(props.appStore.width),
      currentPage: 1,
      inputValue: "",
      titlePool: "",
      limit: this.setPageSize(props.appStore.width),
      toChange: false,
      sharedPool: sessionStore.shared || false,
      talent: {}
    };
  }
  componentDidMount() {
    const { sessionStore, pool } = this.props;
    const { poolName } = sessionStore;
    this.setState(
      { titlePool: poolName, sharedPool: pool.shared },
      function() {}
    );
    sessionStore.changeShared(pool.shared);
    sessionStore.addcreatorID(pool.creatorID);
    sessionStore.changepoolName(this.state.inputValue || pool.poolName);
    sessionStore.changesourceTalentPool(pool.sourceTalentPool);
  }
  setPageSize = width => {
    if (width >= 1440) return 8;
    if (width >= 1024) return 6;
    if (width >= 768) return 6;
    return 2;
  };

  toCV = talent => {
    const { modalStore, talentStore } = this.props;
    const { changeIsVisible2 } = modalStore;
    talentStore.changeTalent(talent);
    changeIsVisible2(true);
  };
  addTalent = talent => {
    const { modalStore, talentStore } = this.props;
    const { changeIsVisibleListPools } = modalStore;
    const { changeTalent } = talentStore;
    changeTalent(talent);
    changeIsVisibleListPools(true);
    this.setState({ talent: talent });
  };

  renderPagination = data => {
    const { pageSize } = this.state;
    const paginationProps = {
      pageSize,
      total: data,
      current: this.props.currentPage,
      onChange: current => {
        this.props.changeCurrent(current);
        this.props.changeSkip((current - 1) * pageSize);
        this.setState({
          currentPage: this.props.currentPage,
          skip: (current - 1) * pageSize
        });
      }
    };
    return <Pagination {...paginationProps} />;
  };

  getExperineceToDisplay = experience => {
    const { i18n } = this.props;
    if (experience != null && experience == 0) {
      return `${i18n._(t`Junior`)}(0-3)`;
    } else if (experience && experience == 1) {
      return `${i18n._(t`Junior`)}(0-3)`;
    } else if (experience && experience == 2) {
      return `${i18n._(t`Confirmé`)} (3-7)`;
    } else if (experience && experience == 3) {
      return `${i18n._(t`Senior`)} (7-10)`;
    } else if (experience == 4) {
      return `${i18n._(t`Expert`)} (10+)`;
    } else {
      return `${i18n._(t`Non assigné`)}`;
    }
  };
  getAddresseToDisplay = address => {
    const { i18n } = this.props;

    if (address && address.city != null && address.zip != null) {
      return address.city + " " + address.zip;
    } else if (address && address.countryName != null && address.zip != null) {
      return address.countryName + " " + address.zip;
    } else if (address && address.zip != null) {
      return address.zip;
    } else {
      return `${i18n._(t`N/A`)}`;
    }
  };
  render() {
    const { modalStore, i18n, order, skip, talents, count } = this.props;
    const { isVisible2, isVisibleCreatePool, isVisibleListPools } = modalStore;
    const { limit, pageSize, talent } = this.state;

    const sortedTalents = talents.reduce((a, b) => {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);
    let TalentsToDisplay = [];
    TalentsToDisplay = sortedTalents;
    let i = 0;
    return (
      <div className={classes.bigContent}>
        <Modal isVisible={isVisible2} position="fixed" withCloseButton={false}>
          <div
            style={{
              width: "80%",
              margin: "auto"
            }}
          >
            <SingleCandidate />
          </div>
        </Modal>

        <Modal
          isVisible={isVisibleListPools}
          withCloseButton={false}
          position="fixed"
        >
          <div
            style={{
              width: "90%",
              margin: "auto"
            }}
          >
            <TalentPoolsListModal
              orderList={order}
              skipList={skip}
              limitList={limit}
              talent={talent}
            />
          </div>
        </Modal>

        {isVisibleCreatePool && (
          <Modal
            isVisible={isVisibleCreatePool}
            withCloseButton={false}
            position="fixed"
          >
            <div
              style={{
                width: "80%",
                margin: "10% auto"
              }}
            >
              <AddTalentPool talent={talent} />
            </div>
          </Modal>
        )}
        <div>
          {sortedTalents.length === 0 ? (
            <Container className={classes.marTop}>
              <Row>
                <Col className={classes.favedMissionsCard}>
                  <div className={classes.talentCards}>
                    <p className={classes.text1}>
                      <Trans>Pas de talent pour ce vivier</Trans>
                    </p>
                    <p className={classes.text2}>
                      <Trans>
                        Pour trouvez des talent, ajouter des talents à ce
                        vivier.
                      </Trans>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container className={classes.marTop}>
              <Row>
                {TalentsToDisplay.map(tp => {
                  i += 1;
                  return (
                    <Col className={classes.favedMissionsCard}>
                      <TalentV2
                        key={tp.talentID}
                        talent={{
                          id: tp.talentID,
                          recommended: tp.recommended,
                          image: tp.profilePhotoURL
                            ? tp.profilePhotoURL
                            : "/defaultAvatar.webp",
                          name: tp.firstName ? tp.firstName : "Talent",
                          position: i18n._(t`${tp.occupation}`),

                          experience: this.getExperineceToDisplay(
                            tp.experience
                          ),
                          addresse: this.getAddresseToDisplay(talent.address),
                          onFave: () => {}
                        }}
                        onClick={() => this.toCV(tp)}
                        onChange={() => this.addTalent(tp)}
                        withFav={false}
                        draggable={false}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          )}
          {isOnePage(pageSize, count) ? null : (
            <div className={classes.pagination}>
              {this.renderPagination(count)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

ListTalents.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  shared: PropTypes.string.isRequired,
  // order: PropTypes.number.isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  appStore: PropTypes.shape({
    changeWidth: PropTypes.func,
    width: PropTypes.number
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisibleCreatePool: PropTypes.func,
    changeIsVisible2: PropTypes.func,
    changeIsVisibleListPools: PropTypes.func
  }).isRequired,
  talentStore: PropTypes.shape({
    changeTalent: PropTypes.func,
    talent: PropTypes.shape
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(withApollo(ListTalents)));
