import gql from "graphql-tag";

const UPDATE_CANDIDATE_MUTATION = gql`
  mutation UpdateCandidateMutation($_id: ID!, $publicProfil: Boolean) {
    updateCandidate(input: { _id: $_id, publicProfil: $publicProfil }) {
      account {
        id
        name
      }
      candidate {
        publicProfil
      }
    }
  }
`;

export { UPDATE_CANDIDATE_MUTATION };
