import React, { PureComponent } from "react";
import { Avatar } from "cf-neo-ui";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react/index";
import { I18nProvider } from "@lingui/react";
import { Trans } from "@lingui/macro";
import moment from "moment";
import { inject, observer } from "mobx-react";
import classes from "./Blogger.module.scss";
import decode from "../../../../utils/Utf8Text";
import catalogEn from "../../../../locales/en/messages";
const catalogs = {
  en: catalogEn
};

@inject("appStore")
@observer
class Blogger extends PureComponent {
  constructor(props) {
    const { appStore } = props;
    const { currentLanguage } = appStore;
    super(props);
    moment.locale(currentLanguage);
    const mAdd = moment(props.addDate);
    const mUpdate = moment(props.updateDate);
    this.creation = {
      date: mAdd.format("DD MMMM YYYY"),
      time: mAdd.format("HH:mm")
    };
    if (props.updateDate) {
      this.update = {
        date: mUpdate.format("DD MMMM YYYY"),
        time: mUpdate.format("HH:mm")
      };
    } else this.update = null;
  }

  render() {
    const { name, avatar, appStore } = this.props;
    const { currentLanguage } = appStore;
    return (
      <div className={classes.container}>
        <Avatar
          id="avatar"
          dimension="64px"
          usedInBlock={true}
          className={classes.avatar}
          src={avatar}
        />
        <I18nProvider language={currentLanguage} catalogs={catalogs}>
          <div>
            <h4 className={classes.name}>
              <span>
                <Trans>Par</Trans>
              </span>
              {decode(name)}
            </h4>
            <p className={classes.description}>
              <span>
                <span>
                  <Trans>Publié le</Trans>
                </span>
                {this.creation.date}
                <span>
                  <Trans>à</Trans>
                </span>
                {this.creation.time}
              </span>
              {this.update && (
                <span>
                  <span>
                    <Trans>, mis à jour le</Trans>
                  </span>
                  {this.update.date}
                  <span>
                    <Trans>à</Trans>
                  </span>
                  {this.update.time}
                </span>
              )}
            </p>
          </div>
        </I18nProvider>
      </div>
    );
  }
}

Blogger.propTypes = {
  name: PropTypes.string,
  avatar: PropTypes.string,
  addDate: PropTypes.string,
  updateDate: PropTypes.string,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

Blogger.defaultProps = {
  name: "",
  avatar: "",
  addDate: "",
  updateDate: ""
};

export default withI18n()(Blogger);
