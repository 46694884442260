import React, { Component } from "react";
import PropTypes from "prop-types";
import { withI18n } from "@lingui/react/index";
import { FindMissionCard, Button } from "cf-neo-ui";
import { withRouter } from "react-router-dom";
import { Trans } from "@lingui/macro";

import classes from "./EntrepriseCard.module.scss";
import Routes from "../../containers/layout/routes";

class EntrepriseCard extends Component {
  render() {
    const h2 = {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: "900",
      fontSize: "30px",
      lineHeight: "30px",
      textTransform: "uppercase",
      margin: "60px 40px 0 40px",
      color: "black"
    };
    const button = {
      margin: " 20px 20px 20px 40px"
    };

    const ul = {
      color: "black",
      margin: "20px",
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "35px",
      mixBlendMode: "normal",
      opacity: "0.6"
    };

    const { history } = this.props;
    return (
      <div className={classes.card}>
        <FindMissionCard
          image="/assets/images/marketing-landing-page/entreprise.webp"
          coverColor="#FEFEFE"
        >
          <header className={classes.header}>
            <h2 style={h2}>
              <Trans>Je suis un décideur IT</Trans>
            </h2>
            <Button
              style={button}
              variant="primary"
              onClick={() => history.push(`/enterprise`)}
            >
              <Trans>Trouver un freelance</Trans>
            </Button>
          </header>
          <section>
            <h2 style={h2}>
              <Trans>Je suis un décideur IT</Trans>
            </h2>
            <ul style={ul}>
              <li>
                <Trans>
                  Une shortlist des meilleurs candidats pour tous vos besoins IT
                </Trans>
              </li>
              <li>
                <Trans>Une Solution complète de talent management</Trans>
              </li>
              <li>
                <Trans>Un Key Account Manager dédié</Trans>
              </li>
              <li>
                <Trans>Aucun prépaiements </Trans>
              </li>
            </ul>
            <Button
              style={button}
              variant="primary"
              onClick={() => history.push(`/enterprise`)}
            >
              <Trans>Trouver un freelance</Trans>
            </Button>
          </section>
        </FindMissionCard>
      </div>
    );
  }
}

EntrepriseCard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(EntrepriseCard));
