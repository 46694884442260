import React, { Component } from "react";
import PropTypes from "prop-types";
import { withI18n } from "@lingui/react/index";
import { FindMissionCard, Button } from "cf-neo-ui";
import { withRouter } from "react-router-dom";
import { Trans } from "@lingui/macro";
import classes from "./FreelanceCard.module.scss";
import theme from "../../configs/theme";

class FreelanceCard extends Component {
  render() {
    const h2 = {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: "900",
      fontSize: "30px",
      lineHeight: "30px",
      textTransform: "uppercase",
      margin: "60px 40px 0 40px",
      color: "white"
    };
    const button = {
      margin: " 20px 20px 20px 40px"
    };

    const ul = {
      color: "white",
      margin: "20px",
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "30px",
      mixBlendMode: "normal",
      opacity: "0.6"
    };

    const { history } = this.props;
    return (
      <div className={classes.card}>
        <FindMissionCard
          image="/assets/images/marketing-landing-page/freelance.webp"
          coverColor={theme.colors.mainColor}
        >
          <header className={classes.header}>
            <h2 style={h2}>
              <Trans>Je suis un talent</Trans>
            </h2>
            <Button
              style={button}
              variant="secondary"
              onClick={() => history.push(`/consultant`)}
            >
              <Trans>Trouver une mission</Trans>
            </Button>
          </header>
          <section>
            <h2 style={h2}>
              <Trans>Je suis un talent</Trans>
            </h2>
            <ul style={ul}>
              <li>
                <Trans>Une sélection d’offres adaptées à votre profil</Trans>
              </li>
              <li>
                <Trans>Un accompagnement personnalisé par nos équipes</Trans>
              </li>
              <li>
                <Trans>La garantie de paiement à 30 jours</Trans>
              </li>
              <li>
                <Trans>Rejoindre une communauté de freelances</Trans>
              </li>
            </ul>
            <Button
              style={button}
              variant="secondary"
              onClick={() => history.push(`/consultant`)}
            >
              <Trans>Trouver une mission</Trans>
            </Button>
          </section>
        </FindMissionCard>
      </div>
    );
  }
}

FreelanceCard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(FreelanceCard));
