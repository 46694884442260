import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from "moment";
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";

import { Container } from "cf-neo-ui";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { inject, observer } from "mobx-react/index";
import classes from "./stylesTalentsInPool.module.scss";
import DeleteTalentPool from "../../talentPool-Modal/deleteTalentPool";
import { Trans } from "@lingui/macro";
import { Modal } from "cf-neo-ui";

@inject("appStore", "sessionStore", "modalStore", "talentStore")
@observer
class Setting extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { sessionStore } = this.props;
    const { poolName, shared } = sessionStore;
    this.setState({ titlePool: poolName, sharedPool: shared }, function() {});
  }
  deletePool = () => {
    const { modalStore } = this.props;
    const { changetoDelete } = modalStore;
    changetoDelete(true);
  };
  updatePool = () => {
    const { sessionStore } = this.props;
    sessionStore.changetoChangePoolName(true);
  };

  render() {
    const { location, sessionStore, i18n, modalStore, poolName } = this.props;
    const { toDelete } = modalStore;
    const vivierID = location.pathname.split("talents-pool/")[1];
    let i = 0;
    return (
      <div className={classes.bigContent}>
        <Modal isVisible={toDelete} withCloseButton={false} position="fixed">
          <DeleteTalentPool vivierID={vivierID} />
        </Modal>
        <div className={classes.settingText}>
          <a onClick={this.updatePool}>
            <Trans>Renommer le vivier </Trans>
          </a>
        </div>
        <div className={classes.settingText}>
          <a onClick={this.deletePool}>
            <Trans>Supprimer le vivier</Trans>
          </a>
        </div>
      </div>
    );
  }
}

Setting.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  shared: PropTypes.string,
  poolName: PropTypes.string,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  appStore: PropTypes.shape({
    changeWidth: PropTypes.func,
    width: PropTypes.number
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisible: PropTypes.func,
    changeIsVisible2: PropTypes.func,
    changeIsVisibleListTalentPool: PropTypes.func
  }).isRequired,
  talentStore: PropTypes.shape({
    changeTalent: PropTypes.func,
    talent: PropTypes.shape
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired,
  Setting: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(withApollo(Setting)));
