import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { ApolloConsumer, Mutation, Query, withApollo } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import {
  Row,
  Col,
  Divider,
  TextInput,
  FormGroup,
  Button,
  Validation,
  toaster,
  Spinner,
  Select,
  InputSkeleton,
  AutoComplete,
  TagList,
  RadioGroup,
  Radio
} from "cf-neo-ui";
import staticMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import validate from "../../../utils/validators";
import { GET_USER_INFO } from "../../../components/hoc/queries";
import "./styles.scss";
import theme from "../../../configs/theme";
import { LOGOUT_MUTATION } from "../../layout/hbfLayout/mutation";
import classes from "../../candidate-to-client/CandidateToClient.module.scss";

const UPDATE_CLIENT_MUTATION = gql`
  mutation UpdateClientMutation(
    $_id: ID!
    $password: String
    $firstName: String
    $lastName: String
    $phonenumber: String
    $preferredContact: String
    $receiveNewsletterTalent: Boolean
    $receiveNewsletterClient: Boolean
    $currentPosition: String
    $companyName: String
    $activityArea: [String]
    $sizeOfTheCompany: Int
    $isESN: Boolean
  ) {
    updateClient(
      input: {
        _id: $_id
        password: $password
        firstName: $firstName
        lastName: $lastName
        phonenumber: $phonenumber
        preferredContact: $preferredContact
        receiveNewsletterTalent: $receiveNewsletterTalent
        receiveNewsletterClient: $receiveNewsletterClient
        currentPosition: $currentPosition
        companyName: $companyName
        activityArea: $activityArea
        sizeOfTheCompany: $sizeOfTheCompany
        isESN: $isESN
      }
    ) {
      account {
        id
        name
      }
      client {
        _id
        id
        email
        companyName
        activityArea
        currentPosition
        sizeOfTheCompany
        isESN
        password
        firstName
        lastName
        phonenumber
        customTextBlock1
        preferredContact
        receiveNewsletterTalent
        receiveNewsletterClient
      }
    }
  }
`;

const GET_BUSINESS_SECTORS = gql`
  {
    businessSectors {
      name
    }
  }
`;

@inject("sessionStore", "candidatesSearchStore")
@observer
class MyCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompanyNameValid: true,
      isActivityAreaValid: true,
      companyNameValidationMessage: "",
      activityAreaValidationMessage: "",
      saveCase: false
    };
    this.isFormValid = this.isFormValid.bind(this);
  }

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  companyNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeCompanyName(e.target.value);
    this.validateCompanyName(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  sizeOfTheCompanyChangeHandler = item => {
    const { sessionStore } = this.props;
    sessionStore.changeSizeOfTheCompany(item.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  activityAreaAddHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.addActivityArea(value);
    this.validateActivityArea(sessionStore.activityArea);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  activityAreaRemoveHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.removeActivityArea(value);
    this.validateActivityArea(sessionStore.activityArea);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  isESNChangeHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.changeIsESN(value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  getMutationVariables = () => {
    const { sessionStore } = this.props;
    const {
      _id,
      firstName,
      lastName,
      phonenumber,
      password,
      preferredContact,
      receiveNewsletterTalent,
      receiveNewsletterClient,
      authToken,
      companyName,
      activityArea,
      sizeOfTheCompany,
      isESN,
      currentPosition
    } = sessionStore;

    return {
      _id,
      firstName,
      lastName,
      phonenumber,
      password,
      preferredContact,
      receiveNewsletterTalent,
      receiveNewsletterClient,
      authToken,
      companyName,
      activityArea,
      sizeOfTheCompany,
      isESN,
      currentPosition
    };
  };

  validateCompanyName(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", { maxlength: 100 }, { minlength: 3 }],
      i18n
    );
    this.setState({
      isCompanyNameValid: res.isValid,
      companyNameValidationMessage: res.message
    });
    return res.isValid;
  }

  isFormValid() {
    const { sessionStore } = this.props;

    const { companyName, activityArea } = sessionStore;
    let valid = true;
    if (!this.validateCompanyName(companyName)) valid = false;
    if (!this.validateActivityArea(activityArea)) valid = false;

    return valid;
  }

  updateClientCompletedHandler() {
    const { i18n } = this.props;
    toaster.success({
      title: i18n._(t`Mon entreprise`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
    this.setState({ saveCase: false });
  }

  validateActivityArea(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        "required",
        {
          rule: { minlength: 1 },
          msg: i18n._(t`Choisissez au moins un secteur d’activité`)
        }
      ],
      i18n
    );
    this.setState({
      isActivityAreaValid: res.isValid,
      activityAreaValidationMessage: res.message
    });
    return res.isValid;
  }

  render() {
    const {
      i18n,
      sessionStore,
      candidatesSearchStore,
      apolloClient
    } = this.props;
    const {
      authToken,
      companyName,
      activityArea,
      sizeOfTheCompany,
      isESN,
      logout
    } = sessionStore;
    const { clearSearch } = candidatesSearchStore;
    const {
      isCompanyNameValid,
      isActivityAreaValid,
      companyNameValidationMessage,
      activityAreaValidationMessage,
      saveCase
    } = this.state;

    let select0 = false;
    let select1 = false;
    let select2 = false;
    let select3 = false;

    if (sizeOfTheCompany === 3) {
      select3 = true;
    } else if (sizeOfTheCompany === 2) {
      select2 = true;
    } else if (sizeOfTheCompany === 1) {
      select1 = true;
    } else {
      select0 = true;
    }
    const meta = staticMetaTags(i18n).dashboardCompany;
    return (
      <div className="profile my-information">
        {metaTags(
          urls.dashboardCompany(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          null,
          null,
          false
        )}
        <div className="formCard no-gutter with-padding">
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="entreprise">
                  <Trans>Entreprise</Trans>
                </label>
                <Validation
                  errorMessage={companyNameValidationMessage}
                  valid={isCompanyNameValid}
                >
                  <TextInput
                    id="entreprise"
                    className="form-input"
                    placeholder={i18n._(t`Nom de l’entreprise`)}
                    iconColor="#D3354A"
                    value={companyName}
                    onChange={this.companyNameChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="company_size">
                  <Trans>Taille de l’entreprise</Trans>
                </label>
                <Select
                  id="company_size"
                  options={{
                    groupA: [
                      {
                        text: i18n._(t`entrer la taille de votre entreprise`),
                        disabled: true,
                        value: 0,
                        selected: select0
                      },
                      {
                        text: i18n._(t`Petite entreprise (1-50)`),
                        value: 1,
                        selected: select1
                      },
                      {
                        text: i18n._(t`Moyenne entreprise (51-100)`),
                        value: 2,
                        selected: select2
                      },
                      {
                        text: i18n._(t`Grande entreprise (101-150)`),
                        value: 3,
                        selected: select3
                      }
                    ]
                  }}
                  onSelect={this.sizeOfTheCompanyChangeHandler}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="buisness_sector">
                  <Trans>Secteur d’activité</Trans>
                </label>
                <div>
                  <Query query={GET_BUSINESS_SECTORS}>
                    {({ loading, error, data }) => {
                      if (loading) return <InputSkeleton />;
                      if (error)
                        return `${i18n._(t`Erreur`)} !${error.message}`;
                      const allCategories = data.businessSectors.map(
                        cat => cat.name
                      );
                      return (
                        <div className="withSrcollBar">
                          <AutoComplete
                            id="buisness_sector"
                            clearOnSelect
                            options={allCategories}
                            iconColor={theme.colors.mainColor}
                            iconColor2={theme.colors.lightMainColor}
                            icon="chevron-down"
                            placeholder={i18n._(
                              t`Rechercher un secteur d’activité`
                            )}
                            onSelect={val => this.activityAreaAddHandler(val)}
                          />
                        </div>
                      );
                    }}
                  </Query>
                </div>
                <Validation
                  errorMessage={activityAreaValidationMessage}
                  valid={isActivityAreaValid}
                >
                  <TagList
                    tags={activityArea}
                    variant="secondary"
                    closable
                    className="tag-list"
                    onClose={val => this.activityAreaRemoveHandler(val)}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="is_esn">
                  <Trans>Je suis ESN</Trans>
                </label>
                <div>
                  <RadioGroup
                    id="is_esn"
                    name="RadioGrp"
                    onChange={this.isESNChangeHandler}
                    defaultSelected={isESN}
                    orientation="horizontal"
                  >
                    <Radio label="Non" value={false} />
                    <Radio label="Oui" value />
                  </RadioGroup>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Divider />
          <div className="next-buttons">
            <ApolloConsumer>
              {apolloClient => (
                <Mutation
                  mutation={UPDATE_CLIENT_MUTATION}
                  variables={this.getMutationVariables()}
                  refetchQueries={[
                    {
                      query: GET_USER_INFO,
                      variables: { token: authToken }
                    }
                  ]}
                  onCompleted={data => this.updateClientCompletedHandler(data)}
                  onError={errors => {
                    errors.graphQLErrors.forEach(({ message, data }) => {
                      if (message === "UNAUTHENTICATED") {
                        apolloClient.mutate({
                          mutation: LOGOUT_MUTATION,
                          variables: { token: sessionStore.authToken }
                        });
                        logout();
                        clearSearch();
                      }
                      if (data && data.isCustomError) {
                        this.onErrorHandler(message);
                      }
                    });
                  }}
                >
                  {(mutation, { loading }) => (
                    <Button
                      disabled={loading || !saveCase}
                      onClick={() => {
                        if (this.isFormValid()) return mutation();
                        return null;
                      }}
                    >
                      {loading ? (
                        <Spinner
                          type="pointed-circular"
                          color="#FFFFFF"
                          size={12}
                        />
                      ) : (
                        <Trans>Enregistrer</Trans>
                      )}
                    </Button>
                  )}
                </Mutation>
              )}
            </ApolloConsumer>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

MyCompany.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    clearSearch: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeCompanyName: PropTypes.func,
    changeSizeOfTheCompany: PropTypes.func,
    changeIsESN: PropTypes.func,
    addActivityArea: PropTypes.func,
    removeActivityArea: PropTypes.func,
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    client: PropTypes.object,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phonenumber: PropTypes.string,
    password: PropTypes.string,
    email: PropTypes.string,
    companyName: PropTypes.string,
    activityArea: PropTypes.arrayOf(PropTypes.string),
    sizeOfTheCompany: PropTypes.number,
    isESN: PropTypes.bool,
    receiveNewsletterTalent: PropTypes.bool,
    receiveNewsletterClient: PropTypes.bool,
    preferredContact: PropTypes.shape({}),
    currentPosition: PropTypes.string,
    authToken: PropTypes.string,
    logout: PropTypes.func
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired
};
export default withApollo(withI18n()(MyCompany));
