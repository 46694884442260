import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import {
  Row,
  Col,
  TextInput,
  FormGroup,
  Button,
  Validation,
  toaster,
  Spinner,
  ImportPhoto
} from "cf-neo-ui";
import staticMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";

import validate from "../../../utils/validators";
import { GET_USER_INFO } from "../../../components/hoc/queries";
import "./styles.scss";

const UPDATE_CLIENT_MUTATION = gql`
  mutation UpdateClientMutation(
    $_id: ID!
    $firstName: String
    $lastName: String
    $phonenumber: String
    $currentPosition: String
    $companyName: String
    $activityArea: [String]
    $sizeOfTheCompany: Int
    $isESN: Boolean
    $profilePhoto: Upload
    $pictureUrl: String
  ) {
    updateClient(
      input: {
        _id: $_id
        firstName: $firstName
        lastName: $lastName
        phonenumber: $phonenumber
        currentPosition: $currentPosition
        companyName: $companyName
        activityArea: $activityArea
        sizeOfTheCompany: $sizeOfTheCompany
        isESN: $isESN
        profilePhoto: $profilePhoto
        pictureUrl: $pictureUrl
      }
    ) {
      account {
        id
        name
      }
      client {
        _id
        id
        email
        companyName
        activityArea
        currentPosition
        sizeOfTheCompany
        profilePhoto
        isESN
        password
        firstName
        lastName
        phonenumber
        customTextBlock1
        preferredContact
        receiveNewsletterTalent
        receiveNewsletterClient
        lastInfo
      }
    }
  }
`;

@inject("sessionStore", "appStore", "candidatesSearchStore")
@observer
class MyInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isfirstNameValid: true,
      islastNameValid: true,
      isCurrentPositionValid: true,
      isPhonenumberValid: true,
      firstNameValidationMessage: "",
      lastNameValidationMessage: "",
      currentPositionValidationMessage: "",
      phonenumberValidationMessage: "",
      saveCase: false
    };
    this.isFormValid = this.isFormValid.bind(this);
  }

  isFormValid = () => {
    const { sessionStore } = this.props;
    const { firstName, lastName, currentPosition, phonenumber } = sessionStore;
    let valid = true;
    if (!this.validatefirstName(firstName)) valid = false;
    if (!this.validatelastName(lastName)) valid = false;
    if (!this.validateCurrentPosition(currentPosition)) valid = false;
    if (!this.validatePhonenumber(phonenumber)) valid = false;
    return valid;
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  validatefirstName = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isfirstNameValid: res.isValid,
      firstNameValidationMessage: res.message
    });
    return res.isValid;
  };

  validatelastName = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      islastNameValid: res.isValid,
      lastNameValidationMessage: res.message
    });
    return res.isValid;
  };

  validateCurrentPosition = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isCurrentPositionValid: res.isValid,
      currentPositionValidationMessage: res.message
    });
    return res.isValid;
  };

  validatePhonenumber = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "number", { maxlength: 13 }, { minlength: 8 }],
      i18n
    );
    this.setState({
      isPhonenumberValid: res.isValid,
      phonenumberValidationMessage: res.message
    });
    return res.isValid;
  };

  firstNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changefirstName(e.target.value);
    this.validatefirstName(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  lastNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changelastName(e.target.value);
    this.validatelastName(e.target.value);

    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  currentPositionChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeCurrentPosition(e.target.value);
    this.validateCurrentPosition(e.target.value);

    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  phonenumberChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changePhonenumber(e.target.value);
    this.validatePhonenumber(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  handleUploadProfilePhoto = fileUploaded => {
    const { sessionStore } = this.props;
    sessionStore.changeProfilePhoto(fileUploaded);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  updateClientCompletedHandler(data) {
    const { i18n, sessionStore, appStore } = this.props;
    toaster.success({
      title: i18n._(t`Mes informations`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
    this.setState({ saveCase: false });
    if (data.updateClient) {
      sessionStore.changePictureUrl(data.updateClient.client.profilePhoto);
    }
    this.setState({ saveCase: false });
  }

  render() {
    const { i18n, sessionStore, candidatesSearchStore } = this.props;
    const {
      _id,
      firstName,
      lastName,
      phonenumber,
      authToken,
      companyName,
      activityArea,
      sizeOfTheCompany,
      profilePhoto,
      pictureUrl,
      isESN,
      currentPosition,
      logout
    } = sessionStore;
    const { clearSearch } = candidatesSearchStore;
    const {
      isfirstNameValid,
      islastNameValid,
      isCurrentPositionValid,
      isPhonenumberValid,
      firstNameValidationMessage,
      lastNameValidationMessage,
      currentPositionValidationMessage,
      phonenumberValidationMessage,
      saveCase
    } = this.state;
    const meta = staticMetaTags(i18n).dashboardCompany;

    return (
      <div className="profile my-information">
        {metaTags(
          urls.dashboardCompany(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          null,
          null,
          false
        )}
        <div className="formCard no-gutter with-padding">
          <Row className="photo-row">
            <Col lg={2} xl={2}>
              <FormGroup>
                <ImportPhoto
                  className="profile-photo"
                  dimension="114px"
                  src={pictureUrl || profilePhoto || "/defaultAvatar.webp"}
                  errorMessageDescription={i18n._(
                    t`Format d’image invalide. Formats recommandés : .jpeg .png`
                  )}
                  errorMessageTitle={`${i18n._(t`Erreur`)} !`}
                  onChange={(e, fileUploaded) =>
                    this.handleUploadProfilePhoto(e, fileUploaded)
                  }
                />
              </FormGroup>
            </Col>
            <Col lg={4} xl={4} md={4} sm={4} xs={6}>
              <Row noGutter>
                <Col lg={12} xl={12}>
                  <FormGroup>
                    <label className="form-label" htmlFor="user_first_name">
                      <Trans>Prénom</Trans>
                    </label>
                    <Validation
                      errorMessage={firstNameValidationMessage}
                      valid={isfirstNameValid}
                    >
                      <TextInput
                        id="user_first_name"
                        type="text"
                        spellCheck="false"
                        className="form-input"
                        placeholder={i18n._(t`Prénom`)}
                        value={firstName}
                        onChange={this.firstNameChangeHandler}
                      />
                    </Validation>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label" htmlFor="user_name">
                      <Trans>Nom</Trans>
                    </label>
                    <Validation
                      errorMessage={lastNameValidationMessage}
                      valid={islastNameValid}
                    >
                      <TextInput
                        id="user_name"
                        type="text"
                        spellCheck="false"
                        className="form-input"
                        placeholder={i18n._(t`Nom de famille`)}
                        value={lastName}
                        onChange={this.lastNameChangeHandler}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col lg={6} xl={6}>
              <Row noGutter>
                <Col lg={12} xl={12}>
                  <FormGroup>
                    <label
                      className="form-label"
                      htmlFor="user_currentPosition"
                    >
                      <Trans>Poste actuel</Trans>
                    </label>
                    <Validation
                      errorMessage={currentPositionValidationMessage}
                      valid={isCurrentPositionValid}
                    >
                      <TextInput
                        id="user_currentPosition"
                        type="text"
                        spellCheck="false"
                        className="form-input"
                        placeholder={i18n._(t`Poste actuel`)}
                        value={currentPosition}
                        onChange={this.currentPositionChangeHandler}
                      />
                    </Validation>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label" htmlFor="user_phone">
                      <Trans>Téléphone</Trans>
                    </label>
                    <Validation
                      errorMessage={phonenumberValidationMessage}
                      valid={isPhonenumberValid}
                    >
                      <TextInput
                        id="user_phone"
                        type="tel"
                        className="form-input"
                        placeholder="06XXXXXXXX"
                        value={phonenumber}
                        onChange={this.phonenumberChangeHandler}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="next-buttons">
            <Mutation
              mutation={UPDATE_CLIENT_MUTATION}
              variables={{
                _id,
                firstName,
                lastName,
                phonenumber,
                companyName,
                activityArea,
                sizeOfTheCompany,
                isESN,
                currentPosition,
                pictureUrl,
                ...(typeof profilePhoto === "object" && {
                  profilePhoto
                })
              }}
              refetchQueries={[
                {
                  query: GET_USER_INFO,
                  variables: { token: authToken }
                }
              ]}
              onCompleted={data => this.updateClientCompletedHandler(data)}
              onError={errors => {
                errors.graphQLErrors.forEach(({ message, data }) => {
                  if (message === "UNAUTHENTICATED") {
                    logout();
                    clearSearch();
                  }
                  if (data && data.isCustomError) {
                    this.onErrorHandler(message);
                  }
                });
              }}
            >
              {(mutation, { loading }) => (
                <Button
                  disabled={loading || !saveCase}
                  onClick={() => {
                    if (this.isFormValid()) return mutation();
                    return null;
                  }}
                >
                  {loading ? (
                    <Spinner
                      type="pointed-circular"
                      color="#FFFFFF"
                      size={12}
                    />
                  ) : (
                    <Trans>Enregistrer</Trans>
                  )}
                </Button>
              )}
            </Mutation>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

MyInformation.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    refreshLayout: PropTypes.func
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    clearSearch: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changefirstName: PropTypes.func,
    changelastName: PropTypes.func,
    changePhonenumber: PropTypes.func,
    changePictureUrl: PropTypes.func,
    changeProfilePhoto: PropTypes.func,
    changeCurrentPosition: PropTypes.func,
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    client: PropTypes.shape({}),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phonenumber: PropTypes.string,
    companyName: PropTypes.string,
    activityArea: PropTypes.arrayOf(PropTypes.string),
    sizeOfTheCompany: PropTypes.number,
    isESN: PropTypes.bool,
    currentPosition: PropTypes.string,
    authToken: PropTypes.string,
    profilePhoto: PropTypes.string,
    pictureUrl: PropTypes.string,
    logout: PropTypes.func
  }).isRequired
};
export default withI18n()(MyInformation);
