import React, { Component } from "react";
import { Container, Row, Col, H2 } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import classes from "./MissionsPageHeader.module.scss";

class MissionsPageHeader extends Component {
  render() {
    return (
      <section className={classes.overlape}>
        <div className={classes.block}>
          <div
            data-velocity="-.1"
            style={{
              background: `url(/assets/images/BACKGROUND_IMG.webp) repeat scroll 50% 422.28px transparent`
            }}
            className={classes.parallax}
          />
          <Container>
            <Row>
              <Col lg={12}>
                <div className={classes["inner-header"]}>
                  <h1>
                    <Trans>Trouvez votre prochain job IT</Trans>
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    );
  }
}

export default MissionsPageHeader;
