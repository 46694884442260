import axios from "axios";
import configs from "../../configs/google-apis-configs";
import runtimeVars from "../../configs/runTimeVars";

const getMapsAddress = async address => {
  const res = await axios.get(`${configs.mapsURL}/geocode/json`, {
    params: {
      v: configs.version,
      key: configs.apiKey,
      address: address.city,
      language: runtimeVars.APP_LANG || "en"
    }
  });
  if (res.data && res.data.status === "OK") {
    if (res.data.results[0] && res.data.results[0].geometry) {
      address.lat = res.data.results[0].geometry.location.lat;
      address.lng = res.data.results[0].geometry.location.lng;
    }
  }
  return address;
};
export default getMapsAddress;
