import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Icon } from "cf-neo-ui";
import theme from "../../../../configs/theme";

class SiderMenuItemSupport extends Component {
  render() {
    const { active, iconType, title, ...resProps } = this.props;
    return (
      <li
        className={`small-sider-menu-item ${
          active ? "small-sider-menu-item-selected" : ""
        }`}
        {...resProps}
      >
        <div className="small-sider-menu-item-title">
          <Icon
            className="icon"
            filled={true}
            type={iconType}
            size="tiny"
            color="#929292"
            color2="#929292"
          />
          <span id={title}>{title}</span>
        </div>
      </li>
    );
  }
}

SiderMenuItemSupport.propTypes = {
  active: PropTypes.bool,
  iconType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

SiderMenuItemSupport.defaultProps = {
  active: false
};

export default SiderMenuItemSupport;
