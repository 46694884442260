import React, { Component } from "react";
import { Button, Row, Col, Numbering } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";

import Fade from "react-reveal/Fade";
import classes from "./PartnerSection.module.scss";
import runtimeVars from "../../../configs/runTimeVars";
import { inject, observer } from "mobx-react";

const { PARTENAIRES_URL } = runtimeVars;

@inject("appStore")
@observer
class PartnerSection extends Component {
  render() {
    const { appStore } = this.props;
    const { currentLanguage } = appStore;
    return (
      <div className={classes.Partner + " partnerSection"}>
        <div className={classes.image2} />
        <Fade>
          <h2>
            <Trans>Un réseau de partenaires pour notre communauté</Trans>
          </h2>
        </Fade>
        <Fade>
          <h3 className={classes.paragraph}>
            <Trans>
              Profitez des avantages que nous avons négociés pour vous
            </Trans>
          </h3>
        </Fade>
        <Fade>
          <p className={classes.description}>
            <Trans>
              Mindquest accorde une grande importance à la recherche de
              partenaires pour vous accompagner dans votre vie de freelance.
              <strong>
                {" "}
                Pour la création de votre entreprise, votre gestion comptable ou
                le développement votre productivité,
              </strong>{" "}
              nous vous avons déniché <strong> les meilleures offres.</strong>
            </Trans>
          </p>
        </Fade>

        {currentLanguage === "fr" ? (
          <Fade>
            <Button
              onClick={() => {
                window.open(PARTENAIRES_URL, "_blank");
              }}
            >
              <Trans>Découvrir nos partenaires</Trans>
            </Button>
          </Fade>
        ) : null}
      </div>
    );
  }
}

export default withI18n()(PartnerSection);
