import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Pagination, Spinner, Table, SwitchButton, Icon } from "cf-neo-ui";
import { Query, Mutation } from "react-apollo";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react/index";
import classes from "./stylesTalentsInPool.module.scss";
import LoadingMissions from "../../../components/LoadingMissions/LoadingMissions";
import { isOnePage } from "../../../utils/helpers";
import gql from "graphql-tag";
import { LIST_COLLABORATOR_BY_POOL_QUERY } from "../../dashboard-company/queries";
import * as queryString from "query-string";
import theme from "../../../configs/theme";

const UPDATE_PRESENT_COLLABORATOR_MUTATION = gql`
  mutation updateCollaborator(
    $creatorID: String
    $clientCompanyId: Int
    $clientEmail: String!
    $vivierID: String
    $clientID: String
  ) {
    updateCollaborator(
      input: {
        creatorID: $creatorID
        clientCompanyId: $clientCompanyId
        clientEmail: $clientEmail
        vivierID: $vivierID
        clientID: $clientID
      }
    ) {
      collaborator {
        clientID
        clientEmail
        clientCompanyId
        clientCompanyName
        clientName
        nbPool
        permitPool
        dateAdded
      }
    }
  }
`;

@inject("appStore", "modalStore", "sessionStore")
@observer
class ListCollaborators extends Component {
  formatTable = (listCollaborator, vivierID) => {
    const { sessionStore, appStore } = this.props;
    const { width } = appStore;

    const isMobile = width <= 1223 ? true : false;
    const { client } = sessionStore;
    if (
      !listCollaborator ||
      !Array.isArray(listCollaborator) ||
      !listCollaborator.length
    )
      return null;
    return listCollaborator.map(
      ({
        clientName,
        clientCompanyName,
        clientEmail,
        emailClient,
        permitPool,
        clientID
      }) => [
        isMobile ? clientName?.substring(0, 25) : clientName,
        clientCompanyName,
        emailClient ? emailClient : clientEmail,
        <div>
          {clientName ? (
            <Mutation
              mutation={UPDATE_PRESENT_COLLABORATOR_MUTATION}
              variables={{
                clientEmail: clientEmail.toLowerCase(),
                creatorID: client._id,
                vivierID: vivierID,
                clientID: clientID
              }}
              refetchQueries={[
                {
                  query: LIST_COLLABORATOR_BY_POOL_QUERY,
                  variables: {
                    vivierID: vivierID,
                    creatorID: client._id
                  }
                }
              ]}
              onCompleted={data => {}}
            >
              {updateCollab => (
                <SwitchButton
                  style={{
                    marginLeft: "40%"
                  }}
                  checked={permitPool.includes(vivierID)}
                  onSwitch={async () => {
                    return updateCollab();
                  }}
                />
              )}
            </Mutation>
          ) : (
            <picture className={classes.tooltip2}>
              <img src="/assets/images/tooltip.webp" alt="tooltip" />
              <div className={classes.right}>
                <Trans>Collaborateur non-inscrit</Trans>
              </div>
              <i />
            </picture>
          )}
        </div>
      ]
    );
  };
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      sortBy: "All",
      sortPool: "All",
      employmentType: "",
      order: 1,
      currentPage: 1,
      pageSize: 5,
      skip: 0
    };
    this.total = 0;
  }
  componentDidMount() {
    const { shared, poolName } = this.props;
    this.setState({ titlePool: poolName, sharedPool: shared }, function() {});
  }

  loading = () => {
    return (
      <Spinner
        type="pointed-circular"
        color={theme.colors.mainColor}
        size={60}
      />
    );
  };
  setTotalCollaborator = collaborator => {
    if (collaborator) this.total = collaborator.count;
    return this.total;
  };
  renderPagination = length => {
    const { pageSize, currentPage } = this.state;
    const paginationProps = {
      pageSize,
      total: length,
      current: currentPage,
      onChange: current => {
        this.setState({ currentPage: current, skip: (current - 1) * pageSize });
      }
    };
    return <Pagination {...paginationProps} />;
  };
  getQueryVariables = () => {
    const { pageSize, skip } = this.state;

    const filter = {};

    if (skip) filter.skip = skip;
    if (pageSize) filter.limit = pageSize;

    return filter;
  };

  render() {
    const { i18n, sessionStore, location } = this.props;
    const { pageSize, skip } = this.state;
    const vivierID = location.pathname.split("talents-pool/")[1];

    let longueur = null;
    const { client, emailCollaborator, companyID } = sessionStore;
    const isMobile = window.screen.width <= 690;
    return (
      <div className={classes.allBody}>
        {/* only in mobile image + text  */}
        {isMobile && (
          <div>
            <div>
              <h3 className={classes.TextCollaborateur}>
                <Trans> Vos collaborateur </Trans>
              </h3>
            </div>
            <div className={classes.scrollMobile}>
              <div className={classes.MobileIcon}>
                <Icon size="small" type="mobile" color="red" />
              </div>

              <div className={classes.TextscrollMobile}>
                <h5 className={classes.TextscrollMobile}>
                  <Trans>
                    Naviguer horizentalement ou basculez votre écran en position
                    paysage pour consulter toutes les statistiques
                  </Trans>
                </h5>
              </div>
            </div>
          </div>
        )}

        {/* table collaborator */}

        <Query
          query={LIST_COLLABORATOR_BY_POOL_QUERY}
          variables={{
            vivierID: vivierID,
            creatorID: client._id,
            skip: skip,
            limit: pageSize
          }}
          fetchPolicy="network-only"
          onCompleted={data => {}}
        >
          {({ loading, error, data }) => {
            if (error) {
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );
            }
            if (loading) return this.loading();

            var tableBody = [];
            const { listCollaboratorByPool } = data;
            const { collaborator } = listCollaboratorByPool;

            if (collaborator)
              longueur = this.setTotalCollaborator(listCollaboratorByPool);
            if (collaborator.length > 0) {
              tableBody = this.formatTable(collaborator, vivierID);
            } else {
              return (
                <div>
                  <Trans>Pas de collaborateur</Trans>
                </div>
              );
            }
            return (
              <div>
                <Table
                  className={classes.tableColaborator}
                  head={[
                    i18n._(t`Prénom Nom`),
                    i18n._(t`Entreprise`),
                    i18n._(t`Adresse email`),
                    i18n._(t`Collaboration sur le vivier`)
                  ]}
                  body={tableBody}
                  fixedColumns={1}
                />
                {!collaborator ||
                collaborator.length === 0 ||
                isOnePage(pageSize, longueur) ? null : (
                  <div
                    className={classes.pagination}
                    style={{ marginTop: "25px" }}
                  >
                    {this.renderPagination(longueur)}
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

ListCollaborators.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    isVisible: PropTypes.bool,
    isVisible2: PropTypes.bool,
    changeIsVisible: PropTypes.func,
    changeIsVisible2: PropTypes.func,
    changeModalType: PropTypes.func,
    modalType: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(ListCollaborators));
