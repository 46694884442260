import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { FormCard, Icon, Row, Col } from "cf-neo-ui";
import moment from "moment";
import runtimeVars from "../../../configs/runTimeVars";
import styles from "./styles.module.scss";

@inject("modalStore", "sessionStore")
@observer
class Success extends Component {
  constructor(props) {
    super(props);
    moment.locale(runtimeVars.APP_LANG);
    const initProps = { ...props };
    this.state = {
      ...initProps
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  clickCancelHandler = () => {
    const { modalStore } = this.props;
    const { changeParamsSuccessPopUp } = modalStore;
    changeParamsSuccessPopUp(false);
  };

  render() {
    return (
      <div className={styles.popUpContainer}>
        <FormCard className="formCard no-gutter">
          <Row>
            <Col className={styles.formCardContent}>
              <div className={styles.header}>
                <Icon
                  className={styles.bellIcon}
                  type="Bell"
                  color="#8D0417"
                  color2="#D3354A"
                  width={14}
                  height={14}
                />
                <p>
                  <Trans>demande déja confirmé</Trans>
                </p>
                <Icon
                  className={styles.closeIcon}
                  type="close"
                  color="#8D0417"
                  color2="#D3354A"
                  width={14}
                  height={14}
                  onClick={() => this.clickCancelHandler()}
                />
              </div>
              <div className={styles.container}>
                <p>
                  <Trans>
                    Vous avez déjà confirmé votre demande, elle sera traitée
                    dans les plus brefs délais
                  </Trans>
                </p>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

Success.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    changeParamsSuccessPopUp: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(Success));
