import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react/index";
import {
  Button,
  Sorter,
  Row,
  Col,
  TextInput,
  Radio,
  FormGroup,
  Spinner,
  toaster,
  Validation,
  RadioGroup
} from "cf-neo-ui";
import CloseIcon from "./icons/CloseIcon";
import classes from "./styles.module.scss";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Trans, t } from "@lingui/macro";
import { TALENT_POOLS_QUERY } from "../dashboard-company/queries";
import validate from "../../utils/validators";

const UPDATE_Talent_POOL_MUTATION = gql`
  mutation updateTalentPoolMutation(
    $poolName: String!
    $clientID: String
    $clientEmail: String
    $clientName: String
    $sourceTalentPool: String
    $creatorID: String
    $shared: Boolean
    $clientCompanyId: Int
    $talentID: Int
  ) {
    updateTalentPool(
      input: {
        poolName: $poolName
        clientID: $clientID
        clientEmail: $clientEmail
        clientName: $clientName
        sourceTalentPool: $sourceTalentPool
        creatorID: $creatorID
        shared: $shared
        clientCompanyId: $clientCompanyId
        talentID: $talentID
      }
    ) {
      poolName
      clientEmail
      clientID
      sourceTalentPool
      creatorID
      shared
      clientCompanyId
    }
  }
`;

@inject("appStore", "sessionStore", "modalStore", "talentStore")
@observer
class NewTalentPool extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    delete initProps.sessionStore;
    this.state = {
      pool: "",
      shared: false,
      pageSize: this.setPageSize(props.appStore.width),
      currentPage: 1,
      skip: 0,
      limit: 100,
      titleValidationMessage: "",
      isTitleValid: false
    };
  }

  setPageSize = width => {
    if (width >= 1440) return 8;
    if (width >= 1024) return 8;
    if (width >= 768) return 6;
    return 2;
  };
  sharedChangeHandler = value => {
    this.setState({ shared: value });
  };
  poolNameChangeHandler = e => {
    this.setState({ pool: e.target.value });
    this.validateTitle(e.target.value);
  };
  validateTitle = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", value, { maxlength: 30 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isTitleValid: res.isValid,
      titleValidationMessage: res.message
    });
    return res.isValid;
  };
  closeModal = () => {
    const { modalStore } = this.props;
    const { changeIsVisibleCreatePool } = modalStore;
    changeIsVisibleCreatePool(false);
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    if (
      msg ===
      'Unexpected error value: "Vous avez dépasser le nombre limité de vivier"'
    ) {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(t`Vous avez dépasser le nombre limité de vivier`)
      });
    } else if (msg === 'Unexpected error value: "Ce nom existe déjà !"') {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(t`Ce nom existe déjà !`)
      });
    } else {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        dfescription: i18n._(
          t`Une erreur est survenue, veuillez réessayer ultérieurement`
        )
      });
    }
  };
  isFormValid = () => {
    const { pool } = this.state;
    let valid = true;
    if (!this.validateTitle(pool)) valid = false;
    return valid;
  };

  render() {
    const {
      i18n,
      modalStore,
      sessionStore,
      appStore,
      talent,
      talentStore
    } = this.props;
    const {
      changeIsVisibleCreatePool,
      changeIsVisibleListPools,
      changeIsVisible2
    } = modalStore;
    const {
      client,
      email,
      companyName,
      lastName,
      firstName,
      companyID
    } = sessionStore;
    const { talentID } = talentStore;
    const { titleValidationMessage, isTitleValid, pool, shared } = this.state;
    const orientation = appStore.width <= 348 ? "vertical" : "horizontal";
    const talentid =
      talent && talent.talentID ? talent.talentID : talentID ? talentID : 0;

    return (
      <div className={classes.content}>
        <a onClick={this.closeModal} className={classes.closeIcon}>
          <CloseIcon />
        </a>
        <Sorter
          text={i18n._(t`Créer un vivier`)}
          menu={false}
          onChange={() => {}}
          chevronSize={0.00001}
          className={classes.sorter}
          chevronColor="#000000"
          classNameText={
            appStore.width <= 348
              ? "font-style: normal; font-weight: bold; font-size: 36px;color: #000000; line-height: 1;"
              : "padding-bottom: 6px;font-style: normal; font-weight: bold; font-size: 24px;color: #000000; line-height: 1;"
          }
        />
        <div className={classes.bodyPage}>
          <FormGroup>
            <div>
              <Trans> Nom du vivier </Trans>
            </div>
            <Validation
              errorMessage={titleValidationMessage}
              valid={isTitleValid}
            >
              <TextInput
                value={pool}
                placeholder={i18n._(t`Ajouter le nom de votre vivier`)}
                name="titleTalentPool"
                spellCheck="false"
                className={classes.textinput}
                onChange={this.poolNameChangeHandler}
              />
            </Validation>
          </FormGroup>

          <Row>
            <FormGroup>
              <Col>
                <label htmlFor="contractType" className={classes.labelText}>
                  <Trans>
                    Souhaitez-vous ouvrir le vivier à vos collaborateurs ?
                  </Trans>
                </label>

                <RadioGroup
                  className="contractType-radio-group"
                  name="contractTypeRadioGrp"
                  orientation={orientation}
                  defaultSelected={shared}
                  style={{ marginLeft: "35%" }}
                  onChange={this.sharedChangeHandler}
                >
                  <Radio label={i18n._(t`Oui`)} value={true} />
                  <Radio label={i18n._(t`Non`)} value={false} />
                </RadioGroup>
              </Col>
            </FormGroup>
          </Row>
          <Row style={{ float: "right", marginTop: "-3%", marginRight: "-3%" }}>
            <FormGroup>
              <Col>
                <Mutation
                  mutation={UPDATE_Talent_POOL_MUTATION}
                  variables={{
                    talentID: talentid,
                    poolName: pool,
                    clientID: client._id,
                    clientEmail: email,
                    clientCompanyName: companyName,
                    clientName: firstName + " " + lastName,
                    sourceTalentPool: "client",
                    creatorID: client._id,
                    shared: shared,
                    clientCompanyId: companyID
                  }}
                  refetchQueries={[
                    {
                      query: TALENT_POOLS_QUERY,
                      variables: {
                        clientID: client._id,
                        clientCompanyId: companyID,
                        limit: 8,
                        skip: 0,
                        sortBy: "All",
                        search: "",
                        order: 1
                      }
                    }
                  ]}
                  onCompleted={data => {
                    this.setState({ pool: "" });
                    changeIsVisibleCreatePool(false);
                  }}
                  onError={errors => {
                    changeIsVisibleCreatePool(false);
                    changeIsVisibleListPools(false);
                    changeIsVisible2(false);
                    errors.graphQLErrors.forEach(({ message, data }) => {
                      if (message) {
                        this.onErrorHandler(message);
                      }
                    });
                  }}
                >
                  {(mutation, { loading }) => (
                    <span>
                      {loading ? (
                        <Button disabled>
                          <Spinner
                            type="pointed-circular"
                            color="#FFFFFF"
                            size={12}
                          />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => this.isFormValid() && mutation()}
                        >
                          <Trans>Créer</Trans>
                        </Button>
                      )}
                    </span>
                  )}
                </Mutation>
              </Col>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}

NewTalentPool.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisibleCreatePool: PropTypes.func,
    changeIsVisible2: PropTypes.func,
    changeIsVisibleListPools: PropTypes.func
  }).isRequired,
  talent: PropTypes.object,
  talentStore: PropTypes.shape({
    changeTalent: PropTypes.func,
    resetTalent: PropTypes.func,
    talent: PropTypes.shape
  }).isRequired,
  jobSubmissionStore: PropTypes.shape({
    changeJobSubmission: PropTypes.func,
    reset: PropTypes.func,
    jobSubmission: PropTypes.shape
  }).isRequired
};

export default withI18n()(withRouter(NewTalentPool));
