import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react/index";
import { Container, Row, Col, Notification } from "cf-neo-ui";
import { Query, Mutation } from "react-apollo";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import AlertModal from "../../components/alert-modal/AlertModal";
import { USER_ALERT_QUERY } from "./queries";
import { DELETE_ALERT } from "./mutations";
import classes from "./styles.module.scss";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";

const conditionalPush = (arr, title, tags) =>
  tags && tags.length && arr.push({ title, tags });

const stringToArr = str => (str ? [str] : []);

@inject("missionsSearchStore", "appStore")
@observer
class MyAlerts extends Component {
  constructor(props) {
    super(props);
  }

  getModalData = (missionAlert, i18n) => {
    const {
      categories,
      skills,
      businessSectors,
      specialties,
      workType,
      employmentType,
      onSite,
      address
    } = missionAlert;
    const cityLabels = address.map(item => item.city);

    const data = [];
    conditionalPush(data, i18n._(t`Domaine d’expertise`), categories);
    conditionalPush(data, i18n._(t`Compétence`), skills);
    conditionalPush(data, i18n._(t`Secteur`), businessSectors);
    conditionalPush(data, i18n._(t`Spécialité`), specialties);
    conditionalPush(data, i18n._(t`Rythme de travail`), stringToArr(workType));
    conditionalPush(
      data,
      i18n._(t`Type de contrat`),
      stringToArr(employmentType)
    );
    conditionalPush(data, i18n._(t`Remote/Présentiel`), stringToArr(onSite));
    conditionalPush(data, i18n._(t`Localisation`), cityLabels);

    return data;
  };

  setFilters = missionAlert => {
    const { missionsSearchStore } = this.props;
    const {
      categories,
      skills,
      businessSectors,
      workType,
      employmentType,
      onSite,
      address
    } = missionAlert;

    if (Array.isArray(categories) && categories.length) {
      categories.map(item => missionsSearchStore.addCategory(item));
      missionsSearchStore.setCategoryOptions();
    }

    if (Array.isArray(skills) && skills.length) {
      skills.map(item => missionsSearchStore.addSkill(item));
      missionsSearchStore.setSkillOptions();
    }

    if (Array.isArray(businessSectors) && businessSectors.length) {
      businessSectors.map(item => missionsSearchStore.addBusinessSector(item));
      missionsSearchStore.setBusinessSectorOptions();
    }

    if (Array.isArray(address) && address.length) {
      address.map(item => missionsSearchStore.addLocation(item));
    }

    if (workType) {
      missionsSearchStore.changeWorkType(workType);
    }

    if (employmentType) {
      missionsSearchStore.changeEmploymentType(employmentType);
    }

    if (onSite) {
      missionsSearchStore.changeOnSite(onSite);
    }
  };

  editAlertHandler = missionAlert => {
    const { history } = this.props;
    this.setFilters(missionAlert);
    history.push("/missions?action=edit");
  };

  render() {
    const { i18n, appStore } = this.props;
    const meta = staticMetaTags(i18n).alertMissions;
    const userGuideURL =
      "https://mindquest.eb-help.com/help/collections/guide-utilistateurs";
    return (
      <>
        {metaTags(
          urls.alertMissions(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          null,
          null,
          false
        )}
        <Container className={classes.marTop}>
          <div className={classes.alert}>
            {appStore.width < 768 ? (
              <Notification
                notificationTitle={i18n._(t`En savoir plus`)}
                text={i18n._(
                  t`Mindquest vous permet d’associer plusieurs critères au sein d‘une même alerte grâce au format Booléen “OU”/“OR”. Vous recevrez par email les opportunités correspondant à une OU plusieurs sélections par critère.`
                )}
                buttonLabel={i18n._(
                  t`Accéder au guide pour plus d’information`
                )}
                onClickButton={() => window.open(userGuideURL, "_blank")}
                h={150}
              />
            ) : (
              <Notification
                notificationTitle={i18n._(t`En savoir plus`)}
                text={i18n._(
                  t`Mindquest vous permet d’associer plusieurs critères au sein d‘une même alerte grâce au format Booléen “OU”/“OR”. Vous recevrez par email les opportunités correspondant à une OU plusieurs sélections par critère.`
                )}
                buttonLabel={i18n._(
                  t`Accéder au guide pour plus d’information`
                )}
                onClickButton={() => window.open(userGuideURL, "_blank")}
                h={80}
              />
            )}
          </div>
          <br />
          <Query query={USER_ALERT_QUERY} fetchPolicy="network-only">
            {({ loading, error, data }) => {
              if (loading) return <div />;

              if (error) return <div />;

              const { userMissionAlert } = data;

              return (
                userMissionAlert && (
                  <Row>
                    <Col lg={4}>
                      <Mutation
                        mutation={DELETE_ALERT}
                        refetchQueries={[{ query: USER_ALERT_QUERY }]}
                      >
                        {deleteAlert => (
                          <AlertModal
                            data={this.getModalData(userMissionAlert, i18n)}
                            primaryButtonLabel={i18n._(t`Editer`)}
                            secondaryButtonLabel={i18n._(t`Supprimer`)}
                            onSubmit={() =>
                              this.editAlertHandler(userMissionAlert)
                            }
                            onCancel={deleteAlert}
                          />
                        )}
                      </Mutation>
                    </Col>
                  </Row>
                )
              );
            }}
          </Query>
        </Container>
      </>
    );
  }
}

MyAlerts.wrappedComponent.propTypes = {
  missionsSearchStore: PropTypes.shape({
    addCategory: PropTypes.func,
    setCategoryOptions: PropTypes.func,
    addSkill: PropTypes.func,
    setSkillOptions: PropTypes.func,
    changeWorkType: PropTypes.func,
    addBusinessSector: PropTypes.func,
    setBusinessSectorOptions: PropTypes.func,
    changeEmploymentType: PropTypes.func,
    changeOnSite: PropTypes.func,
    addLocation: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(MyAlerts));
