import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react/index";
import { Button, Row, Icon, Spinner } from "cf-neo-ui";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Trans, t } from "@lingui/macro";
import { TALENT_POOLS_QUERY } from "../dashboard-company/queries";
import theme from "../../configs/theme";
import classes from "../../components/alert-modal/alertModal.module.scss";

const DELETE_TALENTPOOL_MUTATION = gql`
  mutation deleteTalentPool($vivierID: String) {
    deleteTalentPool(vivierID: $vivierID) {
      _id
      clientCompanyName
      updatedAt
      poolName
      clientID
      clientEmail
      clientCompanyId
      clientName
      sourceTalentPool
      creatorID
      shared
      nbProfile
      talentID
    }
  }
`;

@inject("appStore", "sessionStore", "modalStore", "talentStore")
@observer
class DeleteTalentPool extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    delete initProps.sessionStore;
    this.state = {
      skip: 0,
      limit: 100
    };
  }
  closeModal = () => {
    const { modalStore } = this.props;
    const { changetoDelete } = modalStore;
    changetoDelete(false);
  };
  render() {
    const {
      i18n,
      modalStore,
      sessionStore,
      appStore,
      vivierID,
      history
    } = this.props;
    const { changetoDelete } = modalStore;
    const { id, talentPoolFilter, companyID } = sessionStore;
    return (
      <div className={classes.bodyPage}>
        <div className={classes.alertModal}>
          <div className={classes.header}>
            <div className={classes.headerIcon}>
              <Icon
                size="tiny"
                type="bell"
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
            </div>
            <Trans>Alerte Suppression</Trans>
          </div>
          <div className={classes.body}>
            <Row>
              {i18n._(t`Êtes-vous sûr de vouloir supprimer ce vivier?`)}
            </Row>
          </div>
          <div className={classes.footer}>
            <Button
              style={{ marginRight: "15px" }}
              size="small"
              variant="secondary"
              onClick={this.closeModal}
            >
              <Trans>Annuler</Trans>
            </Button>
            <Mutation
              mutation={DELETE_TALENTPOOL_MUTATION}
              variables={{
                vivierID: vivierID
              }}
              onCompleted={data => {
                history.push("/dashboard/company/talents?active=TalentPool");
                changetoDelete(false);
              }}
            >
              {(deleteTalent, { loading }) => (
                <span>
                  {loading ? (
                    <Button disabled>
                      <Spinner
                        type="pointed-circular"
                        color="#FFFFFF"
                        size={12}
                      />
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      onClick={() => {
                        deleteTalent();
                        changetoDelete(false);
                      }}
                    >
                      <Trans>Supprimer</Trans>
                    </Button>
                  )}
                </span>
              )}
            </Mutation>
          </div>
        </div>
      </div>
    );
  }
}

DeleteTalentPool.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape
  }).isRequired,
  modalStore: PropTypes.shape({
    changetoDelete: PropTypes.func
  }).isRequired,
  vivierID: PropTypes.string
};

export default withI18n()(withRouter(DeleteTalentPool));
