import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import {
  Row,
  Col,
  CheckboxGroup,
  TextInput,
  FormGroup,
  Button,
  Validation,
  Checkbox,
  PasswordInput,
  toaster,
  Spinner,
  Modal,
  Select
} from "cf-neo-ui";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import validate from "../../utils/validators";
import { scorePassword } from "../../utils/helpers";
import "../dashboard/profile/styles.scss";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import EmailChange from "./pop-ups/EmailChange";
import styles from "./styles.module.scss";
import DeleteAccount from "./pop-ups/DeleteAccount";
import PasswordConfirmation from "./pop-ups/PasswordConfirmation";
import { withRouter } from "react-router-dom";
import Success from "./pop-ups/Success";
import { GET_USER_INFO } from "../../components/hoc/queries";
import RequestAlreadyCreated from "./pop-ups/RequestAlreadyCreated";

const queryString = require("query-string");

const UPDATE_CANDIDATE_MUTATION = gql`
  mutation UpdateCandidateMutation(
    $_id: ID!
    $currentPassword: String
    $password: String
    $preferredContact: String
    $receiveNewsletterTalent: Boolean
    $receiveNewsletterClient: Boolean
    $lastInfo: Boolean
    $siteLanguage: String
  ) {
    updateCandidate(
      input: {
        _id: $_id
        password: $password
        currentPassword: $currentPassword
        preferredContact: $preferredContact
        receiveNewsletterTalent: $receiveNewsletterTalent
        receiveNewsletterClient: $receiveNewsletterClient
        lastInfo: $lastInfo
        siteLanguage: $siteLanguage
      }
    ) {
      account {
        id
        name
        siteLanguage
      }
      candidate {
        _id
        email
        name
        occupation
        firstName
        lastName
        phone
        dayRateLow
        dayRate
        employmentPreference
        companyURL
        dateAvailable
        categories
        experience
        customText20
        customText21
        customInt10
        profilePhoto
        preferredContact
        salary
        receiveNewsletterTalent
        receiveNewsletterClient
        lastInfo
        cv
        address {
          address1
          city
          countryID
          zip
          state
          lat
          lng
        }
        searchAddress {
          address1
          city
          countryID
          lat
          lng
          state
        }
      }
      message
    }
  }
`;

const UPDATE_CLIENT_MUTATION = gql`
  mutation UpdateClientMutation(
    $_id: ID!
    $currentPassword: String
    $password: String
    $preferredContact: String
    $receiveNewsletterTalent: Boolean
    $receiveNewsletterClient: Boolean
    $lastInfo: Boolean
    $siteLanguage: String
  ) {
    updateClient(
      input: {
        _id: $_id
        currentPassword: $currentPassword
        password: $password
        preferredContact: $preferredContact
        receiveNewsletterTalent: $receiveNewsletterTalent
        receiveNewsletterClient: $receiveNewsletterClient
        lastInfo: $lastInfo
        siteLanguage: $siteLanguage
      }
    ) {
      account {
        id
        name
        siteLanguage
      }
      client {
        _id
        id
        email
        companyName
        activityArea
        currentPosition
        sizeOfTheCompany
        profilePhoto
        isESN
        password
        firstName
        lastName
        phonenumber
        customTextBlock1
        preferredContact
        receiveNewsletterTalent
        receiveNewsletterClient
        lastInfo
      }
      message
    }
  }
`;

@inject("sessionStore", "appStore", "modalStore")
@observer
class Params extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPassword: "",
      isEmailValid: true,
      isPasswordValid: true,
      isConfirmPasswordValid: true,
      isCurrentPasswordValid: true,
      emailValidationMessage: "",
      passwordValidationMessage: "",
      confirmPasswordValidationMessage: "",
      currentPassword: "",
      currentPasswordValidationMessage: "",
      saveCase: false,
      action: "changeEmail", //take 2 values (deleteAccount or changeEmail),
      popupToShow: "success"
    };
    this.isFormValid = this.isFormValid.bind(this);
  }

  componentDidMount() {
    const { location, modalStore } = this.props;
    const { changeParamsSuccessPopUp } = modalStore;

    const { result } = queryString.parse(location.search);
    if (result && result === "success") {
      this.setState({ popupToShow: "success" });
      changeParamsSuccessPopUp(true);
      window.history.pushState({}, "", "?");
    }
    if (result && result === "request-already-created") {
      this.setState({ popupToShow: "request-already-created" });
      changeParamsSuccessPopUp(true);
      window.history.pushState({}, "", "?");
    }
  }

  handleEvaluator = v => {
    return scorePassword(v);
  };
  validatePassword(value) {
    const { i18n } = this.props;
    const { confirmPassword } = this.state;
    if (!value && confirmPassword !== "") {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`Champ requis`)
      });
      return false;
    }

    if (!value && confirmPassword === "") {
      this.setState({
        isPasswordValid: true,
        passwordValidationMessage: ""
      });
      return true;
    }

    if (value.length < 8) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins 8 caractères`
        )
      });
      return false;
    }

    if (!/[a-z]/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins une minuscule`
        )
      });
      return false;
    }
    if (!/[A-Z]/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins une majuscule`
        )
      });
      return false;
    }
    if (!/\d/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`Doit contenir au moins un chiffre`)
      });
      return false;
    }
    if (!/\W/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins un caractère spécial !*#@...`
        )
      });
      return false;
    }
    this.setState({
      isPasswordValid: true,
      passwordValidationMessage: ""
    });
    return true;
  }
  isFormValid = () => {
    const { sessionStore, i18n } = this.props;
    const { password, changePassword } = sessionStore;
    const { currentPassword, confirmPassword } = this.state;

    let valid = true;
    if (!this.validatePassword(password)) valid = false;
    if (!this.validateConfirmPassword(confirmPassword)) valid = false;
    if (confirmPassword !== password) {
      valid = false;
      this.setState({
        isConfirmPasswordValid: false,
        confirmPasswordValidationMessage: i18n._(
          t`Ce champs ne contient pas la même valeur`
        )
      });
    }
    if (currentPassword && !password) {
      valid = false;
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`Champ requis`)
      });
    }
    if (!this.validatePassword(password)) {
      valid = false;
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`nouveau mot de passe est invalide`)
      });
    }
    return valid;
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  siteLanguageChangeHandler = item => {
    const { sessionStore } = this.props;
    sessionStore.changeSiteLanguage(item.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };

  receiveNewsletterTalentChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeReceiveNewsletterTalent(
      !sessionStore.receiveNewsletterTalent
    );
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(2);
  };

  receiveNewsletterClientChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeReceiveNewsletterClient(
      !sessionStore.receiveNewsletterClient
    );
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(2);
  };
  lastInfoChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeLastInfo(!sessionStore.lastInfo);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(2);
  };

  preferredContactChangeHandler = v => {
    if (Array.isArray(v)) {
      const { sessionStore } = this.props;
      sessionStore.changePreferredContact(v.toString());
    }
    const { saveCase } = this.state;
    const { sessionStore } = this.props;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(2);
  };

  passwordChangeHandler = v => {
    const { sessionStore } = this.props;
    sessionStore.changePassword(v);
    this.validatePassword(v);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  currentPasswordChangeHandler = v => {
    this.setState({ currentPassword: v });
  };

  confirmPasswordChangeHandler = v => {
    this.setState({ confirmPassword: v });
    this.validateConfirmPassword(v);
  };

  updateUserCompletedHandler(data) {
    const { i18n, sessionStore } = this.props;
    const { changePassword, account } = sessionStore;
    const { role } = account;
    if (role === "CANDIDATE") {
      if (data.updateCandidate.message === "Current password is invalid") {
        toaster.error({
          title: i18n._(t`Echec de modification du mot de passe.`),
          description: i18n._(t`Mot de passe actuel est invalide`)
        });
        this.setState({
          isCurrentPasswordValid: false,
          currentPasswordValidationMessage: i18n._(
            t`mot de passe actuel est invalide`
          ),
          isPasswordValid: true,
          passwordValidationMessage: "",
          confirmPassword: "",
          currentPassword: ""
        });
        changePassword("");
      } else {
        toaster.success({
          title: i18n._(t`Mes paramètres`),
          description: i18n._(t`Enregistrement effectué avec succès`)
        });
        this.setState({
          confirmPassword: "",
          currentPassword: ""
        });
        changePassword("");
      }
    } else {
      if (data.updateClient.message === "Current password is invalid") {
        toaster.error({
          title: i18n._(t`Echec de modification du mot de passe.`),
          description: i18n._(t`Mot de passe actuel est invalide`)
        });
        this.setState({
          isCurrentPasswordValid: false,
          currentPasswordValidationMessage: i18n._(
            t`mot de passe actuel est invalide`
          ),
          isPasswordValid: true,
          passwordValidationMessage: "",
          confirmPassword: "",
          currentPassword: ""
        });
        changePassword("");
      } else {
        toaster.success({
          title: i18n._(t`Mes paramètres`),
          description: i18n._(t`Enregistrement effectué avec succès`)
        });
        this.setState({
          confirmPassword: "",
          currentPassword: ""
        });
        changePassword("");
      }
    }

    this.setState({ saveCase: false });
    sessionStore.changeGeneralSaveCase(null);
  }

  validateConfirmPassword(value) {
    const { i18n, sessionStore } = this.props;
    const res = validate(value, [{ similarTo: sessionStore.password }], i18n);
    this.setState({
      isConfirmPasswordValid: res.isValid,
      confirmPasswordValidationMessage: res.message
    });
    return res.isValid;
  }

  render() {
    const { i18n, sessionStore, modalStore, appStore } = this.props;
    const {
      _id,
      email,
      password,
      preferredContact,
      receiveNewsletterTalent,
      receiveNewsletterClient,
      lastInfo,
      account,
      authToken,
      siteLanguage
    } = sessionStore;
    const {
      confirmPassword,
      isEmailValid,
      isPasswordValid,
      isCurrentPasswordValid,
      currentPasswordValidationMessage,
      currentPassword,
      isConfirmPasswordValid,
      emailValidationMessage,
      passwordValidationMessage,
      confirmPasswordValidationMessage,
      saveCase,
      action,
      popupToShow,
      clicked
    } = this.state;
    const {
      paramsConfirmationPasswordPopUp,
      changeParamsConfirmationPasswordPopUp,
      paramsChangeEmailPopUp,
      paramsDeleteAccountPopUp,
      paramsSuccessPopUp
    } = modalStore;
    const preferredContactArray = preferredContact
      ? preferredContact.split(",")
      : [];
    const meta = staticMetaTags(i18n).params;

    const { role } = account;
    return (
      <div className="profile my-information">
        {metaTags(
          urls.myParams(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords
        )}
        <Modal
          centred
          isVisible={paramsConfirmationPasswordPopUp}
          withCloseButton={false}
          position="fixed"
        >
          <PasswordConfirmation action={action} />
        </Modal>
        <Modal
          centred
          isVisible={paramsChangeEmailPopUp}
          withCloseButton={false}
          position="fixed"
        >
          <EmailChange />
        </Modal>
        <Modal
          centred
          isVisible={paramsDeleteAccountPopUp}
          withCloseButton={false}
          position="fixed"
        >
          <DeleteAccount />
        </Modal>
        <Modal
          centred
          isVisible={paramsSuccessPopUp}
          withCloseButton={false}
          position="fixed"
        >
          {popupToShow === "success" && <Success />}
          {popupToShow === "request-already-created" && (
            <RequestAlreadyCreated />
          )}
        </Modal>
        <div className="formCard no-gutter with-padding">
          <form>
            <Row>
              <Col
                lg={6}
                xl={6}
                md={6}
                sm={6}
                xs={6}
                style={{ padding: "0 20px" }}
              >
                <FormGroup>
                  <label
                    className="form-label"
                    htmlFor="user_email"
                    style={{
                      fontSize: "20px"
                    }}
                  >
                    <Trans>Mon adresse email</Trans>
                  </label>
                  <Row>
                    <Col noGape lg={7} xl={6} md={7} sm={7} xs={6}>
                      <Validation
                        errorMessage={emailValidationMessage}
                        valid={isEmailValid}
                      >
                        <TextInput
                          id="user_email"
                          type="email"
                          spellCheck="false"
                          className="form-input"
                          placeholder={i18n._(t`monemail@mail.com`)}
                          value={email}
                          disabled
                        />
                      </Validation>
                    </Col>
                    <Col noGutter noGape lg={5} xl={6} md={5} sm={5} xs={6}>
                      <a
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          textAlign: "center",
                          fontSize: "14px",
                          textDecoration: "underline",
                          cursor: "pointer",
                          fontWeight: "normal",
                          color: "#BF142A"
                        }}
                        onClick={() => {
                          this.setState({ action: "changeEmail" });
                          changeParamsConfirmationPasswordPopUp(true);
                        }}
                      >
                        <Trans>Mettre à jour mon adresse email</Trans>
                      </a>
                    </Col>{" "}
                  </Row>
                </FormGroup>
              </Col>
              <Col
                lg={6}
                xl={6}
                md={6}
                sm={6}
                xs={6}
                style={{ padding: "0 0 0 20px" }}
              >
                <FormGroup>
                  <label
                    className="form-label"
                    htmlFor="user_email"
                    style={{
                      fontSize: "20px"
                    }}
                  >
                    <Trans>Être contacté par :</Trans>
                  </label>
                  <CheckboxGroup
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                    name="CheckboxGrp"
                    defaultSelected={preferredContactArray}
                    onChange={this.preferredContactChangeHandler}
                    orientation={
                      appStore.width < 450 ? "vertical" : "horizontal"
                    }
                  >
                    <Checkbox
                      className={styles.checkBoxComponent}
                      name="email"
                      label={i18n._(t`Email`)}
                      value="email"
                    />
                    <Checkbox
                      className={styles.checkBoxComponent}
                      name="phone"
                      label={i18n._(t`Téléphone`)}
                      value="phone"
                    />
                    <Checkbox
                      className={styles.checkBoxComponent}
                      name="sms"
                      label={i18n._(t`SMS`)}
                      value="sms"
                    />
                    <Checkbox
                      className={styles.checkBoxComponent}
                      name="WhatsApp"
                      label={i18n._(t`WhatsApp`)}
                      value="WhatsApp"
                    />
                  </CheckboxGroup>
                </FormGroup>
              </Col>
            </Row>
            <div style={{ marginTop: "10px", marginBottom: "10px" }} />
            <Row>
              <Col
                lg={6}
                xl={6}
                md={6}
                sm={6}
                xs={6}
                style={{ padding: "0 20px" }}
              >
                <Row>
                  <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                    <span
                      className="form-label"
                      style={{
                        marginBottom: "15px",
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginTop: "30px"
                      }}
                    >
                      <Trans>Changement du mot de passe</Trans>
                    </span>
                  </Col>
                  <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                    <FormGroup>
                      <label
                        className="form-label"
                        htmlFor="current_user_password"
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        <Trans>Saisir le mot de passe actuel</Trans>
                      </label>

                      <PasswordInput
                        id="current_user_password"
                        autoComplete="new-password"
                        className="form-input"
                        placeholder={i18n._(t`Entrez le mot de passe actuel`)}
                        value={currentPassword}
                        onChange={this.currentPasswordChangeHandler}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                    <FormGroup>
                      <label
                        className="form-label"
                        htmlFor="user_password"
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        <Trans>Créer un nouveau mot de passe</Trans>
                      </label>
                      <Validation
                        errorMessage={passwordValidationMessage}
                        valid={isPasswordValid}
                      >
                        <PasswordInput
                          id="user_password"
                          autoComplete="new-password"
                          className="form-input"
                          placeholder={i18n._(t`Entrez un mot de passe`)}
                          message={i18n._(t`Niveau de sécurité`)}
                          passwordEvaluator={this.handleEvaluator}
                          value={password}
                          onChange={this.passwordChangeHandler}
                        />
                      </Validation>
                    </FormGroup>
                  </Col>
                  <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                    <FormGroup>
                      <label
                        className="form-label"
                        htmlFor="user_confirm_pass"
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        <Trans>Confirmer le nouveau mot de passe</Trans>
                      </label>
                      <Validation
                        errorMessage={confirmPasswordValidationMessage}
                        valid={isConfirmPasswordValid}
                      >
                        <PasswordInput
                          id="user_confirm_pass"
                          autoComplete="new-password"
                          className="form-input"
                          message={i18n._(t`Niveau de sécurité`)}
                          placeholder={i18n._(t`Entrez ce même mot de passe`)}
                          value={confirmPassword}
                          onChange={this.confirmPasswordChangeHandler}
                        />
                      </Validation>
                    </FormGroup>
                  </Col>
                  <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                    <FormGroup>
                      <label
                        className="form-label"
                        htmlFor="siteLanguage"
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        <Trans>Langue du compte (Par défault)</Trans>
                        <picture className="tooltip2">
                          <img
                            src="/assets/images/tooltip.webp"
                            alt="tooltip"
                          />
                          <div className="right">
                            <Trans>
                              Cette langue sera utilisée essentiellement pour
                              les e-mails.
                            </Trans>
                          </div>
                          <i />
                        </picture>
                      </label>
                      <div>
                        <Select
                          id="siteLanguage"
                          placeholder={i18n._(
                            t`Sélectionner la Langue par défault de votre compte`
                          )}
                          options={{
                            groupA: [
                              {
                                text: `${i18n._(t`Anglais`)}`,
                                value: "en",
                                ...(account.siteLanguage === "en" && {
                                  selected: true
                                })
                              },

                              {
                                text: `${i18n._(t`Français`)}`,
                                value: "fr",
                                ...(account.siteLanguage === "fr" && {
                                  selected: true
                                })
                              }
                            ]
                          }}
                          onSelect={this.siteLanguageChangeHandler}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col
                lg={6}
                xl={6}
                md={6}
                sm={6}
                xs={6}
                style={{ padding: "0 20px" }}
              >
                <Row>
                  <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                    <span
                      className="form-label"
                      style={{
                        marginBottom: "15px",
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginTop: "30px"
                      }}
                    >
                      <Trans>Suivre les newsletters Mindquest</Trans>
                    </span>
                  </Col>
                  <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                    <Checkbox
                      style={{
                        lineHeight: 1.9,
                        paddingRight: "10px"
                      }}
                      name="receive_newsletter_talent"
                      value="receive_newsletter-talent"
                      checked={receiveNewsletterTalent}
                      label={i18n._(
                        t`Consultants IT - Recevez un contenu spécifique aux consultants du secteur Tech & IT, tous les mois : articles, veilles, sélection d'opportunités, nos avantages Mindquest, etc.`
                      )}
                      onClickButton={this.receiveNewsletterTalentChangeHandler}
                    />
                  </Col>
                  <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                    <Checkbox
                      style={{
                        lineHeight: 1.9,
                        paddingRight: "10px"
                      }}
                      name="receive_newsletter_client"
                      value="receive_newsletter_client"
                      checked={receiveNewsletterClient}
                      label={i18n._(
                        t`Décideurs IT - Recevez le meilleur du contenu tech & IT, tous les mois : articles, veilles, sélection de profils IT ainsi que les dernières actualités du secteur.`
                      )}
                      onClickButton={this.receiveNewsletterClientChangeHandler}
                    />
                  </Col>
                  <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                    <span
                      className="form-label"
                      style={{
                        marginBottom: "15px",
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginTop: "30px"
                      }}
                    >
                      {role === "CANDIDATE" ? (
                        <Trans>
                          Suivre les dernières infos et avantages Mindquest
                        </Trans>
                      ) : (
                        <Trans>Suivre les dernières infos Mindquest</Trans>
                      )}
                    </span>
                  </Col>
                  <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                    <Checkbox
                      style={{
                        lineHeight: 1.9,
                        paddingRight: "10px"
                      }}
                      name="receive_lastInfos"
                      value="receive_lastInfos"
                      checked={lastInfo}
                      label={
                        role === "CANDIDATE"
                          ? i18n._(
                              t`Je souhaite recevoir les dernières informations ainsi que les avantages de Mindquest (webinaires, événements, nouvelles fonctionnalités, offres exclusives communautés, etc.)`
                            )
                          : i18n._(
                              t`Je souhaite recevoir les dernières informations de Mindquest (services, fonctionnalités, webinaires, événements, etc.)`
                            )
                      }
                      onClickButton={this.lastInfoChangeHandler}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>

          <Row>
            <Col lg={5} xl={6} md={4} sm={2} xs={6} />
            <Col lg={7} xl={6} md={8} sm={10} xs={6}>
              <div className="next-buttons" style={{ marginRight: "19px" }}>
                <Row>
                  <Col lg={6} xl={6} md={6} sm={6} xs={6}>
                    <a
                      style={{
                        paddingTop: "20px",
                        textAlign: "center",
                        fontSize: "14px",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontWeight: "normal",
                        color: "#8f8f8f",
                        marginTop: "20px"
                      }}
                      onClick={() => {
                        this.setState({ action: "deleteAccount" });
                        changeParamsConfirmationPasswordPopUp(true);
                      }}
                    >
                      <Trans>Supprimer mon compte et mes données</Trans>
                    </a>
                  </Col>
                  <Col lg={6} xl={6} md={6} sm={6} xs={6}>
                    <div style={{ margin: "auto", marginTop: "20px" }}>
                      <Mutation
                        mutation={
                          role === "CANDIDATE"
                            ? UPDATE_CANDIDATE_MUTATION
                            : UPDATE_CLIENT_MUTATION
                        }
                        variables={{
                          _id,
                          currentPassword,
                          password,
                          preferredContact,
                          receiveNewsletterTalent,
                          receiveNewsletterClient,
                          lastInfo,
                          siteLanguage
                        }}
                        refetchQueries={[
                          {
                            query: GET_USER_INFO,
                            variables: { token: authToken }
                          }
                        ]}
                        onCompleted={data =>
                          this.updateUserCompletedHandler(data)
                        }
                        onError={errors => {
                          errors.graphQLErrors.forEach(({ message, data }) => {
                            if (data && data.isCustomError) {
                              this.onErrorHandler(message);
                            }
                          });
                        }}
                      >
                        {(mutation, { loading }) => (
                          <Button
                            style={{ maxWidth: "150px", textAlign: "center" }}
                            disabled={loading || !saveCase}
                            onClick={() => {
                              if (this.isFormValid()) return mutation();
                              return null;
                            }}
                          >
                            {loading ? (
                              <Spinner
                                type="pointed-circular"
                                color="#FFFFFF"
                                size={12}
                              />
                            ) : (
                              <Trans>Enregistrer</Trans>
                            )}
                          </Button>
                        )}
                      </Mutation>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

Params.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    refreshLayout: PropTypes.func,
    width: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeReceiveNewsletterTalent: PropTypes.func,
    changeReceiveNewsletterClient: PropTypes.func,
    changeLastInfo: PropTypes.func,
    changePreferredContact: PropTypes.func,
    changePassword: PropTypes.func,
    changeEmail: PropTypes.func,
    receiveNewsletterTalent: PropTypes.bool,
    receiveNewsletterClient: PropTypes.bool,
    lastInfo: PropTypes.bool,
    account: PropTypes.shape({}),
    preferredContact: PropTypes.shape({}),
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    password: PropTypes.string,
    email: PropTypes.string,
    authToken: PropTypes.string,
    siteLanguage: PropTypes.shape
  }).isRequired,
  modalStore: PropTypes.shape({
    paramsChangeEmailPopUp: PropTypes.bool,
    paramsDeleteAccountPopUp: PropTypes.bool,
    paramsConfirmationPasswordPopUp: PropTypes.bool,
    paramsSuccessPopUp: PropTypes.bool,
    changeParamsChangeEmailPopUp: PropTypes.func,
    changeParamsDeleteAccountPopUp: PropTypes.func,
    changeParamsConfirmationPasswordPopUp: PropTypes.func,
    changeParamsSuccessPopUp: PropTypes.func
  }).isRequired
};
export default withI18n()(withRouter(Params));
