import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { Button, Icon, Checkbox } from "cf-neo-ui";
import classes from "./ConfirmModal.module.scss";
import routes from "../../routes";
import theme from "../../../../configs/theme";
import { inject, observer } from "mobx-react";

@inject("sessionStore", "appStore")
@observer
class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      talent: false,
      client: false,
      privacy: false
    };
  }

  subscribeHandler = () => {
    const { register } = this.props;
    const { talent, client } = this.state;
    register(talent, client);
  };

  render() {
    const {
      i18n,
      receiveNewsletterClient,
      receiveNewsletterTalent
    } = this.props;
    const { talent, client, privacy } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.headerIcon}>
            <Icon
              size="tiny"
              type="bell"
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
          </div>
          <Trans>Quels contenus aimeriez vous recevoir</Trans>
        </div>
        <div className={classes.body}>
          <div className={classes.checkItems}>
            <Checkbox
              checked={receiveNewsletterTalent}
              label={i18n._(t`Contenu pour consultant`)}
              onClickButton={() => this.setState({ talent: !talent })}
              value={talent}
              disabled={receiveNewsletterTalent}
            />
            <Checkbox
              checked={receiveNewsletterClient}
              label={i18n._(t`Contenu pour client`)}
              onClickButton={() => this.setState({ client: !client })}
              value={client}
              disabled={receiveNewsletterClient}
            />
          </div>
          <div className={classes.actionSection}>
            <Checkbox
              className={classes.actionItem}
              value="accept"
              label={
                <span>
                  <Trans>J’accepte la</Trans>
                  <a
                    href={routes.HbLayoutRoutes.PrivacyPolicy.path}
                    type="text/html"
                    target="_blank"
                    rel="noopener noreferrer"
                    name={i18n._(t`politique de confidentialité`)}
                    style={{ paddingLeft: "4px" }}
                  >
                    <Trans>politique de confidentialité des données</Trans>
                  </a>
                </span>
              }
              onClickButton={() => this.setState({ privacy: !privacy })}
            />
            <Button
              disabled={!privacy || (!talent && !client)}
              className={classes.actionItem}
              onClick={this.subscribeHandler}
            >
              <Trans>Je m’abonne</Trans>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ConfirmModal.propTypes = {
  register: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  receiveNewsletterClient: PropTypes.bool.isRequired,
  receiveNewsletterTalent: PropTypes.bool.isRequired
};

export default withI18n()(ConfirmModal);
