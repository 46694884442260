import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import { Sorter, Tab, Tabs, Modal, Col, IconCircle, Row } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import classes from "./styles.module.scss";
import Columns from "./columns";
import Info from "./Info";
import { ClientVacancyQuery } from "./query";
import SingleCandidate from "../../dashboard-single-candidate";
import PopUpForRejected from "./pop-up/PopUpForRejected";
import PopUpForToMeet from "./pop-up/PopUpForToMeet";
import PopUpForProposal from "./pop-up/PopUpForProposal";
import PopUpForNew from "./pop-up/PopUpForNew";
import NotFoundPage from "../../../components/error-pages/NotFoundPage";
import Stats from "./single-offer-stats";
import Routes from "../../layout/routes";
import PopUpClosedOffer from "./pop-up/PopUpClosedOffer";
import History from "./history";
import TalentPoolsListModal from "../../talentPool-Modal/talentPoolsListModal";
import AddTalentPool from "../../talentPool-Modal/NewTalentPool";

@inject("modalStore")
@observer
class SingleOffer extends Component {
  navigateBack = () => {
    const { history } = this.props;
    if (history && history.action === "PUSH") history.goBack();
    else history.push({ pathname: Routes.LpbLayoutRoutes.Offers.path });
  };

  render() {
    const { i18n, match, modalStore } = this.props;
    const {
      isVisible,
      isVisible2,
      modalType,
      isVisibleListPools,
      isVisibleCreatePool
    } = modalStore;
    let openVacancy = true;
    const activeTab = "1";
    const { history } = this.props;
    // let closedTab = false;
    if (isNaN(match.params.id)) {
      return (
        <NotFoundPage
          message={i18n._(
            t`Nous sommes désolé, l'offre que vous cherchez n’existe pas ou n’est plus disponible`
          )}
          href={Routes.LpbLayoutRoutes.Offers.path}
        />
      );
    }
    return (
      <div className={classes.content}>
        <Query
          query={ClientVacancyQuery}
          variables={{ id: parseInt(match.params.id, 10) }}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <div>loading</div>;
            }

            if (error) {
              return (
                <NotFoundPage
                  message={i18n._(
                    t`Nous sommes désolé, l'offre que vous cherchez n’existe pas ou n’est plus disponible`
                  )}
                  href={Routes.LpbLayoutRoutes.Offers.path}
                />
              );
            }
            const { clientVacancy } = data;
            if (!clientVacancy.isOpen) {
              // activeTab = "2";
              // closedTab = true;
              openVacancy = false;
            }
            return (
              <div>
                <div>
                  <Modal
                    centred
                    isVisible={isVisible}
                    withCloseButton={false}
                    position="fixed"
                  >
                    {openVacancy && modalType === "rejected" && (
                      <PopUpForRejected />
                    )}
                    {openVacancy && modalType === "to meet" && (
                      <PopUpForToMeet />
                    )}
                    {openVacancy && modalType === "proposal" && (
                      <PopUpForProposal />
                    )}
                    {openVacancy && modalType === "new" && <PopUpForNew />}
                    {!openVacancy && <PopUpClosedOffer />}
                  </Modal>
                  <Modal
                    isVisible={isVisible2}
                    withCloseButton={false}
                    position="fixed"
                  >
                    <div
                      style={{
                        width: "80%",
                        margin: "auto"
                      }}
                    >
                      <SingleCandidate />
                    </div>
                  </Modal>
                  <Modal
                    isVisible={isVisibleListPools}
                    withCloseButton={false}
                    position="fixed"
                  >
                    <div
                      style={{
                        width: "80%",
                        margin: "auto"
                      }}
                    >
                      <TalentPoolsListModal talent={null} />
                    </div>
                  </Modal>
                  {isVisibleCreatePool && (
                    <Modal
                      isVisible={isVisibleCreatePool}
                      withCloseButton={false}
                      position="fixed"
                    >
                      <div
                        style={{
                          width: "50%",
                          margin: "10% auto"
                        }}
                      >
                        <AddTalentPool />
                      </div>
                    </Modal>
                  )}

                  <div className={classes.pageHeader}>
                    <Row>
                      <Col>
                        <div
                          className={classes.goBack}
                          onClick={this.navigateBack}
                        >
                          <IconCircle
                            type="chevron-left"
                            color="rgba(110,25,25,1)"
                            color2="rgba(255,0,0,1)"
                            innerColor="rgba(255,255,255,1)"
                            innerColor2="rgba(255,255,255,1)"
                            iconWidth={12}
                            iconHeight={12}
                            dimension={24}
                          />
                          <span>
                            {history && history.action === "PUSH" ? (
                              <Trans>Retour</Trans>
                            ) : (
                              <Trans>Offres</Trans>
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <div className={classes.sorterWithMenu}>
                      <Sorter
                        text={clientVacancy.title}
                        menu={false}
                        positionMenu="left"
                        chevronSize={0.00000001}
                        chevronColor="#000000"
                        classNameText="font-style: normal;
                                         font-weight: bold;
                                         font-size: 36px;
                                         line-height: 35px;
                                         color: #000000;"
                        underline
                      />
                    </div>
                  </div>
                  <Info jobOrder={clientVacancy} />
                  <Tabs
                    defaultActiveTab={activeTab}
                    onChange={this.changeTabHandler}
                    type="transparentV2"
                  >
                    <Tab name="1" tab={i18n._(t`Candidats`)}>
                      <Columns isOpen={clientVacancy.isOpen} />
                    </Tab>
                    <Tab name="2" tab={i18n._(t`Statistiques`)}>
                      <Stats offerId={parseInt(match.params.id, 10)} />
                    </Tab>
                    <Tab name="3" tab={i18n._(t`Historique`)}>
                      <History />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

SingleOffer.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
    action: PropTypes.string
  }).isRequired,
  modalStore: PropTypes.shape({
    isVisible: PropTypes.bool,
    isVisible2: PropTypes.bool,
    changeIsVisible: PropTypes.func,
    changeIsVisible2: PropTypes.func,
    changeModalType: PropTypes.func,
    modalType: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(SingleOffer));
