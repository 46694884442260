import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react/index";
import { inject, observer } from "mobx-react";
import { Trans } from "@lingui/macro";
import {
  Row,
  Col,
  Button,
  FormCard,
  IllustrationIcons,
  Divider
} from "cf-neo-ui";
import Routes from "../layout/routes/index";

import "./styles.scss";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";

@inject("sessionStore", "appStore")
@observer
class ConfirmForBeCalledByAdvisorPath extends Component {
  onClickButton = () => {
    return null;
  };

  onGoToCreateAnAccount = () => {
    const { history, sessionStore } = this.props;
    sessionStore.changeSignUpUserRole("CLIENT");
    history.push(Routes.HbLayoutRoutes.MyCompany.path);
  };

  handleBackRef = () => {
    const { history, sessionStore } = this.props;
    const { MissionsPage } = Routes.HbfLayoutRoutes;
    history.push(`${MissionsPage.path}/${sessionStore.backRef}`);
    sessionStore.setBackRef(null);
  };

  render() {
    const { i18n } = this.props;
    const meta = staticMetaTags(i18n).signUpThanks;
    return (
      <div className="session container confirm">
        {metaTags(
          urls.signUp(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          null,
          null,
          false
        )}
        <br />
        <Row>
          <div className="center">
            <br />
            <IllustrationIcons type="check" color2="#d3354a" />
          </div>
        </Row>
        <Row>
          <Col className="content" lg={6} xl={6}>
            <div className="center-txt bold">
              <h1 className="thanks">
                <Trans>Merci, c’est tout bon !</Trans>
              </h1>
            </div>
            <br />
          </Col>
        </Row>

        <br />

        <FormCard className="formCard no-gutter">
          <Row className="with-padding">
            <Col lg={8} xl={8} className="center">
              <br />
              <span className="center-txt text-bold">
                <Trans>
                  Bienvenue au club, un Key Account Manager va vous recontacter
                  dans les plus brefs délais pour faire le point sur vos
                  recherches
                </Trans>
                .
              </span>
              <br />
              <span className="center-txt text-gray-bold">
                <Trans>
                  Pour faciliter vos échanges avec nous, n’hésitez pas à créer
                  un compte
                </Trans>
              </span>
            </Col>
          </Row>
          <br />
          <Divider />
          <br />
          <Row className="with-padding">
            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
              <div className="centerIcon">
                <div className="illustration">
                  <IllustrationIcons
                    type="targeting"
                    color2="#d3354a"
                    dimension={80}
                  />
                  <span className="text-gray">
                    <Trans>Retrouvez des talents sélectionnés pour vous</Trans>
                  </span>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
              <div className="centerIcon">
                <div className="illustration">
                  <IllustrationIcons
                    type="documents"
                    color2="#d3354a"
                    dimension={80}
                  />
                  <span className="text-gray">
                    <Trans>Gérez vos documents administratifs</Trans>
                  </span>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
              <div className="centerIcon">
                <div className="illustration">
                  <IllustrationIcons
                    type="check"
                    color2="#d3354a"
                    dimension={55}
                  />
                  <span className="text-gray">
                    <Trans>Suivez toutes vos missions</Trans>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className="center">
              <Button
                icon="chevron-right"
                iconPosition="right"
                onClick={this.onGoToCreateAnAccount}
              >
                <Trans>Créer un compte</Trans>
              </Button>
            </div>
          </Row>
          <br /> <br /> <br />
          <br />
          <br />
        </FormCard>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

ConfirmForBeCalledByAdvisorPath.wrappedComponent.propTypes = {
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    backRef: PropTypes.string,
    setBackRef: PropTypes.func,
    signUpUserRole: PropTypes.string,
    changeSignUpUserRole: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(ConfirmForBeCalledByAdvisorPath));
