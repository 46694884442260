import gql from "graphql-tag";

const GET_USER_NEWSLETTER_SUBSCRIPTION = gql`
  query GetUserNewsLetterSubscriptions($email: String, $language: String) {
    getUserNewsLetterSubscriptions(email: $email, language: $language) {
      receiveNewsletterClient
      receiveNewsletterTalent
    }
  }
`;

export { GET_USER_NEWSLETTER_SUBSCRIPTION };
