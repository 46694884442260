import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react/index";
import { Row, Icon, toaster } from "cf-neo-ui";
import gql from "graphql-tag";
import { Trans, t } from "@lingui/macro";
import theme from "../../configs/theme";
import classes from "../../components/alert-modal/alertModal.module.scss";
import { Mutation } from "react-apollo";

const RESEND_EMAIL_COLLABORATOR_MUTATION = gql`
  mutation resendEmailCollaborator($clientEmail: String!, $clientID: String) {
    resendEmailCollaborator(
      input: { clientEmail: $clientEmail, clientID: $clientID }
    ) {
      collaborator {
        clientID
        clientEmail
        clientCompanyId
        clientCompanyName
        clientName
        nbPool
        permitPool
        dateAdded
      }
    }
  }
`;
@inject("appStore", "sessionStore", "modalStore", "talentStore")
@observer
class PoolExistModal extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    delete initProps.sessionStore;
    this.state = {
      skip: 0,
      limit: 100
    };
  }
  closeModal = () => {
    const { modalStore } = this.props;
    const { changePoolExist } = modalStore;
    changePoolExist(false);
  };
  render() {
    const { modalStore, i18n, clientEmail, clientID } = this.props;
    const { changePoolExist } = modalStore;

    return (
      <div className={classes.bodyPage}>
        <div className={classes.alertModal}>
          <div className={classes.header}>
            <div className={classes.headerIcon}>
              <Icon
                size="tiny"
                type="bell"
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
            </div>
            <Trans>Collaboration Existante</Trans>
            <div className={classes.closeSmallIcon}>
              <Icon
                type="close"
                color={theme.colors.mainColor}
                color2={theme.colors.mainColor}
                onClick={this.closeModal}
                width={20}
                filled={true}
                height={20}
              ></Icon>
            </div>
          </div>
          <div className={classes.body}>
            <Row style={{ display: "contents" }}>
              <span
                style={{
                  marginRight: "10px"
                }}
              >
                {i18n._(
                  t`Ce contact est déjà présent sur votre listing de collaboration. N'hésitez pas à renvoyer votre demande de collaboration en cliquant sur `
                )}
              </span>
              <Mutation
                mutation={RESEND_EMAIL_COLLABORATOR_MUTATION}
                variables={{
                  clientEmail: clientEmail && clientEmail.toLowerCase(),
                  clientID: clientID ? clientID : ""
                }}
                onCompleted={data => {
                  changePoolExist(false);
                  toaster.success({
                    title: i18n._(t`Demande par email renvoyée`),
                    description: i18n._(
                      t`Votre demande de collaboration a bien été renvoyée`
                    )
                  });
                }}
              >
                {resendEmail => (
                  <Icon
                    type="resend"
                    color={theme.colors.mainColor}
                    color2={theme.colors.mainColor}
                    onClick={() => {
                      resendEmail(clientEmail);
                    }}
                    width={20}
                    filled={true}
                    height={20}
                  ></Icon>
                )}
              </Mutation>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

PoolExistModal.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape
  }).isRequired,
  modalStore: PropTypes.shape({
    changetoDelete: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(PoolExistModal));
