import { t } from "@lingui/macro";
import urls from "../../utils/urls";

const ldJsonLogin = i18n => [
  {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: i18n._(t`Se connecter`),
        item: urls.login()
      }
    ]
  }
];

export default ldJsonLogin;
