import React, { Component } from "react";
import * as PropTypes from "prop-types";
import SiderMenuItemSupport from "./SiderMenuItemSupport";

class SiderMenuSupport extends Component {
  render() {
    const { children } = this.props;
    return (
      <div>
        <ul className="sider-menu">
          {React.Children.map(children, menuItem => {
            const { active, iconType, title, ...restProps } = menuItem.props;
            return (
              <SiderMenuItemSupport
                active={active}
                iconType={iconType}
                title={title}
                {...restProps}
              />
            );
          })}
        </ul>
      </div>
    );
  }
}

SiderMenuSupport.propTypes = {
  /**  Provide a collection of <SiderMenuItemSupport/> components to render */
  children: PropTypes.arrayOf((propValue, key) => {
    if (propValue[key].type.displayName !== SiderMenuItemSupport.displayName) {
      return new Error(
        "RadioGroup: Invalid Prop children was given. All children must be of type SiderMenuSupportItem"
      );
    }
  })
};

SiderMenuSupport.defaultProps = {
  children: []
};

export default SiderMenuSupport;
