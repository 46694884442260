import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { withI18n } from "@lingui/react/index";
import { inject, observer } from "mobx-react";
import { Trans } from "@lingui/macro";
import {
  Row,
  Col,
  Button,
  FormCard,
  IllustrationIcons,
  Divider
} from "cf-neo-ui";
import Routes from "../layout/routes/index";

import "./styles.scss";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";

@inject("sessionStore", "appStore", "postANeedStore")
@observer
class ConfirmCompany extends Component {
  handleBackRef = () => {
    const { history, sessionStore } = this.props;
    const { MissionsPage } = Routes.HbfLayoutRoutes;
    history.push(`${MissionsPage.path}/${sessionStore.backRef}`);
    sessionStore.setBackRef(null);
  };

  onClickButton = () => {
    return null;
  };

  toForm = () => {
    const { postANeedStore } = this.props;
    postANeedStore.changePostANeedJustAfterSignUp(2);
    const { history } = this.props;
    const { Offer } = Routes.HbLayoutRoutes;
    history.push(Offer.path);
  };

  toUploadFile = () => {
    const { postANeedStore } = this.props;
    postANeedStore.changePostANeedJustAfterSignUp(1);
    const { history } = this.props;
    const { MyNeed } = Routes.HbLayoutRoutes;
    history.push(MyNeed.path);
  };

  render() {
    const { DashboardCompany } = Routes.LpbLayoutRoutes;
    const { i18n } = this.props;
    const meta = staticMetaTags(i18n).signUpThanks;
    return (
      <div className="session container confirm">
        {metaTags(
          urls.signUp(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          null,
          null,
          false
        )}
        <br />
        <Row>
          <div className="center">
            <br />
            <IllustrationIcons color2="#d3354a" type="check" />
          </div>
        </Row>
        <Row>
          <Col className="content" lg={6} xl={6}>
            <div className="center-txt bold">
              <h1 className="thanks">
                <Trans>Merci</Trans>!<br />
                <Trans>Consultez vos emails pour finaliser l’inscription</Trans>
              </h1>
            </div>
            <br />
          </Col>
        </Row>

        <br />

        <FormCard className="formCard no-gutter">
          <Row className="with-padding">
            <Col lg={8} xl={8} className="center">
              <br />
              <span className="center-txt text-bold">
                <Trans>
                  Bienvenue au club, Un email vous a été envoyé pour finaliser
                  votre inscription et accéder à votre Dashboard
                </Trans>
                .
              </span>
              <br />
              <span className="center-txt text-gray-bold">
                <Trans>Pensez à consulter vos courriers indésirables</Trans>
              </span>
            </Col>
          </Row>
          <br />
          <Divider />
          <br />
          <Row className="with-padding">
            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
              <div className="centerIcon">
                <div className="illustration">
                  <IllustrationIcons
                    type="targeting"
                    color2="#d3354a"
                    dimension={80}
                  />
                  <span className="text-gray">
                    <Trans>Retrouvez des talents sélectionnés pour vous</Trans>
                  </span>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
              <div className="centerIcon">
                <div className="illustration">
                  <IllustrationIcons
                    type="documents"
                    color2="#d3354a"
                    dimension={80}
                  />
                  <span className="text-gray">
                    <Trans>Gérez vos documents administratifs</Trans>
                  </span>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
              <div className="centerIcon">
                <div className="illustration">
                  <IllustrationIcons
                    type="check"
                    color2="#d3354a"
                    dimension={55}
                  />
                  <span className="text-gray">
                    <Trans>Suivez toutes vos missions</Trans>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className="center">
              <div className="buttonsBlock">
                <Button
                  icon="chevron-right"
                  iconPosition="right"
                  onClick={this.toForm}
                >
                  <Trans>Poster un besoin via le formulaire</Trans>
                </Button>
              </div>
              <Button
                icon="chevron-right"
                iconPosition="right"
                variant="secondary"
                onClick={this.toUploadFile}
              >
                <Trans>Poster un besoin via une fiche de poste</Trans>
              </Button>
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className="center">
              <Trans>ou</Trans>&nbsp;&nbsp;
              <Link to={DashboardCompany.path}>
                <Trans>Accéder à mon espace client</Trans>
              </Link>
            </div>
          </Row>
          <br /> <br /> <br />
          {/* <Row className="with-padding">
            <div className="center">
              <span className="center-txt text-bold">
                <Trans>
                  Un ressource vous est offert pour votre inscription
                </Trans>
              </span>
              <br /> <br />
            </div>
          </Row>
          <Row className="with-padding">
            <div className="center">
              {appStore.width > 1040 ? (
                <TestimonialCard
                  image="./assets/images/testimonies/Sophie.webp"
                  paragraph={i18n._(
                    t`Indépendants: tout savoir sur le prélèvement forfaitaire libératoire`
                  )}
                  version="v2"
                  widthTextZone={70}
                  imagePosition={100}
                  buttonText={i18n._(t`Télécharger le livre blanc`)}
                  onClickButton={this.onClickButton}
                />
              ) : (
                <TestimonialCardV2
                  image="./assets/images/testimonies/Sophie.webp"
                  name=""
                  job=""
                  paragraph={i18n._(
                    t`Indépendants: tout savoir sur le prélèvement forfaitaire libératoire`
                  )}
                  buttonText={i18n._(t`Télécharger le livre blanc`)}
                  onClickButton={this.onClickButton}
                />
              )}
            </div>
          </Row> */}
          <br />
          <br />
        </FormCard>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

ConfirmCompany.wrappedComponent.propTypes = {
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    backRef: PropTypes.string,
    setBackRef: PropTypes.func,
    signUpUserRole: PropTypes.string
  }).isRequired,
  postANeedStore: PropTypes.shape({
    postANeedJustAfterSignUp: PropTypes.number,
    changePostANeedJustAfterSignUp: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(ConfirmCompany));
