import { t } from "@lingui/macro";
const getTestimoniesData = (i18n, page) =>
  page === "consultant"
    ? [
        {
          image: "/assets/images/testimonies/Sophie.webp",
          name: "Sophie Duflot",
          position: i18n._(t`SAP SD MM Project Manager`),
          domain: i18n._(t`ERP supply`),
          paragraph: i18n._(
            t`Je suis très satisfaite de Mindquest. Retour rapide sur la mission suite à un entretien, contrat envoyé dans la foulée et aucun problème de facturation. L’équipe est très disponible et la communication avec l’ensemble des interlocuteurs est facile et agréable`
          ),
          version: "v1"
        },
        {
          image: "/assets/images/testimonies/Aymen.webp",
          name: "Aymen Fitati",
          position: i18n._(t`Consultant AWS Cloud Devops`),
          domain: i18n._(t`Infrastructure & Cloud`),
          paragraph: i18n._(
            t`Le service du Mindquest est rapide et efficace. Les membres de l’équipe savent ce qu’ils recherchent avec leurs partenaires: aucun contact n’est destiné à créer une base de données de CV mais à proposer des emplois intéressants. Je recommande fortement.`
          ),
          version: "v1"
        },
        {
          image: "/assets/images/testimonies/Mark.webp",
          name: "Mark Disley",
          position: i18n._(t`PMO BI`),
          domain: i18n._(t`Data Science & Bi`),
          paragraph: i18n._(
            t`Mindquest propose un service simple et efficace, facilité par un espace personnel virtuel et la possibilité de gérer toute sa documentation en ligne. Pour moi, c’est la manière la plus simple pour émettre une facture et se faire payer. En temps et en heure.`
          ),
          version: "v1"
        }
      ]
    : [
        {
          image: "/assets/images/testimonies/activityDomain/Ecommerce.webp",
          position: i18n._(t`DSI`),
          domain: i18n._(t`E-commerce`),
          paragraph: i18n._(
            t`Des compétences specifiques trouvées rapidement et efficacement.`
          ),
          version: "v2"
        },
        {
          image: "/assets/images/testimonies/activityDomain/TechCompanies.webp",
          position: i18n._(t`Business development`),
          domain: i18n._(t`Tech company`),
          paragraph: i18n._(
            t`Bonne qualité de profils et bonne réactivité ! Merci.`
          ),
          version: "v2"
        },
        {
          image: "/assets/images/testimonies/activityDomain/Energy.webp",
          name: "Raluca Nottebar",
          position: i18n._(t`Recruteur IT`),
          domain: i18n._(t`Energy`),
          paragraph: i18n._(
            t`Toujours à l'écoute des besoins et professionnel.`
          ),
          version: "v1"
        },
        {
          image: "/assets/images/testimonies/activityDomain/Energy.webp",
          position: i18n._(t`PMO`),
          domain: i18n._(t`Energy`),
          paragraph: i18n._(
            t`Une expertise technique des interlocuteurs qui permet une recherche de candidats très pointue !`
          ),
          version: "v2"
        },
        {
          image:
            "/assets/images/testimonies/activityDomain/PharmaChemicals.webp",
          name: "Guy Rendu",
          position: i18n._(t`Responsable Achat IT`),
          domain: i18n._(t`PHARMA & CHEMICALS`),
          paragraph: i18n._(
            t`Très grande écoute du besoin et profil des candidats très adapté.`
          ),
          version: "v1"
        },
        {
          image: "/assets/images/testimonies/activityDomain/Logistic.webp",
          name: "Francois Neves",
          position: i18n._(t`Responsable TMA RUN`),
          domain: i18n._(t`Logistic`),
          paragraph: i18n._(
            t`Réactivité pour proposer des consultants au bon niveau pour les missions.`
          ),
          version: "v1"
        }
      ];

export default getTestimoniesData;
