import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import {
  Button,
  FormCard,
  Icon,
  Row,
  Col,
  Validation,
  FormGroup,
  PasswordInput
} from "cf-neo-ui";
import { Mutation } from "react-apollo";
import moment from "moment";
import { PASSWORD_VERIFICATION_MUTATION } from "./mutations";
import runtimeVars from "../../../configs/runTimeVars";
import styles from "./styles.module.scss";
import validate from "../../../utils/validators";

@inject("modalStore", "sessionStore")
@observer
class PasswordConfirmation extends Component {
  constructor(props) {
    super(props);
    moment.locale(runtimeVars.APP_LANG);
    const initProps = { ...props };
    this.state = {
      ...initProps,
      check: false,
      buttonClicked: false,
      isPasswordValid: true,
      passwordValidationMessage: "",
      password: "",
      clicked: false
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  isFormValid() {
    const { password } = this.state;
    let valid = true;
    if (!password) valid = false;
    if (!valid) return false;
    return valid;
  }

  clickCancelHandler = () => {
    const { modalStore } = this.props;
    const { changeParamsConfirmationPasswordPopUp } = modalStore;
    changeParamsConfirmationPasswordPopUp(false);
    this.setState({ clicked: false });
  };

  passwordChangeHandler = v => {
    this.setState({ password: v });
    if (v.length)
      this.setState({
        passwordValidationMessage: "",
        isPasswordValid: true
      });
  };

  render() {
    const { i18n, modalStore, action } = this.props;
    const {
      changeParamsConfirmationPasswordPopUp,
      changeParamsChangeEmailPopUp,
      changeParamsDeleteAccountPopUp
    } = modalStore;
    const { passwordValidationMessage, isPasswordValid, password } = this.state;
    return (
      <div className={styles.popUpContainer}>
        <FormCard className="formCard no-gutter">
          <Row>
            <Col className={styles.formCardContent}>
              <div className={styles.header}>
                <Icon
                  className={styles.bellIcon}
                  type="Bell"
                  color="#8D0417"
                  color2="#D3354A"
                  width={14}
                  height={14}
                />
                <p>
                  <Trans>Saisir votre mot de passe</Trans>
                </p>
                <Icon
                  className={styles.closeIcon}
                  type="close"
                  color="#8D0417"
                  color2="#D3354A"
                  width={14}
                  height={14}
                  onClick={() => this.clickCancelHandler()}
                />
              </div>
              <div className={styles.container}>
                <p>
                  <Trans>
                    Merci de saisir votre mot de passe afin de confirmer votre
                    action.
                  </Trans>
                </p>
                <FormGroup>
                  <Validation
                    errorMessage={passwordValidationMessage}
                    valid={isPasswordValid}
                    style={{ textAlign: "left" }}
                  >
                    <PasswordInput
                      id="user_password"
                      autoComplete="new-password"
                      className="form-input"
                      placeholder={i18n._(t`Insérez votre mot de passe`)}
                      value={password}
                      onChange={this.passwordChangeHandler}
                    />
                  </Validation>
                </FormGroup>

                <div className={styles.buttonsBlock}>
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => this.clickCancelHandler()}
                  >
                    <Trans>Retour</Trans>
                  </Button>
                  <Mutation
                    mutation={PASSWORD_VERIFICATION_MUTATION}
                    variables={{
                      password
                    }}
                    onCompleted={({ passwordVerification }) => {
                      if (passwordVerification === "valid") {
                        changeParamsConfirmationPasswordPopUp(false);
                        if (action === "deleteAccount")
                          changeParamsDeleteAccountPopUp(true);
                        else if (action === "changeEmail")
                          changeParamsChangeEmailPopUp(true);
                        this.setState({
                          passwordValidationMessage: "",
                          isPasswordValid: true
                        });
                      } else {
                        this.setState({
                          passwordValidationMessage: i18n._(
                            t`mot de passe incorrect`
                          ),
                          isPasswordValid: false
                        });
                      }
                      this.setState({ password: "" });
                    }}
                    onError={errors => {
                      errors.graphQLErrors.forEach(({ message, data }) => {
                        if (data && data.isCustomError) {
                          this.onErrorHandler(message);
                        }
                      });
                    }}
                  >
                    {(mutation, { loading }) => (
                      <Button
                        disabled={loading}
                        style={{ marginLeft: "20px" }}
                        variant="primary"
                        size="small"
                        onClick={() => {
                          if (this.isFormValid()) {
                            return mutation();
                          } else {
                            this.setState({
                              passwordValidationMessage: i18n._(
                                t`Ce champs est requis`
                              ),
                              isPasswordValid: false
                            });
                          }
                          return null;
                        }}
                      >
                        <Trans>Confirmer</Trans>
                      </Button>
                    )}
                  </Mutation>
                </div>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

PasswordConfirmation.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    changeParamsChangeEmailPopUp: PropTypes.func,
    changeParamsConfirmationPasswordPopUp: PropTypes.func,
    changeParamsDeleteAccountPopUp: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(PasswordConfirmation));
