const en = {
  days: {
    long: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ],
    short: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    shortest: ["Mo.", "Tu.", "We.", "Tu.", "Fr.", "Sa.", "Su"]
  },
  months: {
    long: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    short: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec"
    ]
  },
  firstDaySuffix: "st"
};

const fr = {
  days: {
    long: [
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
      "Dimanche"
    ],
    short: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
    shortest: ["Lu.", "Ma.", "Me.", "Je.", "Ve.", "Sa.", "Di."]
  },
  months: {
    long: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre ",
      "Décembre"
    ],
    short: [
      "janv",
      "févr",
      "mars",
      "avril",
      "Mai",
      "Juin",
      "juil",
      "Août",
      "Sept",
      "Oct",
      "Nov",
      "Déc"
    ]
  },
  firstDaySuffix: "er"
};

const locals = { en, fr };

export default locals;
