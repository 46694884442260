import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Col, Icon, Row } from "cf-neo-ui";
import "./ResumeTemplates-carousel.scss";
import { Trans } from "@lingui/macro";

function ResumeTemplatesCarousel(props) {
  const [slideTotal, setSlideTotal] = useState(0);
  const [slideCurrent, setSlideCurrent] = useState(-1);
  const [slides, setSlides] = useState([]);
  const [height, setHeight] = useState("0px");
  const intervalRef = useRef();
  const handlers = useSwipeable({
    onSwipedLeft: () => slideRight(),
    onSwipedRight: () => slideLeft(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });
  useEffect(() => {
    const locSlides = [];
    props.slides.forEach(slide => {
      const slideobject = {
        class: "slider-single proactivede",
        template: slide.template,
        title: slide.title,
        description: slide.description
      };
      locSlides.push(slideobject);
    });
    if (props.slides.length === 2) {
      props.slides.forEach(slide => {
        const slideobject = {
          class: "slider-single proactivede",
          template: slide.template,
          title: slide.title,
          description: slide.description
        };
        locSlides.push(slideobject);
      });
    }
    setSlides(locSlides);
    setSlideTotal(locSlides.length - 1);
    setSlideCurrent(-1);
    if (slideCurrent === -1) {
      setTimeout(() => {
        slideRight();
        if (props.autoplay) {
          intervalRef.interval = setTimeout(() => {
            slideRight();
          }, props.interval);
        }
      }, 500);
    }
  }, [props.slides]);
  useEffect(() => {
    if (slideCurrent === -1) {
      setTimeout(() => {
        slideRight();
      }, 500);
    }
  }, [slides, slideCurrent]);

  const urlAccess = url => {
    window.open(url, "_blank");
  };
  const slideRight = () => {
    let preactiveSlide;
    let proactiveSlide;
    let slideCurrentLoc = slideCurrent;

    const activeClass = "slider-single active";
    const slide = [...slides];
    if (slideTotal > 1) {
      if (slideCurrentLoc < slideTotal) {
        slideCurrentLoc++;
      } else {
        slideCurrentLoc = 0;
      }
      if (slideCurrentLoc > 0) {
        preactiveSlide = slide[slideCurrentLoc - 1];
      } else {
        preactiveSlide = slide[slideTotal];
      }
      const activeSlide = slide[slideCurrentLoc];
      if (slideCurrentLoc < slideTotal) {
        proactiveSlide = slide[slideCurrentLoc + 1];
      } else {
        proactiveSlide = slide[0];
      }

      slide.forEach((slid, index) => {
        if (slid.class.includes("preactivede")) {
          slid.class = "slider-single proactivede";
        }
        if (slid.class.includes("preactive")) {
          slid.class = "slider-single preactivede";
        }
      });

      preactiveSlide.class = "slider-single preactive";
      activeSlide.class = activeClass;
      proactiveSlide.class = "slider-single proactive";
      setSlides(slide);
      setSlideCurrent(slideCurrentLoc);

      if (document.getElementsByClassName("slider-single active").length > 0) {
        setTimeout(() => {
          if (
            document.getElementsByClassName("slider-single active").length > 0
          ) {
            const height = document.getElementsByClassName(
              "slider-single active"
            )[0].clientHeight;
            setHeight(`${height}px`);
          }
        }, 500);
      }
      if (props.autoplay) {
        clearTimeout(intervalRef.interval);
        intervalRef.interval = setTimeout(() => {
          slideRight();
        }, props.interval);
      }
    } else if (slide[0] && slide[0].class !== activeClass) {
      slide[0].class = activeClass;
      setSlides(slide);
      setSlideCurrent(0);
    }
    if (
      slide[slideCurrentLoc] === "undefined" ||
      slideCurrentLoc === undefined ||
      slideCurrentLoc === 0
    ) {
      props.sessionStore.changeResumeModel(props.slides[0].title);
      props.sessionStore.changeDisplay(true);
    } else if (slideCurrentLoc === 1) {
      props.sessionStore.changeResumeModel(props.slides[1].title);
      props.sessionStore.changeDisplay(false);
    } else if (slideCurrentLoc === 2) {
      props.sessionStore.changeResumeModel(props.slides[2].title);
      props.sessionStore.changeDisplay(false);
    }
  };
  const slideLeft = () => {
    if (slideTotal > 1) {
      let preactiveSlide;
      let proactiveSlide;
      let slideCurrentLoc = slideCurrent;
      const slide = [...slides];
      if (slideCurrentLoc > 0) {
        slideCurrentLoc--;
      } else {
        slideCurrentLoc = slideTotal;
      }

      if (slideCurrentLoc < slideTotal) {
        proactiveSlide = slide[slideCurrentLoc + 1];
      } else {
        proactiveSlide = slide[0];
      }
      const activeSlide = slide[slideCurrentLoc];
      if (slideCurrentLoc > 0) {
        preactiveSlide = slide[slideCurrentLoc - 1];
      } else {
        preactiveSlide = slide[slideTotal];
      }
      slide.forEach((slid, index) => {
        if (slid.class.includes("proactivede")) {
          slid.class = "slider-single preactivede";
        }
        if (slid.class.includes("proactive")) {
          slid.class = "slider-single proactivede";
        }
      });
      preactiveSlide.class = "slider-single preactive";
      activeSlide.class = "slider-single active";
      proactiveSlide.class = "slider-single proactive";
      setSlides(slide);
      setSlideCurrent(slideCurrentLoc);
      if (document.getElementsByClassName("slider-single active").length > 0) {
        setTimeout(() => {
          if (
            document.getElementsByClassName("slider-single active").length > 0
          ) {
            const height = document.getElementsByClassName(
              "slider-single active"
            )[0].clientHeight;
            setHeight(`${height}px`);
          }
        }, 500);
      }
      if (
        slide[slideCurrentLoc] === "undefined" ||
        slideCurrentLoc === undefined ||
        slideCurrentLoc === 0
      ) {
        props.sessionStore.changeResumeModel(props.slides[0].title);
        props.sessionStore.changeDisplay(true);
      } else if (slideCurrentLoc === 1) {
        props.sessionStore.changeResumeModel(props.slides[1].title);
        props.sessionStore.changeDisplay(false);
      } else if (slideCurrentLoc === 2) {
        props.sessionStore.changeResumeModel(props.slides[2].title);
        props.sessionStore.changeDisplay(false);
      }
    }
  };

  const sliderClass = direction => {
    let sliderClass = `slider-${direction}`;
    if (!props.arrows) {
      sliderClass = "slider-disabled";
    } else if (props.arrows && !props.arrowBorders) {
      sliderClass = `slider-${direction}-noborders`;
    }
    return sliderClass;
  };

  return (
    <>
      <div className="react-3d-carousel" style={{ height }} {...handlers}>
        {slides && slides.length > 0 && (
          <div className="slider-container">
            <div className="slider-content">
              {slides.map((slider, index) => (
                <div className={slider.class} key={index}>
                  <div className={sliderClass("left")} onClick={slideLeft}>
                    <div>
                      <Icon
                        type="chevron-left"
                        size="medium"
                        color="rgba(0,0,0,1)"
                        color2="rgba(0,0,0,1)"
                      />
                    </div>
                  </div>
                  <div className={sliderClass("right")} onClick={slideRight}>
                    <div>
                      <Icon
                        type="chevron-right"
                        size="medium"
                        color="rgba(0,0,0,1)"
                        color2="rgba(0,0,0,1)"
                      />
                    </div>
                  </div>
                  <div className="slider-single-content">
                    <img src={slider.template} alt="slide resume" />
                  </div>
                  <br />
                  <div
                    className="center-txt slider-single-content"
                    style={{ color: "#d3354a" }}
                  >
                    <Trans>{slider.title}</Trans>
                  </div>
                  <br />
                  <div
                    className="center-txt slider-single-content hidden"
                    style={{ color: "#000000" }}
                  >
                    <Trans>{slider.description}</Trans>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
ResumeTemplatesCarousel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  slides: PropTypes.arrayOf(PropTypes.element),
  autoplay: PropTypes.bool,
  interval: PropTypes.number,
  arrows: PropTypes.bool,
  arrowBorders: PropTypes.bool,
  sessionStore: PropTypes.shape({
    changeCv: PropTypes.func,
    isAuthenticatedByLinkedin: PropTypes.bool,
    _id: PropTypes.string,
    resumeModel: PropTypes.string,
    email: PropTypes.string,
    pictureUrl: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.number,
    address: PropTypes.string,
    searchAddress: PropTypes.string,
    candidateWorkHistory: PropTypes.shape({}),
    signUpUserRole: PropTypes.string,
    changeResumeModel: PropTypes.func,
    cv: PropTypes.shape({
      name: PropTypes.string
    }),
    authToken: PropTypes.string
  }).isRequired
};
ResumeTemplatesCarousel.defaultProps = {
  autoplay: false,
  interval: 3000,
  arrows: true,
  arrowBorders: true
};

export default withI18n()(ResumeTemplatesCarousel);
