import React, { Component } from "react";
import * as PropTypes from "prop-types";
import * as queryString from "query-string";
import { withRouter } from "react-router-dom";
import {
  Tabs,
  Tab,
  Sorter,
  Modal,
  ItemMenu,
  Button,
  TextInput,
  Icon,
  Col
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import classes from "./styles.module.scss";
import MyTalents from "./MyTalents";
import MyTalentPool from "./MyTalentPool";
import AllTalents from "./AllTalents";
import SingleCandidate from "../dashboard-single-candidate";
import Routes from "../layout/routes";
import theme from "../../configs/theme";
import AddTalentPool from "../talentPool-Modal/NewTalentPool";
import TalentPoolsListModal from "../talentPool-Modal/talentPoolsListModal";
import metaTags from "../../utils/editMetaData";
import ManagementTalentPool from "./ManagementTalentPool";

@inject("modalStore", "sessionStore", "talentStore", "appStore")
@observer
class DashboardTalents extends Component {
  constructor(props) {
    super(props);
    const queryObj = queryString.parse(props.location.search);
    this.state = {
      search: "",
      activeTab: queryObj.active,
      employmentType: "",
      order: 1,
      currentPage: 1,
      talentPoolFilter: ""
    };

    this.total = 0;
  }

  tabChangeHandler = tabName => {
    const { history } = this.props;
    history.replace({ search: `?active=${tabName}` });
  };
  addTalentPool = () => {
    const { modalStore } = this.props;
    const { changeIsVisibleCreatePool } = modalStore;
    changeIsVisibleCreatePool(true);
  };
  handleEnterPressed = e => {
    this.setState({
      search: e.target.value,
      currentPage: 1,
      isVisibleCreatePool: false
    });
    this.total = 0;
  };
  talentPoolFilterFct = (value, order) => {
    const { sessionStore } = this.props;
    sessionStore.changetalentPoolFilter(value);
    this.setState({
      currentPage: 1,
      talentPoolFilter: value,
      order: order
    });
    this.total = 0;
  };
  talentFilterFct = (value, order) => {
    const { sessionStore } = this.props;
    sessionStore.changetalentFilter(value);
    this.setState({
      currentPage: 1,
      isVisibleCreatePool: false,
      talentFilter: value,
      order: order
    });
    this.total = 0;
  };
  management = () => {
    const { history } = this.props;
    history.push(`/dashboard/company/talentpool-management`);
  };
  openInNewTab = () => {
    const win = window.open(
      Routes.HbfLayoutRoutes.SearchCandidatesPage.path,
      "_blank"
    );
    win?.focus();
  };
  render() {
    const {
      i18n,
      modalStore,
      history,
      location,
      talentStore,
      appStore
    } = this.props;
    const { isVisibleCreatePool, isVisible2, isVisibleListPools } = modalStore;
    const { talent } = talentStore;
    const { width } = appStore;
    const {
      activeTab,
      employmentType,
      search,
      talentPoolFilter,
      talentFilter,
      order
    } = this.state;
    const currentPath = location.search.split("=")[1];
    var url = "";
    if (typeof window !== "undefined") {
      url = window.location.href;
    }
    return (
      <div className={classes.content}>
        {metaTags(url, null, null, null, null, null, null, null, false)}
        {/* modal part */}
        <Modal isVisible={isVisible2} position="fixed" withCloseButton={false}>
          <div
            style={{
              width: "80%",
              margin: "auto"
            }}
          >
            <SingleCandidate />
          </div>
        </Modal>
        <Modal
          isVisible={isVisibleListPools}
          withCloseButton={false}
          position="fixed"
        >
          <div
            style={{
              width: "90%",
              margin: "auto"
            }}
          >
            <TalentPoolsListModal
              talent={talent}
              orderList={order}
              skipList={0}
              limitList={10}
            />
          </div>
        </Modal>
        <Modal
          isVisible={isVisibleCreatePool}
          withCloseButton={false}
          position="fixed"
        >
          <div
            style={{
              margin: "10% auto"
            }}
          >
            <AddTalentPool />
          </div>
        </Modal>

        {/* header name et button (ajout ou search )+ beta */}
        <Col className={classes.pageHeader} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.sorterWithoutMenu}>
            <Sorter
              text={i18n._(t`Talents`)}
              menu={false}
              onChange={() => {}}
              chevronSize={0.00001}
              chevronColor="#000000"
              classNameText="font-style: normal;
                            font-weight: bold;
                            font-size: 36px;
                            color: #000000;
                            line-height: 1;padding-right:0px !important;"
              underline
            />
          </div>

          <Col
            className={classes.buttonCreateOffer}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            {activeTab != "Talents" &&
            activeTab != "management" &&
            activeTab != "allTalents" ? (
              <Button
                variant="tertiary"
                className={classes.buttonStyle}
                size="small"
                onClick={this.addTalentPool}
              >
                <Trans>Ajouter un vivier</Trans>
              </Button>
            ) : (
              <Button
                variant="tertiary"
                className={classes.buttonStyle}
                size="small"
                onClick={this.openInNewTab}
              >
                <Trans>Rechercher un consultant</Trans>
              </Button>
            )}

            <img
              style={{ verticalAlign: "middle" }}
              src="/beta_red.webp"
              alt="Beta"
              width="60px"
              height="27px"
            />
          </Col>
        </Col>

        {/* all the body tabs + serach + sort */}
        <div className={classes.pageBody}>
          {/* tabs  */}
          <div className={classes.tab}>
            <Tabs
              className={classes.dashboardTalents}
              defaultActiveTab="TalentPool"
              activeTab={activeTab}
              type="transparentV2"
              onChange={this.tabChangeHandler}
              isMobile={width <= 1170 ? true : false}
            >
              <Tab name="TalentPool" tab={i18n._(t`Mes viviers de talents`)}>
                <MyTalentPool
                  className={classes.listTalents}
                  search={search}
                  order={order}
                />
              </Tab>
              <Tab
                name="Talents"
                className={classes.listTalents}
                tab={i18n._(t`Mes talents`)}
              >
                <MyTalents
                  className={classes.listTalents}
                  employmentType={employmentType}
                  search={search}
                  order={order}
                />
              </Tab>
              <Tab name="allTalents" tab={i18n._(t`Tous les talents`)}>
                <AllTalents
                  employmentType={employmentType}
                  search={search}
                  order={order}
                />
              </Tab>
              <Tab
                name="management"
                tab={i18n._(t`Gestion des collaborateurs`)}
              >
                <ManagementTalentPool />
              </Tab>
            </Tabs>
          </div>

          {/* search + sort */}
          {activeTab != "management" && (
            <Col className={classes.filter} lg={12} md={12} sm={12} xs={12}>
              {/* input search */}
              <div className={classes.inputSearch}>
                <TextInput
                  placeholder={
                    activeTab != "Talents" &&
                    activeTab != "management" &&
                    activeTab != "allTalents"
                      ? i18n._(t`Rechercher un vivier...`)
                      : i18n._(t`Rechercher un talent...`)
                  }
                  onEnterPressed={e => this.handleEnterPressed(e)}
                  size="small"
                  icon="search"
                  clickableIcon={false}
                  color={theme.colors.mainColor}
                  color2={theme.colors.lightMainColor}
                  radius={10}
                />
              </div>

              {/* sort + icon */}
              <Col
                className={classes.searchPart}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                {/* icon  */}
                <div className={classes.sortBy}>
                  <Icon
                    type={order === 1 ? "sortingup" : "sortingdown"}
                    size="large"
                    filled={true}
                    color={theme.colors.mainColor}
                    color2={theme.colors.lightMainColor}
                    width={20}
                    height={20}
                    onClick={() => {
                      currentPath === "TalentPool"
                        ? this.talentPoolFilterFct(talentPoolFilter, -order)
                        : this.talentFilterFct(talentFilter, -order);
                    }}
                  />
                </div>
              </Col>
            </Col>
          )}
        </div>
      </div>
    );
  }
}

DashboardTalents.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    isVisibleCreatePool: PropTypes.bool,
    isVisible2: PropTypes.bool,
    changeIsVisibleCreatePool: PropTypes.func,
    changeIsVisible2: PropTypes.func,
    changeModalType: PropTypes.func,
    modalType: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired
};

export default withI18n()(withRouter(DashboardTalents));
