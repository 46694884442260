import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { Button, FormCard, Icon, Row, Col } from "cf-neo-ui";
import "./styles.scss";
import Routes from "../../../layout/routes";
import theme from "../../../../configs/theme";

@inject("modalStore", "talentStore", "jobSubmissionStore")
@observer
class PopUpClosedOffer extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    this.state = {
      ...initProps
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  clickHandler = () => {
    const { modalStore } = this.props;
    const { changeIsVisible } = modalStore;
    changeIsVisible(false);
  };

  redirection = () => {
    const { modalStore, history } = this.props;
    const { changeIsVisible } = modalStore;
    changeIsVisible(false);
    history.push(Routes.HbLayoutRoutes.Offer.path);
  };

  commentChangeHandler = e => {
    this.setState({ comment: e.target.value });
    this.validateComment(e.target.value);
  };

  submit = () => {
    const { comment } = this.state;
    const valid = this.validateComment(comment);
    if (!valid) return;
    const { modalStore } = this.props;
    const { changeIsVisible, changeModalType } = modalStore;
    changeIsVisible(false);
    changeModalType("");
  };

  render() {
    return (
      <div className="pop-up-container">
        <FormCard className="formCard no-gutter">
          <Row>
            <Col className="form-card-content center">
              <span className="center">
                <span className="icon">
                  <Icon
                    className="icon"
                    type="Bell"
                    color={theme.colors.mainColor}
                    color2={theme.colors.lightMainColor}
                    width={25}
                    height={25}
                  />
                </span>
                <span className="title">Offre fermée</span>
              </span>
              <p>
                <Trans>
                  Cette offre est fermée et la disponibilité de ce profil n'est
                  plus d'actualité
                </Trans>
              </p>
              <p>
                <Trans>
                  Si vous êtes interressé par ce profil ou un candidat
                  similaire, nous vous invitons à soumettre une nouvelle offre
                  ou à nous contacter
                </Trans>
              </p>

              <br />
              <div className="center">
                <span className="button-left">
                  <Button
                    icon="chevron-right"
                    iconPosition="right"
                    size="small"
                    onClick={() => this.redirection()}
                  >
                    <Trans>Créer une offre</Trans>
                  </Button>
                </span>
                <span className="button-right">
                  <Button
                    variant="tertiary"
                    size="small"
                    onClick={() => this.clickHandler()}
                  >
                    <Trans>Annuler</Trans>
                  </Button>
                </span>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

PopUpClosedOffer.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisible: PropTypes.func,
    changeModalType: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  talentStore: PropTypes.shape({
    changeTalentDropped: PropTypes.func,
    changeLastParent: PropTypes.func,
    changeNewParent: PropTypes.func,
    talentDropped: PropTypes.object,
    newParent: PropTypes.object,
    lastParent: PropTypes.object
  }).isRequired,
  jobSubmissionStore: PropTypes.shape({
    changeJobSubmission: PropTypes.func,
    jobSubmission: PropTypes.object
  }).isRequired
};

export default withI18n()(withRouter(PopUpClosedOffer));
