export default {
  name: "Mindquest",
  foundingDate: "2020",
  globalLocationNumber: 3,
  partners: {
    lien1: "https://visite.mindquest.io/nos-partenaires",
    lien2: "https://visite.mindquest.io/nos-partenaires-portage-salarial"
  },
  locations: {
    london: {
      streetAddress: "100 Drummond Road",
      addressLocality: "Greater London",
      addressRegion: "London",
      postalCode: "SE16 4DG ",
      addressCountry: "GB"
    },
    paris: {
      streetAddress: "44 Boulevard de Sébastopol",
      addressLocality: "Paris",
      addressRegion: "Paris",
      postalCode: "75003 ",
      addressCountry: "FR"
    },
    barcelone: {
      streetAddress: "Ronda de Sant Pere, 17",
      addressLocality: "Barcelona",
      addressRegion: "CT",
      postalCode: "08010 Barcelona",
      addressCountry: "ES"
    }
  },
  contactEmail: "contact@mindquest.io",
  phoneNumber: {
    fr: "+33 9 75 12 27 36",
    uk: "+44 20 3854 5788"
  },
  socialMedia: {
    facebook: "https://www.facebook.com/MindquestConnect/",
    twitter: "https://twitter.com/mindquest_fr",
    twitter_en: "https://twitter.com/mindquesting",
    twitterScreenName: "mindquest_fr",
    linkedIn: "https://www.linkedin.com/company/mindquest-connect/",
    jungle: "https://www.welcometothejungle.com/fr/companies/mindquest"
  },
  address: {
    london: "AbiuUjfQLGiFfEGB9",
    paris: "exePekb8iR66CTCD7",
    barcelone: "oS2dU1Y59Ap1A3Wr8"
  },
  postNeed: {
    link_fr:
      "https://forms.office.com/Pages/ResponsePage.aspx?id=__MEfkdzvEWErRo52fbLlGXxrRB_qDBBo4AyblkL2PpURDdGMEZZRVczUkc4VkZCNkZOUzZQR0sxNC4u",
    link_uk:
      "https://forms.office.com/Pages/ResponsePage.aspx?id=__MEfkdzvEWErRo52fbLlGXxrRB_qDBBo4AyblkL2PpUQkU0SFBHMFNDVjNOVVFTNzBUWjlTVFlPNy4u"
  },
  cvTemplateUrl: "https://visite.mindquest.io/kit-demarrage-freelance",
  cvTemplateUrlEn: "https://visit.mindquest.io/freelancer-starter-kit",
  advicesEnterprise_fr: "https://fr.mindquest.io/blog/",
  advicesEnterprise_uk: "https://mindquest.io/blog/",
  cf_website: "https://club-freelance.com/",
  mq_website: "https://mindquest.io/",
  uk_blog: "https://club-freelance.co.uk/blog"
};
