import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  ProfileCard,
  Notification,
  toaster,
  Icon
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { Mutation, Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import classes from "./dashboard.module.scss";
import RecommendedMissions from "./recommended-missions";
import CalendarCard from "./calendar-card";
import StatisticsCard from "./statistics-card";
import SuggestCard from "../../components/SuggestCard/SuggestCard";
import { USER_PROFILE_STRENGTH } from "../layout/lpbLayout/queries";

import Routes from "../layout/routes";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import configs from "../../configs/club-freelance-globals";
import theme from "../../configs/theme";
import runtimeVars from "../../configs/runTimeVars";
import gql from "graphql-tag";
import HbfLayoutRoutes from "../layout/routes/hbfLayoutRoutes";

const GENERATE_RESUME_MUTATION = gql`
  mutation generateCandidateResumeMutation(
    $_id: ID!
    $source: String
    $siteLanguage: String
    $resumeModel: String
  ) {
    generateCandidateResume(
      input: {
        _id: $_id
        source: $source
        siteLanguage: $siteLanguage
        resumeModel: $resumeModel
      }
    ) {
      resume
      format
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  completeButtonHandler = candidate => {
    const { history } = this.props;
    let myInformationPath = [
      "photo",
      "Photo",
      "numéro de téléphone",
      "Phone",
      "phone number",
      "First name",
      "first name",
      "prénom",
      "Last name",
      "last name",
      "nom",
      "adresse",
      "address",
      "Address",
      "Addresse",
      "linkedIn",
      "LinkedIn"
    ];
    let MyProfile = ["CV"];
    let myJobPath = [
      "zone géographique souhaitée",
      "desired geographic locations",
      "Desired geographic locations",
      "presentation",
      "présentation",
      "About",
      "specialties",
      "Specialties",
      "spécialité",
      "Areas of expertise",
      "domaines d’expertise",
      "job Title",
      "Occupation",
      "skills",
      "Skills",
      "compétence",
      "intitulé de poste",
      "langues parlée",
      "Spoken languages",
      "spoken languages",
      "soft skills",
      "Soft skills"
    ];
    const myExperiencePath = ["experience", "expérience", "Experience"];
    const myEducationPath = ["formation", "education", "Education"];
    const mySearchPath = [
      "Location",
      "Localisation",
      "mobility",
      "Mobility",
      "mobilité",
      "availability",
      "disponibilité",
      "Date Available"
    ];

    if (candidate.profileStrength.firstTimeResumeGeneration === true) {
      history.push(Routes.LpbLayoutRoutes.MyProfileResumeDownloader.path);
    } else if (
      candidate.profileStrength.resumeGeneration === true &&
      candidate.profileStrength.missing.length === 0
    ) {
      history.push(Routes.HbfLayoutRoutes.MissionsPage.path);
    } else {
      if (candidate.profileStrength.missing[0] !== undefined) {
        if (
          myInformationPath.includes(
            candidate.profileStrength.missing[0].toString()
          )
        ) {
          history.push(Routes.LpbLayoutRoutes.MyInformation.path);
        }
        if (
          MyProfile.includes(candidate.profileStrength.missing[0].toString())
        ) {
          history.push(Routes.LpbLayoutRoutes.MyProfile.path);
        }
        if (
          myJobPath.includes(candidate.profileStrength.missing[0].toString())
        ) {
          history.push(Routes.LpbLayoutRoutes.MyJob.path);
        }
        if (
          myExperiencePath.includes(
            candidate.profileStrength.missing[0].toString()
          )
        ) {
          history.push(Routes.LpbLayoutRoutes.MyExperience.path);
        }
        if (
          mySearchPath.includes(candidate.profileStrength.missing[0].toString())
        ) {
          history.push(Routes.LpbLayoutRoutes.MySearch.path);
        }
        if (
          myEducationPath.includes(
            candidate.profileStrength.missing[0].toString()
          )
        ) {
          history.push(Routes.LpbLayoutRoutes.MyEducation.path);
        }
      }
    }
  };

  toDocs = currentLanguage => {
    window.open(
      currentLanguage == "en" ? configs.cvTemplateUrlEn : configs.cvTemplateUrl
    );
  };

  redirectTo = v => {
    if (v === 1) window.open(configs.partners.lien1, "_blank");
    else window.open(configs.partners.lien2, "_blank");
  };

  getText = candidate => {
    const { i18n } = this.props;
    if (candidate.profileStrength.resumeGeneration === false) {
      return i18n._(
        t`Votre profil est complet à ${candidate.profileStrength.score.toFixed(
          1
        )}%, Pour générer votre CV Mindquest, terminez de remplir les informations indispensables.`
      );
    } else if (candidate.profileStrength.firstTimeResumeGeneration === true) {
      return i18n._(
        t`Générez dès maintenant votre CV Mindquest et diffusez le grâce au lien partageable !`
      );
    } else if (
      candidate.profileStrength.resumeGeneration === true &&
      candidate.profileStrength.missing.length > 0
    ) {
      return i18n._(
        t`Votre profil est presque complet. Un profil complété à 100% permettra à nos recruteurs de vous proposer les offres les plus adaptés.`
      );
    } else if (
      candidate.profileStrength.resumeGeneration === true &&
      candidate.profileStrength.missing.length === 0
    ) {
      return i18n._(
        t`Félicitations, votre profil est complété à 100% ! Découvrez dès maintenant nos offres recommandées pour votre profil.`
      );
    }
  };

  getButtonText = candidate => {
    const { i18n, appStore } = this.props;
    if (candidate.profileStrength.firstTimeResumeGeneration) {
      return i18n._(t`Génerer votre CV`);
    } else if (
      candidate.profileStrength.resumeGeneration === true &&
      candidate.profileStrength.missing.length === 0
    ) {
      return i18n._(t`Découvrir nos offres`);
    }

    // S'il vous plait pour cette partie ne faites jamais la merge avec cf ( il n' y pas dde langue fr)
    if (appStore.currentLanguage === "en") {
      if (candidate.profileStrength.missing.length > 0) {
        if (candidate.profileStrength.missing[0].toString() === "Photo")
          candidate.profileStrength.missing[0] = "photo";
        else if (
          candidate.profileStrength.missing[0].toString() === "Occupation" ||
          candidate.profileStrength.missing[0].toString() ===
            "intitulé de poste"
        )
          candidate.profileStrength.missing[0] = "job Title";
        else if (
          candidate.profileStrength.missing[0].toString() === "First name" ||
          candidate.profileStrength.missing[0].toString() === "prénom" ||
          candidate.profileStrength.missing[0].toString() === "first name"
        )
          candidate.profileStrength.missing[0] = "first name";
        else if (
          candidate.profileStrength.missing[0].toString() === "Last name" ||
          candidate.profileStrength.missing[0].toString() === "nom" ||
          candidate.profileStrength.missing[0].toString() === "last name"
        )
          candidate.profileStrength.missing[0] = "last name";
        else if (
          candidate.profileStrength.missing[0].toString() === "Phone" ||
          candidate.profileStrength.missing[0].toString() ===
            "numéro de téléphone"
        )
          candidate.profileStrength.missing[0] = "phone number";
        else if (candidate.profileStrength.missing[0].toString() === "LinkedIn")
          candidate.profileStrength.missing[0] = "linkedIn";
        else if (
          candidate.profileStrength.missing[0].toString() === "Mobility" ||
          candidate.profileStrength.missing[0] === "mobilité"
        )
          candidate.profileStrength.missing[0] = "mobility";
        else if (
          candidate.profileStrength.missing[0].toString() ===
            "Desired geographic locations" ||
          candidate.profileStrength.missing[0].toString() ===
            "desired geographic locations" ||
          candidate.profileStrength.missing[0] === "zone géographique souhaitée"
        )
          candidate.profileStrength.missing[0] = "desired geographic locations";
        else if (
          candidate.profileStrength.missing[0].toString() === "About" ||
          candidate.profileStrength.missing[0] === "présentation"
        )
          candidate.profileStrength.missing[0] = "presentation";
        else if (
          candidate.profileStrength.missing[0].toString() ===
            "Date Available" ||
          candidate.profileStrength.missing[0].toString() === "disponibilité"
        )
          candidate.profileStrength.missing[0] = "availability";
        else if (
          candidate.profileStrength.missing[0].toString() === "Address" ||
          candidate.profileStrength.missing[0] === "adresse"
        )
          candidate.profileStrength.missing[0] = "address";
        else if (
          candidate.profileStrength.missing[0].toString() === "Experience" ||
          candidate.profileStrength.missing[0].toString() === "expérience"
        )
          candidate.profileStrength.missing[0] = "experience";
        else if (
          candidate.profileStrength.missing[0].toString() === "Education" ||
          candidate.profileStrength.missing[0].toString() === "formation"
        )
          candidate.profileStrength.missing[0] = "education";
        else if (
          candidate.profileStrength.missing[0].toString() === "Skills" ||
          candidate.profileStrength.missing[0] === "compétence"
        )
          candidate.profileStrength.missing[0] = "skills";
        else if (
          candidate.profileStrength.missing[0].toString() ===
            "Spoken languages" ||
          candidate.profileStrength.missing[0] === "langues parlée"
        )
          candidate.profileStrength.missing[0] = "Spoken languages";
        else if (
          candidate.profileStrength.missing[0].toString() === "Specialties" ||
          candidate.profileStrength.missing[0].toString() === "spécialité"
        )
          candidate.profileStrength.missing[0] = "specialties";
        else if (
          candidate.profileStrength.missing[0].toString() === "Soft skills"
        )
          candidate.profileStrength.missing[0] = "soft skills";
        return `${i18n._(
          t`Ajoutez votre `
        )} ${candidate.profileStrength.missing[0].toString()}`;
      }
    } else {
      if (candidate.profileStrength.missing.length > 0) {
        if (candidate.profileStrength.missing[0].toString() === "Photo")
          candidate.profileStrength.missing[0] = "photo";
        else if (
          candidate.profileStrength.missing[0].toString() === "Occupation" ||
          candidate.profileStrength.missing[0].toString() === "job Title"
        )
          candidate.profileStrength.missing[0] = "intitulé de poste";
        else if (
          candidate.profileStrength.missing[0].toString() === "First name" ||
          candidate.profileStrength.missing[0].toString() === "first name"
        )
          candidate.profileStrength.missing[0] = "prénom";
        else if (
          candidate.profileStrength.missing[0].toString() === "Last name" ||
          candidate.profileStrength.missing[0].toString() === "last name"
        )
          candidate.profileStrength.missing[0] = "nom";
        else if (
          candidate.profileStrength.missing[0].toString() === "Phone" ||
          candidate.profileStrength.missing[0].toString() === "phone number"
        )
          candidate.profileStrength.missing[0] = "numéro de téléphone";
        else if (candidate.profileStrength.missing[0].toString() === "LinkedIn")
          candidate.profileStrength.missing[0] = "linkedIn";
        else if (
          candidate.profileStrength.missing[0].toString() === "Address" ||
          candidate.profileStrength.missing[0].toString() === "address"
        )
          candidate.profileStrength.missing[0] = "adresse";
        else if (
          candidate.profileStrength.missing[0].toString() === "Mobility" ||
          candidate.profileStrength.missing[0].toString() === "mobilité" ||
          candidate.profileStrength.missing[0].toString() === "mobility"
        )
          candidate.profileStrength.missing[0] = "mobilité";
        else if (
          candidate.profileStrength.missing[0].toString() ===
            "Desired geographic locations" ||
          candidate.profileStrength.missing[0].toString() ===
            "desired geographic locations"
        )
          candidate.profileStrength.missing[0] = "zone géographique souhaitée";
        else if (
          candidate.profileStrength.missing[0].toString() === "About" ||
          candidate.profileStrength.missing[0] === "presentation"
        )
          candidate.profileStrength.missing[0] = "présentation";
        else if (
          candidate.profileStrength.missing[0].toString() ===
            "Date Available" ||
          candidate.profileStrength.missing[0].toString() === "availability"
        )
          candidate.profileStrength.missing[0] = "disponibilité";
        else if (
          candidate.profileStrength.missing[0].toString() === "Categories"
        )
          candidate.profileStrength.missing[0] = "domaines d’expertise";
        else if (
          candidate.profileStrength.missing[0].toString() === "experience" ||
          candidate.profileStrength.missing[0].toString() === "expérience"
        )
          candidate.profileStrength.missing[0] = "expérience";
        else if (
          candidate.profileStrength.missing[0].toString() === "Education" ||
          candidate.profileStrength.missing[0].toString() === "education"
        )
          candidate.profileStrength.missing[0] = "formation";
        else if (
          candidate.profileStrength.missing[0].toString() === "Skills" ||
          candidate.profileStrength.missing[0].toString() === "skills"
        )
          candidate.profileStrength.missing[0] = "compétence";
        else if (
          candidate.profileStrength.missing[0].toString() === "Spoken languages"
        )
          candidate.profileStrength.missing[0] = "langues parlée";
        else if (
          candidate.profileStrength.missing[0].toString() === "Specialties" ||
          candidate.profileStrength.missing[0].toString() === "specialties"
        )
          candidate.profileStrength.missing[0] = "spécialité";
        else if (
          candidate.profileStrength.missing[0].toString() === "Soft skills"
        )
          candidate.profileStrength.missing[0] = "soft skills";
        return `${i18n._(
          t`Ajoutez votre `
        )} ${candidate.profileStrength.missing[0].toString()}`;
      }
    }
  };

  urlAccess = url => {
    window.open(url, "_blank");
  };

  displayProfileCard = () => {
    const { sessionStore, i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    const siteLanguage = currentLanguage;
    const { APP_WEBSITE } = runtimeVars;
    const source = APP_WEBSITE;
    const resumeModel = "1";
    const {
      _id,
      profilePhoto,
      firstName,
      lastName,
      title,
      pictureUrl
    } = sessionStore;
    const candidateName = sessionStore.candidate.name;
    const BHId = sessionStore.candidate.id;
    const slugedName = sessionStore.candidate.name
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    const link = BHId ? slugedName + "-" + BHId.toString() : "";
    return (
      <div>
        <Query query={USER_PROFILE_STRENGTH} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error) return null;
            const { candidate } = data;
            if (candidate.profileStrength.resumeGenerationIcon === true) {
              return (
                <>
                  <Mutation
                    mutation={GENERATE_RESUME_MUTATION}
                    variables={{
                      _id,
                      source,
                      siteLanguage,
                      resumeModel
                    }}
                    onCompleted={data => {
                      var a = document.createElement("a");
                      a.href =
                        "data:application/octet-stream;base64," +
                        encodeURI(data.generateCandidateResume.resume); //Image Base64 Goes here
                      a.download =
                        `${candidateName}-CV-Mindquest.` +
                        data.generateCandidateResume.format;
                      a.click();
                    }}
                    onError={errors => {
                      errors.graphQLErrors.forEach(({ message }) => {
                        if (message) {
                          this.onErrorHandler(message);
                        }
                      });
                    }}
                  >
                    {(mutation, { loading }) => (
                      <span>
                        {loading ? (
                          <ProfileCard
                            className={classes.profileCard}
                            image={
                              pictureUrl ||
                              profilePhoto ||
                              "/defaultAvatar.webp"
                            }
                            heading={i18n._(t`Salut ${firstName} ${lastName}`)}
                            sub={title}
                            text={i18n._(
                              t`Votre CV est en cours de génération . . . `
                            )}
                            buttonLabel={this.getButtonText(candidate)}
                            percentage={parseFloat(
                              candidate.profileStrength.score.toFixed(1)
                            )}
                            onComplete={() =>
                              this.completeButtonHandler(candidate)
                            }
                          />
                        ) : (
                          <ProfileCard
                            className={classes.profileCard}
                            image={
                              pictureUrl ||
                              profilePhoto ||
                              "/defaultAvatar.webp"
                            }
                            heading={i18n._(t`Salut ${firstName} ${lastName}`)}
                            sub={title}
                            text={this.getText(candidate)}
                            buttonLabel={this.getButtonText(candidate)}
                            percentage={parseFloat(
                              candidate.profileStrength.score.toFixed(1)
                            )}
                            onComplete={() =>
                              this.completeButtonHandler(candidate)
                            }
                            showLinkIcon={true}
                            showDownloadIcon={true}
                            onClickLinkIcon={() => {
                              this.urlAccess(`/candidates/${link}`);
                            }}
                            onClickDownloadIcon={() => mutation()}
                            textForLinkIcon={i18n._(
                              t`Obtenir votre lien partageable`
                            )}
                            textForDownloadIcon={i18n._(
                              t`Télécharger votre CV favori`
                            )}
                          />
                        )}
                      </span>
                    )}
                  </Mutation>
                </>
              );
            } else {
              return (
                <ProfileCard
                  className={classes.profileCard}
                  image={pictureUrl || profilePhoto || "/defaultAvatar.webp"}
                  heading={i18n._(t`Salut ${firstName} ${lastName}`)}
                  sub={title}
                  text={this.getText(candidate)}
                  buttonLabel={this.getButtonText(candidate)}
                  percentage={parseFloat(
                    candidate.profileStrength.score.toFixed(1)
                  )}
                  onComplete={() => this.completeButtonHandler(candidate)}
                />
              );
            }
          }}
        </Query>
      </div>
    );
  };

  render() {
    const { i18n, appStore } = this.props;
    const meta = staticMetaTags(i18n).dashboard;
    return (
      <div className={classes.dashboard}>
        {metaTags(
          urls.myProfile(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          null,
          null,
          false
        )}
        <Container>
          <Row>
            <Col lg={8} md={8} sm={12} xs={6}>
              {this.displayProfileCard()}
              <RecommendedMissions />
            </Col>
            <Col lg={4} md={4} sm={12} xs={6}>
              <CalendarCard />
              <StatisticsCard />
              <SuggestCard
                text={i18n._(
                  t`Mutuelle, formation, crédit bancaire... découvrez les offres privilègiées dont vous pouvez bénéficier auprès de nos partenaires.`
                )}
                buttonLabel={i18n._(t`Découvrir`)}
                onClick={() => this.redirectTo(1)}
                masked={false}
              />
              <div style={{ paddingTop: "30px" }} />
              <SuggestCard
                text={i18n._(
                  t`Vous souhaitez être accompagné(e) en portage salarial ? Découvrez les solutions de nos partenaires.`
                )}
                buttonLabel={i18n._(t`Découvrir`)}
                onClick={() => this.redirectTo(2)}
                masked={false}
              />
              {/* <SuggestCard */}
              {/*  text={i18n._( */}
              {/*    t`Vous souhaitez coopter quelqu’un de votre entourage ?` */}
              {/*  )} */}
              {/*  buttonLabel={i18n._(t`Coopter`)} */}
              {/* /> */}
              {/* <SuggestCard */}
              {/*  text={i18n._( */}
              {/*    t`Devenez apporteur d’affaire et faites-vous rémunérer` */}
              {/*  )} */}
              {/*  buttonLabel={i18n._(t`Coopter`)} */}
              {/* /> */}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Dashboard.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    profilePhoto: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
    pictureUrl: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(Dashboard));
