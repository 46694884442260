import urls from "../../utils/urls";

const ldJson = [
  {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "about",
        item: urls.about()
      }
    ]
  }
];

export default ldJson;
