import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react/index";
import {
  Container,
  Row,
  Col,
  MissionDisplay,
  toaster,
  Pagination
} from "cf-neo-ui";
import { Query, Mutation } from "react-apollo";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import routes from "../layout/routes/hbfLayoutRoutes";
import { USER_FAVED_MISSIONS_QUERY } from "./queries";
import { UNFAV_MISSION } from "../missions-page/mutations";
import LoadingMissions from "../../components/LoadingMissions/LoadingMissions";
import classes from "./styles.module.scss";
import { isOnePage, setUrgent } from "../../utils/helpers";
import formatMissionDuration from "../../utils/formatMissionDuration";
import getContractType from "../../utils/getContractType";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";

@inject("appStore")
@observer
class FavoriteMissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: this.setPageSize(props.appStore.width),
      currentPage: 1,
      skip: 0
    };
  }

  setPageSize = width => {
    if (width >= 1440) return 8;
    if (width >= 1024) return 6;
    if (width >= 768) return 4;
    return 2;
  };

  renderPagination = data => {
    const { pageSize, currentPage } = this.state;
    const paginationProps = {
      pageSize,
      total: data.length,
      current: currentPage,
      onChange: current => {
        this.setState({ currentPage: current, skip: (current - 1) * pageSize });
      }
    };
    return <Pagination {...paginationProps} />;
  };

  toDetails = id => {
    const { history } = this.props;
    history.push(`${routes.MissionsPage.path}/${id}`);
  };

  render() {
    const { i18n } = this.props;
    const { skip, pageSize, currentPage } = this.state;
    const meta = staticMetaTags(i18n).favouriteMissions;
    return (
      <>
        {metaTags(
          urls.favouriteMissions(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords
        )}
        <Query query={USER_FAVED_MISSIONS_QUERY}>
          {({ loading, error, data }) => {
            if (loading)
              return (
                <LoadingMissions
                  cardsCount={4}
                  lg={3}
                  md={4}
                  sm={6}
                  xs={6}
                  colClassName={classes.favedMissionsCard}
                  containerClassName={classes.marTop}
                />
              );

            if (error)
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );

            const { userFavedMissions } = data;
            return (
              <div>
                <Container className={classes.marTop}>
                  <Row>
                    {userFavedMissions
                      .slice(skip, pageSize * currentPage)
                      .map(mission => (
                        <Col
                          key={mission.id}
                          lg={3}
                          md={4}
                          sm={6}
                          xs={6}
                          className={classes.favedMissionsCard}
                        >
                          <Mutation
                            mutation={UNFAV_MISSION}
                            onError={() =>
                              toaster.error({
                                title: "Erreur",
                                description: i18n._(
                                  t`Erreur côté de serveur. Essayez plus tard`
                                )
                              })
                            }
                            refetchQueries={[
                              { query: USER_FAVED_MISSIONS_QUERY }
                            ]}
                          >
                            {unFavMission => (
                              <MissionDisplay
                                title={mission.title}
                                location={mission.address.city}
                                duration={formatMissionDuration(
                                  mission.duration,
                                  i18n,
                                  mission.employmentType
                                )}
                                contractType={getContractType(
                                  i18n,
                                  mission.employmentType
                                )}
                                field={mission.customText1}
                                urgent={setUrgent(mission.customText10)}
                                onShowDetails={() => this.toDetails(mission.id)}
                                showDetailsLabel={i18n._(t`Voir l’offre`)}
                                href={`${routes.MissionsPage.path}/${mission.id}`}
                                onPrefer={() =>
                                  unFavMission({
                                    variables: { jobOrderId: mission.id }
                                  })
                                }
                                preferred
                              />
                            )}
                          </Mutation>
                        </Col>
                      ))}
                  </Row>
                </Container>
                {isOnePage(pageSize, userFavedMissions.length) ? null : (
                  <div className={classes.pagination}>
                    {this.renderPagination(userFavedMissions)}
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </>
    );
  }
}

FavoriteMissions.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired
};

export default withI18n()(withRouter(FavoriteMissions));
