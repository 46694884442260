import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { Button, Icon } from "cf-neo-ui";
import "./styles.scss";
import Routes from "../../layout/routes";

@inject("modalStore", "sessionStore")
@observer
class PopUp extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    this.state = {
      ...initProps
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  onNextStepHandler() {
    const { history, modalStore } = this.props;
    const { changeExperiencePopUp } = modalStore;
    const { MySearch } = Routes.Hb2LayoutRoutes;
    localStorage.removeItem("Experiences");
    this.cleanCandidateWorkHistory();
    changeExperiencePopUp();
    history.push(MySearch.path);
  }

  cancelHandler = () => {
    const { modalStore } = this.props;
    const { changeExperiencePopUp } = modalStore;
    changeExperiencePopUp();
  };

  cleanCandidateWorkHistory() {
    const { sessionStore } = this.props;
    const { changeCandidateWorkHistory } = sessionStore;
    changeCandidateWorkHistory([]);
  }

  render() {
    return (
      <div
        style={{
          maxWidth: "500px",
          padding: "20px",
          boxSizing: "border-box",
          background: "white",
          borderRadius: "5px"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Icon
            className="icon"
            type="Bell"
            color="#8D0417"
            color2="#D3354A"
            width={20}
            height={20}
          />
          <h4 style={{ color: "#8D0417" }}>
            <Trans>Vous risquez de perdre vos données</Trans>
          </h4>
        </div>
        <div style={{ textAlign: "left", padding: "30px 0" }}>
          <p>
            <Trans>
              Certaines données n'ont pas été enregistrées seront perdues.
              Etes-vous sûr de vouloir passer à létape suivante ?
            </Trans>
          </p>
          <p>
            <Trans>
              Pour enregistrer les données saisies, fermez cette fenêtre en
              cliquant sur "annuler" puis cliquez sur le bouton "enregistrer" à
              la fin du formulaire.
            </Trans>
          </p>
          <p>
            <Trans>Sinon cliquez sur continuer.</Trans>
          </p>
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <Button
            style={{
              width: "50%"
            }}
            icon="chevron-right"
            iconPosition="right"
            size="small"
            onClick={() => this.onNextStepHandler()}
          >
            <Trans>Continuer</Trans>
          </Button>

          <Button
            style={{
              marginLeft: "50px",
              width: "50%"
            }}
            variant="tertiary"
            size="small"
            onClick={() => this.cancelHandler()}
          >
            <Trans>Annuler</Trans>
          </Button>
        </div>
      </div>
    );
  }
}

PopUp.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    }),
    candidateWorkHistory: PropTypes.shape({}),
    changeCandidateWorkHistory: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    changeExperiencePopUp: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(PopUp));
