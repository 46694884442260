import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react/index";
import { Icon } from "cf-neo-ui";
import classes from "./dashboardHeader.module.scss";

@inject("referenceTask", "timesheetStore")
@observer
class DashBoardHeader extends Component {
  render() {
    const { referenceTask, timesheetStore } = this.props;
    const { setSideMenu } = referenceTask;
    const { activatedPage, changeActivatedPage } = timesheetStore;
    const w = typeof window !== "undefined" ? window.innerWidth : 1024;
    return (
      <div
        className={classes.dashRespHeader}
        style={{
          padding:
            typeof window !== "undefined" &&
            window.location.pathname.substring(
              window.location.pathname.lastIndexOf("/") - 13
            ) === "timesheets/1"
              ? "25px 25px 0 25px"
              : "25px"
        }}
      >
        <div className={classes.actionBtn}>
          <Icon type="bars" width={14} height={12} onClick={setSideMenu} />
        </div>
        <div className={classes.logo}>
          {w > 1024 ? (
            <Link to="/missions">
              <picture>
                <source
                  type="images/webp"
                  srcSet="/assets/images/logo_mindquest_dash.webp"
                />
                <img
                  src="/assets/images/logo_mindquest_dash.webp"
                  alt="mindquest"
                />
              </picture>
            </Link>
          ) : (
            <Link to="/enterprise">
              <picture>
                <source
                  type="images/webp"
                  srcSet="/assets/images/logo_mindquest_dash.webp"
                />
                <img
                  src="/assets/images/logo_mindquest_dash.webp"
                  alt="mindquest"
                />
              </picture>
            </Link>
          )}
        </div>
      </div>
    );
  }
}

DashBoardHeader.wrappedComponent.propTypes = {
  referenceTask: PropTypes.shape({
    setSideMenu: PropTypes.func
  }).isRequired,
  timesheetStore: PropTypes.shape({
    activatedPage: PropTypes.string,
    changeActivatedPage: PropTypes.func
  }).isRequired
};

export default DashBoardHeader;
