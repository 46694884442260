import gql from "graphql-tag";

export const CREATE_EMAIL_CHANGE_REQUEST_MUTATION = gql`
  mutation CreateEmailChangeRequest($newEmail: String!) {
    createEmailChangeRequest(newEmail: $newEmail)
  }
`;

export const PASSWORD_VERIFICATION_MUTATION = gql`
  mutation PasswordVerification($password: String!) {
    passwordVerification(password: $password)
  }
`;

export const CREATE_ACCOUNT_DELETION_REQUEST_MUTATION = gql`
  mutation CreateAccountDeletionRequest($option: String!) {
    createAccountDeletionRequest(option: $option)
  }
`;
