import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import {
  Row,
  Col,
  FormGroup,
  FormCard,
  Button,
  Validation,
  Textarea
} from "cf-neo-ui";
import Routes from "../layout/routes/index";
import validate from "../../utils/validators";

import "./styles.scss";

@inject("sessionStore", "appStore", "postANeedStore")
@observer
class Offer2 extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    this.state = {
      ...initProps,
      isRecruitmentContextValid: true,
      recruitmentContextValidationMessage: "",
      isJobDescriptionValid: true,
      jobDescriptionValidationMessage: ""
    };
    this.onNextStepHandler = this.onNextStepHandler.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  onNextStepHandler() {
    const valid = this.isFormValid();
    if (!valid) return;
    const { history } = this.props;
    const { DesiredProfile } = Routes.HbLayoutRoutes;
    history.push(DesiredProfile.path);
  }

  jobDescriptionChangeHandler = e => {
    const { postANeedStore } = this.props;
    postANeedStore.changeJobDescription(e.target.value);
    this.validateJobDescription(e.target.value);
  };

  recruitmentContextChangeHandler = e => {
    const { postANeedStore } = this.props;
    postANeedStore.changeRecruitmentContext(e.target.value);
    this.validateRecruitmentContext(e.target.value);
  };

  validateJobDescription(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isJobDescriptionValid: res.isValid,
      jobDescriptionValidationMessage: res.message
    });
    return res.isValid;
  }

  validateRecruitmentContext(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isRecruitmentContextValid: res.isValid,
      recruitmentContextValidationMessage: res.message
    });
    return res.isValid;
  }

  isFormValid() {
    const { postANeedStore } = this.props;
    const { recruitmentContext, jobDescription } = postANeedStore;
    let valid = true;
    if (!this.validateJobDescription(jobDescription)) valid = false;
    if (!this.validateRecruitmentContext(recruitmentContext)) valid = false;
    return valid;
  }

  render() {
    const {
      isRecruitmentContextValid,
      recruitmentContextValidationMessage,
      isJobDescriptionValid,
      jobDescriptionValidationMessage
    } = this.state;
    const { postANeedStore, i18n, history } = this.props;
    const {
      changeStep,
      recruitmentContext,
      jobDescription,
      jobTitle
    } = postANeedStore;

    if (!jobTitle) {
      history.push(Routes.HbLayoutRoutes.Offer.path);
      changeStep(2);
    } else {
      changeStep(3);
    }

    return (
      <div className="session signup-profile">
        <br />

        <Row>
          <Col className="content">
            <h1 className="title">
              <Trans>Offre</Trans>
            </h1>
          </Col>
        </Row>

        <br />

        <FormCard className="formCard no-gutter with-padding">
          <Row>
            <Col lg={12} xl={12} md={12} sd={12} xs={6}>
              <FormGroup>
                <label className="form-label" htmlFor="experienceDescription">
                  <Trans>Description de la mission</Trans>
                  <span className="asterisc">*</span>
                </label>
                <Validation
                  errorMessage={jobDescriptionValidationMessage}
                  valid={isJobDescriptionValid}
                >
                  <Textarea
                    id="missionDescription"
                    size="xLarge"
                    className="form-input"
                    defaultValue=""
                    onEnterPressed={this.jobDescriptionChangeHandler}
                    onEscapePressed={this.jobDescriptionChangeHandler}
                    onKeyDown={this.jobDescriptionChangeHandler}
                    onChange={this.jobDescriptionChangeHandler}
                    value={jobDescription}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={12} md={12} sd={12} xs={6}>
              <FormGroup>
                <label className="form-label" htmlFor="experienceDescription">
                  <Trans>Contexte projet</Trans>
                  <span className="asterisc">*</span>
                </label>
                <Validation
                  errorMessage={recruitmentContextValidationMessage}
                  valid={isRecruitmentContextValid}
                >
                  <Textarea
                    id="recruitmentContext"
                    size="xLarge"
                    className="form-input"
                    placeholder={i18n._(
                      t`Indiquez des informations de contexte comme la phase du projet, la composition de l’équipe ou l’organisation interne...`
                    )}
                    defaultValue=""
                    onEnterPressed={this.recruitmentContextChangeHandler}
                    onEscapePressed={this.recruitmentContextChangeHandler}
                    onKeyDown={this.recruitmentContextChangeHandler}
                    onChange={this.recruitmentContextChangeHandler}
                    value={recruitmentContext}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
        </FormCard>
        <br />

        <div className="next-buttons">
          <Button icon="chevron-right" onClick={this.onNextStepHandler}>
            <Trans>Etape suivante</Trans>
          </Button>
        </div>
      </div>
    );
  }
}

Offer2.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  postANeedStore: PropTypes.shape({
    changeStep: PropTypes.func,
    changeRecruitmentContext: PropTypes.func,
    changeJobDescription: PropTypes.func,
    recruitmentContext: PropTypes.string,
    jobDescription: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};
export default withI18n()(withRouter(Offer2));
