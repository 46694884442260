import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Col, Row } from "cf-neo-ui";
import * as PropTypes from "prop-types";
import SingleCandidateBodyLeftSlide from "./SingleCandidateBodyLeftSlide/SingleCandidateBodyLeftSlide";
import SingleCandidateDescription from "./SingleCandidateDescription/SingleCandidateDescription";

@inject("appStore")
@observer
class SingleCandidateBody extends Component {
  render() {
    const {
      loading,
      candidate,
      candidateWorkHistory,
      candidateEducation,
      candidateCertification,
      id,
      appStore
    } = this.props;
    return (
      <Row noGape>
        {appStore.width >= 1024 && (
          <Col noGutter xl={3} lg={3} md={3} sm={12} xs={6}>
            <SingleCandidateBodyLeftSlide
              loading={loading}
              candidate={candidate}
              id={id}
              clickHandler={this.props.clickHandler}
              closeModal={this.props.closeModal}
              isVisible={this.props.isVisible}
              clickHandler2={this.props.clickHandler2}
              closeModal2={this.props.closeModal2}
              isVisible2={this.props.isVisible2}
            />
          </Col>
        )}
        <Col noGutter xl={9} lg={9} md={9} sm={12} xs={6}>
          <Row noGape>
            <Col noGutter lg={12} md={12} sm={12}>
              <SingleCandidateDescription
                candidate={candidate}
                candidateWorkHistory={candidateWorkHistory}
                candidateEducation={candidateEducation}
                candidateCertification={candidateCertification}
                loading={loading}
              />
              {appStore.width < 1024 && (
                <SingleCandidateBodyLeftSlide
                  loading={loading}
                  candidate={candidate}
                  id={id}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

SingleCandidateBody.wrappedComponent.propTypes = {
  loading: PropTypes.bool,
  candidate: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    avatar: PropTypes.string,
    occupation: PropTypes.string,
    description: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.string),
    spokenLanguages: PropTypes.arrayOf(PropTypes.string),
    specialties: PropTypes.arrayOf(PropTypes.string)
  }),
  candidateWorkHistory: PropTypes.arrayOf(PropTypes.object),
  candidateEducation: PropTypes.arrayOf(PropTypes.object),
  candidateCertification: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string.isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired
};
SingleCandidateBody.defaultProps = {
  loading: true,
  candidate: {
    avatar: "",
    occupation: "",
    description: "",
    skills: [""],
    spokenLanguages: [""],
    specialties: [""]
  },
  candidateWorkHistory: {
    companyName: "",
    title: "",
    summary: "",
    email: "",
    startDate: "",
    endDate: "",
    refName: "",
    refEmail: "",
    refPhone: "",
    skillExperience: [""],
    specialty: [""],
    companyAddress: {
      countryID: "",
      city: ""
    },
    BusinessSector: [""]
  },
  candidateEducation: {
    diplomeType: "",
    degree: "",
    school: "",
    startDate: "",
    endDate: ""
  },
  candidateCertification: {
    certification: "",
    school: "",
    startDate: "",
    endDate: ""
  }
};

export default SingleCandidateBody;
