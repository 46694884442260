import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Tabs, Tab, Modal, ToastCard } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import "./styles.scss";
import "./style.scss";
import { inject, observer } from "mobx-react";
import Routes from "../../layout/routes/index";
import MyProfile from "./myProfile";
import MyJob from "./myJob";
import MyExperience from "./myExperience";
import MySearch from "./mySearch";
import MyInformation from "./myInformation";
import MyEducation from "./myEducation";
import Params from "../../params";
@inject("appStore", "sessionStore")
@observer
class DashboardProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false
    };
  }
  clickTabHandler() {
    const { i18n, sessionStore } = this.props;
    const { generalSaveCase } = sessionStore;
    this.setState({ isVisible: generalSaveCase ? true : false });
    sessionStore.changeGeneralSaveCase(null);
  }

  changeTabHandler(activeTab) {
    const { history, sessionStore } = this.props;
    const routes = Routes.LpbLayoutRoutes;

    if (activeTab === 1) history.push(routes.MyProfile.path);
    if (activeTab === 2) history.push(routes.MyInformation.path);
    if (activeTab === 3) history.push(routes.MyJob.path);
    if (activeTab === 4) history.push(routes.MyExperience.path);
    if (activeTab === 5) history.push(routes.MyEducation.path);
    if (activeTab === 6) history.push(routes.MySearch.path);
    if (activeTab === 7) history.push(routes.MyParams.path);
  }

  render() {
    const { i18n, location, sessionStore } = this.props;
    const currentPath = location.pathname;
    const { generalSaveCase } = sessionStore;
    const routes = Routes.LpbLayoutRoutes;
    const { isVisible } = this.state;
    let cvDownload = "/dashboard/my-profile/CV-download";
    let cvUpload = "/dashboard/my-profile/CV-upload";
    let activeTab =
      currentPath === routes.MyProfile.path ||
      currentPath === cvDownload ||
      currentPath === cvUpload
        ? 1
        : 5;

    if (currentPath === routes.MyProfile.path) activeTab = 1;
    if (currentPath === routes.MyInformation.path) activeTab = 2;
    if (currentPath === routes.MyJob.path) activeTab = 3;
    if (currentPath === routes.MyExperience.path) activeTab = 4;
    if (currentPath === routes.MyEducation.path) activeTab = 5;
    if (currentPath === routes.MySearch.path) activeTab = 6;
    if (currentPath === routes.MyParams.path) activeTab = 7;

    return (
      <div>
        <Modal
          centred
          isVisible={isVisible}
          withCloseButton={false}
          position="fixed"
        >
          <ToastCard
            title={i18n._(t`Alerte`)}
            description={i18n._(
              t`Veuillez enregistrer avant de passer à l’étape suivante`
            )}
            expiresIn={0}
            isVisible
            closable
            intent="error"
            showButton
            buttonText={i18n._(t`j'ai compris`)}
            onClose={() => {
              this.setState({ isVisible: false });
            }}
          />
        </Modal>

        <Tabs
          defaultActiveTab={activeTab}
          onChange={activeTab => this.changeTabHandler(activeTab)}
        >
          <Tab
            name={1}
            tab={i18n._(t`Mon CV`)}
            closedTab={generalSaveCase && generalSaveCase !== 1}
            moreAction={() => this.clickTabHandler()}
          >
            <MyProfile />
          </Tab>
          <Tab
            name={2}
            tab={i18n._(t`Mes Coordonnées`)}
            closedTab={generalSaveCase && generalSaveCase !== 2}
            moreAction={() => this.clickTabHandler()}
          >
            <MyInformation />
          </Tab>
          <Tab
            name={3}
            tab={i18n._(t`Mon métier`)}
            closedTab={generalSaveCase && generalSaveCase !== 3}
            moreAction={() => this.clickTabHandler()}
          >
            <MyJob />
          </Tab>
          <Tab
            name={4}
            tab={i18n._(t`Mon expérience`)}
            closedTab={generalSaveCase && generalSaveCase !== 4}
            moreAction={() => this.clickTabHandler()}
          >
            <MyExperience />
          </Tab>
          <Tab
            name={5}
            tab={i18n._(t`Ma Formation`)}
            closedTab={generalSaveCase && generalSaveCase !== 5}
            moreAction={() => this.clickTabHandler()}
          >
            <MyEducation />
          </Tab>
          <Tab
            name={6}
            tab={i18n._(t`Ma recherche`)}
            closedTab={generalSaveCase && generalSaveCase !== 6}
            moreAction={() => this.clickTabHandler()}
          >
            <MySearch />
          </Tab>
          <Tab
            name={7}
            tab={i18n._(t`Mes paramètres`)}
            closedTab={generalSaveCase && generalSaveCase !== 7}
            moreAction={() => this.clickTabHandler()}
          >
            <Params />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

DashboardProfile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(DashboardProfile));
