import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import {
  Button,
  FormCard,
  Icon,
  Row,
  Col,
  Validation,
  TextInput,
  FormGroup
} from "cf-neo-ui";
import { Mutation } from "react-apollo";
import moment from "moment";
import { CREATE_EMAIL_CHANGE_REQUEST_MUTATION } from "./mutations";
import runtimeVars from "../../../configs/runTimeVars";
import styles from "./styles.module.scss";
import validate from "../../../utils/validators";
import Routes from "../../layout/routes";

@inject("modalStore", "sessionStore")
@observer
class EmailChange extends Component {
  constructor(props) {
    super(props);
    moment.locale(runtimeVars.APP_LANG);
    const initProps = { ...props };
    this.state = {
      ...initProps,
      check: false,
      buttonClicked: false,
      isNewEmailValid: true,
      newEmailValidationMessage: "",
      newEmail: "",
      popupToShow: 1
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  isFormValid() {
    const { newEmail } = this.state;
    let valid = true;
    if (!this.validateNewEmail(newEmail)) valid = false;
    if (!valid) return false;
    return valid;
  }

  validateNewEmail(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required", "email"], i18n);
    this.setState({
      isNewEmailValid: res.isValid,
      newEmailValidationMessage: res.message
    });
    return res.isValid;
  }

  clickCancelHandler = () => {
    const { modalStore } = this.props;
    const { changeParamsChangeEmailPopUp } = modalStore;
    changeParamsChangeEmailPopUp(false);
    this.setState({ popupToShow: 1 });
  };

  emailChangeHandler = e => {
    this.setState({ newEmail: String(e.target.value).toLowerCase() });
    this.validateNewEmail(e.target.value);
  };

  render() {
    const { i18n } = this.props;
    const {
      newEmailValidationMessage,
      isNewEmailValid,
      newEmail,
      popupToShow
    } = this.state;
    const { contact } = Routes.HbLayoutRoutes;
    return (
      <div className={styles.popUpContainer}>
        <FormCard className="formCard no-gutter">
          <Row>
            <Col className={styles.formCardContent}>
              <div className={styles.header}>
                <Icon
                  className={styles.bellIcon}
                  type="Bell"
                  color="#8D0417"
                  color2="#D3354A"
                  width={14}
                  height={14}
                />
                <p>
                  {popupToShow === 1 && (
                    <Trans>Changement de l'adresse email</Trans>
                  )}
                  {popupToShow === 2 && <Trans>Changement en cours</Trans>}
                  {popupToShow === 3 && (
                    <Trans>Email déjà associé à un compte existant</Trans>
                  )}
                </p>
                <Icon
                  className={styles.closeIcon}
                  type="close"
                  color="#8D0417"
                  color2="#D3354A"
                  width={14}
                  height={14}
                  onClick={() => this.clickCancelHandler()}
                />
              </div>
              <div className={styles.container}>
                <p>
                  {popupToShow === 2 && (
                    <Trans>
                      Un email de confirmation vous a été envoyé sur votre
                      nouvelle adresse. Une fois confirmé, le changement sera
                      effectif dans un délais de 24 heures
                    </Trans>
                  )}
                  {popupToShow === 1 && (
                    <Trans>
                      Vos futures connexions à votre espace personnel se feront
                      avec votre nouvelle adresse email
                    </Trans>
                  )}
                  {popupToShow === 3 && (
                    <p>
                      <Trans>
                        Cette adresse email est déjà associée à un compte
                        existant. Vous pouvez supprimer l'un de vos deux compte
                        ou{" "}
                        <Link
                          to={contact.path}
                          target="_blank"
                          style={{ color: "#8D0417" }}
                        >
                          contacter le support technique
                        </Link>{" "}
                        pour les fusionner
                      </Trans>
                    </p>
                  )}
                </p>
                {popupToShow === 1 && (
                  <FormGroup className={styles.inputEmail}>
                    <Validation
                      errorMessage={newEmailValidationMessage}
                      valid={isNewEmailValid}
                    >
                      <TextInput
                        id="newEmail"
                        type="email"
                        spellCheck="false"
                        placeholder={i18n._(
                          t`Entrez votre nouvelle adresse email`
                        )}
                        value={newEmail}
                        onChange={this.emailChangeHandler}
                        className={styles.popUpEnterYourNewEmail}
                      />
                    </Validation>
                  </FormGroup>
                )}
                {popupToShow === 1 && (
                  <div className={styles.buttonsBlock}>
                    <Button
                      variant="secondary"
                      size="small"
                      onClick={() => this.clickCancelHandler()}
                    >
                      <Trans>Annuler</Trans>
                    </Button>
                    <Mutation
                      mutation={CREATE_EMAIL_CHANGE_REQUEST_MUTATION}
                      variables={{
                        newEmail
                      }}
                      onCompleted={res => {
                        if (
                          res &&
                          res.createEmailChangeRequest ===
                            "this email already exist"
                        ) {
                          this.setState({ popupToShow: 3 });
                        } else {
                          this.setState({ popupToShow: 2 });
                          this.setState({ newEmail: "" });
                        }
                      }}
                      onError={errors => {
                        errors.graphQLErrors.forEach(({ message, data }) => {
                          if (data && data.isCustomError) {
                            this.onErrorHandler(message);
                          }
                        });
                      }}
                    >
                      {(mutation, { loading }) => (
                        <Button
                          disabled={loading}
                          style={{ marginLeft: "20px" }}
                          variant="primary"
                          size="small"
                          onClick={() => {
                            if (this.isFormValid()) {
                              return mutation();
                            }
                            return null;
                          }}
                        >
                          <Trans>Confirmer</Trans>
                        </Button>
                      )}
                    </Mutation>
                  </div>
                )}
                {popupToShow === 3 && (
                  <div className={styles.buttonsBlock}>
                    <Button
                      variant="primary"
                      size="small"
                      onClick={() =>
                        this.setState({ popupToShow: 1, newEmail: "" })
                      }
                    >
                      <Trans>J'ai compris</Trans>
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

EmailChange.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    changeParamsChangeEmailPopUp: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(EmailChange));
