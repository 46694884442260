import gql from "graphql-tag";

const CLIENT_STATS_CSV_QUERY = gql`
  query clientStatsCSV($id: Int!, $dateStart: Float, $dateEnd: Float) {
    clientStatsCSV(
      id: $id
      filter: { dateStart: $dateStart, dateEnd: $dateEnd }
    )
  }
`;

const ACCESSIBLE_CLIENT_STATS_CSV_QUERY = gql`
  query AccessibleclientStatsCSV(
    $id: Int!
    $dateStart: Float
    $dateEnd: Float
  ) {
    accessibleClientStatsCSV(
      id: $id
      filter: { dateStart: $dateStart, dateEnd: $dateEnd }
    )
  }
`;

const CLIENT_RECOMMENDED_TALENTS_QUERY = gql`
  query ClientRecommendedTalents(
    $id: Int!
    $limit: Int
    $skip: Int
    $employmentType: String
  ) {
    clientRecommendedTalents(
      id: $id
      filter: { limit: $limit, skip: $skip, employmentType: $employmentType }
    ) {
      id
      firstName
      lastName
      occupation
      dateAvailable
      address {
        address1
        city
      }
      candidateCv
      comments {
        comment
        commenter
        jobOrder
      }
      status
      payRate
      reference {
        id
        referenceFirstName
        referenceLastName
        referenceTitle
        outcome
      }
      accessible
      employmentPreference
    }
  }
`;

const ACCESSIBLE_CLIENT_RECOMMENDED_TALENTS_QUERY = gql`
  query AccessibleClientRecommendedTalents(
    $id: Int!
    $limit: Int
    $skip: Int
    $employmentType: String
  ) {
    accessibleClientRecommendedTalents(
      id: $id
      filter: { limit: $limit, skip: $skip, employmentType: $employmentType }
    ) {
      id
      firstName
      lastName
      occupation
      dateAvailable
      address {
        address1
        city
      }
      candidateCv
      comments {
        comment
        commenter
        jobOrder
      }
      status
      payRate
      reference {
        id
        referenceFirstName
        referenceLastName
        referenceTitle
        outcome
      }
      accessible
      employmentPreference
    }
  }
`;
const GET_TALENTS_QUERY = gql`
  query talents(
    $clientID: String
    $clientCompanyId: Int
    $limit: Int
    $skip: Int
    $search: String
    $order: Int
    $all: Boolean
  ) {
    talents(
      clientID: $clientID
      clientCompanyId: $clientCompanyId
      filter: {
        limit: $limit
        skip: $skip
        search: $search
        order: $order
        all: $all
      }
    ) {
      talents {
        vivierID
        talentID
        recommended
        profilePhotoURL
        clientID
        firstName
        address {
          address1
          city
          countryID
          countryName
          zip
          state
          lat
          lng
        }
        lastName
        occupation
        dateAvailable
        status
        accessible
        canSeeCandidateInformation
        employmentPreference
        experience
        candidateCv
        comments {
          comment
          commenter
          jobOrder
        }
        payRate
        reference {
          id
          referenceFirstName
          referenceLastName
          referenceTitle
          outcome
        }
        publicProfil
        isDeleted
      }
      count
    }
  }
`;

const LIST_POOL_LOGS_QUERY = gql`
  query listPoolLogs($clientID: String, $vivierID: String) {
    listPoolLogs(clientID: $clientID, vivierID: $vivierID) {
      createdAt
      clientID
      talentID
      vivierID
      operation
      client {
        firstName
        lastName
      }
      talent {
        firstName
        lastName
      }
    }
  }
`;
const LIST_COLLABORATOR_QUERY = gql`
  query listCollaborator(
    $creatorID: String
    $limit: Int
    $skip: Int
    $search: String
    $order: Int
  ) {
    listCollaborator(
      creatorID: $creatorID
      filter: { limit: $limit, skip: $skip, search: $search, order: $order }
    ) {
      collaborator {
        clientID
        clientEmail
        clientCompanyName
        clientCompanyId
        clientName
        nbPool
        permitPool
        dateAdded
        emailClient
      }
      count
    }
  }
`;
const LIST_COLLABORATOR_BY_POOL_QUERY = gql`
  query listCollaboratorByPool(
    $creatorID: String
    $vivierID: String
    $limit: Int
    $skip: Int
    $search: String
    $order: Int
  ) {
    listCollaboratorByPool(
      creatorID: $creatorID
      vivierID: $vivierID
      filter: { limit: $limit, skip: $skip, search: $search, order: $order }
    ) {
      collaborator {
        clientID
        clientEmail
        clientCompanyName
        clientCompanyId
        clientName
        nbPool
        permitPool
        dateAdded
        emailClient
      }
      count
    }
  }
`;

const TALENT_POOLS_QUERY = gql`
  query talentPools(
    $clientID: String
    $clientCompanyId: Int
    $limit: Int
    $skip: Int
    $search: String
    $order: Int
  ) {
    talentPools(
      clientID: $clientID
      clientCompanyId: $clientCompanyId
      filter: { limit: $limit, skip: $skip, search: $search, order: $order }
    ) {
      talentPools {
        _id
        clientCompanyName
        poolName
        updatedAt
        nbCollaborator
        clientID
        clientEmail
        clientCompanyId
        clientName
        sourceTalentPool
        nbProfile
        creatorID
        shared
      }
      count
    }
  }
`;

const LIST_TALENT_POOL = gql`
  query listTalentPool(
    $clientID: String
    $talentID: Int
    $clientCompanyId: Int
  ) {
    listTalentPool(
      clientID: $clientID
      talentID: $talentID
      clientCompanyId: $clientCompanyId
    ) {
      vivierID
      talentID
      poolName
      shared
      exist
      creatorID
    }
  }
`;

const TALENT_POOL_QUERY = gql`
  query talentPool(
    $_id: String
    $clientID: String
    $limit: Int
    $skip: Int
    $search: String
    $order: Int
  ) {
    talentPool(
      _id: $_id
      clientID: $clientID
      filter: { limit: $limit, skip: $skip, search: $search, order: $order }
    ) {
      pool {
        _id
        clientCompanyName
        poolName
        updatedAt
        clientID
        clientEmail
        clientCompanyId
        clientName
        sourceTalentPool
        nbProfile
        creatorID
        shared
      }
      talents {
        vivierID
        talentID
        recommended
        publicProfil
        isDeleted
        profilePhotoURL
        clientID
        firstName
        address {
          address1
          city
          countryID
          countryName
          zip
          state
          lat
          lng
        }
        lastName
        occupation
        dateAvailable
        status
        accessible
        canSeeCandidateInformation
        employmentPreference
        experience
        candidateCv
        comments {
          comment
          commenter
          jobOrder
        }
        payRate
        reference {
          id
          referenceFirstName
          referenceLastName
          referenceTitle
          outcome
        }
      }
      count
    }
  }
`;

const CLIENT_VACANCIES_QUERY = gql`
  query ClientVacancies(
    $id: Int!
    $limit: Int
    $skip: Int
    $search: String
    $status: [String]
  ) {
    clientVacancies(
      id: $id
      filter: { limit: $limit, skip: $skip, search: $search, status: $status }
    ) {
      id
      title
      address {
        city
      }
      duration {
        months
        days
      }
      startDate
      dateEnd
      customText1
      totalNotes
      totalShortListed
      totalCVSent
      totalInterviews
      totalPlacements
    }
  }
`;

const CLIENT_OPPORTUNITIES_QUERY = gql`
  query ClientOpportunities($id: Int!, $limit: Int, $skip: Int) {
    clientOpportunities(id: $id, filter: { limit: $limit, skip: $skip }) {
      _id
      id
      title
      type
      onSite
      address {
        address1
      }
      estimatedStartDate
      estimatedEndDate
      project
      context
      description
      categories
      specialties
      skills
      certifications
      expectedBillRate
      spokenLanguages
      yearsRequired
      duration {
        months
        days
      }
      status
    }
  }
`;

const SINGLE_OPPORTUNITY_QUERY = gql`
  query GetOpportunity($id: Int!) {
    getOpportunity(id: $id) {
      _id
      id
      title
      type
      onSite
      address {
        address1
        city
      }
      estimatedStartDate
      estimatedEndDate
      project
      context
      description
      categories
      specialties
      skills
      certifications
      expectedBillRate
      spokenLanguages
      yearsRequired
      duration {
        months
        weeks
        days
      }
      status
      total
    }
  }
`;

const CLIENT_OPENED_OFFERS_QUERY = gql`
  query(
    $id: Int!
    $limit: Int
    $skipVac: Int
    $skipOpp: Int
    $search: String
    $projects: [String]
    $status: [String]
    $isOpen: Boolean
  ) {
    clientVacancies(
      id: $id
      filter: {
        limit: $limit
        skip: $skipVac
        search: $search
        isOpen: $isOpen
        projects: $projects
        status: $status
      }
    ) {
      id
      title
      address {
        address1
        city
      }
      duration {
        months
        days
      }
      startDate
      dateEnd
      customText1
      totalNotes
      totalCVSent
      totalCVValidated
      total
      dateAdded
      project
      isOpen
      employmentType
      __typename
    }

    clientOpportunities(
      id: $id
      filter: {
        limit: $limit
        search: $search
        skip: $skipOpp
        excludeStatus: ["closed", "converted", "rejected"]
        projects: $projects
      }
    ) {
      _id
      title
      type
      onSite
      address {
        address1
        city
      }
      estimatedStartDate
      estimatedEndDate
      project
      context
      description
      categories
      specialties
      skills
      workRate
      certifications
      expectedBillRate
      spokenLanguages
      yearsRequired
      duration {
        months
        days
      }
      status
      dateAdded
      total
      __typename
    }
  }
`;

const CLIENT_ARCHIVED_OFFERS_QUERY = gql`
  query(
    $id: Int!
    $limit: Int
    $skipVac: Int
    $skipOpp: Int
    $search: String
    $projects: [String]
    $status: [String]
    $isOpen: Boolean
  ) {
    clientVacancies(
      id: $id
      filter: {
        limit: $limit
        skip: $skipVac
        search: $search
        isOpen: $isOpen
        projects: $projects
        status: $status
      }
    ) {
      id
      title
      address {
        address1
        city
      }
      duration {
        months
        days
      }
      startDate
      dateEnd
      customText1
      totalNotes
      totalCVSent
      totalCVValidated
      total
      dateAdded
      project
      isOpen
      employmentType
      __typename
    }

    clientOpportunities(
      id: $id
      filter: {
        limit: $limit
        search: $search
        skip: $skipOpp
        status: ["closed"]
        projects: $projects
      }
    ) {
      _id
      title
      type
      onSite
      address {
        address1
        city
      }
      estimatedStartDate
      estimatedEndDate
      project
      context
      workRate
      description
      categories
      specialties
      skills
      certifications
      expectedBillRate
      spokenLanguages
      yearsRequired
      duration {
        months
        days
      }
      status
      dateAdded
      total
      __typename
    }
  }
`;

const ACCESSIBLE_CLIENT_OPENED_OFFERS_QUERY = gql`
  query(
    $id: Int!
    $limit: Int
    $skipVac: Int
    $search: String
    $projects: [String]
    $status: [String]
    $isOpen: Boolean
  ) {
    accessibleClientVacancies(
      id: $id
      filter: {
        limit: $limit
        skip: $skipVac
        search: $search
        isOpen: $isOpen
        projects: $projects
        status: $status
      }
    ) {
      id
      title
      address {
        address1
        city
      }
      duration {
        months
        days
      }
      startDate
      dateEnd
      customText1
      totalNotes
      totalCVSent
      totalCVValidated
      total
      dateAdded
      project
      isOpen
      employmentType
      __typename
    }
  }
`;

const ACCESSIBLE_CLIENT_ARCHIVED_OFFERS_QUERY = gql`
  query(
    $id: Int!
    $limit: Int
    $skipVac: Int
    $search: String
    $projects: [String]
    $status: [String]
    $isOpen: Boolean
  ) {
    accessibleClientVacancies(
      id: $id
      filter: {
        limit: $limit
        skip: $skipVac
        search: $search
        isOpen: $isOpen
        projects: $projects
        status: $status
      }
    ) {
      id
      title
      address {
        address1
        city
      }
      duration {
        months
        days
      }
      startDate
      dateEnd
      customText1
      totalNotes
      totalCVSent
      totalCVValidated
      total
      dateAdded
      project
      isOpen
      employmentType
      __typename
    }
  }
`;

export {
  CLIENT_STATS_CSV_QUERY,
  ACCESSIBLE_CLIENT_STATS_CSV_QUERY,
  CLIENT_VACANCIES_QUERY,
  CLIENT_OPPORTUNITIES_QUERY,
  LIST_TALENT_POOL,
  TALENT_POOLS_QUERY,
  TALENT_POOL_QUERY,
  GET_TALENTS_QUERY,
  LIST_COLLABORATOR_QUERY,
  SINGLE_OPPORTUNITY_QUERY,
  CLIENT_OPENED_OFFERS_QUERY,
  CLIENT_ARCHIVED_OFFERS_QUERY,
  ACCESSIBLE_CLIENT_OPENED_OFFERS_QUERY,
  ACCESSIBLE_CLIENT_ARCHIVED_OFFERS_QUERY,
  CLIENT_RECOMMENDED_TALENTS_QUERY,
  ACCESSIBLE_CLIENT_RECOMMENDED_TALENTS_QUERY,
  LIST_POOL_LOGS_QUERY,
  LIST_COLLABORATOR_BY_POOL_QUERY
};
