import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import uniqid from "uniqid";
import { Row, Col, Button, toaster } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import SingleEducation from "./singleFormation";

import "../styles.scss";

@inject("sessionStore", "appStore")
@observer
class MyFormation extends Component {
  constructor(props) {
    super(props);
    this.onAddHandler = this.onAddHandler.bind(this);
    this.state = { saveCase: false };
    const { sessionStore } = props;
    const { changeIscandidateEducationValid } = sessionStore;
    changeIscandidateEducationValid(false);
  }

  onAddHandler() {
    const { sessionStore } = this.props;
    const { candidateEducation, changecandidateEducation } = sessionStore;
    const newcandidateEducation = candidateEducation.slice();
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    newcandidateEducation.push({
      id: "CE" + uniqid(),
      isEditing: true,
      isNew: true
    });

    changecandidateEducation(newcandidateEducation);
  }

  updateCandidateCompletedHandler = () => {
    const { i18n, appStore } = this.props;
    toaster.success({
      title: i18n._(t`Ma Formation`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
    this.setState({ saveCase: true });
    appStore.refreshLayout();
  };

  isFormValid = () => {
    const { sessionStore, i18n } = this.props;
    const { iscandidateEducationValid } = sessionStore;
    // the Education has to be saved before submitted
    if (!iscandidateEducationValid) {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Veuillez valider la formation ouverte avant l’enregistrement`
        )
      });
      return false;
    }
    return true;
  };

  turnSaveOn = () => {
    const { sessionStore } = this.props;
    const { changeSaveCaseEducation } = sessionStore;
    changeSaveCaseEducation(true);
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  render() {
    const { sessionStore } = this.props;
    const { candidateEducation, iscandidateEducationValid } = sessionStore;

    return (
      <div className="profile my-experience">
        <div className="formCard no-gutter with-padding">
          <Row>
            <Col />
            <Col>
              <div className="add-buttons">
                <Button
                  style={{ marginRight: "35px", "min-width": "221px" }}
                  disabled={!iscandidateEducationValid}
                  onClick={this.onAddHandler}
                >
                  <Trans>Ajouter une Formation</Trans>
                </Button>
              </div>
            </Col>
          </Row>

          {candidateEducation.map(Education => (
            <div>
              <SingleEducation
                {...Education}
                key={Education.id}
                showButton="true"
                onValidate={this.turnSaveOn}
              />
              <br />
            </div>
          ))}
        </div>
        <br />
      </div>
    );
  }
}

MyFormation.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    candidateEducation: PropTypes.shape({}),
    changecandidateEducation: PropTypes.shape({}),
    changeIscandidateEducationValid: PropTypes.func,
    _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iscandidateEducationValid: PropTypes.bool
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    refreshLayout: PropTypes.func
  }).isRequired
};

export default withI18n()(MyFormation);
