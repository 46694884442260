import gql from "graphql-tag";
const EXPORT_CONTRACT_PDF = gql`
  mutation exportContractPDF($envelopeId: String) {
    exportContractPDF(envelopeId: $envelopeId) {
      title
      result
    }
  }
`;
export { EXPORT_CONTRACT_PDF };
