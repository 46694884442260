import gql from "graphql-tag";

const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($_id: ID!, $siteLanguage: String) {
    updateAccount(_id: $_id, siteLanguage: $siteLanguage) {
      email
      siteLanguage
    }
  }
`;

const LOGOUT_MUTATION = gql`
  mutation Logout($token: String!) {
    logout(token: $token)
  }
`;

export { UPDATE_ACCOUNT, LOGOUT_MUTATION };
