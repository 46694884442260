import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Pagination, TalentV2 } from "cf-neo-ui";
import { Query } from "react-apollo";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react/index";
import { GET_TALENTS_QUERY } from "../dashboard-company/queries";
import classes from "./styles.module.scss";
import LoadingMissions from "../../components/LoadingMissions/LoadingMissions";
import { isOnePage } from "../../utils/helpers";
@inject("appStore", "sessionStore", "modalStore", "talentStore")
@observer
class AllTalents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: this.setPageSize(props.appStore.width),
      currentPage: 1,
      skip: 0,
      limit: this.setPageSize(props.appStore.width),
      talent: {},
      color: "blue"
    };
  }

  setPageSize = width => {
    if (width >= 1440) return 8;
    if (width >= 1024) return 8;
    if (width >= 768) return 6;
    return 2;
  };

  toCV = talent => {
    const { modalStore, talentStore } = this.props;
    const { changeIsVisible2 } = modalStore;
    talentStore.changeTalent(talent);
    talentStore.changeTalentID(talent.id);
    changeIsVisible2(true);
  };
  getAddresseToDisplay = address => {
    const { i18n } = this.props;

    if (address.city != null && address.zip != null) {
      return address.city + " " + address.zip;
    } else if (address.countryName != null && address.zip != null) {
      return address.countryName + " " + address.zip;
    } else if (address.zip != null) {
      return address.zip;
    } else {
      return `${i18n._(t`N/A`)}`;
    }
  };
  renderPagination = total => {
    const { pageSize, currentPage } = this.state;
    const paginationProps = {
      pageSize,
      total: total,
      current: currentPage,
      onChange: current => {
        this.setState({ currentPage: current, skip: (current - 1) * pageSize });
      }
    };

    return <Pagination {...paginationProps} />;
  };
  getAddresseToDisplay = address => {
    const { i18n } = this.props;

    if (address && address.city != null && address.zip != null) {
      return address.city + " " + address.zip;
    } else if (address && address.countryName != null && address.zip != null) {
      return address.countryName + " " + address.zip;
    } else if (address && address.zip != null) {
      return address.zip;
    } else {
      return `${i18n._(t`N/A`)}`;
    }
  };
  addTalent = talent => {
    const { modalStore, talentStore } = this.props;
    const { changeIsVisibleListPools } = modalStore;
    const { changeTalent } = talentStore;
    changeTalent(talent);
    changeIsVisibleListPools(true);
    this.setState({ talent: talent });
  };
  getTalentsToDisplay = Talents => {
    const { employmentType } = this.props;
    const list = [];
    for (let i = 0; i < Talents.length; i++) {
      if (Talents[i].employmentPreference) {
        const employmentPreferenceArray = Talents[i].employmentPreference.split(
          ","
        );
        for (let j = 0; j < employmentPreferenceArray.length; j++) {
          if (employmentPreferenceArray[j] === employmentType) {
            list.push(Talents[i]);
          }
        }
      } else list.push(Talents[i]);
    }
    return list;
  };
  getExperineceToDisplay = experience => {
    const { i18n } = this.props;
    if (experience != null && experience == 0) {
      return `${i18n._(t`Junior`)}(0-3)`;
    } else if (experience && experience == 1) {
      return `${i18n._(t`Junior`)}(0-3)`;
    } else if (experience && experience == 2) {
      return `${i18n._(t`Confirmé`)} (3-7)`;
    } else if (experience && experience == 3) {
      return `${i18n._(t`Senior`)} (7-10)`;
    } else if (experience == 4) {
      return `${i18n._(t`Expert`)} (10+)`;
    } else {
      return `${i18n._(t`Non assigné`)}`;
    }
  };
  render() {
    const {
      sessionStore,
      appStore,
      i18n,
      employmentType,
      search,
      order
    } = this.props;
    const { client, companyID } = sessionStore;

    let i = 0;
    const { skip, pageSize, currentPage, limit } = this.state;
    return (
      <div>
        <Query
          query={GET_TALENTS_QUERY}
          variables={{
            clientID: client._id,
            clientCompanyId: companyID,
            limit: limit,
            skip: skip,
            search: search,
            order: order,
            all: true
          }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading)
              return (
                <LoadingMissions
                  cardsCount={this.setPageSize(appStore.width)}
                  lg={3}
                  md={3}
                  sm={4}
                  xs={6}
                  colClassName={classes.favedMissionsCard}
                  containerClassName={classes.marTopTalent}
                />
              );

            if (error) {
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );
            }

            let { talents } = data;
            const Talents = talents.talents.reduce((a, b) => {
              if (a.indexOf(b) < 0) a.push(b);
              return a;
            }, []);

            if (Talents.length === 0)
              return (
                <div className={classes.talentCards}>
                  <p className={classes.text1}>
                    <Trans>
                      Il n’y a pas de talents qualifiés par nos équipes pour le
                      moment.
                    </Trans>
                  </p>
                  <p className={classes.text2}>
                    <Trans>
                      Pour recevoir des profils, soumettez une offre.
                    </Trans>
                  </p>
                </div>
              );
            let TalentsToDisplay = Talents;
            return (
              <div>
                <Container className={classes.marTopTalent}>
                  <Row>
                    {TalentsToDisplay.map(talent => {
                      i += 1;
                      return (
                        <Col
                          key={i}
                          lg={3}
                          md={3}
                          sm={4}
                          xs={12}
                          className={classes.favedMissionsCard}
                        >
                          <TalentV2
                            key={talent.talentID}
                            talent={{
                              id: talent.talentID,
                              image: talent.profilePhotoURL
                                ? talent.profilePhotoURL
                                : "/defaultAvatar.webp",
                              name: talent.firstName
                                ? talent.firstName
                                : "Talent",
                              position: i18n._(t`${talent.occupation}`),
                              recommended: talent.recommended,

                              experience: this.getExperineceToDisplay(
                                talent.experience
                              ),
                              addresse: this.getAddresseToDisplay(
                                talent.address
                              ),
                              onFave: () => {}
                            }}
                            onClick={() => this.toCV(talent)}
                            onChange={() => this.addTalent(talent)}
                            withFav={false}
                            draggable={false}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Container>
                {isOnePage(pageSize, talents.count) ? null : (
                  <div className={classes.pagination}>
                    {this.renderPagination(talents.count)}
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

AllTalents.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  employmentType: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  appStore: PropTypes.shape({
    changeWidth: PropTypes.func,
    width: PropTypes.number
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisible: PropTypes.func,
    changeIsVisible2: PropTypes.func
  }).isRequired,
  talentStore: PropTypes.shape({
    changeTalent: PropTypes.func,
    talent: PropTypes.shape
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(AllTalents));
