import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import { t, Trans } from "@lingui/macro";
import { Row, Col, FormCard, Table } from "cf-neo-ui";
import Routes from "../layout/routes/index";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";

import "./styles.scss";
import { Link } from "react-router-dom";

@inject("sessionStore", "appStore")
@observer
class TermsAndConditionsOfUse extends Component {
  handleBackRef = () => {
    const { history, sessionStore } = this.props;
    const { MissionsPage } = Routes.HbfLayoutRoutes;
    history.push(`${MissionsPage.path}/${sessionStore.backRef}`);
    sessionStore.setBackRef(null);
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  componentDidMount() {
    this.scrollToTop();
  }

  render() {
    const { i18n, appStore } = this.props;
    const meta = staticMetaTags(i18n).termsOfUse;
    const { currentLanguage } = appStore;
    return (
      <div className="session container privacy-policy">
        {metaTags(
          urls.termsOfUse(),
          meta.title,
          meta.description,
          null,
          null,
          meta.keywords
        )}
        <br />
        <Row>
          <Col className="content" lg={6} xl={6}>
            <div className="center-txt bold">
              <h1 className="privacy-policy-title">
                <Trans>
                  Conditions Générales d'Utilisation et Mentions Légales
                </Trans>
              </h1>
              <span className="text-gray-bold">
                <Trans>Dernière mise à jour : 1er février 2022</Trans>
              </span>
            </div>
            <br />
          </Col>
        </Row>

        <br />

        <FormCard className="formCard no-gutter">
          <Row className="with-padding">
            <Col className="center">
              <br />
              <div className="center-txt bold">
                <h2 className="privacy-policy-title">
                  <Trans>Conditions Générales d’Utilisation</Trans>
                </h2>
              </div>
              <br />
              <h3 className="title">
                <Trans>ARTICLE 1. Objet</Trans>
              </h3>

              <span>
                <Trans>
                  Les présentes Conditions Générales d’Utilisation (ci-après les
                  « <strong>CGU</strong> ») sont proposées par la société
                  <strong> CLUB FREELANCE LIMITED</strong>, société enregistrée
                  en Angleterre et au Pays de Galles sous le numéro 9050953,
                  dont le siège social est Biscuit Factory, 100 Drummond Rd,
                  London SE16 4DG, Royaume-Uni, son adresse de courrier
                  électronique est <a href="#">contact@mindquest.io</a> et son
                  numéro individuel d’identification TVA britannique GB19
                  4905473, (ci-après la « <strong>Société</strong> »).
                </Trans>
                <br />
                <br />
                <Trans>
                  La Société met à disposition une plateforme de mise en
                  relation entre candidats à un emploi ou une mission et
                  clients/donneurs d’ordres (la « <strong>Plateforme</strong>{" "}
                  »).
                </Trans>
                <br />
                <br />
                <span>
                  <Trans>
                    Les présentes conditions générales d'utilisation («{" "}
                    <strong>CGU</strong> ») ont pour objet d’encadrer les
                    modalités de mise à disposition et d’accès de la Plateforme
                    par les candidats à un emploi ou une mission afin d’entrer
                    en relation avec toute personne morale ou physique ayant
                    créé un compte et pouvant proposer une offre d’emploi ou une
                    mission.
                  </Trans>
                </span>
                <br />
                <br />
                <Trans>
                  Les CGU doivent être acceptées par tout Utilisateur souhaitant
                  accéder à la Plateforme. Elles constituent le contrat entre la
                  Société et l’Utilisateur. L’accès à la Plateforme par
                  l’Utilisateur signifie son acceptation des présentes
                  conditions générales d’utilisation.
                </Trans>
                <br />
                <br />
              </span>
              <br />
              <h3 className="title">
                <Trans>ARTICLE 2. Définitions</Trans>
              </h3>
              <span>
                {currentLanguage === "en" ? (
                  <div>
                    <Trans>
                      <strong>Assignment</strong> means the period during which
                      Talent renders services as a Freelancer or an employee of
                      an umbrella company.
                    </Trans>
                    <br />
                    <br />
                  </div>
                ) : null}

                <Trans>
                  <strong>Client (s)</strong> désigne(nt) toute personne morale
                  ou physique ayant créé un compte et pouvant proposer une offre
                  d’emploi ou une mission dont les Utilisateurs référencés et
                  inscrits sur la Plateforme peuvent postuler.
                </Trans>
                <br />
                <br />
                <Trans>
                  <strong> Plateforme </strong> désigne la plateforme de mise en
                  relation accessible en ligne à l’adresse{" "}
                  <Link to="/">
                    <Trans> https://mindquest.io</Trans>
                  </Link>{" "}
                  , inclut notamment le site internet précité, l’ensemble des
                  services pouvant y être proposés, logiciels applicatifs
                  protégés ou non par tout droit de propriété intellectuelle.
                </Trans>
                <br />
                <br />
                <span>
                  <Trans>
                    <strong>Talent (s)</strong> désigne(nt) toute personne
                    candidate à un emploi en CDI ou dûment inscrite à tout
                    registre du commerce et des sociétés ou auprès de toute
                    chambre des métiers ou considérée comme salarié porté ou et
                    qui souhaite proposer ses services à des Clients et être
                    ainsi référencés sur la Plateforme.
                  </Trans>
                </span>
                <br />
                <br />
                <Trans>
                  <strong>Timesheet Manager</strong> désigne l’application mise
                  en place au sein de la Plateforme par Club Freelance
                  permettant aux Clients de valider les comptes-rendus
                  d’activité remplis par le(s) Utilisateur(s) durant une
                  mission.
                </Trans>
                <br />
                <br />
                <Trans>
                  <strong>Utilisateurs</strong> désigne(nt) les Clients (s)
                  et/ou Consultant(s) référencé(s) et inscrit(s) sur la
                  Plateforme.
                </Trans>
                <br />
                <br />
              </span>

              <h3 className="title">
                <Trans>ARTICLE 3. Description de la Plateforme</Trans>
              </h3>
              <span>
                <Trans>
                  La Plateforme est une solution informatique accessible en
                  ligne depuis tout poste informatique ou téléphone
                  préalablement configuré et connecté au réseau Internet
                </Trans>
                <br />
                <br />
                <Trans>
                  La Plateforme offre aux Talents la possibilité de répondre aux
                  offres d’emploi et/ou aux missions proposées par les Clients.
                </Trans>
                <br />
                <br />
                <span>
                  <Trans>
                    A ce titre, il est également prévu que la Plateforme
                    permettre la publication pseudonymisée des profils des
                    Talents.
                  </Trans>
                </span>
                <br />
                <br />
                <Trans>
                  La Plateforme offre également aux Clients la possibilité de
                  publier des offres, de proposer des missions et de consulter
                  les profils des Talents (profils ayant été pseudonymisées au
                  préalable par la Société). Si un Client est intéressé par le
                  profil d’un Talent, il en informe la Société qui organisera la
                  mise en la relation, notamment par le biais d’un entretien.
                </Trans>
                <br />
                <br />
                <Trans>
                  En outre, la plateforme permet aux Talents en mission de faire
                  valider chaque mois leur compte-rendu d’activité par le Client
                  grâce à l’application Timesheet Manager. A ce titre, la
                  Société se réserve la possibilité de créer un identifiant
                  unique relatif à un Utilisateur, de sorte à permettre la
                  validation des comptes-rendus.
                </Trans>
                <br />
                <br />
                <Trans>
                  La Plateforme est accessible gratuitement en tout lieu et en
                  tout temps dans les conditions décrites ci-avant à tout
                  Utilisateur ayant un accès à un poste informatique permettant
                  l’accès à la Plateforme.
                </Trans>
                <br />
                <br />
                <Trans>
                  Tous les frais supportés par l'Utilisateur pour accéder au
                  service (matériel informatique, logiciels, connexion Internet,
                  etc.) sont à sa charge. La Société ne prend pas en charge ces
                  coûts d’accès.
                </Trans>
                <br />
                <br />
              </span>

              <h3 className="title">
                <Trans>
                  ARTICLE 4. Modalités d’accès et inscription à la Plateforme
                </Trans>
              </h3>
              <span>
                <Trans>
                  La Plateforme n’est accessible qu’aux Utilisateurs s’étant
                  préalablement inscrits et ayant défini un identifiant, un mot
                  de passe personnel et ayant validé leur inscription.
                </Trans>
                <br />
                <br />
                {currentLanguage === "fr" ? (
                  <div>
                    <Trans>
                      Pour pouvoir accéder aux services, l’Utilisateur doit
                      créer un compte en s’inscrivant gratuitement sur le site.
                    </Trans>
                    <br />
                    <br />
                  </div>
                ) : null}

                <span>
                  <Trans>
                    L’Utilisateur doit être âgé d’au moins 18 ans et être
                    capable juridiquement de contracter et d’utiliser le Site
                    conformément aux présentes. L’Utilisateur est tenu de
                    fournir des informations exactes qu’il s’engage à mettre à
                    jour régulièrement.
                  </Trans>
                </span>
                <br />
                <br />
                {currentLanguage === "fr" ? (
                  <div>
                    <Trans>
                      L’accès au compte Utilisateur créé est protégé par un
                      identifiant et un mot de passe choisi par l’Utilisateur
                      lors de son inscription sur le Site.
                    </Trans>
                    <br />
                    <br />
                  </div>
                ) : null}
                <Trans>
                  L’Utilisateur est seul responsable de tout usage qui pourrait
                  être fait de son identifiant et mot de passe, et seul garant
                  de leur confidentialité, ainsi que de toute utilisation de son
                  compte.
                </Trans>
                <br />
                <br />
                <Trans>
                  Dans l'hypothèse où l’Utilisateur fournirait des données
                  fausses ou inexactes, la Société sera en droit de suspendre ou
                  de procéder à la fermeture de son compte et de lui refuser
                  l'accès à tout service fourni par la Société.
                </Trans>
                <br />
                <br />
              </span>

              <h3 className="title">
                <Trans>ARTICLE 5. Obligations des Utilisateurs</Trans>
              </h3>
              <span>
                <Trans>
                  Les Utilisateurs s’engagent à faire une utilisation de la
                  Plateforme conforme à son objet et aux présentes conditions
                  générales d’utilisation.
                </Trans>
                <br />
                <br />
                <Trans>Notamment, les Utilisateurs s’interdisent:</Trans>
                <ul>
                  <li>
                    <Trans>
                      D’utiliser la Plateforme de toute manière illégale, pour
                      toute finalité illégale ou de toute manière incompatible
                      avec les CGU;
                    </Trans>
                  </li>
                  <br />
                  <li>
                    <Trans>
                      De vendre, copier, reproduire, louer, prêter, distribuer,
                      transférer ou concéder une sous-licence, tout ou partie,
                      des contenus figurant sur la Plateforme ou de décompiler,
                      désosser, désassembler, modifier, tenter de découvrir tout
                      code source ou utiliser tout logiciel activant ou
                      comprenant tout ou partie de la Plateforme;
                    </Trans>
                  </li>
                  <br />
                  <li>
                    <Trans>
                      De tenter d’obtenir l’accès non autorisé au système
                      informatique de la Plateforme ou de se livrer à toute
                      activité perturbant, diminuant la qualité ou interférant
                      avec les performances ou détériorant les fonctionnalités
                      de la Plateforme;
                    </Trans>
                  </li>
                  <br />
                  <li>
                    <Trans>
                      D’utiliser la Plateforme à des fins abusives en y
                      introduisant volontairement des virus ou tout autre
                      programme malveillant et de tenter d’accéder de manière
                      non autorisée à la Plateforme;
                    </Trans>
                  </li>
                  <br />
                  <li>
                    <Trans>
                      De porter atteinte aux droits de propriété intellectuelle
                      de la Société et au prestataire;
                    </Trans>
                  </li>
                  <br />
                  <li>
                    <Trans>
                      De dénigrer la Plateforme et / ou la Société et;
                    </Trans>
                  </li>
                  <br />
                  <li>
                    <Trans>
                      De façon générale de manquer à toutes obligations légales
                      ou réglementaires qui s’imposeraient de droit et de fait à
                      eux.
                    </Trans>
                  </li>
                </ul>
                <Trans>
                  Les Utilisateurs reconnaissent et acceptent qu’en cas de
                  non-respect des CGU, leur accès à la Plateforme pourra être
                  suspendu et/ou définitivement supprimé sans préavis ni
                  indemnité et sans préjudice de tout dommage-intérêt que la
                  Société serait en droit de demander.
                </Trans>
                <br />
                <br />
              </span>

              <h3 className="title">
                <Trans>ARTICLE 6. Responsabilité</Trans>
              </h3>
              <span>
                <Trans>
                  Chaque Utilisateur reconnait et accepte qu’il est seul
                  responsable et maître des contenus qu’il publie par le biais
                  de la Plateforme ainsi que des propos qui y sont échangés.
                </Trans>
                <br />
                <br />
                <Trans>
                  En aucun cas, la Société n’intervient pour assurer un rôle de
                  modérateur à cet égard.
                </Trans>
                <br />
                <br />
                <span>
                  <Trans>
                    Les Utilisateurs reconnaissent et acceptent qu’ils ne
                    pourront en aucun cas être indemnisés ou obtenir réparation
                    pour quelque motif que ce soit concernant tout propos ou
                    contenu échangés sur la Plateforme qu’ils en soient à
                    l’origine ou les destinataires.
                  </Trans>
                </span>
                <br />
                <br />
                <Trans>
                  Les différents litiges entre Utilisateurs devront se régler
                  entre eux hors la présence de la Société et sans utilisation
                  de la Plateforme.
                </Trans>
                <br />
                <br />
              </span>

              <h3 className="title">
                <Trans>ARTICLE 7. Relation entre Talents et Clients</Trans>
              </h3>
              <span>
                <Trans>
                  Dans le cadre des services proposés et mis à disposition par
                  la Société par le biais de la Plateforme, les Talents
                  s’engagent à ne pas démarcher les Clients directement en
                  dehors de la Plateforme et ce, afin d’éviter le recours à la
                  Société.
                </Trans>
                <br />
                <br />
                <Trans>
                  De la même manière, les Clients s’engagent à ne pas démarcher
                  les Talents directement en dehors de la Plateforme et ce, afin
                  d’éviter le recours à la Société.
                </Trans>
                <br />
                <br />
                <span>
                  <Trans>
                    En outre, les Consultants s’engagent à informer la Société
                    de tout démarchage ou sollicitation de la part de Clients
                    avec lesquels ils auraient été mis en relation dans le cadre
                    de l’utilisation de la Plateforme.
                  </Trans>
                </span>
                <br />
                <br />
                <Trans>
                  Les Utilisateurs reconnaissent et acceptent que le respect de
                  cette obligation constitue une condition essentielle sans
                  laquelle la Société ne saurait accepter de mettre à
                  disposition des Utilisateurs la Plateforme.
                </Trans>
                <br />
                <br />
                <Trans>
                  En cas de non-respect de cette obligation, la Société pourra
                  suspendre l’accès à la Plateforme pour une durée qu’elle
                  déterminera ou y mettre définitif sans préjudice de toute
                  demande de dommages-intérêts qu’elle pourrait solliciter
                  devant toute juridiction compétente.
                </Trans>
                <br />
                <br />
              </span>

              <h3 className="title">
                <Trans>ARTICLE 8. Evaluation des Utilisateurs</Trans>
              </h3>
              <span>
                <Trans>
                  La Société se réserve le droit de permettre aux Clients
                  d’effectuer des commentaires et une évaluation concernant les
                  Consultants. Cette évaluation est uniquement réalisée à des
                  fins internes et privées à la Société.
                </Trans>
                <br />
                <br />
                <Trans>
                  En aucun cas, cette évaluation ne pourra être utilisée contre
                  les Consultants.
                </Trans>
                <br />
                <br />
              </span>

              <h3 className="title">
                <Trans>
                  ARTICLE 9. Garantie relative à la mise à disposition de la
                  Plateforme
                </Trans>
              </h3>
              <span>
                <Trans>
                  La Société garantit la mise à disposition de la Plateforme
                  dans des conditions d’utilisation normales.
                </Trans>
                <br />
                <br />
                <Trans>
                  Toutefois, la Société se réserve le droit, sans préavis, ni
                  indemnité, de fermer temporairement ou définitivement la
                  Plateforme notamment pour effectuer une mise à jour, des
                  opérations de maintenance, des modifications ou changements
                  sur les méthodes opérationnelles, les serveurs et les heures
                  d’accessibilité, sans que cette liste ne soit limitative.
                </Trans>
                <br />
                <br />
                <Trans>
                  En outre, la Société garantit qu’il met en œuvre tous les
                  moyens à sa disposition pour assurer la sécurité et
                  l’intégrité de la Plateforme ainsi que des données qui y sont
                  stockées.
                </Trans>
                <br />
                <br />
                <Trans>
                  Toutefois, en cas de défaut dans la sécurité des données et de
                  la Plateforme, la Société ne saurait être tenue à aucun
                  dommage-intérêt ou réparation à l’égard de quiconque ; ce que
                  les Utilisateurs reconnaissent et acceptent.
                </Trans>
                <br />
                <br />
                <Trans>
                  Par ailleurs, la Société, en tant qu’opérateur indépendant
                  n’intervenant pas dans le cadre d’une relation entre le Client
                  et un Utilisateur, ne saurait être responsable de toute
                  difficulté pouvant survenir entre un Utilisateur et un Client
                  à ce stade de leur collaboration.
                </Trans>
                <br />
                <br />
              </span>

              <h3 className="title">
                <Trans>ARTICLE 10.Propriété intellectuelle</Trans>
              </h3>
              <span>
                <Trans>
                  L’ensemble des éléments composant la Plateforme, en ce compris
                  l’interface de la Plateforme ainsi que son arborescence, sont
                  la propriété exclusive de la Société ; ce que les Utilisateurs
                  acceptent.
                </Trans>
                <br />
                <br />
                <Trans>
                  Aux fins de permettre aux Utilisateurs d’utiliser la
                  Plateforme, la Société octroie aux Utilisateurs une licence
                  d’utilisation strictement limitée à l’objet des présentes et
                  en France uniquement.
                </Trans>
                <br />
                <br />
                <Trans>
                  En aucun cas, cette licence n’autorise les Utilisateurs à
                  reproduire, copier, dupliquer, modifier, transformer, traduire
                  les différents éléments composant la Plateforme.
                </Trans>
                <br />
                <br />
                {currentLanguage === "fr" ? (
                  <div>
                    <Trans>
                      En cas de non-respect des conditions de ladite licence
                      d’utilisation, les Utilisateurs s’exposent à des sanctions
                      et des poursuites sur le fondement de la contrefaçon
                      notamment.
                    </Trans>
                    <br />
                    <br />
                  </div>
                ) : null}

                <Trans>
                  En outre, il est précisé que les marques, logotypes, signes et
                  tout autre élément de la Plateforme appartiennent à la Société
                  et ne sauraient être librement reproduits par un Utilisateur.
                </Trans>
                <br />
                <br />
                <Trans>
                  L’Utilisateur s’engage à ne reproduire aucun des éléments de
                  la Plateforme.
                </Trans>
                <br />
                <br />
              </span>

              <h3 className="title">
                <Trans>ARTICLE 11. Assurances</Trans>
              </h3>
              <span>
                <Trans>
                  La Société invite tout Utilisateur qui souhaite proposer ses
                  services par le biais de la Plateforme à recourir aux services
                  d’assurance d’une compagnie d’assurance et y souscrire un
                  contrat permettre de garantir sa responsabilité civile
                  professionnelle.
                </Trans>
                <br />
                <br />
                <Trans>
                  A tout le moins, il est recommandé de souscrire une police
                  d’assurance couvrant les dommages qui peuvent être causés en
                  France.
                </Trans>
                <br />
                <br />
                <Trans>
                  Sans préjudice de ce qui précède, l’Utilisateur est informé
                  qu’il demeure libre de ses choix et qu’en tout état de cause,
                  la Société ne saurait assumer aucun risque en ce qui le
                  concerne.
                </Trans>
                <br />
                <br />
                <Trans>
                  La Société n’a pas souscrit de police d’assurance concernant
                  les activités des Utilisateurs utilisant la Plateforme et ce,
                  dans le cadre de leurs relations avec les Clients.
                </Trans>
                <br />
                <br />
              </span>

              <h3 className="title">
                <Trans>
                  ARTICLE 12. Liens vers d’autres sites internet et réseaux
                  sociaux
                </Trans>
              </h3>
              <span>
                <Trans>
                  La Plateforme peut occasionnellement contenir des liens vers
                  les sites Internet de nos partenaires ou de sociétés tierces.
                </Trans>
                <br />
                <br />
                <Trans>
                  Veuillez noter que ces sites internet ont leur propre
                  politique de confidentialité et que la Société décline toute
                  responsabilité quant à l’utilisation faite par ces sites des
                  informations collectées lorsque vous cliquez sur ces liens. La
                  Société vous invite à prendre connaissance de politiques de
                  confidentialité de ces sites avant de leur transmettre vos
                  données personnelles.
                </Trans>
                <br />
                <br />
              </span>

              <h3 className="title">
                <Trans>ARTICLE 13. Durée</Trans>
              </h3>
              <span>
                <Trans>
                  Les présentes CGU sont applicables à compter de leur
                  acceptation par les Utilisateurs et pendant toute la durée
                  d’utilisation de la Plateforme par l’Utilisateur.
                </Trans>
                <br />
                <br />
                {currentLanguage === "fr" ? (
                  <div>
                    <Trans>
                      Elles cessent le jour où le compte de l’Utilisateur est
                      supprimé pour quelque motif que ce soit.
                    </Trans>
                    <br />
                    <br />
                  </div>
                ) : null}
              </span>

              <h3 className="title">
                <Trans>ARTICLE 14. Résiliation, suspension</Trans>
              </h3>
              <span>
                <Trans>
                  Les Utilisateurs sont informés que toute utilisation de la
                  Plateforme et de tout service qui pourrait y être associé
                  contraire aux présentes ainsi qu’à toute réglementation
                  applicable pourra se traduire par une résiliation des
                  présentes et ce, sans préavis.
                </Trans>
                <br />
                <br />
                <Trans>
                  La Société se réserve également le droit de suspendre tout
                  accès à la Plateforme.
                </Trans>
                <br />
                <br />
                <Trans>
                  Ces mesures ne donnent pas droit à une quelconque indemnité au
                  bénéfice de l’Utilisateur.
                </Trans>
                <br />
                <br />
                <Trans>
                  Sans préjudice de ce qui précède, la Société demeure en droit
                  de solliciter des dommages intérêts.
                </Trans>
                <br />
                <br />
              </span>

              <h3 className="title">
                <Trans>ARTICLE 15. Divers</Trans>
              </h3>
              <span>
                <Trans>
                  Dans l’hypothèse où l’une des clauses des présentes serait
                  déclarée nulle et non avenue pour quelque raison que ce soit,
                  cela ne saurait en aucun cas affecter la validité et le
                  respect des autres clauses.
                </Trans>
                <br />
                <br />
                <Trans>
                  La Société se réserve la possibilité de modifier tout ou
                  partie des présentes. La Société informera ainsi les
                  Utilisateurs par la mise en ligne d’une version mise à jour
                  des présentes.
                </Trans>
                <br />
                <br />
                <Trans>
                  Dès lors que l’Utilisateur continue d’accéder et d’utiliser la
                  Plateforme, il sera réputé acceptent les conditions générales
                  ainsi modifiées.
                </Trans>
                <br />
                <br />
              </span>

              <h3 className="title">
                <Trans>
                  ARTICLE 16. Loi applicable et juridiction compétente
                </Trans>
              </h3>
              <span>
                <Trans>
                  Les présentes conditions générales d’utilisation sont soumises
                  à la législation française.
                </Trans>
                <br />
                <br />
                <Trans>
                  En cas de litige les parties feront leurs meilleurs efforts
                  pour trouver un accord amiable.
                </Trans>
                <br />
                <br />
                <Trans>
                  A défaut, elles entendent soumettre leur litige à la
                  compétence du tribunal compétent selon la législation
                  applicable.
                </Trans>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </span>
              {currentLanguage === "fr" ? (
                <div>
                  <div className="center-txt bold">
                    <h2 className="privacy-policy-title">
                      <Trans>Mentions légales</Trans>
                    </h2>
                  </div>
                  <br />
                  <h3 className="title">
                    <Trans> Editeur</Trans>
                  </h3>
                  <br />
                  <span>
                    <Trans>
                      {" "}
                      <strong>CLUB FREELANCE LIMITED</strong>, société
                      enregistrée en Angleterre et au Pays de Galles sous le
                      numéro 9050953, dont le siège social est Biscuit Factory,
                      100 Drummond road, London SE16 4DG, Royaume-Uni, ayant
                      pour numéro individuel d’identification TVA britannique
                      GB19 4905473.
                    </Trans>
                    <br />
                    <br />
                    <Trans>Téléphone : +33 652 72 16 50</Trans>
                    <br />
                    <br />
                    <Trans>
                      Adresse électronique :<a href="#">contact@mindquest.io</a>
                    </Trans>
                    <br />
                    <br />
                    <Trans>Directeur de la publication : Thomas Delfort</Trans>
                  </span>
                  <br />
                  <br />
                  <h3 className="title">
                    <Trans> Hébergeur du Site Internet</Trans>
                  </h3>
                  <br />
                  <span>
                    <Trans> Société OVH</Trans>
                    <br />
                    <br />
                    <Trans>
                      Siège social : 2 rue Kellermann - 59100 Roubaix, France
                    </Trans>
                    <br />
                    <br />
                    <Trans>Tél. +33 (0)8 99 70 17 61</Trans>
                    <br />
                    <br />
                    <Trans>
                      <a href="https://www.ovhcloud.com/fr-tn/">www.ovh.com</a>
                    </Trans>
                  </span>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              ) : null}
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

TermsAndConditionsOfUse.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    backRef: PropTypes.string,
    setBackRef: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(TermsAndConditionsOfUse);
