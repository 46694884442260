import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Tabs, Tab } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import "./styles.scss";
import Routes from "../../layout/routes/index";
import MyCompany from "./myCompany";
import MyInformation from "./myInformation";
import Params from "../../params";

class DashboardCompanyProfile extends Component {
  changeTabHandler = activeTab => {
    const { history } = this.props;
    const routes = Routes.LpbLayoutRoutes;
    if (activeTab === "1") history.push(routes.MyInformationCompany.path);
    if (activeTab === "2") history.push(routes.MyCompany.path);
    if (activeTab === "3") history.push(routes.MyParamsCompany.path);
  };

  render() {
    const { i18n, location } = this.props;
    const routes = Routes.LpbLayoutRoutes;
    let activeTab = "1";
    const currentPath = location.pathname;
    if (currentPath === routes.MyInformationCompany.path) activeTab = "1";
    if (currentPath === routes.MyCompany.path) activeTab = "2";
    if (currentPath === routes.MyParamsCompany.path) activeTab = "3";
    return (
      <Tabs defaultActiveTab={activeTab} onChange={this.changeTabHandler}>
        <Tab name="1" tab={i18n._(t`Mes informations`)}>
          <MyInformation />
        </Tab>
        <Tab name="2" tab={i18n._(t`Mon entreprise`)}>
          <MyCompany />
        </Tab>
        <Tab name="3" tab={i18n._(t`Mes paramètres`)}>
          <Params />
        </Tab>
      </Tabs>
    );
  }
}

DashboardCompanyProfile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(DashboardCompanyProfile));
