import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Mutation } from "react-apollo";
import { Col, MissionSuggestion } from "cf-neo-ui";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { SKIP_RECOMMENDED } from "../../dashboard-missions/mutations";
import FaveUnfave from "../../../components/fave-unfave";
import routes from "../../layout/routes/hbfLayoutRoutes";
import { setUrgent } from "../../../utils/helpers";
import classes from "../../dashboard-missions/styles.module.scss";
import formatMissionDuration from "../../../utils/formatMissionDuration";
import getContractType from "../../../utils/getContractType";

class List extends Component {
  constructor(props) {
    super(props);
    const { recommended } = this.props;
    this.state = {
      recommended
    };
  }

  skipHandler = (id, skipMutation) => {
    const { recommended } = this.state;
    const index = recommended.findIndex(i => i.id === id);
    recommended.splice(index, 1);
    this.setState({ recommended });
    if (!recommended.length) {
      const { fetchMore } = this.props;
      fetchMore();
    }
    if (skipMutation) skipMutation();
  };

  interested = id => {
    const { history } = this.props;
    history.push(`${routes.MissionsPage.path}/${id}`);
  };

  render() {
    const { i18n, n, xl, lg, md, sm, xs } = this.props;
    let { recommended } = this.state;
    recommended = Array.isArray(recommended) ? recommended.slice(0, n) : [];

    return recommended.map(mission => (
      <Mutation
        key={mission.id}
        mutation={SKIP_RECOMMENDED}
        onCompleted={() => {}}
        onError={() => {}}
        variables={{ id: mission.id }}
      >
        {skipFn => (
          <Col
            xl={xl}
            lg={lg}
            md={md}
            sm={sm}
            xs={xs}
            className={classes.favedMissionsCard}
          >
            <FaveUnfave missionId={mission.id}>
              <MissionSuggestion
                title={mission.title}
                location={mission.address.city}
                duration={formatMissionDuration(
                  mission.duration,
                  i18n,
                  mission.employmentType
                )}
                field={mission.customText1}
                urgent={setUrgent(mission.customText10)}
                href={`${routes.MissionsPage.path}/${mission.id}`}
                onSkip={() => this.skipHandler(mission.id, skipFn)}
                onInterested={() => this.interested(mission.id)}
                onShowDetails={() => this.interested(mission.id)}
                label={i18n._(t`Intéressé`)}
                skipLabel={i18n._(t`Non Merci`)}
                contractType={getContractType(i18n, mission.employmentType)}
              />
            </FaveUnfave>
          </Col>
        )}
      </Mutation>
    ));
  }
}

List.propTypes = {
  recommended: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.shape({}),
      customText1: PropTypes.string,
      customText10: PropTypes.string,
      duration: PropTypes.shape({}),
      employmentType: PropTypes.string,
      id: PropTypes.string,
      title: PropTypes.string,
      total: PropTypes.string
    })
  ).isRequired,
  fetchMore: PropTypes.func,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  n: PropTypes.number,
  xl: PropTypes.number,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number
};

List.defaultProps = {
  fetchMore: () => {},
  n: 3,
  xl: 3,
  lg: 3,
  md: 4,
  sm: 6,
  xs: 6
};

export default withI18n()(withRouter(List));
