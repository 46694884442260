import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { I18nProvider } from "@lingui/react";
import { BlogCardDisplaySkeleton } from "cf-neo-ui";
import classes from "./BlogCardsDisplay.module.scss";
import catalogEn from "../../../locales/en/messages";
import catalogFr from "../../../locales/fr/messages";

const catalogs = {
  en: catalogEn,
  fr: catalogFr
};
@inject("appStore")
@observer
class LoadingBlogCardDisplay extends PureComponent {
  render() {
    const { cardsNumber, appStore } = this.props;
    const { currentLanguage } = appStore;
    const res = [];
    for (let i = 0; i < cardsNumber; i += 1)
      res.push(
        <BlogCardDisplaySkeleton key={i} className={classes.blogCard} />
      );
    return (
      <I18nProvider language={currentLanguage} catalogs={catalogs}>
        <div className={classes.container}>{res}</div>
      </I18nProvider>
    );
  }
}

LoadingBlogCardDisplay.propTypes = {
  cardsNumber: PropTypes.number.isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

export default LoadingBlogCardDisplay;
