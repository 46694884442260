import cfConfig from "../../configs/club-freelance-globals";
import urls from "../../utils/urls";
import staticPagesMetaTags from "../../configs/staticPagesMetaTags";

const ldJson = i18n => [
  {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Mindquest",
    url: urls.home(),
    logo: `${urls.home()}/mq.webp`,
    founders: [
      {
        "@type": "Person",
        name: "Thomas Delfort"
      },
      {
        "@type": "Person",
        name: "Manuela Garampon"
      }
    ],
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: cfConfig.phoneNumber.fr,
        email: cfConfig.contactEmail,
        contactType: "customer service",
        availableLanguage: ["English", "Spanish", "French"]
      },
      {
        "@type": "ContactPoint",
        telephone: cfConfig.phoneNumber.uk,
        email: cfConfig.contactEmail,
        contactType: "customer service",
        availableLanguage: ["English", "Spanish", "French"]
      }
    ],
    description: staticPagesMetaTags(i18n).home.description,
    email: cfConfig.contactEmail,
    address: [
      {
        "@type": "PostalAddress",
        streetAddress: cfConfig.locations.london.addressCountry,
        addressLocality: cfConfig.locations.london.streetAddress,
        addressRegion: cfConfig.locations.london.addressRegion,
        postalCode: cfConfig.locations.london.postalCode,
        addressCountry: cfConfig.locations.london.addressCountry
      },
      {
        "@type": "PostalAddress",
        streetAddress: cfConfig.locations.paris.addressCountry,
        addressLocality: cfConfig.locations.paris.streetAddress,
        addressRegion: cfConfig.locations.paris.addressRegion,
        postalCode: cfConfig.locations.paris.postalCode,
        addressCountry: cfConfig.locations.paris.addressCountry
      },
      {
        "@type": "PostalAddress",
        streetAddress: cfConfig.locations.barcelone.addressCountry,
        addressLocality: cfConfig.locations.barcelone.streetAddress,
        addressRegion: cfConfig.locations.barcelone.addressRegion,
        postalCode: cfConfig.locations.barcelone.postalCode,
        addressCountry: cfConfig.locations.barcelone.addressCountry
      }
    ],
    foundingDate: cfConfig.foundingDate,
    globalLocationNumber: cfConfig.globalLocationNumber,
    sameAs: [
      cfConfig.socialMedia.linkedIn,
      cfConfig.socialMedia.twitter,
      cfConfig.socialMedia.facebook
    ]
  }
];

export default ldJson;
