import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Button, Col, FormCard, Row, toaster } from "cf-neo-ui";
import { withRouter } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import "./ConnectedAndSubscribedUserModal.scss";

@inject()
@observer
class ConnectedAndSubscribedUserModal extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { close } = this.props;

    return (
      <div className="already-subscribed-modal-session already-subscribed-modal-container already-subscribed-modal-selection">
        <FormCard>
          <br />
          <Row>
            <Col
              className="already-subscribed-modal-form-card-content already-subscribed-modal-center"
              style={{ textAlign: "center" }}
            >
              <Trans>
                Vous êtes déjà abonné aux newsletter Mindquest Connect
              </Trans>
            </Col>
          </Row>
          <Row>
            <Col className="already-subscribed-modal-form-card-content already-subscribed-modal-center">
              <div className="already-subscribed-modal-center">
                <Button
                  icon="chevron-right"
                  iconPosition="right"
                  onClick={() => close()}
                >
                  <Trans>j’ai compris</Trans>
                </Button>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

ConnectedAndSubscribedUserModal.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  close: PropTypes.func
};

export default withI18n()(withRouter(ConnectedAndSubscribedUserModal));
