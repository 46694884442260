import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { Icon, Button } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import routes from "../../../routes";
import classes from "./HeaderMenu.module.scss";
import theme from "../../../../../configs/theme";
import BlogCategories from "../../../../../utils/BlogCategories";
import { withI18n } from "@lingui/react";

class HeaderMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expand: props.location.pathname.split("/")[2] === "advices"
    };
  }

  toggleChildrenList = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };

  render() {
    const { onClose, i18n } = this.props;
    const { expand } = this.state;
    const blogCategoriesFN = BlogCategories(i18n);
    const blogCategories = Object.values(blogCategoriesFN);
    return (
      <div className={classes.container}>
        <header className={classes.header}>
          <NavLink to={routes.HbfLayoutRoutes.Home.path}>
            <picture>
              <picture>
                <source type="image/webp" />
                <img src="/assets/images/le_club.svg" alt="Mindquest" />
              </picture>
            </picture>
          </NavLink>
          <Icon
            className={classes.closeIcon}
            type="close"
            width={12}
            height={12}
            color="#BBBABA"
            onClick={onClose}
          />
        </header>
        <div id="header_body">
          <div className={classes.bodyItem}>
            <NavLink
              className={classes.text}
              activeClassName={classes.activeItem}
              exact
              to={routes.BlogLayoutRoutes.Blog.path}
            >
              <Trans>Accueil</Trans>
            </NavLink>
          </div>

          {blogCategories.map(category => (
            <div>
              {category.lang.includes("fr") && (
                <div className={classes.bodyItem}>
                  <NavLink
                    className={classes.text}
                    activeClassName={classes.activeItem}
                    exact
                    to={category.url}
                  >
                    <Trans>{category.title}</Trans>
                  </NavLink>
                </div>
              )}
            </div>
          ))}
        </div>
        <NavLink to={routes.BlogLayoutRoutes.Search.path}>
          <Button
            variant="tertiary"
            size="medium"
            icon="search"
            iconColor="#8F8F8F"
          >
            <Trans>Recherche</Trans>
          </Button>
        </NavLink>
      </div>
    );
  }
}

HeaderMenu.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  onClose: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};

HeaderMenu.defaultProps = {
  onClose: () => {}
};

export default withI18n()(withRouter(HeaderMenu));
