import { t } from "@lingui/macro";
import configs from "../../configs/club-freelance-globals";
import runtimeVars from "../../configs/runTimeVars";

const singleCandidateDefault = i18n => {
  return {
    avatar: "/defaultAvatar.webp",
    name: i18n._(t`inconnue`),
    email: configs.contactEmail,
    occupation: i18n._(t`Freelance`),
    address: { city: i18n._(t`Non précis`) },
    phoneNumber: configs.phoneNumber[runtimeVars.APP_LANG],
    description: i18n._(t`Contact me if you are interested !`),
    linkedIn: "https://www.linkedin.com/company/club-freelance/",
    headShot: "/defaultAvatar.webp",
    contractType: i18n._(t`Non précis`),
    employmentPreference: i18n._(t`Non précis`)
  };
};

export default singleCandidateDefault;
