/* eslint-disable no-unused-expressions,no-plusplus,no-underscore-dangle */
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { parse } from "query-string";
import { withRouter } from "react-router-dom";
import {
  Tabs,
  Tab,
  Sorter,
  ItemMenu,
  Button,
  TextInput,
  VoidOfferCard,
  Container,
  Row,
  Col,
  MissionOfferCard,
  Pagination,
  Modal,
  toaster
} from "cf-neo-ui";
import { Query } from "react-apollo";
import { withI18n } from "@lingui/react";
import { Trans, t, plural } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import gql from "graphql-tag";
import classes from "./styles.module.scss";
import Routes from "../layout/routes";
import {
  CLIENT_OPENED_OFFERS_QUERY,
  CLIENT_ARCHIVED_OFFERS_QUERY,
  ACCESSIBLE_CLIENT_ARCHIVED_OFFERS_QUERY,
  ACCESSIBLE_CLIENT_OPENED_OFFERS_QUERY
} from "../dashboard-company/queries";
import LoadingMissions from "../../components/LoadingMissions/LoadingMissions";
import getIconType from "../../utils/getIconType";
import getContractType from "../../utils/getContractType";
import { isOnePage } from "../../utils/helpers";
import PostNeedSelection from "../post-a-need/postNeedSelection";
import theme from "../../configs/theme";
import metaTags from "../../utils/editMetaData";

@inject("appStore", "offersSearchStore", "sessionStore", "postANeedStore")
@observer
class FilteredOffers extends Component {
  constructor(props) {
    super(props);
    const queryObj = parse(props.location.search);
    this.state = {
      activeTab: "allTheOffers",
      pageSize: this.setPageSize(props.appStore.width),
      cardsCount: this.setPageSize(props.appStore.width),
      currentPage: 1,
      skipVac: 0,
      skipOpp: 0,
      search: "",
      isVisible: false,
      filter: "open",
      isOpen: queryObj.status ? null : true,
      status: queryObj.status ? [queryObj.status] : null,
      projectFilter: "",
      currentPageAccessibleClientVacancies: 1,
      skipAccessibleClientVacancies: 0,
      limitAccessibleClientVacancies: this.setPageSize(props.appStore.width)
    };

    this.displayedAcc = {
      0: {
        displayedVac: 0,
        displayedOpp: 0
      }
    };
    this.total = 0;
  }

  formatMissionDuration = (duration, employmentType) => {
    const { i18n } = this.props;
    if (
      employmentType &&
      (employmentType.toLowerCase() === "permanent" ||
        employmentType.toLowerCase() === "cdi")
    )
      return i18n._(t`Permanent`);
    if (!duration.days && !duration.months) return "";
    if (duration.days < 30)
      return i18n._(
        plural({
          value: duration.days,
          one: "# jour",
          other: "# jours"
        })
      );
    return i18n._(
      plural({
        value: duration.months,
        one: "# mois",
        other: "# mois"
      })
    );
  };

  toDetails = (opportunityId, vacancyId, type, status) => {
    const { history, i18n, appStore } = this.props;
    const { changeUrl } = appStore;
    const { LpbLayoutRoutes } = Routes;
    changeUrl(LpbLayoutRoutes.Offers.path);
    if (opportunityId && type && status === "underView")
      history.push(
        `/dashboard/company/opportunities/offer-details/edit/${opportunityId}`
      );
    else if ((opportunityId && !type) || status === "rejected") {
      toaster.info({
        title: i18n._(t`Désolé`),
        description: i18n._(t`Vous ne pouvez pas modifier cette opportunité`),
        expiresIn: 3
      });
    }
    if (vacancyId) history.push(`/dashboard/company/offers/${vacancyId}`);
  };

  setPageSize = width => {
    if (width >= 1440) return 8;
    if (width >= 1024) return 6;
    if (width >= 768) return 6;
    return 2;
  };

  tabChangeHandler = tab => {
    this.setState({
      currentPage: 1,
      skipVac: 0,
      skipOpp: 0,
      search: "",
      isVisible: false,
      activeTab: tab
    });
    this.resetDisplayedAcc();
    this.total = 0;
  };

  renderPagination = length => {
    const { pageSize, currentPage } = this.state;
    const paginationProps = {
      withoutPager: true,
      pageSize,
      total: length,
      current: currentPage,
      onChange: current => {
        const { displayedOpp, displayedVac } = this.displayedAcc[current - 1];
        this.setState({
          currentPage: current,
          skipVac: displayedVac,
          skipOpp: displayedOpp
        });
      }
    };

    return <Pagination {...paginationProps} />;
  };

  renderPagination1 = length => {
    const { pageSize, currentPageAccessibleClientVacancies } = this.state;
    const paginationProps = {
      withoutPager: true,
      pageSize,
      total: length,
      current: currentPageAccessibleClientVacancies,
      onChange: current => {
        this.setState({
          currentPageAccessibleClientVacancies: current,
          skipAccessibleClientVacancies: (current - 1) * pageSize
        });
      }
    };

    return <Pagination {...paginationProps} />;
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({
      search: e.target.value,
      currentPage: 1,
      skipVac: 0,
      skipOpp: 0,
      isVisible: false
    });
    this.resetDisplayedAcc();
    this.total = 0;
  };

  handleEnterPressed = e => {
    e.preventDefault();
    this.setState({
      search: e.target.value,
      currentPage: 1,
      skipVac: 0,
      skipOpp: 0,
      isVisible: false
    });
    this.resetDisplayedAcc();
    this.total = 0;
  };

  toForm = () => {
    const { history } = this.props;
    const { Offer } = Routes.HbLayoutRoutes;
    history.push(Offer.path);
  };

  getQueryVariables = () => {
    const { idClient } = this.props;
    const {
      pageSize,
      skipOpp,
      skipVac,
      search,
      projectFilter,
      status,
      isOpen
    } = this.state;
    const clientID = Number(idClient);
    if (projectFilter !== "")
      return {
        id: clientID,
        limit: pageSize,
        skipOpp,
        skipVac,
        search,
        projects: [projectFilter],
        status,
        isOpen
      };
    return {
      id: clientID,
      limit: pageSize,
      skipOpp,
      skipVac,
      search,
      status,
      isOpen
    };
  };

  getQueryVariables2 = () => {
    const { idClient } = this.props;
    const {
      search,
      projectFilter,
      status,
      isOpen,
      skipAccessibleClientVacancies,
      limitAccessibleClientVacancies
    } = this.state;
    const clientID = Number(idClient);
    if (projectFilter !== "")
      return {
        id: clientID,
        limit: limitAccessibleClientVacancies,
        skipVac: skipAccessibleClientVacancies,
        search,
        projects: [projectFilter],
        status,
        isOpen
      };
    return {
      id: clientID,
      limit: limitAccessibleClientVacancies,
      skipVac: skipAccessibleClientVacancies,
      search,
      status,
      isOpen
    };
  };

  getStatus = (status, type) => {
    const { i18n } = this.props;
    if (type === "Opportunity") {
      if (status.toLowerCase() === "open") {
        return {
          status: "underView",
          text: i18n._(t`A l’étude`)
        };
      }
      if (status.toLowerCase() === "closed") {
        return {
          status: "rejected",
          text: i18n._(t`rejetée`)
        };
      }
    }

    if (type === "ClientVacancy") {
      if (status === false) {
        return {
          status: "closed",
          text: i18n._(t`fermée`)
        };
      }
    }

    return { status: "open", text: "ouverte" };
  };

  getOffersToDisplay = (clientVacancies, clientOpportunities) => {
    const { pageSize, currentPage, status } = this.state;

    const offersTemp = status
      ? [...clientVacancies]
      : [...clientOpportunities, ...clientVacancies];
    const offers = offersTemp
      .sort((a, b) => b.dateAdded - a.dateAdded)
      .slice(0, pageSize);

    if (!this.displayedAcc[currentPage]) {
      this.displayedAcc[currentPage] = {
        ...this.displayedAcc[currentPage - 1]
      };

      offers.reduce((acc, { __typename }) => {
        __typename === "Opportunity" ? acc.displayedOpp++ : acc.displayedVac++;
        return acc;
      }, this.displayedAcc[currentPage]);
    }

    return offers;
  };

  getOffersToDisplay2 = clientVacancies => {
    const { pageSize, currentPage, status } = this.state;

    const offersTemp = status ? [...clientVacancies] : [...clientVacancies];
    const offers = offersTemp
      .sort((a, b) => b.dateAdded - a.dateAdded)
      .slice(0, pageSize);

    if (!this.displayedAcc[currentPage]) {
      this.displayedAcc[currentPage] = {
        ...this.displayedAcc[currentPage - 1]
      };

      offers.reduce((acc, { __typename }) => {
        __typename === "ClientVacancy" && acc.displayedVac++;
        return acc;
      }, this.displayedAcc[currentPage]);
    }

    return offers;
  };

  setTotalOffers = (clientVacancies, clientOpportunities) => {
    if (this.total) return this.total;
    const opp = clientOpportunities[0];
    const vac = clientVacancies[0];
    if (opp) this.total += opp.total;
    if (vac) this.total += vac.total;
    return this.total;
  };

  setTotalOffers2 = clientVacancies => {
    if (this.total) return this.total;
    const vac = clientVacancies[0];

    if (vac) this.total += vac.total;
    return this.total;
  };

  getOpportunities = () => {
    const { i18n } = this.props;
    const { pageSize, filter, search, cardsCount, projectFilter } = this.state;
    let QUERY;
    if (filter === "archive") QUERY = CLIENT_ARCHIVED_OFFERS_QUERY;
    if (filter === "open") QUERY = CLIENT_OPENED_OFFERS_QUERY;
    return (
      <Query
        query={QUERY}
        variables={this.getQueryVariables()}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <LoadingMissions
                cardsCount={cardsCount}
                xl={3}
                lg={3}
                md={4}
                sm={6}
                xs={6}
                colClassName={classes.favedMissionsCard}
                containerClassName={classes.marTop}
              />
            );

          if (error) {
            return (
              <div className="error_container">
                <span>
                  {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                </span>
                <img
                  style={{
                    margin: 20
                  }}
                  src={"/assets/svg/download.svg"}
                  alt="error"
                />
              </div>
            );
          }
          const { clientVacancies, clientOpportunities } = data;
          const offers = this.getOffersToDisplay(
            clientVacancies,
            clientOpportunities
          );
          const totalOffers = this.setTotalOffers(
            clientVacancies,
            clientOpportunities
          );

          if (!totalOffers) {
            if (search === "" && projectFilter === "")
              return (
                <div className={classes.missionCards}>
                  <div style={{ width: "220px", padding: "20px" }}>
                    <VoidOfferCard onClick={this.clickHandler} />
                    <div style={{ marginTop: "20px" }}>
                      <Trans>Poster une offre</Trans>
                    </div>
                  </div>
                </div>
              );
            return (
              <p className={classes.missionCards}>
                <Trans>Désolé, aucun résultat trouvé.</Trans>
              </p>
            );
          }

          return (
            <div>
              <Container className={classes.marTop}>
                <Row>
                  {offers.map(offer => {
                    let offerStatus = { status: "open", text: "ouverte" };

                    offerStatus = this.getStatus(
                      offer.status || offer.isOpen,
                      offer.__typename
                    );

                    return (
                      <Col
                        key={offer.id || offer._id}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={6}
                        className={classes.favedMissionsCard}
                      >
                        <MissionOfferCard
                          preferred
                          title={offer.title}
                          location={
                            offer.address.city || offer.address.address1
                          }
                          duration={this.formatMissionDuration(
                            offer.duration,
                            offer.employmentType || offer.type
                          )}
                          projectName={offer.project}
                          NumberOfCandidates={offer.totalCVValidated}
                          field={offer.customText1 || offer.workRate || "N/A"}
                          contractType={getContractType(
                            i18n,
                            offer.employmentType || offer.type || ""
                          )}
                          iconType={getIconType(
                            offer.employmentType || offer.type || ""
                          )}
                          onPrefer={() => {}}
                          onShowDetails={() => {}}
                          onClick={() =>
                            this.toDetails(
                              offer._id,
                              offer.id,
                              offer.type,
                              offerStatus.status
                            )
                          }
                          href="https://www.club-freelance.com/fr/"
                          label={i18n._(t`candidat`)}
                          onInterested={() => {}}
                          onSkip={() => {}}
                          avatarsNumber={offer.totalCVValidated}
                          avatarTable={[
                            { src: "/defaultAvatar.webp" },
                            { src: "/defaultAvatar.webp" }
                          ]}
                          cardState={offerStatus.status}
                          cardStateText={offerStatus.text}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Container>
              {isOnePage(pageSize, totalOffers) ? null : (
                <div className={classes.pagination}>
                  {this.renderPagination(totalOffers)}
                </div>
              )}
            </div>
          );
        }}
      </Query>
    );
  };

  getAccessibleOpportunities = () => {
    const { i18n } = this.props;
    const { pageSize, filter, search, cardsCount, projectFilter } = this.state;
    let QUERY;
    if (filter === "archive") QUERY = ACCESSIBLE_CLIENT_ARCHIVED_OFFERS_QUERY;
    if (filter === "open") QUERY = ACCESSIBLE_CLIENT_OPENED_OFFERS_QUERY;
    return (
      <Query
        query={QUERY}
        variables={this.getQueryVariables2()}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <LoadingMissions
                cardsCount={cardsCount}
                xl={3}
                lg={3}
                md={4}
                sm={6}
                xs={6}
                colClassName={classes.favedMissionsCard}
                containerClassName={classes.marTop}
              />
            );

          if (error) {
            return (
              <div className="error_container">
                <span>
                  {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                </span>
                <img
                  style={{
                    margin: 20
                  }}
                  src={"/assets/svg/download.svg"}
                  alt="error"
                />
              </div>
            );
          }
          const { accessibleClientVacancies } = data;
          const offers = this.getOffersToDisplay2(accessibleClientVacancies);
          const totalOffers = this.setTotalOffers2(accessibleClientVacancies);
          if (!totalOffers) {
            if (search === "" && projectFilter === "")
              return (
                <div className={classes.missionCards}>
                  <div style={{ width: "220px", padding: "20px" }}>
                    <VoidOfferCard onClick={this.clickHandler} />
                    <div style={{ marginTop: "20px" }}>
                      <Trans>Poster une offre</Trans>
                    </div>
                  </div>
                </div>
              );
            return (
              <p className={classes.missionCards}>
                <Trans>Désolé, aucun résultat trouvé.</Trans>
              </p>
            );
          }

          return (
            <div>
              <Container className={classes.marTop}>
                <Row>
                  {offers.map(offer => {
                    let offerStatus = { status: "open", text: "ouverte" };

                    offerStatus = this.getStatus(
                      offer.status || offer.isOpen,
                      offer.__typename
                    );

                    return (
                      <Col
                        key={offer.id || offer._id}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={6}
                        className={classes.favedMissionsCard}
                      >
                        <MissionOfferCard
                          preferred
                          title={offer.title}
                          location={
                            offer.address.city || offer.address.address1
                          }
                          duration={this.formatMissionDuration(
                            offer.duration,
                            offer.employmentType || offer.type
                          )}
                          projectName={offer.project}
                          NumberOfCandidates={offer.totalCVValidated}
                          field={offer.customText1 || offer.workRate || "N/A"}
                          contractType={getContractType(
                            i18n,
                            offer.employmentType || offer.type || ""
                          )}
                          iconType={getIconType(
                            offer.employmentType || offer.type || ""
                          )}
                          onPrefer={() => {}}
                          /* onShowDetails={() =>
                            this.toDetails(
                              offer._id,
                              offer.id,
                              offer.type,
                              offerStatus.status
                            )
                          } */
                          onShowDetails={() => {}}
                          onClick={() =>
                            this.toDetails(
                              offer._id,
                              offer.id,
                              offer.type,
                              offerStatus.status
                            )
                          }
                          href="https://www.club-freelance.com/fr/"
                          label={i18n._(t`candidat`)}
                          onInterested={() => {}}
                          onSkip={() => {}}
                          avatarsNumber={offer.totalCVValidated}
                          avatarTable={[
                            { src: "/defaultAvatar.webp" },
                            { src: "/defaultAvatar.webp" }
                          ]}
                          cardState={offerStatus.status}
                          cardStateText={offerStatus.text}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Container>
              {isOnePage(pageSize, totalOffers) ? null : (
                <div className={classes.pagination}>
                  {this.renderPagination1(totalOffers)}
                </div>
              )}
            </div>
          );
        }}
      </Query>
    );
  };

  filterByProject = value => {
    this.setState({
      currentPage: 1,
      skipVac: 0,
      skipOpp: 0,
      isVisible: false,
      projectFilter: value
    });
    this.resetDisplayedAcc();
    this.total = 0;
  };

  clickHandler = () => {
    this.props.postANeedStore.reset();
    this.setState({ isVisible: true });
  };

  getArchivedOffers = () => {
    const { appStore } = this.props;
    this.setState({
      filter: "archive",
      currentPage: 1,
      skipVac: 0,
      skipOpp: 0,
      search: "",
      isVisible: false,
      isOpen: false,
      currentPageAccessibleClientVacancies: 1,
      skipAccessibleClientVacancies: 0,
      limitAccessibleClientVacancies: this.setPageSize(appStore.width)
    });
    this.resetDisplayedAcc();
    this.total = 0;
  };

  getOpenedOffers = () => {
    const { appStore } = this.props;
    this.setState({
      filter: "open",
      currentPage: 1,
      skipVac: 0,
      skipOpp: 0,
      search: "",
      isVisible: false,
      isOpen: true,
      currentPageAccessibleClientVacancies: 1,
      skipAccessibleClientVacancies: 0,
      limitAccessibleClientVacancies: this.setPageSize(appStore.width)
    });
    this.resetDisplayedAcc();
    this.total = 0;
  };

  generateQuery = () => {
    const { idClient } = this.props;
    const id = Number.parseInt(idClient, 10);
    return gql`
      {
        clientProjects(id: ${id})
      }
    `;
  };

  generateQuery2 = () => {
    const { idClient } = this.props;
    const id = Number.parseInt(idClient, 10);
    return gql`
      {
        accessibleClientProjects(id: ${id})
      }
    `;
  };

  resetDisplayedAcc = () => {
    this.displayedAcc = {
      0: {
        displayedVac: 0,
        displayedOpp: 0
      }
    };
  };

  render() {
    const { i18n } = this.props;
    const { activeTab, isVisible, filter } = this.state;
    let myOffers = i18n._(t`Mes offres en cours`);
    if (filter === "archive") myOffers = i18n._(t`Mes offres archivées`);
    let allTheOffers = i18n._(t`Toutes les offres en cours`);
    if (filter === "archive")
      allTheOffers = i18n._(t`Toutes les offres archivées`);

    var url = "";
    if (typeof window !== "undefined") {
      url = window.location.href;
    }

    return (
      <div className={classes.content}>
        {metaTags(url, null, null, null, null, null, null, null, false)}
        <Modal
          centred
          isVisible={isVisible}
          withCloseButton={false}
          onClose={() => this.setState({ isVisible: false })}
          position="fixed"
        >
          <PostNeedSelection />
        </Modal>
        <div className={classes.pageHeader}>
          <div className={classes.sorterWithMenu}>
            <Sorter
              text={i18n._(t`Offres`)}
              menu
              positionMenu="left"
              onChange={() => {}}
              chevronSize={14}
              chevronColor="#000000"
              classNameText="font-style: normal;
                             font-weight: bold;
                             font-size: 36px;
                             line-height: 35px;
                             color: #000000;"
              underline
            >
              <ItemMenu
                className={classes.item}
                label={i18n._(t`Offres en cours`)}
                value={1}
                onClick={this.getOpenedOffers}
              />
              <ItemMenu
                className={classes.item}
                label={i18n._(t`Offres archivées`)}
                value={2}
                onClick={this.getArchivedOffers}
              />
            </Sorter>
          </div>

          <div className={classes.buttonCreateOffer}>
            <Button
              variant="tertiary"
              className={classes.buttonStyle}
              size="small"
              onClick={this.clickHandler}
            >
              <Trans>Créer une offre</Trans>
            </Button>
          </div>
          <div className={classes.searchBar}>
            <TextInput
              id="mi_sb"
              placeholder={i18n._(t`Chercher une offre...`)}
              onEnterPressed={e => this.handleEnterPressed(e)}
              size="small"
              onChange={e => this.handleChange(e)}
              icon="search"
              clickableIcon={false}
              className={classes.search}
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
              radius={10}
            />
          </div>
        </div>
        <div className={classes.pageBody}>
          <div className={classes.tab}>
            <Tabs
              className={classes.dashboardOffers}
              defaultActiveTab="allTheOffers"
              activeTab={activeTab}
              type="transparentV2"
              onChange={this.tabChangeHandler}
            >
              <Tab name="myOffers" tab={myOffers}>
                {this.getOpportunities()}
              </Tab>
              <Tab name="allTheOffers" tab={allTheOffers}>
                {this.getAccessibleOpportunities()}
              </Tab>
            </Tabs>
          </div>
          <div className={classes.filter}>
            {activeTab === "myOffers" ? (
              <Query query={this.generateQuery()} fetchPolicy="network-only">
                {({ loading, error, data }) => {
                  if (loading) return null;
                  // if (error) return `${i18n._(t`Erreur`)} !${error.message}`;
                  if (error) return "";
                  const { clientProjects } = data;
                  return (
                    <Sorter
                      text={i18n._(t`Filtrer par projet`)}
                      menu
                      positionMenu="right"
                      onChange={() => {}}
                      withScroll
                    >
                      {clientProjects.length > 0 ? (
                        <ItemMenu
                          className={classes.item}
                          label={i18n._(t`Annuler le filtrage par projet`)}
                          value=""
                          onClick={() => this.filterByProject("")}
                        />
                      ) : (
                        <ItemMenu
                          className={classes.item}
                          label={i18n._(
                            t`Il n’y a pas de projets pour les filtrer`
                          )}
                          value=""
                          onClick={() => {}}
                        />
                      )}
                      {clientProjects.map(project => {
                        return (
                          <ItemMenu
                            className={classes.item}
                            label={project}
                            value={project}
                            onClick={() => this.filterByProject(project)}
                          />
                        );
                      })}
                    </Sorter>
                  );
                }}
              </Query>
            ) : (
              <Query query={this.generateQuery2()} fetchPolicy="network-only">
                {({ loading, error, data }) => {
                  if (loading) return null;
                  // if (error) return `${i18n._(t`Erreur`)} !${error.message}`;
                  if (error) return "";
                  const { accessibleClientProjects } = data;
                  return (
                    <Sorter
                      text={i18n._(t`Filtrer par projet`)}
                      menu
                      positionMenu="right"
                      onChange={() => {}}
                      withScroll
                    >
                      {accessibleClientProjects.length > 0 ? (
                        <ItemMenu
                          className={classes.item}
                          label={i18n._(t`Annuler le filtrage par projet`)}
                          value=""
                          onClick={() => this.filterByProject("")}
                        />
                      ) : (
                        <ItemMenu
                          className={classes.item}
                          label={i18n._(
                            t`Il n’y a pas de projets pour les filtrer`
                          )}
                          value=""
                          onClick={() => {}}
                        />
                      )}
                      {accessibleClientProjects.map(project => {
                        return (
                          <ItemMenu
                            className={classes.item}
                            label={project}
                            value={project}
                            onClick={() => this.filterByProject(project)}
                          />
                        );
                      })}
                    </Sorter>
                  );
                }}
              </Query>
            )}
          </div>
        </div>
      </div>
    );
  }
}

FilteredOffers.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    changeUrl: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  idClient: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  skip: PropTypes.number.isRequired,
  offersSearchStore: PropTypes.shape({
    searchSubmit: PropTypes.func,
    search: PropTypes.string
  }).isRequired
};

// noinspection JSAnnotator
export default withI18n()(withRouter(FilteredOffers));
