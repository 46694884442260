import gql from "graphql-tag";

const USER_PROFILE_STRENGTH = gql`
  query UserProfileStrenght {
    candidate {
      id
      profileStrength {
        score
        missing
        status
        resumeGeneration
        firstTimeResumeGeneration
        resumeGenerationIcon
      }
    }
  }
`;
const USER_RESUME_GENERATION_REQUIREMENTS = gql`
  query UserResumeGenerationRequirements {
    candidate {
      resumeGenerationRequirements {
        missing
        status
      }
    }
  }
`;

const CLIENT_PROFILE_STRENGTH = gql`
  query UserProfileStrenght {
    client {
      profileStrength {
        score
        missing
        status
      }
    }
  }
`;

const UPDATE_CANDIDATE_MUTATION = gql`
  mutation UpdateCandidateMutation($_id: ID!, $availability: Float) {
    updateCandidate(input: { _id: $_id, availability: $availability }) {
      account {
        id
        name
      }
      candidate {
        dateAvailable
      }
    }
  }
`;

export {
  USER_PROFILE_STRENGTH,
  UPDATE_CANDIDATE_MUTATION,
  CLIENT_PROFILE_STRENGTH,
  USER_RESUME_GENERATION_REQUIREMENTS
};
