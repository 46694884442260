import gql from "graphql-tag";
export const GET_CONTRACTS_QUERY = gql`
  query contracts($limit: Int, $skip: Int, $filter: ContractFilterInput) {
    contracts(limit: $limit, skip: $skip, filter: $filter) {
      contracts {
        id
        placementId
        title
        companyName
        dateBegin
        name
        dateBegin
        status
        envelopId
      }
      total
    }
  }
`;
