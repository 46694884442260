import SignUp from "../../session/signupSelection";
import Login from "../../session/login";

import MyInformationClient from "../../session/myInformationClient";
import MyCompany from "../../session/myCompany";
import Confirm from "../../session/confirm";
import ConfirmCompany from "../../session/confirmCompany";
import ResetPassword from "../../session/resetPassword";
import Contact from "../../contact/contact";
import PrivacyPolicy from "../../session/privacyPolicy";
import TermsAndConditionsOfUse from "../../session/termsAndConditionsOfUse";
import CalledByAdvisor from "../../session/CalledByAdvisor";
import ConfirmForBeCalledByAdvisorPath from "../../session/confirmForBeCalledByAdvisorPath";
import PendingPage from "../../pending-page/PendingPage";
import CandidateToClient from "../../candidate-to-client/CandidateToClient";
import PostNeedSelection from "../../post-a-need/postNeedSelection";
import MyNeed from "../../post-a-need/myNeed";
import Offer from "../../post-a-need/theOffer";
import Offer2 from "../../post-a-need/theOffer_2";
import DesiredProfile from "../../post-a-need/desiredProfile";
import CreateOfferThanks from "../../post-a-need/createOfferThanks";
import AccountCreation from "../../session/accountCreation";
import SiteMap from "../../session/siteMap";
/** !!! For any modification in this file, please re-do it in src/utils/urls.js */

const HbLayoutRoutes = {
  Login: { path: "/login", component: Login, logoutRequired: true },
  SignUp: { path: "/signup", component: SignUp, logoutRequired: true },

  AccountCreation: {
    path: "/signup-create-account",
    component: AccountCreation,
    logoutRequired: true
  },
  MyCompany: {
    path: "/signup-my-company",
    component: MyCompany,
    logoutRequired: true
  },
  MyInformationClient: {
    path: "/signup-my-information-client",
    component: MyInformationClient,
    logoutRequired: true
  },
  CalledByAdvisor: {
    path: "/called-by-advisor",
    component: CalledByAdvisor,
    logoutRequired: true
  },
  Confirm: { path: "/signup-thanks", component: Confirm, loginRequired: true },
  ConfirmCompany: {
    path: "/signup-company-thanks",
    component: ConfirmCompany,
    loginRequired: true
  },
  ConfirmCalledByAdvisor: {
    path: "/called-by-advisor-thanks",
    component: ConfirmForBeCalledByAdvisorPath,
    loginRequired: true
  },
  ResetPassword: {
    path: "/reset-password",
    component: ResetPassword,
    logoutRequired: true
  },
  contact: { path: "/contact", component: Contact },
  PrivacyPolicy: { path: "/privacy-policy", component: PrivacyPolicy },
  TermsAndConditionsOfUse: {
    path: "/terms-and-conditions-of-use",
    component: TermsAndConditionsOfUse
  },
  SiteMap: { path: "/site-map", component: SiteMap },
  Pending: { path: "/pending", component: PendingPage },
  UserRoleNotification: {
    path: "/change-account",
    component: CandidateToClient
  },
  MyNeed: {
    path: "/post-a-need/via-file",
    component: MyNeed,
    logoutRequired: false,
    type: true
  },
  Offer: {
    path: "/post-a-need/via-form/step-1",
    component: Offer,
    logoutRequired: false,
    type: true
  },
  Offer2: {
    path: "/post-a-need/via-form/step-2",
    component: Offer2,
    logoutRequired: false,
    type: true
  },
  DesiredProfile: {
    path: "/post-a-need/via-form/step-3",
    component: DesiredProfile,
    logoutRequired: false,
    type: true
  },
  ConfirmPostANeed: {
    path: "/post-a-need/success",
    component: CreateOfferThanks,
    logoutRequired: false,
    type: true
  }
};

export default HbLayoutRoutes;
