import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import gql from "graphql-tag";
import { Col, Icon, Row, Spinner, toaster } from "cf-neo-ui";
import { Mutation, Query } from "react-apollo";
import Routes from "../../../../../layout/routes";
import theme from "../../../../../../configs/theme";
import classes from "../Resumes.module.scss";
import runtimeVars from "../../../../../../configs/runTimeVars";

const CANDIDATE_FAVED_MODELS_QUERY = gql`
  query CandidateFavedModels {
    candidateFavedModels {
      resumeModel
    }
  }
`;

const FAV_RESUME_MODEL = gql`
  mutation favResumeModelMutation($resumeModel: String!) {
    favResumeModel(resumeModel: $resumeModel) {
      _id
      candidateId
      resumeModel
    }
  }
`;
const UNFAV_RESUME_MODEL = gql`
  mutation unFavResumeModelMutation($resumeModel: String!) {
    unFavResumeModel(resumeModel: $resumeModel) {
      _id
      candidateId
      resumeModel
    }
  }
`;
const GENERATE_RESUME_MUTATION = gql`
  mutation generateCandidateResumeMutation(
    $_id: ID!
    $source: String
    $siteLanguage: String
    $resumeModel: String
  ) {
    generateCandidateResume(
      input: {
        _id: $_id
        source: $source
        siteLanguage: $siteLanguage
        resumeModel: $resumeModel
      }
    ) {
      resume
      format
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class ResumeGenerationButtons extends Component {
  queue = [];

  constructor(props) {
    super(props);
    this.state = {
      display: true
    };
  }

  likeModel = mutation => {
    if (mutation) this.queue.push(mutation);

    /* mutate only when the queue is empty */
    if (this.queue.length === 1) {
      this.favorite(mutation);
    }

    if (this.queue.length > 2) {
      this.queue.splice(
        1,
        (this.queue.length - 1) % 2
          ? this.queue.length - 2
          : this.queue.length - 1
      );
    }
  };

  mutationCompleted = () => {
    this.queue.shift();
    this.likeModel();
  };

  favorite = mutation => {
    const { history, sessionStore } = this.props;
    if (sessionStore.authToken)
      mutation({ variables: { resumeModel: "Modèle Freelance classique" } });
    else history.push(Routes.HbLayoutRoutes.Login.path);
  };

  isFaved = (favedModels, selectedModel) => {
    selectedModel = "Modèle Freelance classique";
    const arr = favedModels.filter(
      model => model.resumeModel === selectedModel
    );
    return !!arr.length;
  };

  displayFaveButton = () => {
    const { sessionStore, i18n } = this.props;
    if (sessionStore.authToken && sessionStore.signUpUserRole === "CLIENT")
      return null;
    if (sessionStore.authToken)
      return (
        <Query query={CANDIDATE_FAVED_MODELS_QUERY}>
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error) return null;

            const { candidateFavedModels } = data;
            return (
              <Mutation
                mutation={FAV_RESUME_MODEL}
                onCompleted={this.mutationCompleted}
                onError={() =>
                  toaster.error({
                    title: "Erreur",
                    description: i18n._(
                      t`Erreur côté de serveur. Essayez plus tard`
                    )
                  })
                }
                refetchQueries={[{ query: CANDIDATE_FAVED_MODELS_QUERY }]}
              >
                {favModel => (
                  <Mutation
                    mutation={UNFAV_RESUME_MODEL}
                    onCompleted={this.mutationCompleted}
                    onError={() =>
                      toaster.error({
                        title: "Erreur",
                        description: i18n._(
                          t`Erreur côté de serveur. Essayez plus tard`
                        )
                      })
                    }
                    refetchQueries={[{ query: CANDIDATE_FAVED_MODELS_QUERY }]}
                  >
                    {unFavModel => (
                      <div className={classes.iconText}>
                        <Icon
                          type="heart"
                          color={theme.colors.mainColor}
                          color2={theme.colors.lightMainColor}
                          filled={this.isFaved(candidateFavedModels)}
                          width={16}
                          height={15}
                          onClick={() => {
                            this.isFaved(candidateFavedModels)
                              ? this.likeModel(unFavModel)
                              : this.likeModel(favModel);
                          }}
                        />
                        <span
                          onClick={() => {
                            this.isFaved(candidateFavedModels)
                              ? this.favorite(unFavModel)
                              : this.favorite(favModel);
                          }}
                        >
                          {this.isFaved(candidateFavedModels) ? (
                            <Trans>Enlever de favoris</Trans>
                          ) : (
                            <Trans>Enregistrer en favoris</Trans>
                          )}
                        </span>
                        <picture className="tooltip2">
                          <img
                            src="/assets/images/tooltip.webp"
                            alt="tooltip"
                          />
                          <div className="right">
                            <Trans>
                              Votre CV favori sera accessible directement depuis
                              votre Dashboard
                            </Trans>
                          </div>
                          <i />
                        </picture>
                      </div>
                    )}
                  </Mutation>
                )}
              </Mutation>
            );
          }}
        </Query>
      );
    return (
      <div className={classes.iconText}>
        <span onClick={this.favorite}>
          <Trans>Mettre en favori</Trans>
        </span>
        <Icon
          type="heart"
          color={theme.colors.mainColor}
          color2={theme.colors.lightMainColor}
          width={16}
          height={15}
          onClick={this.favorite}
        />
      </div>
    );
  };

  urlAccess = url => {
    window.open(url, "_blank");
  };

  render() {
    const { i18n, sessionStore, appStore } = this.props;
    const BHId = sessionStore.candidate.id;
    const candidateName = sessionStore.candidate.name;
    const slugedName = sessionStore.candidate.name
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    const link = slugedName + "-" + BHId.toString();

    const { currentLanguage } = appStore;
    const siteLanguage = currentLanguage;
    const { _id } = sessionStore;
    const { APP_WEBSITE } = runtimeVars;
    const source = APP_WEBSITE;
    const resumeModel = "1";
    return (
      <>
        {sessionStore.display && (
          <Mutation
            mutation={GENERATE_RESUME_MUTATION}
            variables={{
              _id,
              source,
              siteLanguage,
              resumeModel
            }}
            onCompleted={data => {
              const a = document.createElement("a");
              a.href =
                "data:application/octet-stream;base64," +
                encodeURI(data.generateCandidateResume.resume); //Image Base64 Goes here
              a.download =
                `${candidateName}-CV-Mindquest.` +
                data.generateCandidateResume.format;
              a.click();
            }}
            onError={errors => {
              errors.graphQLErrors.forEach(({ message }) => {
                if (message) {
                  this.onErrorHandler(message);
                }
              });
            }}
          >
            {(mutation, { loading }) => (
              <span>
                {loading ? (
                  <img
                    src="/assets/images/ResumeLoading.gif"
                    alt="donwload resume"
                    className={classes.loadImg}
                  />
                ) : (
                  <>
                    <Row>
                      <Col lg={1} xl={1} />
                      <Col lg={10} xl={10}>
                        <Row>
                          <Col lg={4} xl={4}>
                            <div className={classes.iconText}>
                              <Icon
                                type="download"
                                color={theme.colors.mainColor}
                                color2={theme.colors.lightMainColor}
                                width={16}
                                height={15}
                                onClick={() => mutation()}
                              />
                              <span onClick={() => mutation()}>
                                <Trans>Télécharger ce modéle</Trans>
                              </span>
                            </div>
                          </Col>
                          <br />
                          <br />
                          <br />
                          <Col lg={4} xl={4}>
                            <div className={classes.iconText}>
                              <Icon
                                type="link"
                                color={theme.colors.mainColor}
                                color2={theme.colors.lightMainColor}
                                width={16}
                                height={15}
                                onClick={() => {
                                  this.urlAccess(`/candidates/${link}`);
                                }}
                              />
                              <span
                                onClick={() => {
                                  this.urlAccess(`/candidates/${link}`);
                                }}
                              >
                                <Trans>Obtenir un lien partageable</Trans>
                              </span>
                            </div>
                          </Col>
                          <br />
                          <br />
                          <br />
                          <Col lg={4} xl={4}>
                            {this.displayFaveButton()}
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={1} xl={1} />
                    </Row>
                  </>
                )}
              </span>
            )}
          </Mutation>
        )}
      </>
    );
  }
}

ResumeGenerationButtons.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeCv: PropTypes.func,
    isAuthenticatedByLinkedin: PropTypes.bool,
    _id: PropTypes.string,
    email: PropTypes.string,
    resumeModel: PropTypes.string,
    pictureUrl: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.number,
    address: PropTypes.string,
    searchAddress: PropTypes.string,
    candidateWorkHistory: PropTypes.shape({}),
    signUpUserRole: PropTypes.string,
    cv: PropTypes.shape({
      name: PropTypes.string
    }),
    authToken: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
    action: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    refreshLayout: PropTypes.func,
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(ResumeGenerationButtons);
