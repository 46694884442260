import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import {
  Button,
  Col,
  FormCard,
  FormGroup,
  Row,
  Icon,
  toaster
} from "cf-neo-ui";
import { withRouter } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import theme from "../../../../../configs/theme";
import classes from "../../../../../components/alert-modal/alertModal.module.scss";

@inject("sessionStore", "appStore", "modalStore", "candidatesSearchStore")
@observer
class SingleCandidateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDescriptionValid: true,
      descriptionValidationMessage: ""
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  mutationCompleted = () => {
    const { i18n, close, candidateBhId, candidatesSearchStore } = this.props;
    const { changeDescription } = candidatesSearchStore;
    close();
    changeDescription("");
    toaster.success({
      title: i18n._(t`Email envoyé avec succées`),
      description:
        i18n._(t`Vous pouvez maintenant suivre le candidat`) + candidateBhId
    });
  };
  onErrorHandlerupdate = msg => {
    const { i18n } = this.props;
    if (
      msg ===
      'Unexpected error value: "Vous avez dépasser le nombre limité de vivier"'
    ) {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(t`Vous avez dépasser le nombre limité de vivier`)
      });
    } else {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Une erreur est survenue, veuillez réessayer ultérieurement`
        )
      });
    }
  };

  render() {
    const { history, close, operation } = this.props;
    return (
      <div className="single-candidate-page-modal-session single-candidate-page-modal-container single-candidate-page-modal-signup-selection">
        <div className={classes.header}>
          <div className={classes.headerIcon}>
            <Icon
              size="small"
              type="user"
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
          </div>
          <div style={{ marginLeft: "45px" }}>
            {" "}
            <Trans>Mes viviers de talents</Trans>{" "}
          </div>
        </div>
        <FormCard style={{ borderRadius: "0px" }}>
          <Row>
            <Col
              className="single-candidate-page-modal-form-card-content single-candidate-page-modal-center"
              style={{ textAlign: "center" }}
            >
              {operation === "supprimer" ? (
                <Trans>
                  Ce consultant a été supprimé de votre vivier "Mes recherches
                  de Talents IT".
                </Trans>
              ) : (
                <Trans>
                  Ce consultant a été ajouté à votre vivier "Mes recherches de
                  Talents IT".
                </Trans>
              )}

              <br />
              <Trans>
                Consultez et gérez vos viviers de talents au sein de la rubrique
                "Talents" de votre Dashbord.
              </Trans>
              <br />
            </Col>
          </Row>
          <Row className="single-candidate-page-modal-center"></Row>
          <Row>
            <Col className="single-candidate-page-modal-form-card-content single-candidate-page-modal-center">
              <div className="single-candidate-page-modal-center">
                <Col>
                  <FormGroup>
                    <Button
                      onClick={() =>
                        history.push(
                          `/dashboard/company/talents?active=TalentPool`
                        )
                      }
                    >
                      <Trans>Accéder à mes viviers</Trans>
                    </Button>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mysearch">
                    <a
                      onClick={() => {
                        if (typeof window !== "undefined") {
                          window.location.pathname.includes("candidates")
                            ? close()
                            : history.push(`/candidates`);
                        }
                      }}
                    >
                      <Trans>Continuer ma recherche</Trans>
                    </a>
                  </FormGroup>
                </Col>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

SingleCandidateModal.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  candidateBhId: PropTypes.number,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.number,
    signUpUserRole: PropTypes.string,
    reset: PropTypes.func,
    changeSignUpUserRole: PropTypes.func,
    changeExpressSignUp: PropTypes.func
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    jobDescription: PropTypes.string,
    description: PropTypes.string,
    changeDescription: PropTypes.func
  }).isRequired,
  close: PropTypes.func,
  operation: PropTypes.string,
  modalStore: PropTypes.shape({
    changeIsVisible: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(SingleCandidateModal));
