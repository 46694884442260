import { ApolloLink, InMemoryCache } from "apollo-boost";
import ApolloClient from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import cookie from "react-cookies";
import fetch from "isomorphic-unfetch";
import runtimeVars from "../configs/runTimeVars";
import { cookieRemoveOpt } from "../configs/domainConfigs";

const { AUTH_TOKEN, FRONTEND_BASE_FR_URL, FRONTEND_BASE_URL } = runtimeVars;
let currentLanguage = "en";

const authLink = new ApolloLink((operation, forward) => {
  const token = cookie.load(AUTH_TOKEN) || localStorage.getItem(AUTH_TOKEN);

  operation.setContext({
    headers: {
      sourcesite: runtimeVars.APP_SOURCE_SITE,
      authorization: token || "",
      webSite: runtimeVars.APP_WEBSITE
    }
  });

  return forward(operation);
});

const link = ApolloLink.from([
  authLink,
  createUploadLink({
    ssr: false,
    uri: runtimeVars.APP_GRAPHQL_EP,
    fetch
  })
]);

const formatDateLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    if (
      response.errors &&
      response.errors.length &&
      response.errors[0].message === "UNAUTHENTICATED"
    ) {
      localStorage.removeItem(AUTH_TOKEN);
      cookie.remove(AUTH_TOKEN, cookieRemoveOpt);

      currentLanguage = localStorage.getItem("language");
      const baseUrl =
        currentLanguage === "fr" ? FRONTEND_BASE_FR_URL : FRONTEND_BASE_URL;
      window.location.replace(baseUrl + "/login");
    }
    return response;
  });
});

const client = new ApolloClient({
  link: formatDateLink.concat(link),
  cache: new InMemoryCache({
    addTypename: false
  })
});

export default client;
