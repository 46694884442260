import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { H2, H5, TextInput, Button, Modal, toaster } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { Mutation, withApollo } from "react-apollo";
import ConfirmModal from "./ConfirmModal";
import ConnectedAndSubscribedUserModal from "./ConnectedAndSubscribedUserModal";
import classes from "./NewsBanner.module.scss";
import { SUB_TO_NEWSLETTER } from "../mutations";
import { GET_USER_NEWSLETTER_SUBSCRIPTION } from "../queries";
import { GET_USER_INFO } from "../../../../components/hoc/queries";

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@inject("sessionStore")
@observer
class NewsBanner extends Component {
  constructor(props) {
    super(props);
    this.email = "";
    this.state = {
      validate: false,
      valid: false,
      showModal: false,
      showIsConnectedAndSubscribedModal: false,
      toasterId: "nl_consultant",
      receiveNewsletterTalent: false,
      receiveNewsletterClient: false
    };
  }

  focusHandler = e => {
    const { sessionStore } = this.props;
    if (sessionStore.authToken && sessionStore.email) {
      e.target.value = sessionStore.email;
      this.email = sessionStore.email;
    }
  };

  changeHandler = e => {
    this.email = e.target.value;
    this.setState({ validate: false });
  };

  subscribe = async () => {
    const { client, i18n } = this.props;
    const valid = EMAIL_REG.test(this.email);
    if (valid) {
      const { loading, error, data } = await client.query({
        query: GET_USER_NEWSLETTER_SUBSCRIPTION,
        variables: {
          email: this.email,
          language: localStorage.getItem("language")
        },
        fetchPolicy: "network-only"
      });
      if (!loading && !error) {
        const { getUserNewsLetterSubscriptions } = data;
        const {
          receiveNewsletterTalent,
          receiveNewsletterClient
        } = getUserNewsLetterSubscriptions;
        this.setState({
          receiveNewsletterClient:
            getUserNewsLetterSubscriptions.receiveNewsletterClient,
          receiveNewsletterTalent:
            getUserNewsLetterSubscriptions.receiveNewsletterTalent
        });
        if (receiveNewsletterTalent && receiveNewsletterClient)
          this.setState({ showIsConnectedAndSubscribedModal: true });
        else this.setState({ showModal: true });
      }
    } else {
      toaster.warning({
        title: i18n._(t`Avertissement`),
        description: i18n._(t`Veillez entrer un email valide`)
      });

      this.setState({ validate: true, valid });
    }
  };

  register = (talent, client, mutation) => {
    this.setState({ showModal: false });
    const list = [];
    if (talent) list.push("talent");
    if (client) list.push("client");
    const input = {
      email: this.email,
      language: localStorage.getItem("language"),
      list
    };

    if (list.includes("client") && list.includes("talent"))
      this.setState({ toasterId: "nl_consultant_client" });
    else if (list.includes("client")) this.setState({ toasterId: "nl_client" });
    else if (list.includes("talent"))
      this.setState({ toasterId: "nl_consultant" });

    return mutation({ variables: input });
  };

  closeModal = () => {
    this.setState({ showIsConnectedAndSubscribedModal: false });
  };

  render() {
    const { i18n, sessionStore } = this.props;
    const { authToken } = sessionStore;
    const {
      validate,
      valid,
      showModal,
      showIsConnectedAndSubscribedModal,
      toasterId,
      receiveNewsletterTalent,
      receiveNewsletterClient
    } = this.state;
    const styles = {
      color: "black",
      borderColor: "#dadee3"
    };
    if (validate && !valid) {
      styles.color = "red";
      styles.borderColor = "red";
    }
    return (
      <div className={classes.container}>
        <div className={classes.body}>
          <H2 className={classes.bigHeader}>
            <Trans>Subscribe to our IT newsletter</Trans>
          </H2>
          <H5 className={classes.header}>
            <Trans>
              Connect with the latest news in the IT and Tech sector
            </Trans>
          </H5>
          <div className={classes.email}>
            <TextInput
              style={styles}
              onChange={this.changeHandler}
              onEnterPressed={this.subscribe}
              placeholder={i18n._(t`Enter your email address`)}
              spellCheck="false"
              onFocus={this.focusHandler}
            />
            <Button
              icon="chevron-right"
              variant="secondary"
              onClick={this.subscribe}
            >
              <Trans>Subscribe</Trans>
            </Button>
          </div>
        </div>
        <Modal
          centred
          isVisible={showIsConnectedAndSubscribedModal}
          withCloseButton={false}
          position="fixed"
        >
          <ConnectedAndSubscribedUserModal close={this.closeModal} />
        </Modal>
        {showModal && (
          <Modal
            centred
            isVisible={showModal}
            onClose={() => this.setState({ showModal: false })}
          >
            <Mutation
              mutation={SUB_TO_NEWSLETTER}
              onCompleted={({ subToNewsletter }) => {
                if (subToNewsletter.status === "ok")
                  toaster.success({
                    id: toasterId,
                    title: i18n._(t`Merci`),
                    description: i18n._(
                      t`Vous êtes désormais abonné à la newsletter de Mindquest`
                    )
                  });
                if (subToNewsletter.status === "error")
                  toaster.error({
                    title: i18n._(t`Erreur`),
                    description: i18n._(
                      t`Un problème est survenu lors de la création de l’abonnement`
                    )
                  });
              }}
              onError={() =>
                toaster.error({
                  title: i18n._(t`Erreur`),
                  description: i18n._(
                    t`Un problème est survenu lors de la création de l’abonnement`
                  )
                })
              }
              ignoreResults
              refetchQueries={[
                {
                  query: GET_USER_INFO,
                  variables: { token: authToken }
                }
              ]}
            >
              {subToNewsletter => (
                <ConfirmModal
                  receiveNewsletterTalent={receiveNewsletterTalent}
                  receiveNewsletterClient={receiveNewsletterClient}
                  register={(talent, client) =>
                    this.register(talent, client, subToNewsletter)
                  }
                />
              )}
            </Mutation>
          </Modal>
        )}
      </div>
    );
  }
}

NewsBanner.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    email: PropTypes.string,
    isMapsScriptReady: PropTypes.bool
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withApollo(NewsBanner));
