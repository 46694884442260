export default {
  center: true,
  nav: true,
  navText: [
    "<div className='prev-slide'></div>",
    "<div className='next-slide'></div>"
  ],

  // goToFirstNav : true,
  // scrollPerPage : false,

  items: 2,
  // itemsDesktop : [1199,4],
  // itemsDesktopSmall : [980,3],
  // itemsTablet: [768,2],
  // itemsMobile : [479,1],
  // singleItem : false,

  //Basic Speeds
  // slideSpeed : 200,
  // paginationSpeed : 800,
  slideBy: 1,
  loop: true,
  autoWidth: true,

  //Autoplay
  // autoPlay : true,
  stopOnHover: true,
  // goToFirst : true,
  // goToFirstSpeed : 1000,

  margin: 40,
  dots: true,
  dotsClass: "owlDots",
  dotClass: "owlDot"

  // rewind: true,

  // dotsEach: true,
  // dotData: true

  //Pagination
  // pagination : true,
  // paginationNumbers: false,

  // Responsive
  // responsive: true,
  // responsiveRefreshRate : 200,

  // CSS Styles
  // baseClass : "owl-carousel",
  // theme : "owl-theme",

  //Lazy load
  // lazyLoad : false,
  // lazyFollow : true,

  //Auto height
  // autoHeight : false,

  //JSON
  // jsonPath : false,
  // jsonSuccess : false,

  //Mouse Events
  // mouseDrag : true,
  // touchDrag : true,

  //Callbacks
  // beforeInit: false,
  // afterInit: false,
  // beforeMove: false,
  // afterMove: false,
  // afterAction: false,
  // startDragging : false,

  // addClassActive : false
};
