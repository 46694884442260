import Dashboard from "../../dashboard";
import DashboardCompany from "../../dashboard-company";
import DashboardMissions from "../../dashboard-missions";
import GetProps from "../../dashboard-offers";
import GetDocs from "../../dashboard-documents";
import DashboardTalents from "../../dashboard-talents";
import ManagementTalentPool from "../../dashboard-talents/ManagementTalentPool";
import profile from "../../dashboard/profile";
import Timesheets from "../../timesheets/Timesheets";
import TimesheetsSinglePage from "../../timesheet-single-page";
import profileCompany from "../../dashboard-company/profile";
import DashboardSingleMission from "../../dashboard-single-mission";
import StatsPage from "../../dashboard-company/stats-page";
import DashboardUnderConstruction from "../../../components/error-pages/DashboardUnderConstruction";
import updateOpportunity from "../../post-a-need/update-opportunity/index";
import singleOffer from "../../dashboard-offers/single-offer/index";
import SingleCandidate from "../../dashboard-single-candidate/index";
import DashbordPool from "../../dashboard-talents/talent-pool";

/** !!! For any modification in this file, please re-do it in src/utils/urls.js */

const LpbLayoutRoutes = {
  Dashboard: {
    path: "/dashboard",
    component: Dashboard,
    dedicatedFor: ["CANDIDATE"]
  },
  Missions: {
    path: "/dashboard/missions",
    component: DashboardMissions,
    dedicatedFor: ["CANDIDATE"]
  },
  Documents: {
    path: "/dashboard/documents",
    component: DashboardUnderConstruction,
    dedicatedFor: ["CANDIDATE"]
  },

  Timesheets: {
    path: "/dashboard/timesheets",
    component: Timesheets,
    dedicatedFor: ["CANDIDATE", "CLIENT"]
  },
  TimesheetsSinglePage: {
    path: "/dashboard/timesheets/:id",
    component: TimesheetsSinglePage,
    dedicatedFor: ["CANDIDATE", "CLIENT"]
  },
  Calendar: {
    path: "/dashboard/calendar",
    component: DashboardUnderConstruction,
    dedicatedFor: ["CANDIDATE"]
  },
  MyProfile: {
    path: "/dashboard/my-profile",
    component: profile,
    dedicatedFor: ["CANDIDATE"]
  },
  MyProfileResumeUploader: {
    path: "/dashboard/my-profile/CV-upload",
    component: profile,
    dedicatedFor: ["CANDIDATE"]
  },
  MyCertification: {
    path: "/dashboard/my-formation/certification",
    component: profile,
    dedicatedFor: ["CANDIDATE"]
  },
  MyFormation: {
    path: "/dashboard/my-formation/formation",
    component: profile,
    dedicatedFor: ["CANDIDATE"]
  },
  MyProfileResumeDownloader: {
    path: "/dashboard/my-profile/CV-download",
    component: profile,
    dedicatedFor: ["CANDIDATE"]
  },
  MyInformation: {
    path: "/dashboard/my-information",
    component: profile,
    dedicatedFor: ["CANDIDATE"]
  },
  MyParams: {
    path: "/dashboard/my-settings",
    component: profile,
    dedicatedFor: ["CANDIDATE"]
  },
  MyEducation: {
    path: "/dashboard/my-formation",
    component: profile,
    dedicatedFor: ["CANDIDATE"]
  },
  MyJob: {
    path: "/dashboard/my-job",
    component: profile,
    dedicatedFor: ["CANDIDATE"]
  },
  MyExperience: {
    path: "/dashboard/my-experience",
    component: profile,
    dedicatedFor: ["CANDIDATE"]
  },
  MySearch: {
    path: "/dashboard/my-search",
    component: profile,
    dedicatedFor: ["CANDIDATE"]
  },
  SingleMission: {
    path: "/dashboard/missions/:id",
    component: DashboardSingleMission,
    dedicatedFor: ["CANDIDATE"]
  },

  DashboardCompany: {
    path: "/dashboard/company",
    component: DashboardCompany,
    dedicatedFor: ["CLIENT"]
  },

  Offers: {
    path: "/dashboard/company/offers",
    component: GetProps,
    dedicatedFor: ["CLIENT"]
  },
  Talents: {
    path: "/dashboard/company/talents",
    component: DashboardTalents,
    dedicatedFor: ["CLIENT"]
  },
  TalentsPool: {
    path: "/dashboard/company/talents-pool/:id",
    component: DashbordPool,
    dedicatedFor: ["CLIENT"]
  },
  ManagementTalentPool: {
    path: "/dashboard/company/talentpool-management",
    component: ManagementTalentPool,
    dedicatedFor: ["CLIENT"]
  },

  MyCompany: {
    path: "/dashboard/company/my-profile",
    component: profileCompany,
    dedicatedFor: ["CLIENT"]
  },
  MyInformationCompany: {
    path: "/dashboard/company/my-information",
    component: profileCompany,
    dedicatedFor: ["CLIENT"]
  },
  MyParamsCompany: {
    path: "/dashboard/company/my-settings",
    component: profileCompany,
    dedicatedFor: ["CLIENT"]
  },
  UpdateOfferDetails: {
    path: "/dashboard/company/opportunities/offer-details/edit/:id",
    component: updateOpportunity,
    dedicatedFor: "CLIENT"
  },
  UpdateOfferDescription: {
    path: "/dashboard/company/opportunities/offer-description/edit/:id",
    component: updateOpportunity,
    dedicatedFor: "CLIENT"
  },
  UpdateDesiredProfile: {
    path: "/dashboard/company/opportunities/desired-profile/edit/:id",
    component: updateOpportunity,
    dedicatedFor: "CLIENT"
  },
  SingleOffer: {
    path: "/dashboard/company/offers/:id",
    component: singleOffer,
    dedicatedFor: "CLIENT"
  },
  SingleCandidate: {
    path: "/dashboard/company/talents/:id",
    component: SingleCandidate,
    dedicatedFor: "CLIENT"
  },
  /*  SingleCandidatePage: {
    path: "/dashboard/company/candidates/:id",
    component: SingleCandidatePage,
    dedicatedFor: "CLIENT"
  }, */
  /* CalendarCompany: {
    path: "/dashboard/company/calendar",
    component: DashboardUnderConstruction,
    dedicatedFor: ["CLIENT"]
  }, */
  TimesheetsCompany: {
    path: "/dashboard/company/timesheets",
    component: Timesheets,
    dedicatedFor: ["CLIENT"]
  },
  Stats: {
    path: "/dashboard/company/stats",
    component: StatsPage,
    dedicatedFor: ["CLIENT"]
  },
  MarketData: {
    path: "/dashboard/company/market-data",
    component: DashboardUnderConstruction,
    dedicatedFor: ["CLIENT"]
  },
  DocumentsContracts: {
    path: "/dashboard/documentsContracts",
    component: GetDocs,
    dedicatedFor: ["CANDIDATE", "CLIENT"]
  }
};

export default LpbLayoutRoutes;
