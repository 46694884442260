import React, { Component } from "react";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import Zoom from "react-reveal/Zoom";
import classes from "./CompanySection1.module.scss";
import Fade from "react-reveal/Fade";

class CompanySection1 extends Component {
  render() {
    return (
      <div className={classes.company}>
        <Fade>
          <h2>
            <Trans>Entreprises</Trans>
          </h2>
        </Fade>
        <Fade>
          <h3 className={classes.paragraph}>
            <Trans>Ils ont réalisés des projets avec nos freelances.</Trans>
          </h3>
        </Fade>
        <div className={classes.comapniesLogos}>
          <div className={classes.logo}>
            <Zoom>
              <img
                src="/assets/images/companies/air-liquide.svg"
                alt="AIR Liquide"
              />
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/biogaran.svg"
                />
                <img
                  src="/assets/images/companies/biogaran.svg"
                  alt="BIOGARAN"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/coyote.svg"
                />
                <img src="/assets/images/companies/coyote.svg" alt="Coyote" />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/imerys.svg"
                />
                <img src="/assets/images/companies/imerys.svg" alt="Imerys" />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/laposte.svg"
                />
                <img
                  src="/assets/images/companies/laposte.svg"
                  alt="La poste"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/microsoft.svg"
                />
                <img
                  src="/assets/images/companies/microsoft.svg"
                  alt="Microsoft"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/svg"
                  srcSet="/assets/images/companies/saint-gobain.svg"
                />
                <img
                  src="/assets/images/companies/saint-gobain.svg"
                  alt="Saint Gobain"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/savencia.svg"
                />
                <img
                  src="/assets/images/companies/savencia.svg"
                  alt="Savencia"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/suez.svg"
                />
                <img src="/assets/images/companies/suez.svg" alt="Suez" />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <img src="/assets/images/companies/vivescia.svg" alt="Vivescia" />
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/vinci.svg"
                />
                <img src="/assets/images/companies/vinci.svg" alt="Vinci" />
              </picture>
            </Zoom>
          </div>
        </div>
      </div>
    );
  }
}

export default withI18n()(CompanySection1);
