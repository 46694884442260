import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import { withI18n } from "@lingui/react";
import {
  MissionDisplay,
  SourcerControlPanel,
  H2,
  H3,
  MissionCardSkeleton
} from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import $ from "jquery";

import similarJobOrdersQuery from "./query";
import carouselConfig from "./CarouselConfig";
import Banner from "../../../../components/Banner/Banner";
import Routes from "../../../layout/routes";
import FaveUnfave from "../../../../components/fave-unfave";
import formatMissionDuration from "../../../../utils/formatMissionDuration";

import classes from "./SimilarJobOrders.module.scss";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./disable.scss";

@inject("sessionStore")
@observer
class Suggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false
    };
  }

  componentDidMount() {
    this.setState({ render: true });
  }

  showDetails = id => {
    const { history } = this.props;
    history.push(`${Routes.HbfLayoutRoutes.MissionsPage.path}/${id}`);
  };

  redirectToLogin = () => {
    const { history } = this.props;
    history.push(Routes.HbLayoutRoutes.Login.path);
  };

  toLinkedIn = () => {
    const { sourcer } = this.props;
    window.open(sourcer.linkedIn, "_blank");
  };

  toContact = () => {
    const { sessionStore, sourcer, history } = this.props;
    sessionStore.setContactPageEmail({
      email: sourcer.email,
      name: sourcer.name
    });
    history.push(Routes.HbLayoutRoutes.contact.path);
  };

  toSourcerMissions = () => {
    const { history, sourcer } = this.props;
    history.push(/offers/ + sourcer.id);
  };

  render() {
    const { i18n, sourcer, match, loading: loadingG, customText7 } = this.props;
    const { render } = this.state;
    if (!render) return null;
    if (typeof window !== "undefined") {
      window.$ = $;
      window.jQuery = $;
    }
    /* eslint-disable global-require */
    const OwlCarousel = require("react-owl-carousel");
    /* eslint-enable global-require */
    return (
      <div className={classes.suggestion}>
        <div className={classes.contact}>
          {loadingG ? null : customText7?.includes("Caroline G") ? null : (
            <SourcerControlPanel
              avatar={sourcer.avatar}
              name={sourcer.name}
              position={sourcer.position}
              email={sourcer.email}
              phoneNumber={sourcer.phoneNumber}
              description={sourcer.description}
              textButton1={i18n._(t`Contacter`)}
              textButton2={i18n._(t`Voir ses offres`)}
              textLink={i18n._(t`Voir ses offres`)}
              onIconClick={this.toLinkedIn}
              onContacterClick={this.toContact}
              onVoirOffresClick={this.toSourcerMissions}
            />
          )}
        </div>
        <Banner />
        <H2 className={classes.heading}>
          <Trans>Offres similaires</Trans>
        </H2>
        <div>
          <Query
            query={similarJobOrdersQuery}
            variables={{ id: match.params.id }}
          >
            {({ loading, error, data }) => {
              if (error) return <div>Error in Suggestion</div>;
              if (loading) {
                return (
                  <div className={classes.skeleton}>
                    {[1, 2, 3, 4, 5].map(i => (
                      <MissionCardSkeleton key={i} />
                    ))}
                  </div>
                );
              }
              const { similarJobOrders } = data;
              if (!similarJobOrders.length)
                return (
                  <div className={classes.noSimilar}>
                    <Trans>Il n’y a pas des missions similaires</Trans>
                  </div>
                );
              return (
                <div className={classes.carouselStyling}>
                  <OwlCarousel {...carouselConfig}>
                    {similarJobOrders.map(jobOrder => (
                      <div className="item" key={jobOrder.id}>
                        <FaveUnfave missionId={jobOrder.id}>
                          <MissionDisplay
                            title={jobOrder.title}
                            location={jobOrder.address.city}
                            duration={formatMissionDuration(
                              jobOrder.duration,
                              i18n,
                              jobOrder.employmentType
                            )}
                            field={jobOrder.customText1}
                            urgent={
                              jobOrder.customText10 ? i18n._(t`URGENT`) : ""
                            }
                            onShowDetails={() => this.showDetails(jobOrder.id)}
                            showDetailsLabel={i18n._(t`Voir l’offre`)}
                            href={`${Routes.HbfLayoutRoutes.MissionsPage.path}/${jobOrder.id}`}
                          />
                        </FaveUnfave>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
}

Suggestion.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    plural: PropTypes.func,
    t: PropTypes.func,
    _: PropTypes.func
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  sourcer: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    description: PropTypes.string,
    linkedIn: PropTypes.string
  }).isRequired,
  customText7: PropTypes.arrayOf(PropTypes.string).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  sessionStore: PropTypes.shape({
    setContactPageEmail: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(Suggestion));
