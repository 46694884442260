import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Tabs,
  Tab,
  Button,
  toaster,
  Spinner,
  Row,
  Modal,
  ToastCard
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import "./styles.scss";
import Routes from "../../layout/routes/index";
import MyFormation from "./formation/MyFormation";
import MyCertification from "./certification/MyCertification";
import { Mutation } from "react-apollo";
import { Trans, t } from "@lingui/macro";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import staticMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import { GET_USER_INFO } from "../../../components/hoc/queries";

const UPDATE_CANDIDATE_MUTATION = gql`
  mutation UpdateCandidateMutation(
    $_id: ID!
    $candidateEducation: [EducationCreateInput]
  ) {
    updateCandidate(
      input: { _id: $_id, candidateEducation: $candidateEducation }
    ) {
      account {
        id
        name
      }
      candidate {
        _id
        email
      }
    }
  }
`;
@inject("sessionStore", "appStore")
@observer
class MyEducation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false
    };
  }
  componentWillMount() {
    const { i18n, sessionStore } = this.props;
    const { generalSaveCase } = sessionStore;
    const { isVisible } = this.state;
    this.setState({ isVisible: generalSaveCase ? true : false });
  }
  changeTabHandler(activeEducationTab) {
    const { history } = this.props;
    const routes = Routes.LpbLayoutRoutes;

    if (activeEducationTab === "myformation") {
      history.push(routes.MyFormation.path);
    }
    if (activeEducationTab === "mycertification") {
      history.push(routes.MyCertification.path);
    }
  }
  updateCandidateCompletedHandler = () => {
    const { sessionStore, location, appStore, i18n } = this.props;
    const currentPath = location.pathname;
    if (currentPath === "/dashboard/my-formation/certification") {
      toaster.success({
        title: i18n._(t`Ma Certification`),
        description: i18n._(t`Enregistrement effectué avec succès`)
      });
    } else {
      toaster.success({
        title: i18n._(t`Ma Formation`),
        description: i18n._(t`Enregistrement effectué avec succès`)
      });
    }

    const { changeSaveCaseEducation, changeGeneralSaveCase } = sessionStore;
    changeSaveCaseEducation(false);
    changeGeneralSaveCase(null);

    // appStore.refreshLayout();
  };
  clickTabHandler() {
    const { i18n, sessionStore } = this.props;
    const { generalSaveCase } = sessionStore;
    const { isVisible } = this.state;
    this.setState({ isVisible: generalSaveCase ? true : false });
    sessionStore.changeGeneralSaveCase(null);
  }
  render() {
    const { i18n, location, sessionStore } = this.props;
    const routes = Routes.LpbLayoutRoutes;
    const { isVisible } = this.state;
    let activeEducationTab = "myformation";
    const currentPath = location.pathname;
    if (currentPath === routes.MyFormation.path)
      activeEducationTab = "myformation";
    if (currentPath === routes.MyCertification.path)
      activeEducationTab = "mycertification";

    const {
      _id,
      authToken,
      candidateEducation,
      candidateCertification,
      saveCaseEducation,
      generalSaveCase
    } = sessionStore;

    let candidateEducationToSend = [];
    const candidateEducationToSendx = candidateEducation.concat(
      candidateCertification
    );

    for (let key = 0; key < candidateEducationToSendx.length; key += 1) {
      const Education = candidateEducationToSendx[key];
      candidateEducationToSend.push({
        ...(Education._id && {
          _id: Education._id
        }),
        id: Education.id.toString(),
        candidateId: _id,
        degree: Education.degree,
        startDate: Education.startDate,
        endDate: Education.endDate ? Education.endDate : null,
        school: Education.school,
        city: Education.city,
        diplomeType: Education.diplomeType,
        certification: Education.certification
      });
    }
    const obj = {};

    for (let i = 0, len = candidateEducationToSend.length; i < len; i++) {
      obj[candidateEducationToSend[i]["id"]] = candidateEducationToSend[i];
    }

    candidateEducationToSend = new Array();

    for (const key in obj) {
      candidateEducationToSend.push(obj[key]);
    }

    const meta = staticMetaTags(i18n).training;
    return (
      <div className="profile my-formation">
        {metaTags(
          urls.myTraining(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          null,
          null,
          false
        )}
        <br />
        <Modal
          centred
          isVisible={isVisible}
          withCloseButton={false}
          position="fixed"
        >
          <ToastCard
            title={i18n._(t`Alerte`)}
            description={i18n._(
              t`Veuillez enregistrer avant de passer à l’étape suivante`
            )}
            expiresIn={0}
            isVisible
            closable
            intent="error"
            showButton
            buttonText={i18n._(t`j'ai compris`)}
            onClose={() => {
              this.setState({ isVisible: false });
            }}
          />
        </Modal>
        <Tabs
          defaultActiveTab={activeEducationTab}
          onChange={activeEducationTab =>
            this.changeTabHandler(activeEducationTab)
          }
        >
          <Tab
            name="myformation"
            tab={i18n._(t`Mes Formations`)}
            closedTab={
              generalSaveCase && generalSaveCase != null ? true : false
            }
            moreAction={() => this.clickTabHandler()}
          >
            <MyFormation />
          </Tab>
          <Tab
            name="mycertification"
            tab={i18n._(t`Mes Certifications`)}
            closedTab={
              generalSaveCase && generalSaveCase != null ? true : false
            }
            moreAction={() => this.clickTabHandler()}
          >
            <MyCertification />
          </Tab>
        </Tabs>
        <Row style={{ flexDirection: "row-reverse", marginRight: "100px" }}>
          <div className="next-buttons">
            <Mutation
              mutation={UPDATE_CANDIDATE_MUTATION}
              variables={{
                _id,
                candidateEducation: candidateEducationToSend
              }}
              onCompleted={data => this.updateCandidateCompletedHandler(data)}
              onError={errors => {
                errors.graphQLErrors.forEach(({ message, data }) => {
                  if (data && data.isCustomError) {
                    this.onErrorHandler(message);
                  }
                });
              }}
              refetchQueries={[
                {
                  query: GET_USER_INFO,
                  variables: { token: authToken }
                }
              ]}
            >
              {(mutation, { loading }) => (
                <Button
                  style={{
                    marginTop: "10px",

                    marginRight: "3%",
                    "min-width": "221px"
                  }}
                  disabled={loading || !saveCaseEducation}
                  onClick={() => {
                    mutation();
                  }}
                >
                  {loading ? (
                    <Spinner
                      type="pointed-circular"
                      color="#FFFFFF"
                      size={12}
                    />
                  ) : (
                    <Trans>Enregistrer</Trans>
                  )}
                </Button>
              )}
            </Mutation>
          </div>
        </Row>

        <br />
      </div>
    );
  }
}

MyEducation.propTypes = {
  sessionStore: PropTypes.shape({
    candidateEducation: PropTypes.shape({}),
    changecandidateEducation: PropTypes.shape({}),
    changeIscandidateEducationValid: PropTypes.func,
    authToken: PropTypes.string,
    _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iscandidateEducationValid: PropTypes.bool
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(MyEducation));
