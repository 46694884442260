import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react/index";
import { I18nProvider } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { H2 } from "cf-neo-ui";
import classes from "./MoreArticles.module.scss";
import SugVideo from "./suggestion-display/videos/SugDisplayVideos";
import SugArticle from "./suggestion-display/articles/SugDisplayArticles";
import BloggerSection from "../../../blogger-section/BloggerSection";
import { inject } from "mobx-react";
import catalogEn from "../../../../../locales/en/messages";
const catalogs = {
  en: catalogEn
};
@inject("appStore")
class MoreArticles extends Component {
  render() {
    const { blogger, currentBlog, relatedPosts, appStore } = this.props;
    const { currentLanguage } = appStore;
    return (
      <div className={classes.container}>
        <I18nProvider language={currentLanguage} catalogs={catalogs}>
          <BloggerSection blogger={blogger} />
          <H2 className={classes.heading}>
            {currentBlog.type === "video" ? (
              <Trans>Videos qui peuvent vous intéresser</Trans>
            ) : (
              <Trans>Articles qui peuvent vous intéresser</Trans>
            )}
          </H2>
          {currentBlog.type === "video" ? (
            <SugVideo currentBlog={currentBlog} relatedPosts={relatedPosts} />
          ) : (
            <SugArticle currentBlog={currentBlog} relatedPosts={relatedPosts} />
          )}
        </I18nProvider>
      </div>
    );
  }
}

MoreArticles.propTypes = {
  blogger: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    avatar_url: PropTypes.string
  }),
  relatedPosts: PropTypes.arrayOf(PropTypes.string),
  currentBlog: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf(["video", "book", "article"])
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};
MoreArticles.defaultProps = {
  blogger: {
    name: "",
    description: "",
    avatar_url: ""
  },
  relatedPosts: []
};
export default withI18n()(MoreArticles);
