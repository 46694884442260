import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Tabs,
  Tab,
  Sorter,
  Icon,
  Button,
  Validation,
  SwitchButton,
  Modal,
  IconCircle,
  TextInput,
  toaster,
  Col
} from "cf-neo-ui";
import { Query, Mutation } from "react-apollo";
import { withApollo } from "react-apollo";
import * as queryString from "query-string";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react/index";
import classes from "./stylesTalentsInPool.module.scss";
import routes from "../../layout/routes/hbfLayoutRoutes";
import gql from "graphql-tag";
import theme from "../../../configs/theme";
import { TALENT_POOL_QUERY } from "../../dashboard-company/queries";
import Listcollaborators from "./listCollaborators";
import History from "./history";
import ListTalents from "./listTalents";
import DeleteTalentPool from "../../talentPool-Modal/deleteTalentPool";
import Setting from "./setting";
import LoadingMissions from "../../../components/LoadingMissions/LoadingMissions";
import metaTags from "../../../utils/editMetaData";
import staticMetaTags from "../../../configs/staticPagesMetaTags";
import validate from "../../../utils/validators";

const UPDATE_Talent_POOL_MUTATION = gql`
  mutation updateTalentPoolMutation(
    $poolName: String
    $shared: Boolean
    $_id: String
    $clientID: String
    $creatorID: String
  ) {
    updateTalentPool(
      input: {
        poolName: $poolName
        shared: $shared
        _id: $_id
        clientID: $clientID
        creatorID: $creatorID
      }
    ) {
      poolName
      clientCompanyId
      clientEmail
      clientID
      sourceTalentPool
      creatorID
      shared
    }
  }
`;

@inject("appStore", "sessionStore", "modalStore", "talentStore")
@observer
class DashbordPool extends Component {
  constructor(props) {
    super(props);
    const { sessionStore } = this.props;
    const queryObj = queryString.parse(props.location.search);
    this.total = 0;
    this.state = {
      search: "",
      isVisible: false,
      activeTab: queryObj.active,
      currentPage: 1,
      tri: "all",
      talentFilter: "",
      inputValue: "",
      titlePool: "",
      skip: 0,
      limit: this.setPageSize(props.appStore.width),
      sharedPool: sessionStore.shared || false,
      order: 1,
      talent: {},
      searchTest: false,
      titleValidationMessage: "",
      isTitleValid: false
    };
  }
  componentDidMount() {
    const { sessionStore } = this.props;
    const { poolName, shared } = sessionStore;
    this.setState({ titlePool: poolName, sharedPool: shared }, function() {});
  }
  setPageSize = width => {
    if (width >= 1440) return 8;
    if (width >= 1024) return 6;
    if (width >= 768) return 6;
    return 2;
  };
  tabChangeHandler = tabName => {
    const { history } = this.props;
    history.replace({ search: `?active=${tabName}` });
  };
  validateTitle = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", value, { maxlength: 30 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isTitleValid: res.isValid,
      titleValidationMessage: res.message
    });
    return res.isValid;
  };
  talentFilter = (value, order) => {
    const { sessionStore } = this.props;
    sessionStore.changetalentFilter(value);
    this.setState({
      currentPage: 1,
      isVisible: false,
      talentFilter: value,
      order: order
    });
    this.total = 0;
  };
  handleIsShared = () => {
    const { sessionStore } = this.props;
    const { shared } = sessionStore;
    const { sharedPool } = this.state;
    sessionStore.changeShared(!shared);
    this.setState({ sharedPool: !sharedPool });
  };

  updateTitle = () => {
    const { sessionStore } = this.props;
    sessionStore.changetoChangePoolName(true);
  };
  EnterHandler = e => {
    this.validateTitle(e.target.value);
    this.setState({ inputValue: e.target.value });
  };
  deletePool = () => {
    const { modalStore } = this.props;
    const { changetoDelete } = modalStore;
    changetoDelete(true);
  };
  handleEnterPressed = e => {
    this.setState({
      search: e.target.value,
      currentPage: 1,
      isVisible: false
    });
    this.total = 0;
  };

  navigateBack = () => {
    const { history } = this.props;
    if (history && history.action === "PUSH") history.goBack();
    else history.push(`/dashboard/company/talents?active=TalentPool`);
  };
  onErrorHandler = msg => {
    const { i18n } = this.props;

    if (msg === 'Unexpected error value: "Ce nom existe déjà !"') {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(t`Ce nom existe déjà !`)
      });
    } else {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Une erreur est survenue, veuillez réessayer ultérieurement`
        )
      });
    }
  };
  openInNewTab = () => {
    const win = window.open(routes.SearchCandidatesPage.path, "_blank");
    win?.focus();
  };

  changeSkip = data => {
    this.setState({ skip: data });
  };
  changeCurrent = data => {
    this.setState({ currentPage: data });
  };

  render() {
    const { location, sessionStore, i18n, appStore, modalStore } = this.props;
    const {
      titleValidationMessage,
      isTitleValid,
      activeTab,
      inputValue,
      order,
      search,
      searchTest,
      limit,
      skip,
      currentPage
    } = this.state;
    const { toDelete } = modalStore;
    const {
      client,
      creatorID,
      talentPoolFilter,
      poolName,
      shared,
      sourceTalentPool,
      toChangePoolName
    } = sessionStore;
    const vivierID = location.pathname.split("talents-pool/")[1];
    const { width } = appStore;
    const isMobile = width <= 1223 ? true : false;
    let i = 0;
    const meta = staticMetaTags(i18n).talentPool;

    return (
      <div className={classes.content}>
        {metaTags(
          null,
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          null
        )}
        <Modal isVisible={toDelete} withCloseButton={false} position="fixed">
          <DeleteTalentPool vivierID={vivierID} />
        </Modal>
        <Query
          query={TALENT_POOL_QUERY}
          variables={{
            _id: vivierID,
            clientID: client._id,
            limit: limit,
            skip: skip,
            search: search,
            order: order
          }}
          fetchPolicy="network-only"
          onCompleted={data => {
            this.setState({ sharedPool: data.talentPool.pool.shared });
            sessionStore.changeShared(data.talentPool.pool.shared);
            sessionStore.changepoolName(
              this.state.inputValue || data.talentPool.pool.poolName
            );
            this.setState({
              searchTest: data.talentPool.pool.sourceTalentPool == "search"
            });
          }}
        >
          {({ loading, error, data }) => {
            if (loading)
              return (
                <LoadingMissions
                  cardsCount={this.setPageSize(appStore.width)}
                  lg={3}
                  md={3}
                  sm={4}
                  xs={6}
                  colClassName={classes.favedMissionsCard}
                  containerClassName={classes.marTop}
                />
              );

            if (error) {
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );
            }
            return (
              <div>
                {/*  goback + icon */}
                <Col className={classes.goBack} onClick={this.navigateBack}>
                  <IconCircle
                    type="chevron-left"
                    color="rgba(110,25,25,1)"
                    color2="rgba(255,0,0,1)"
                    innerColor="rgba(255,255,255,1)"
                    innerColor2="rgba(255,255,255,1)"
                    iconWidth={12}
                    iconHeight={12}
                    dimension={24}
                  />
                  <span>
                    <Trans>Retour</Trans>
                  </span>
                </Col>

                {/* header name + icon shared + et button  search + beta */}

                <Col
                  className={classes.pageHeader}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  {/* title + icon */}
                  <div>
                    <Col
                      lg={8}
                      md={8}
                      sm={8}
                      className={classes.sorterWithoutMenu}
                      xs={8}
                    >
                      {toChangePoolName ? (
                        <Mutation
                          mutation={UPDATE_Talent_POOL_MUTATION}
                          variables={{
                            _id: vivierID,
                            poolName: inputValue.toLowerCase(),
                            clientID: client._id,
                            creatorID: creatorID,
                            shared: shared
                          }}
                          onCompleted={data => {
                            sessionStore.changepoolName(
                              data.updateTalentPool.poolName
                            );
                            sessionStore.changesourceTalentPool(
                              data.updateTalentPool.sourceTalentPool
                            );
                            sessionStore.changetoChangePoolName(false);
                          }}
                          onError={errors => {
                            errors.graphQLErrors.forEach(({ message }) => {
                              if (message) {
                                this.onErrorHandler(message);
                                close();
                              }
                            });
                          }}
                        >
                          {(updateTalentPool, { loading }) => (
                            <Validation
                              errorMessage={titleValidationMessage}
                              valid={isTitleValid}
                            >
                              <TextInput
                                spellCheck="false"
                                placeholder={poolName}
                                name="poolName"
                                defaultValue={poolName}
                                onChange={this.EnterHandler}
                                onEnterPressed={() => {
                                  if (isTitleValid) return updateTalentPool();
                                }}
                              />
                            </Validation>
                          )}
                        </Mutation>
                      ) : (
                        <div className={classes.poolName}>
                          <Sorter
                            text={poolName.toLowerCase()}
                            menu={false}
                            onChange={() => {}}
                            chevronSize={0.00001}
                            chevronColor="#000000"
                            classNameText="font-style: normal;
                              font-weight: bold;
                              text-transform: capitalize;
                              font-size: 36px;
                              color: #000000;
                              line-height: 1;"
                            underline
                          />

                          {data.talentPool.pool.sourceTalentPool ===
                            "Cockpit" && (
                            <div style={{ marginTop: "-30px" }}>
                              {" "}
                              <Icon
                                className="icon"
                                type="mindquest"
                                color="black"
                                color2="#D3354A"
                                width={50}
                                height={50}
                              />{" "}
                            </div>
                          )}
                          {shared && (
                            <Icon
                              className="icon"
                              type="group"
                              color="#8D0417"
                              color2="#D3354A"
                              width={25}
                              height={25}
                            />
                          )}
                        </div>
                      )}
                    </Col>
                  </div>
                  {/* button search new + icon */}
                  <Col
                    className={classes.buttonCreateOffer}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                  >
                    <Button
                      variant="tertiary"
                      className={classes.buttonStyle}
                      size="small"
                      onClick={this.openInNewTab}
                    >
                      <Trans>Rechercher un nouveau talent</Trans>
                    </Button>
                    <img
                      style={{ verticalAlign: "middle" }}
                      src="/beta_red.png"
                      alt="Beta"
                      width="60px"
                      height="27px"
                    />
                  </Col>
                </Col>

                {/* all the body tabs +shared + serach + sort */}
                <div className={classes.pageBody}>
                  {/* tabs  */}
                  <div className={classes.tab}>
                    <Tabs
                      className={classes.dashboardTalents}
                      defaultActiveTab="TalentsInPool"
                      activeTab={activeTab}
                      type="transparentV2"
                      onChange={this.tabChangeHandler}
                      isMobile={isMobile}
                    >
                      <Tab
                        name="TalentsInPool"
                        tab={i18n._(t`Les talents du vivier`)}
                      >
                        <ListTalents
                          currentPage={currentPage}
                          changeCurrent={this.changeCurrent}
                          changeSkip={this.changeSkip}
                          count={data.talentPool.count}
                          pool={data.talentPool.pool}
                          talents={data.talentPool.talents}
                          skip={skip}
                          search={search}
                          order={order}
                          limit={limit}
                        />
                      </Tab>
                      {data.talentPool.pool.clientID === client._id && (
                        <Tab
                          closedTab={!shared}
                          name="Collaborators"
                          tab={i18n._(t`Les collaborateurs`)}
                        >
                          <Listcollaborators
                            poolName={poolName}
                            shared={shared}
                          />
                        </Tab>
                      )}
                      <Tab name="history" tab={i18n._(t`Historique`)}>
                        <History
                          poolName={poolName}
                          shared={shared}
                          searchTest={searchTest}
                        />
                      </Tab>
                      {creatorID === client._id &&
                        sourceTalentPool != undefined &&
                        sourceTalentPool != "search" && (
                          <Tab
                            text={i18n._(t`Paramètres`)}
                            name="setting"
                            sorterOptions={[
                              {
                                nameTab: i18n._(t`Renommer`),
                                sorterAction: this.updateTitle
                              },
                              {
                                nameTab: i18n._(t`Supprimer`),
                                sorterAction: this.deletePool
                              }
                            ]}
                            sorter={true}
                            tab={i18n._(t`Paramètres`)}
                          >
                            <Setting poolName={poolName} shared={shared} />
                          </Tab>
                        )}
                    </Tabs>
                  </div>
                  {/* shared + serach + sort */}
                  {activeTab != "history" &&
                    activeTab != "setting" &&
                    activeTab != "Collaborators" && (
                      <Col
                        className={
                          data &&
                          data.talentPool &&
                          data.talentPool.talents.length > 0
                            ? classes.settingPart
                            : classes.settingPartEmpty
                        }
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {/* shared */}
                        {data.talentPool.pool.clientID === client._id && (
                          <Mutation
                            mutation={UPDATE_Talent_POOL_MUTATION}
                            variables={{
                              _id: vivierID,
                              clientID: client._id,
                              creatorID: creatorID,
                              shared: shared
                            }}
                          >
                            {(updateShared, { loading }) => (
                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className={classes.switcher}
                              >
                                <span>{i18n._(t`Individuel `)}</span>
                                <SwitchButton
                                  style={{
                                    marginRight: "10px",
                                    marginLeft: "10px"
                                  }}
                                  checked={shared}
                                  onSwitch={async () => {
                                    await this.handleIsShared();
                                    return updateShared();
                                  }}
                                />
                                <span>{i18n._(t` Collaboratif`)}</span>
                              </Col>
                            )}
                          </Mutation>
                        )}
                        {/* search + sort */}
                        <Col
                          className={classes.filter}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                        >
                          {/* search */}
                          <div className={classes.inputSearch}>
                            <TextInput
                              placeholder={i18n._(t`Chercher un talent...`)}
                              onEnterPressed={e => this.handleEnterPressed(e)}
                              size="small"
                              icon="search"
                              clickableIcon={false}
                              color={theme.colors.mainColor}
                              color2={theme.colors.lightMainColor}
                              radius={10}
                            />
                          </div>

                          {/* sort */}
                          <Col
                            className={classes.sortPart}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <div className={classes.sortBy}>
                              <Icon
                                type={order === 1 ? "sortingup" : "sortingdown"}
                                size="large"
                                filled={true}
                                color={theme.colors.mainColor}
                                color2={theme.colors.lightMainColor}
                                width={20}
                                height={20}
                                onClick={() =>
                                  this.talentFilter(talentPoolFilter, -order)
                                }
                              />
                            </div>
                          </Col>
                        </Col>
                      </Col>
                    )}
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

DashbordPool.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  shared: PropTypes.string.isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  appStore: PropTypes.shape({
    changeWidth: PropTypes.func,
    width: PropTypes.number
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisible: PropTypes.func,
    changeIsVisible2: PropTypes.func,
    changeIsVisibleListPools: PropTypes.func
  }).isRequired,
  talentStore: PropTypes.shape({
    changeTalent: PropTypes.func,
    talent: PropTypes.shape
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(withApollo(DashbordPool)));
