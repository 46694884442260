import gql from "graphql-tag";

const UPDATE_APPROVAL = gql`
  mutation UpdateApproval(
    $placementID: Int!
    $approvingClient: String
    $approvingClient2: String
  ) {
    updateApproval(
      placementID: $placementID
      approvingClient: $approvingClient
      approvingClient2: $approvingClient2
    ) {
      status
      isSigned
    }
  }
`;

const DELETE_APPROVAL = gql`
  mutation DeleteApproval(
    $placementID: Int!
    $approvingClient: String
    $approvingClient2: String
  ) {
    deleteApproval(
      placementID: $placementID
      approvingClient: $approvingClient
      approvingClient2: $approvingClient2
    )
  }
`;

export { UPDATE_APPROVAL, DELETE_APPROVAL };
