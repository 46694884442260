import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Trans } from "@lingui/macro";
import classes from "./errorPage.module.scss";
import ServerStatus from "react-server-status";

class DeletedPage extends PureComponent {
  render() {
    const { message, history, href, withButton } = this.props;
    return (
      <ServerStatus status={410}>
        <div className={classes.container}>
          <div className={classes.breakLogo}>
            <img src="/assets/svg/NotFound.svg" alt="not found" />
          </div>
          <div className={classes.text}>
            <p>{message}</p>
            {withButton && (
              <button
                name="goback"
                type="button"
                onClick={() => history.push(href)}
                className={classes.link}
              >
                <Trans>Retour à la page recherche</Trans>
              </button>
            )}
          </div>
        </div>
      </ServerStatus>
    );
  }
}

DeletedPage.propTypes = {
  message: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  href: PropTypes.string,
  withButton: PropTypes.bool
};

DeletedPage.defaultProps = {
  message: "410 Gone",
  href: "/",
  withButton: true
};

export default withRouter(DeletedPage);
