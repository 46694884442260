import React, { Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Layout from "../../containers/layout/layout";
import ServerStoppedPage from "../error-pages/ServerStoppedPage";
import runtimeVars from "../../configs/runTimeVars";

const CATEGORIES_QUERY = gql`
  query categoriesQuery {
    categories {
      id
    }
  }
`;
class ServerTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      network: true
    };
  }
  render() {
    if (typeof window !== "undefined") {
      if (!navigator.onLine) {
        window.location.href = `${runtimeVars.FRONTEND_BASE_URL}/offline.html`;
        return null;
      }
    }

    return (
      <div>
        <Query query={CATEGORIES_QUERY} fetchPolicy="cache-and-network">
          {({ loading, error }) => {
            if (loading) return null;
            if (error) return <ServerStoppedPage />;
            return <Layout />;
          }}
        </Query>
      </div>
    );
  }
}
export default ServerTest;
