import React, { Component } from "react";
import * as PropTypes from "prop-types";
import SingleCandidateStickyHeader from "./SingleCandidateStickyHeaderComponent/SingleCandidateStickyHeader";

class SingleCandidateStickyHeaderManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  componentDidMount() {
    document.addEventListener("scroll", this.scrollHandler, { passive: true });
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.scrollHandler);
  }

  scrollHandler = () => {
    const headerHeight = document.getElementById("__SM__FULL__HEADER")
      .offsetHeight;
    this.setState({ show: window.scrollY >= headerHeight });
  };

  render() {
    const { show } = this.state;
    const {
      id,
      occupation,
      city,
      employmentPreference,
      dateAvailable,
      availability,
      existInSearchTalentPool
    } = this.props;
    return show ? (
      <SingleCandidateStickyHeader
        id={id}
        occupation={occupation}
        city={city}
        employmentPreference={employmentPreference}
        dateAvailable={dateAvailable}
        availability={availability}
        existInSearchTalentPool={existInSearchTalentPool}
        clickHandler={this.props.clickHandler}
        closeModal={this.props.closeModal}
        clickHandler2={this.props.clickHandler2}
        closeModal2={this.props.closeModal2}
      />
    ) : null;
  }
}

SingleCandidateStickyHeaderManager.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  occupation: PropTypes.string,
  city: PropTypes.string,
  employmentPreference: PropTypes.string,
  dateAvailable: PropTypes.string,
  availability: PropTypes.string
};

SingleCandidateStickyHeaderManager.defaultProps = {
  occupation: "",
  city: "",
  employmentPreference: "",
  availability: "",
  dateAvailable: ""
};
export default SingleCandidateStickyHeaderManager;
