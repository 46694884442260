import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import {
  RadioGroup,
  Radio,
  Row,
  Col,
  Collapse,
  DatePicker,
  ProgressBar,
  Validation
} from "cf-neo-ui";
import { inject, observer } from "mobx-react/index";
import moment from "moment";
import classes from "../SideFilters.module.scss";
import validate from "../../../../../utils/validators";

@inject("missionsSearchStore")
@observer
class DateWidget extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    delete initProps.sessionStore;
    this.state = {
      ...initProps,
      isStartDateValid: true,
      startDateValidationMessage: ""
    };
    this.startDateChangeHandler = this.startDateChangeHandler.bind(this);
  }

  startDateChangeHandler(value) {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.changeStartDate(value);
    this.validateStartDate(moment(value).valueOf());
  }

  validateStartDate(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);

    if (res.isValid === false || Number.isNaN(value) === true) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(t`Champ requis`)
      });
      return false;
    }

    this.setState({
      isStartDateValid: true,
      startDateValidationMessage: ""
    });
    return true;
  }

  render() {
    const { isStartDateValid, startDateValidationMessage } = this.state;
    const { i18n, missionsSearchStore } = this.props;
    return (
      <Collapse
        title={i18n._(t`Mission`)}
        used={!!(missionsSearchStore.startDate || missionsSearchStore.workType)}
      >
        <div className={classes.widgetBody}>
          <Row>
            <Col lg={6}>
              <p className={classes.widgetSubtitle}>
                <Trans>Date de début</Trans>
              </p>
            </Col>
            <Col lg={6}>
              <Validation
                errorMessage={startDateValidationMessage}
                valid={isStartDateValid}
              >
                <DatePicker
                  // value={(() => {return missionsSearchStore.startDate ? moment(missionsSearchStore.startDate).format("DD/MM/YY") : "";})()}
                  // placeholder={i18n._(t`Choisir date`)}
                  defaultDate={
                    missionsSearchStore.startDate
                      ? moment
                          .unix(missionsSearchStore.startDate / 1000)
                          .format("DD/MM/YYYY")
                      : ""
                  }
                  onChange={value => this.startDateChangeHandler(value)}
                  acceptWhenOutsideClick
                  locale={i18n._(t`fr`)}
                />
              </Validation>
            </Col>
          </Row>
          {missionsSearchStore.startDate && (
            <div>
              <p className={classes.widgetSubtitle}>
                <Trans>Dans un rayon de</Trans>
              </p>
              <ProgressBar
                min={0}
                max={12}
                step={1}
                defaultValue={0}
                unit={i18n._(t`mois`)}
                onChange={val => missionsSearchStore.changeStartDateRange(val)}
              />
            </div>
          )}
          <p className={classes.widgetSubtitle}>
            <Trans>Rythme de travail</Trans>
          </p>
          <RadioGroup
            className={classes.collapseRadioGroup}
            name="mission-type"
            onChange={val => missionsSearchStore.changeWorkType(val)}
            defaultSelected={missionsSearchStore.workType}
          >
            <Radio label={i18n._(t`À plein temps`)} value="Full Time" />
            <Radio label={i18n._(t`À temps partiel`)} value="Part Time" />
            <Radio label={i18n._(t`Indifférent`)} value="" />
          </RadioGroup>
        </div>
      </Collapse>
    );
  }
}

DateWidget.wrappedComponent.propTypes = {
  missionsSearchStore: PropTypes.PropTypes.shape({
    changeWorkType: PropTypes.func,
    changeStartDate: PropTypes.func,
    changeStartDateRange: PropTypes.func,
    startDate: PropTypes.string,
    workType: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(DateWidget);
