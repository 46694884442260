import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Mutation } from "react-apollo";
import ReCAPTCHA from "react-google-recaptcha";
import gql from "graphql-tag";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";

import {
  Row,
  Col,
  Validation,
  Button,
  FormCard,
  FormGroup,
  TextInput,
  toaster,
  Divider,
  Checkbox,
  Spinner
} from "cf-neo-ui";
import Routes from "../layout/routes/index";
import validate from "../../utils/validators";
import "./styles.scss";
import runtimeVars from "../../configs/runTimeVars";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";

const CALLED_BY_ADVISOR_MUTATION = gql`
  mutation AdvisoryCallRequest(
    $companyName: String!
    $firstName: String!
    $lastName: String!
    $phonenumber: String!
  ) {
    advisoryCallRequest(
      input: {
        firstName: $firstName
        lastName: $lastName
        company: $companyName
        phone: $phonenumber
      }
    ) {
      status
    }
  }
`;

@inject("sessionStore")
@observer
class CalledByAdvisor extends Component {
  constructor(props) {
    super(props);
    this.source = "unknown";
    this.captchaRef = null;
    this.reCaptchaCase = false;
    this.state = {
      isCompanyNameValid: true,
      isfirstNameValid: true,
      islastNameValid: true,
      isPhonenumberValid: true,
      firstNameValidationMessage: "",
      lastNameValidationMessage: "",
      phonenumberValidationMessage: "",
      companyNameValidationMessage: "",
      isReCaptchaValid: true
    };

    this.isFormValid = this.isFormValid.bind(this);
  }

  componentDidMount() {
    const { host } = window.location;
    const webDomain = host.split(".").pop();
    if (webDomain === "com") this.source = "Website FR";
    if (webDomain === "uk") this.source = "Website UK";
    window.scroll(0, 0);
    if (this.captchaRef) this.captchaRef.reset();
  }

  companyNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeCompanyName(e.target.value);
    this.validateCompanyName(e.target.value);
  };

  firstNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changefirstName(e.target.value);
    this.validatefirstName(e.target.value);
  };

  lastNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changelastName(e.target.value);
    this.validatelastName(e.target.value);
  };

  phonenumberChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changePhonenumber(e.target.value);
    this.validatePhonenumber(e.target.value);
  };

  acceptTermsChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeAcceptTerms(!sessionStore.acceptTerms);
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    this.captchaRef = null;
    this.setState({});
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  calledByAdvisorMutationCompletedHandler = () => {
    const { ConfirmCalledByAdvisor } = Routes.HbLayoutRoutes;
    const { sessionStore, history } = this.props;
    sessionStore.reset();
    history.push(ConfirmCalledByAdvisor.path);
  };

  isFormValid() {
    const { sessionStore } = this.props;
    const {
      firstName,
      lastName,
      phonenumber,
      companyName,
      acceptTerms
    } = sessionStore;
    let valid = true;
    if (!this.validateCompanyName(companyName)) valid = false;
    if (!this.validatefirstName(firstName)) valid = false;
    if (!this.validatelastName(lastName)) valid = false;
    if (!this.validatePhonenumber(phonenumber)) valid = false;
    if (!valid) return false;
    if (!this.validateAcceptTerms(acceptTerms)) valid = false;
    return valid;
  }

  validateCompanyName(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", { maxlength: 100 }, { minlength: 3 }],
      i18n
    );
    this.setState({
      isCompanyNameValid: res.isValid,
      companyNameValidationMessage: res.message
    });
    return res.isValid;
  }

  validatefirstName(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isfirstNameValid: res.isValid,
      firstNameValidationMessage: res.message
    });
    return res.isValid;
  }

  validatelastName(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      islastNameValid: res.isValid,
      lastNameValidationMessage: res.message
    });
    return res.isValid;
  }

  validatePhonenumber(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "number", { maxlength: 13 }, { minlength: 8 }],
      i18n
    );
    this.setState({
      isPhonenumberValid: res.isValid,
      phonenumberValidationMessage: res.message
    });
    return res.isValid;
  }

  validateAcceptTerms(value) {
    const { i18n } = this.props;
    if (!value) {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Veuillez accepter notre politique de confidentialité`
        )
      });
    }

    return value;
  }

  submit = mutation => {
    if (this.isFormValid()) {
      if (this.reCaptchaCase) {
        if (this.captchaRef.getValue()) mutation();
        else this.setState({ isReCaptchaValid: false });
      } else {
        mutation();
        this.reCaptchaCase = true;
      }
    }
  };

  render() {
    const { PrivacyPolicy, TermsAndConditionsOfUse } = Routes.HbLayoutRoutes;
    const { sessionStore, i18n } = this.props;
    const {
      isCompanyNameValid,
      isfirstNameValid,
      islastNameValid,
      isPhonenumberValid,
      companyNameValidationMessage,
      firstNameValidationMessage,
      lastNameValidationMessage,
      phonenumberValidationMessage,
      isReCaptchaValid
    } = this.state;

    const {
      companyName,
      firstName,
      lastName,
      phonenumber,
      acceptTerms
    } = sessionStore;

    const checkBoxLabel = (
      <Trans>
        J’accepte{" "}
        <Link to={PrivacyPolicy.path} target="_blank">
          {" "}
          la politique de confidentialités{" "}
        </Link>{" "}
        Mindquest ainsi que{" "}
        <Link to={TermsAndConditionsOfUse.path} target="_blank">
          {" "}
          les conditions générales d’utilisation{" "}
        </Link>{" "}
        .
      </Trans>
    );
    const meta = staticMetaTags(i18n).calledByAdvisor;

    return (
      <div className="session ">
        {metaTags(
          urls.calledByAdvisor(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords
        )}

        <br />
        <Row>
          <Col className="content">
            <h1 className="title">
              <Trans>
                Etre recontacté par l'un de nos expert en recrutement
              </Trans>
            </h1>
          </Col>
        </Row>
        <br />
        <FormCard className="formCard no-gutter with-padding">
          <Row>
            <Col className="content">
              <h2 className="title" style={{ fontSize: "27px" }}>
                <Trans>Entrer les informations de mon entreprise</Trans>
              </h2>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="lastName">
                  <Trans>Prénom</Trans>
                </label>
                <Validation
                  errorMessage={firstNameValidationMessage}
                  valid={isfirstNameValid}
                >
                  <TextInput
                    id="lastName"
                    type="text"
                    spellCheck="false"
                    className="form-input"
                    placeholder={i18n._(t`Prénom`)}
                    value={firstName}
                    onChange={this.firstNameChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="familyName">
                  <Trans>Nom de famille</Trans>
                </label>
                <Validation
                  errorMessage={lastNameValidationMessage}
                  valid={islastNameValid}
                >
                  <TextInput
                    id="familyName"
                    type="text"
                    spellCheck="false"
                    className="form-input"
                    placeholder={i18n._(t`Nom de famille`)}
                    value={lastName}
                    onChange={this.lastNameChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="enterprise-name">
                  <Trans>Entreprise</Trans>
                </label>
                <Validation
                  errorMessage={companyNameValidationMessage}
                  valid={isCompanyNameValid}
                >
                  <TextInput
                    id="enterprise-name"
                    className="form-input"
                    placeholder={i18n._(t`Nom de l’entreprise`)}
                    iconColor="#D3354A"
                    value={companyName}
                    onChange={this.companyNameChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="phoneNumber">
                  <Trans>Téléphone</Trans>
                </label>
                <Validation
                  errorMessage={phonenumberValidationMessage}
                  valid={isPhonenumberValid}
                >
                  <TextInput
                    id="phoneNumber"
                    autoComplete="off"
                    type="tel"
                    className="form-input"
                    placeholder="06XXXXXXXX"
                    value={phonenumber}
                    onChange={this.phonenumberChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <Divider />
          <Row className="accept_terms">
            <Col>
              <Checkbox
                name="accept_terms"
                value="accept_terms"
                checked={acceptTerms}
                label={checkBoxLabel}
                onClickButton={this.acceptTermsChangeHandler}
              />
            </Col>
          </Row>
        </FormCard>
        <br />
        {this.reCaptchaCase && (
          <ReCAPTCHA
            ref={el => {
              this.captchaRef = el;
            }}
            size="normal"
            sitekey={runtimeVars.APP_CAPTCHA_KEY}
          />
        )}
        {!isReCaptchaValid && (
          <p className="ValidationErrorMessage">
            <Trans>ReCAPCHA non valid</Trans>
          </p>
        )}
        <div className="next-buttons">
          <Mutation
            mutation={CALLED_BY_ADVISOR_MUTATION}
            variables={{ firstName, lastName, companyName, phonenumber }}
            onCompleted={() => {
              this.calledByAdvisorMutationCompletedHandler();
            }}
            onError={errors => {
              this.captchaRef.reset();
              this.reCaptchaCase = true;
              this.setState();
              errors.graphQLErrors.forEach(({ message, data }) => {
                if (data && data.isCustomError) {
                  this.onErrorHandler(message);
                }
              });
            }}
          >
            {(mutation, { loading }) => (
              <span>
                {loading ? (
                  <Button disabled>
                    <Spinner
                      type="pointed-circular"
                      color="#FFFFFF"
                      size={12}
                    />
                  </Button>
                ) : (
                  <Button
                    icon="chevron-right"
                    onClick={() => this.submit(mutation)}
                  >
                    <Trans>Soumettre</Trans>
                  </Button>
                )}
              </span>
            )}
          </Mutation>
        </div>
      </div>
    );
  }
}

CalledByAdvisor.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    reset: PropTypes.func,
    changeAcceptTerms: PropTypes.func,
    changefirstName: PropTypes.func,
    changelastName: PropTypes.func,
    changePhonenumber: PropTypes.func,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phonenumber: PropTypes.string,
    acceptTerms: PropTypes.bool,
    companyName: PropTypes.string,
    changeCompanyName: PropTypes.func,
    resetFields: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(CalledByAdvisor));
