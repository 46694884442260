import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Icon } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import routes from "../../routes";
import HeaderMenu from "./header-menu/index";
import classes from "./BlogHeader.module.scss";
import BlogCategories from "../../../../utils/BlogCategories";

class BlogHeader extends Component {
  render() {
    const { location, i18n } = this.props;
    const activeAdvices =
      location.pathname.split("/")[2] === "advices" ? classes.activeHeader : "";

    const blogCategoriesFN = BlogCategories(i18n);
    const blogCategories = Object.values(blogCategoriesFN);

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.logo}>
            <NavLink to={routes.HbfLayoutRoutes.Home.path}>
              <picture>
                <source
                  type="image/webp"
                  media="(max-width: 1040px)"
                  srcSet="/assets/images/le_club.svg"
                />
                <source
                  type="image/png"
                  media="(max-width: 1040px)"
                  srcSet="/assets/images/le_club.svg"
                />
                <source
                  type="image/webp"
                  media="(man-width: 1040px)"
                  srcSet="/assets/images/le_club.svg"
                />
                <img
                  src="/assets/images/le_club.svg"
                  alt="Mindquest"
                  className={classes.imgLogo}
                />
              </picture>
            </NavLink>
          </div>
          <div className={classes.headerItem}>
            <NavLink
              className={classes.text}
              activeClassName={classes.activeHeader}
              exact
              to={routes.BlogLayoutRoutes.Blog.path}
            >
              <Trans>Accueil</Trans>
            </NavLink>
          </div>
          {blogCategories.map(category => (
            <div>
              {category.lang.includes("fr") && (
                <div className={classes.headerItem}>
                  <NavLink
                    className={classes.text}
                    activeClassName={classes.activeHeader}
                    exact
                    to={category.url}
                  >
                    <Trans>{category.title}</Trans>
                  </NavLink>
                </div>
              )}
            </div>
          ))}
          <div className={classes.headerItem}>
            <NavLink
              className={classes.text}
              activeClassName={classes.activeHeader}
              exact
              to={routes.BlogLayoutRoutes.Search.path}
            >
              <Icon
                className={classes.searchIcon}
                type="search"
                width={23}
                height={23}
                color="#BBBABA"
              />
            </NavLink>
          </div>
          <div className={classes.barsItem}>
            <HeaderMenu />
          </div>
        </div>
      </div>
    );
  }
}

BlogHeader.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(BlogHeader));
