import { t } from "@lingui/macro";
import urls from "../../../utils/urls";

const ldJson = i18n => [
  {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "blog",
        item: urls.blog()
      },
      {
        "@type": "ListItem",
        position: 2,
        name: i18n._(t`actualité`),
        item: urls.newsBlog()
      }
    ]
  }
];

export default ldJson;
