import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { Trans, t, plural } from "@lingui/macro";
import { H1, H2, H5, Spinner } from "cf-neo-ui";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import Articles from "../blog-cards-display/BlogCardsDisplay";
import classes from "./FilterBy.module.scss";
import routes from "../../layout/routes";
import { latestPostsQuery } from "../query";
import staticPagesMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import ldJson from "../main/ldJson";

const queryString = require("query-string");

@inject("appStore")
@observer
class FilterBy extends Component {
  constructor(props) {
    super(props);
    this.filterOption = {};
    this.case();
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  case = () => {
    const { location, history } = this.props;
    const params = queryString.parse(location.search);
    if (Object.prototype.hasOwnProperty.call(params, "category")) {
      this.filterOption.categoryIds = params.category;
      return;
    }
    if (Object.prototype.hasOwnProperty.call(params, "tag")) {
      this.filterOption.tagIds = params.tag;
      return;
    }
    history.push(routes.BlogLayoutRoutes.Blog.path);
  };

  loadingDisplay = () => {
    return (
      <div className={classes.limitedBody}>
        <section>
          <H2 className={classes.heading}>
            <Trans>Application de filtre</Trans>
          </H2>
          <div className={classes.text} />
        </section>
        <section>
          <Spinner
            className={classes.spinner}
            type="pointed-circular"
            color="#c40b24"
            size={120}
          />
        </section>
      </div>
    );
  };

  zeroResults = () => {
    return (
      <div className={classes.notFound}>
        <div>
          <Trans>
            Aucun résultat pour votre filtre.
            <br />
          </Trans>
          <Link to="/blog/news" className={classes.backHome}>
            <Trans>revenir à la page d’accueil</Trans>
          </Link>
        </div>
      </div>
    );
  };

  filterName = (cats, tags) => {
    const { location, i18n } = this.props;
    const params = queryString.parse(location.search);
    if (Object.prototype.hasOwnProperty.call(params, "category")) {
      const category = cats.find(cat => cat.id === params.category);
      return category ? i18n._(t`par catégorie: ${category.name}`) : "";
    }
    if (Object.prototype.hasOwnProperty.call(params, "tag")) {
      const tag = tags.find(tg => tg.id === params.tag);
      return tag ? i18n._(t`par tag: ${tag.name}`) : "";
    }
    return "";
  };

  render() {
    const { i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    const meta =
      currentLanguage === "fr"
        ? staticPagesMetaTags(i18n).blog.main
        : staticPagesMetaTags(i18n).blogEn.main;
    return (
      <div>
        {metaTags(
          urls.blog(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          ldJson
        )}
        <Query
          query={latestPostsQuery}
          variables={{
            page: this.latestPostsPage,
            perPage: 9,
            language: currentLanguage,
            ...this.filterOption
          }}
        >
          {({ loading, error, data }) => {
            if (error)
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );
            if (loading) return this.loadingDisplay();
            if (!data.posts.length) return this.zeroResults();
            return (
              <div className={classes.limitedBody}>
                <section>
                  <H1 className={classes.heading}>
                    <Trans>Filtré</Trans>
                    {` ${this.filterName(
                      data.posts[0].categories,
                      data.posts[0].tags
                    )}`}
                  </H1>
                  <div className={classes.text}>
                    <H5 className={classes.articleHeading}>
                      <span>
                        <Trans>
                          {i18n._(
                            plural({
                              value: data.posts ? data.posts[0].total : 0,
                              one: "# Article à lire",
                              other: "# Articles à lire"
                            })
                          )}
                        </Trans>
                      </span>
                    </H5>
                  </div>
                </section>
                <section>
                  <Articles articles={data.posts} />
                </section>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

FilterBy.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

FilterBy.defaultProps = {};

export default withI18n()(withRouter(FilterBy));
