import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { detect } from "detect-browser";
import cookie from "react-cookies";
import AddToHomeScreen from "a2hs.js";
import createHost from "cross-domain-storage/host";
import withSession from "../../components/hoc/withSession";
import HbLayout from "./hbLayout";
import Hb2Layout from "./hb2Layout";
import HbfLayout from "./hbfLayout";
import LpbLayout from "./lpbLayout";
import BLayout from "./bLayout";
import Routes from "./routes/index";
import BlogLayout from "./blog-layout";
import NotFoundPage from "../../components/error-pages/NotFoundPage";
import CFGlobals from "../../configs/club-freelance-globals";
import configs from "../../configs/google-apis-configs";
import "./layout.scss";
import runtimeVars from "../../configs/runTimeVars";
import {
  cookieRemoveOpt,
  cookieSaveOpt,
  cookieSaveToken
} from "../../configs/domainConfigs";

const { CF_URL, AUTH_TOKEN } = runtimeVars;

@inject("sessionStore", "appStore")
@observer
class Layout extends Component {
  constructor(props) {
    super(props);
    this.key = 0;
  }

  componentDidMount = async () => {
    const { appStore } = this.props;
    /* if (!runtimeVars.devMode) await this.verifyConnectionCookie(); */
    window.addEventListener("resize", appStore.changeWidth, { capture: true });
    this.mountMapsScript();
    if (document.referrer)
      cookie.save("firstVisitOrigin", document.referrer, cookieSaveOpt);
    this.SafariAddToHomeScreen();
  };

  componentWillUnmount() {
    const { appStore } = this.props;
    window.removeEventListener("resize", appStore.changeWidth);
  }

  SafariAddToHomeScreen = () => {
    const { i18n } = this.props;
    const browser = detect();
    if (browser.name === "safari") {
      AddToHomeScreen({
        brandName: CFGlobals.name,
        logoImage: "/public/assets/icons/apple/icon-152.webp",
        htmlContent: i18n._(t`Ajouter Club Freelance à l’écran d’accueil`)
      });
    }
  };

  verifyConnectionCookie = async () => {
    const { sessionStore } = this.props;
    this.createHostDomain();
    if (!localStorage.getItem(AUTH_TOKEN)) {
      if (cookie.load(AUTH_TOKEN)) {
        cookie.remove(AUTH_TOKEN, cookieRemoveOpt);
        sessionStore.changeAuthToken("");
        sessionStore.reset();
      }
    } else {
      if (!cookie.load(AUTH_TOKEN)) {
        cookie.save(
          AUTH_TOKEN,
          localStorage.getItem(AUTH_TOKEN),
          cookieSaveToken
        );
      }
      sessionStore.changeAuthToken(localStorage.getItem(AUTH_TOKEN));
    }
  };

  createHostDomain = () => {
    createHost([
      {
        origin: CF_URL,
        allowedMethods: ["get", "set", "remove"]
      }
    ]);
  };

  mountMapsScript = () => {
    if (typeof document !== "undefined") {
      if (document.getElementById("google_maps_api_script")) return;
      const { appStore } = this.props;
      const script = document.createElement("script");

      const mapsUrl = `${configs.mapsURL}/js?v=${configs.v}&key=${configs.apiKey}&libraries=places`;

      script.id = "google_maps_api_script";
      script.type = "text/javascript";
      script.src = mapsUrl;
      script.async = true;
      script.defer = true;

      script.onload = () => appStore.setMapsScript();
      script.onerror = () => {};

      document.getElementById("root").appendChild(script);
    }
  };

  render() {
    const user = {};
    const { sessionStore } = this.props;
    user.logged = !!sessionStore.authToken;

    const {
      HbfLayoutRoutes,
      HbLayoutRoutes,
      Hb2LayoutRoutes,
      LpbLayoutRoutes,
      BLayoutRoutes,
      BlogLayoutRoutes
    } = Routes;
    return (
      <Switch>
        {/* <Redirect from="/" exact to="/missions" /> */}
        {/* <Redirect from="/blog" exact to="/blog/news" /> */}
        <Redirect from="/blog/advices" exact to="/blog/advices/consultant" />
        {Object.values(HbfLayoutRoutes).map(route => {
          this.key += 1;
          const { component, path } = route;
          return (
            <Route
              exact
              path={path}
              key={this.key}
              render={
                () => <HbfLayout component={component} user={user} />
                /*user.logged && path === Routes.HbfLayoutRoutes.Home.path ? (
                  <Redirect to={Routes.HbfLayoutRoutes.MissionsPage.path} />
                ) : (
                  <HbfLayout component={component} user={user} />
                )*/
              }
            />
          );
        })}
        {Object.values(HbLayoutRoutes).map(route => {
          this.key += 1;
          const { component, path, logoutRequired, type } = route;
          let userType;
          let pa;
          if (sessionStore.account) {
            userType = sessionStore.account.role;
            if (userType === "CLIENT")
              pa = LpbLayoutRoutes.DashboardCompany.path;
            else pa = LpbLayoutRoutes.Dashboard.path;
          }
          if (type) {
            return (
              <Route
                exact
                path={path}
                key={this.key}
                render={() =>
                  user.logged && userType === "CLIENT" ? (
                    <HbLayout component={component} user={user} />
                  ) : (
                    <Redirect to={HbLayoutRoutes.Login.path} />
                  )
                }
              />
            );
          }
          return (
            <Route
              exact
              path={path}
              key={this.key}
              render={() =>
                logoutRequired && user.logged ? (
                  <Redirect to={pa} />
                ) : (
                  <HbLayout component={component} user={user} />
                )
              }
            />
          );
        })}

        {Object.values(Hb2LayoutRoutes).map(route => {
          this.key += 1;

          const { component, path } = route;
          let userType;
          let pa = null;
          if (sessionStore.account) {
            userType = sessionStore.account.role;
            if (userType === "CLIENT") {
              pa = LpbLayoutRoutes.DashboardCompany.path;
            } else {
              if (sessionStore.account.isCompleted) {
                pa = LpbLayoutRoutes.Dashboard.path;
              }
              if (
                !sessionStore.account.isVerified &&
                path !== "/signup-confirm-code"
              ) {
                pa = Hb2LayoutRoutes.ConfirmCode.path;
              } else if (sessionStore.account.isVerified) {
                if (path == "/signup-confirm-code")
                  pa = Hb2LayoutRoutes.MyProfile.path;
              }
            }
          } else {
            pa = Routes.HbfLayoutRoutes.MissionsPage.path;
          }

          return (
            <Route
              exact
              path={path}
              key={this.key}
              render={() =>
                pa ? (
                  <Redirect to={pa} />
                ) : (
                  <Hb2Layout component={component} user={user} />
                )
              }
            />
          );
        })}

        {Object.values(LpbLayoutRoutes).map(route => {
          this.key += 1;
          const { component, path, dedicatedFor } = route;

          let userType;
          if (sessionStore.account) {
            userType = sessionStore.account.role;
          }
          return (
            <Route
              exact
              path={path}
              key={this.key}
              render={() => {
                if (user.logged) {
                  if (sessionStore.account.isCompleted) {
                    if (dedicatedFor.includes(userType)) {
                      return <LpbLayout component={component} user={user} />;
                    }
                    return <Redirect to={HbLayoutRoutes.Login.path} />;
                  } else {
                    return <Redirect to={sessionStore.account.path} />;
                  }
                }
                return <Redirect to={HbLayoutRoutes.Login.path} />;
              }}
            />
          );
        })}
        {Object.values(BLayoutRoutes).map(route => {
          this.key += 1;
          const { component, path } = route;
          return (
            <Route
              exact
              path={path}
              key={this.key}
              render={() => <BLayout component={component} user={user} />}
            />
          );
        })}
        {Object.values(BlogLayoutRoutes).map(route => {
          this.key += 1;
          const { component, path } = route;
          return (
            <Route
              exact
              path={path}
              key={this.key}
              render={() => <BlogLayout component={component} />}
            />
          );
        })}
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

Layout.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    account: PropTypes.shape({}),
    changeAuthToken: PropTypes.func,
    reset: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    setMapsScript: PropTypes.func,
    changeWidth: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(withSession(Layout)));
