import Carousel from "react-multi-carousel";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Col, ExpertiseCard, ExpertiseCardV2, Row } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import Fade from "react-reveal/Fade";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import classes from "./ExpertisesSection1.module.scss";

@inject("missionsSearchStore", "appStore")
@observer
class Result extends Component {
  key = 0;

  key2 = 0;

  createId = categoryName => {
    const diff = categoryName.split(" ");
    // .reduce((a, c) => (c[0].match(/[&\\/+-]/) ? a : a + c[0]), "");
    return `cta_hf_sec4_offers_${diff}`;
  };

  toDetails = val => {
    const { history, missionsSearchStore } = this.props;
    missionsSearchStore.addCategory(val);
    missionsSearchStore.setCategoryOptions();
    history.push(`/missions`);
  };

  getImage = id => {
    return `/assets/images/categories/${id}.webp`;
  };

  render() {
    let te = "";
    const { appStore } = this.props;

    let n = 0;
    if (appStore.width >= 768 && appStore.width < 1024) n = 9;
    if (appStore.width >= 1024) n = 12;

    const myFunction = () => {
      const { i18n, categories } = this.props;
      const table = [];
      let i = 0;
      if (appStore.width >= 1024) {
        categories.forEach(category => {
          if ((i - 2) % 8 === 0) {
            table.push(category);
            table.push(null, null);
            i += 3;
          } else {
            table.push(category);
            i += 1;
          }
        });
      }
      if (appStore.width >= 768 && appStore.width < 1024) {
        categories.forEach(category => {
          if ((i - 1) % 6 === 0) {
            table.push(category);
            table.push(null, null);
            i += 3;
          } else {
            table.push(category);
            i += 1;
          }
        });
      }

      return (
        <div>
          {appStore.width < 768 ? (
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={false}
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={false}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              responsive={{
                mobile: {
                  breakpoint: {
                    max: 535,
                    min: 0
                  },
                  items: 1,
                  paritialVisibilityGutter: 30
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 536
                  },
                  items: 2,
                  paritialVisibilityGutter: 30
                }
              }}
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {categories.map(category => {
                this.key += 1;
                return (
                  <div key={this.key}>
                    <ExpertiseCard
                      image={this.getImage(category.id)}
                      field={category.name}
                      text={`${te.substring(0, 37)}...`}
                      // text={i18n._(t`Front end, Back end,API…`)}
                      buttonText={i18n._(t`Voir les offres`)}
                      onClickButton={() => this.toDetails(category.name)}
                      buttonId={this.createId(category.name)}
                    />
                  </div>
                );
              })}
            </Carousel>
          ) : (
            <Row className={classes.row}>
              {table.map((category, index) => {
                this.key2 += 1;
                if (index < n) {
                  if (category) {
                    return (
                      <Col
                        key={this.key2}
                        xs={3}
                        sm={4}
                        md={3}
                        lg={3}
                        classeName={classes.space}
                      >
                        {category.skills.forEach(skill => {
                          te = `${te + skill.name}, `;
                        })}
                        <div className={classes.space}>
                          {appStore.width < 1441 ? (
                            <Fade cascade>
                              <ExpertiseCardV2
                                image={this.getImage(category.id)}
                                field={category.name}
                                text={`${te.substring(0, 37)}...`}
                                // text={i18n._(t`Front end, Back end,API…`)}
                                buttonText={i18n._(t`Voir les offres`)}
                                onClickButton={() =>
                                  this.toDetails(category.name)
                                }
                                buttonId={this.createId(category.name)}
                              />
                            </Fade>
                          ) : (
                            <Fade cascade>
                              <ExpertiseCard
                                image={this.getImage(category.id)}
                                field={category.name}
                                text={`${te.substring(0, 37)}...`}
                                // text={i18n._(t`Front end, Back end,API…`)}
                                buttonText={i18n._(t`Voir les offres`)}
                                onClickButton={() =>
                                  this.toDetails(category.name)
                                }
                                buttonId={this.createId(category.name)}
                              />
                            </Fade>
                          )}
                        </div>
                        {(te = "")}
                      </Col>
                    );
                  }

                  return <Col key={this.key2} xs={4} sm={4} md={3} lg={3} />;
                }

                if (appStore.clicked === true) {
                  if (category) {
                    return (
                      <Col
                        key={this.key2}
                        xs={4}
                        sm={4}
                        md={3}
                        lg={3}
                        classeName={classes.space}
                      >
                        {category.skills.forEach(skill => {
                          te = `${te + skill.name}, `;
                        })}
                        <div className={classes.space}>
                          {appStore.width < 1441 ? (
                            <Fade cascade>
                              <ExpertiseCardV2
                                image={this.getImage(category.id)}
                                field={category.name}
                                text={`${te.substring(0, 37)}...`}
                                // text={i18n._(t`Front end, Back end,API…`)}
                                buttonText={i18n._(t`Voir les offres`)}
                                onClickButton={() =>
                                  this.toDetails(category.name)
                                }
                                buttonId={this.createId(category.name)}
                              />
                            </Fade>
                          ) : (
                            <Fade cascade>
                              <ExpertiseCard
                                image={this.getImage(category.id)}
                                field={category.name}
                                text={`${te.substring(0, 37)}...`}
                                // text={i18n._(t`Front end, Back end,API…`)}
                                buttonText={i18n._(t`Voir les offres`)}
                                onClickButton={() =>
                                  this.toDetails(category.name)
                                }
                                buttonId={this.createId(category.name)}
                              />
                            </Fade>
                          )}
                        </div>
                        {(te = "")}
                      </Col>
                    );
                  }

                  return <Col key={this.key2} xs={4} sm={4} md={3} lg={3} />;
                }
              })}
            </Row>
          )}
        </div>
      );
    };

    return myFunction();
  }
}

Result.wrappedComponent.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  missionsSearchStore: PropTypes.shape({
    addCategory: PropTypes.func,
    setCategoryOptions: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    clicked: PropTypes.bool
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(Result));
